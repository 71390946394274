import {useFormik} from 'formik';
import React from 'react';
import {isMobile, isTablet} from 'react-device-detect';
import {useNavigate} from 'react-router-dom';
import * as Yup from 'yup';
import '../../App.css';
import {identify, track} from '../../api/analytics';
import {dateToTimestamp} from '../../api/dates';
import {
  FormikTextfield,
  MultilineFormikTextfield,
} from '../../components/inputs/textfields';
import {AuthHeader} from '../../components/layout/layout';
import {useAuth} from '../../hooks/use-auth';
import {useMounted} from '../../hooks/use-mounted';

const CreateProfile = ({}) => {
  const navigate = useNavigate();
  const isMounted = useMounted();

  const {
    state: {id, profile},
    updateProfile,
  } = useAuth();

  const mobile = isMobile || isTablet;

  const formik = useFormik({
    initialValues: {
      first_name: profile?.first_name ?? '',
      last_name: profile?.last_name ?? '',
      position: profile?.position ?? '',
      phone: profile?.phone ?? '',
      bio: profile?.bio ?? '',
      properties: profile?.properties ?? [],
    },
    validationSchema: Yup.object({
      first_name: Yup.string().trim(),
      last_name: Yup.string().trim(),
      position: Yup.string().trim(),
      phone: Yup.number('Phone number must be a number.'),
    }),
    onSubmit: async (values, helpers) => {
      try {
        const {first_name, last_name, position, bio, phone, properties} =
          values;

        const status =
          profile?.status === 'complete' ? 'complete' : 'in_progress';
        const update = {
          id,
          first_name,
          last_name,
          position,
          bio,
          phone,
          status,
          properties,
          post_created: dateToTimestamp(),
        };
        const {success, error} = await updateProfile(update);

        if (success) {
          if (error) {
            navigate('/home');
          }

          // HANDLE TRACKING
          track('create_profile', update);
          const {organization_ids, createdAt = ''} = profile;
          identify(id, {
            $email: id,
            $first_name: first_name,
            $last_name: last_name,
            $created: createdAt,
            aws_sub: id,
            organization_ids,
            phone,
            position,
            bio,
            status,
          });
        }
        if (error) {
          helpers.setStatus({success: false});
          helpers.setErrors({submit: error});
          helpers.setSubmitting(false);
        }
      } catch (err) {
        if (isMounted()) {
          helpers.setStatus({success: false});
          helpers.setErrors({submit: err.message});
          helpers.setSubmitting(false);
        }
      }
    },
  });

  return (
    <>
      <div className="container">
        <br />
        <AuthHeader
          header="Welcome to the Network"
          subheader={'Introduce yourself to the group.'}
          action={true}
        />
        <form className={mobile ? 'mobile-form' : ''}>
          <div className="grid-container">
            <div className="grid-6">
              <div className="padding-h8">
                <FormikTextfield
                  formik={formik}
                  header="First Name"
                  placeholder=""
                  name="first_name"
                  type="text"
                />
              </div>
            </div>
            <div className="grid-6">
              <div className="padding-h8">
                <FormikTextfield
                  formik={formik}
                  header="Last Name"
                  placeholder=""
                  name="last_name"
                  type="text"
                />
              </div>
            </div>
          </div>

          <div className="padding-h8 padding-bottom16">
            <FormikTextfield
              formik={formik}
              header="Job Title"
              placeholder=""
              name="position"
              type="text"
            />
            <MultilineFormikTextfield
              formik={formik}
              header="Bio"
              placeholder=""
              name="bio"
              type="text"
            />
          </div>
          {formik.errors.submit && (
            <p className="error">{formik.errors.submit}</p>
          )}
        </form>
      </div>

      <div className="button padding-top16">
        <button
          type="button"
          className="button-container"
          onClick={formik.handleSubmit}
          disabled={formik.isSubmitting}>
          CONTINUE
        </button>
      </div>
    </>
  );
};

export default CreateProfile;
