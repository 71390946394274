import React, {useCallback, useMemo, useState} from 'react';
import {RxChevronRight, RxDotsHorizontal} from 'react-icons/rx';
import {useNavigate} from 'react-router-dom';
import {useThreads} from '../../../hooks/use-threads';
import './ThreadHierarchy.css';

export const ThreadHierarchy = React.memo(({hierarchy}) => {
  const navigate = useNavigate();
  const {
    state: {threads},
  } = useThreads();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleDropdown = useCallback(() => {
    setIsDropdownOpen(prev => !prev);
  }, []);

  const hierarchyData = useMemo(() => {
    if (!Array.isArray(hierarchy) || hierarchy.length === 0) {
      return {
        firstItem: null,
        lastItems: [],
        dropdownItems: [],
        currentThread: null,
      };
    }

    const contentIdMap = new Map();
    const filteredHierarchy = hierarchy.reduce((acc, item) => {
      if (
        item.type === 'thread' ||
        item.type === 'sub-thread' ||
        item.type === 'direct'
      ) {
        acc.push(item);
      } else if (
        item.type === 'comment' ||
        item.type === 'milestone' ||
        item.type === 'task' ||
        item.type === 'poll' ||
        item.type === 'decision_point' ||
        item.type === 'question'
      ) {
        contentIdMap.set(item.thread_id, item.id);
      }
      return acc;
    }, []);

    const hierarchyWithContentIds = filteredHierarchy.map(item => ({
      ...item,
      content_id: contentIdMap.get(item.id) || null,
    }));

    let firstItem, lastItems, dropdownItems;

    if (hierarchyWithContentIds.length === 1) {
      firstItem = hierarchyWithContentIds[0];
      lastItems = [];
      dropdownItems = [];
    } else if (hierarchyWithContentIds.length === 2) {
      firstItem = hierarchyWithContentIds[0];
      lastItems = [hierarchyWithContentIds[1]];
      dropdownItems = [];
    } else {
      firstItem = hierarchyWithContentIds[0];
      lastItems = hierarchyWithContentIds.slice(-2);
      dropdownItems = hierarchyWithContentIds.slice(1, -2);
    }

    const currentThread =
      hierarchyWithContentIds[hierarchyWithContentIds.length - 1];

    return {firstItem, lastItems, dropdownItems, currentThread};
  }, [hierarchy]);

  const {firstItem, lastItems, dropdownItems, currentThread} = hierarchyData;

  const handleItemClick = useCallback(
    item => {
      if (!item) return;
      if (item.content_id) {
        navigate(`/threads/${item.id}/${item.content_id}`);
      } else {
        navigate(`/threads/${item.id}`);
      }
      setIsDropdownOpen(false);
    },
    [navigate],
  );

  const renderBreadcrumbItem = useCallback(
    (item, index, isLast) => (
      <li key={item.id || index}>
        {index > 0 && (
          <RxChevronRight
            className="thread-hierarchy__chevron"
            aria-hidden="true"
          />
        )}
        {!isLast && (
          <button
            className={`thread-hierarchy__breadcrumb-item ${
              isLast ? 'thread-hierarchy__breadcrumb-item--current' : ''
            }`}
            onClick={() => handleItemClick(item)}
            disabled={isLast}>
            {item.logo} {item.title}
          </button>
        )}
      </li>
    ),
    [handleItemClick],
  );

  const renderDropdownItems = useMemo(() => {
    return dropdownItems.map((item, index) => (
      <button
        key={item.id || index}
        className="thread-hierarchy__dropdown-item"
        onClick={() => handleItemClick(item)}>
        <span className="thread-hierarchy__dropdown-item-thread">
          {item.logo} {item.title || 'Untitled Thread'}
        </span>
      </button>
    ));
  }, [dropdownItems, handleItemClick]);

  if (!firstItem || currentThread === firstItem) {
    return null;
  }

  return (
    <nav className="thread-hierarchy" aria-label="Thread hierarchy">
      <ol className="thread-hierarchy__breadcrumb">
        {renderBreadcrumbItem(firstItem, 0, false)}
        {dropdownItems.length > 0 && (
          <li className="thread-hierarchy__dropdown">
            <RxChevronRight
              className="thread-hierarchy__chevron"
              aria-hidden="true"
            />
            <button
              onClick={toggleDropdown}
              className="thread-hierarchy__dropdown-toggle"
              aria-haspopup="true"
              aria-expanded={isDropdownOpen}>
              <RxDotsHorizontal aria-hidden="true" />
              <span className="visually-hidden">More items</span>
            </button>
            {isDropdownOpen && (
              <div className="thread-hierarchy__dropdown-menu" role="menu">
                {renderDropdownItems}
              </div>
            )}
          </li>
        )}
        {lastItems?.length >= 1 &&
          lastItems.map((item, index) => {
            return renderBreadcrumbItem(
              item,
              index + 1,
              index === lastItems.length - 1,
            );
          })}
      </ol>
    </nav>
  );
});
