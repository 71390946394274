import React, {useState} from 'react';
import {AuthHeader} from '../../components/layout/layout';
import {useAuth} from '../../hooks/use-auth';
const Pending = ({}) => {
  const {
    state: {profile, organizations},
  } = useAuth();

  const [org, setOrg] = useState(null);

  return (
    <div className="flex-column page-container justify-center align-center">
      <AuthHeader header="" subheader="" />
      <div className="card half-width">
        <h1>Checking if {org} has accepted your invite?</h1>
        <br />
        <h1>You will get an email once it occurs.</h1>
      </div>
      <br />
      <br />
      <p className="text-secondary">
        <strong> Need help now?</strong> Reach out to us via the black circle in
        the bottom right of your screen
      </p>
    </div>
  );
};

export default Pending;
