import React, {useEffect, useState} from 'react';
import {isMobile, isTablet} from 'react-device-detect';
import {FaHouse} from 'react-icons/fa6';
import {MdLogout} from 'react-icons/md';
import {RxPaperPlane} from 'react-icons/rx';
import QRCode from 'react-qr-code';
import {Outlet, useNavigate} from 'react-router-dom';
import '../../App.css';
import {useAuth} from '../../hooks/use-auth';
import useLogout from '../../hooks/use-logout';
import useSearchParameters from '../../hooks/use-search-parameters';
import {LinkButton} from '../buttons/buttons';
import {PublicMindLogoLightNoText} from '../images/logos';
import {NetworkBackground} from '../loading/network-background';
import {Modal} from '../modal/modal';
import HorizontalSidebar from './horizontal-sidebar';
import './styles.css';

export const PublicLayout = props => {
  const {children} = props;

  return (
    <div className="base-container align-center">
      <div className="public-layout">{children || <Outlet />}</div>
      <NetworkBackground />
      <Footer />
    </div>
  );
};

export const AuthLayout = props => {
  const {children} = props;
  const [darkMode, setDarkMode] = useState(
    window.matchMedia &&
      window.matchMedia('(prefers-color-scheme: dark)').matches,
  );
  useEffect(() => {
    document.documentElement.setAttribute(
      'data-theme',
      darkMode ? 'dark' : 'light',
    );
  }, [darkMode]);
  return (
    <div className="base-container auth-layout">
      {children || <Outlet />}
      <NetworkBackground />
      <Footer />
    </div>
  );
};

export const FeedLayout = props => {
  const {children} = props;

  return (
    <>
      <div className="base-container">
        <HorizontalSidebar />
        <div className={'feed-layout'}>{children || <Outlet />}</div>
      </div>
    </>
  );
};

const Footer = () => {
  const navigate = useNavigate();
  const mobile = isMobile || isTablet;

  return (
    <div className={mobile ? '' : 'footer-container'}>
      <p className="footer-text text-secondary">
        © 2024 by PublicMind {process.env.REACT_APP_VERSION} <br />
      </p>
      {/* <p
        className="text-10 text-secondary text-underline cursor-pointer"
        onClick={() => {
          navigate('/terms-of-service');
        }}>
        Terms of Service
      </p>
      <p
        className="text-10 text-secondary text-underline cursor-pointer"
        onClick={() => {
          navigate('/privacy-policy');
        }}>
        Privacy Policy
      </p> */}
    </div>
  );
};

export const AuthHeader = ({header, subheader, action}) => {
  const logout = useLogout();

  const mobile = isMobile;

  return (
    <div className="container">
      {action && (
        <LinkButton onClick={logout} className="top-left">
          Log Out
        </LinkButton>
      )}
      <div className="flex-column align-center">
        <div className={mobile ? 'width-80' : 'width-66'}>
          <br />
          <div className="flex-column align-center">
            <PublicMindLogoLightNoText />
            {header && <h3 className="padding-top8">{header}</h3>}
            {subheader && (
              <p className="text-secondary padding-bottom16">{subheader}</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export const MobileLayout = ({children}) => {
  const {createReferralLink} = useSearchParameters();
  const logout = useLogout();
  const navigate = useNavigate();

  const {
    state: {id, current_organization, profile},
  } = useAuth();

  const [share, setShare] = useState(false);
  const [link, setLink] = useState('');

  useEffect(() => {
    const link = createReferralLink();
    setLink(link);
  }, [id, current_organization]);

  return (
    <>
      <div className="base-container">
        <div
          className="flex-row justify-between align-center"
          style={{marginBottom: '32px'}}>
          <div className="flex-row align-center">
            <button
              className="button-container-mobile"
              onClick={() => {
                navigate('/home');
              }}>
              <FaHouse />
            </button>
            <button
              className="invite-external-button-mobile"
              onClick={() => {
                setShare(!share);
              }}>
              <RxPaperPlane />
            </button>
          </div>
          <LinkButton onClick={logout}>
            <MdLogout size={'24px'} />
          </LinkButton>
        </div>
        {children || <Outlet />}
        <Footer />
      </div>
      <Modal active={share} setActive={setShare}>
        <p className="text-bold">Invite Members to the platform</p>
        <br />
        <div
          style={{
            height: 'auto',
            margin: '0 auto',
            width: '60%',
          }}>
          <QRCode
            size={256}
            style={{height: 'auto', maxWidth: '100%', width: '100%'}}
            value={link}
            viewBox={`0 0 256 256`}
          />
        </div>
        <br />
      </Modal>
    </>
  );
};
