import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {MdRssFeed} from 'react-icons/md';
import {
  RxBell,
  RxCheckCircled,
  RxChevronRight,
  RxClock,
  RxEnter,
  RxInfoCircled,
  RxLockClosed,
  RxLockOpen1,
  RxLockOpen2,
} from 'react-icons/rx';
import {useNavigate} from 'react-router-dom';
import {v4} from 'uuid';
import {track} from '../../../api/analytics';
import {dateToTimestamp, timeSince} from '../../../api/dates';
import constants from '../../../components/constants';
import {
  FileViewerSimple,
  ProfileImage,
} from '../../../components/inputs/uploader';
import {SpinningIndicator} from '../../../components/loading/loading-indicator';
import {useAuth} from '../../../hooks/use-auth';
import useCrypto from '../../../hooks/use-crypto';
import useLoader from '../../../hooks/use-loader';
import useStringFormatter from '../../../hooks/use-string-formatter';
import {useThreads} from '../../../hooks/use-threads';
import ContentDisplay from '../components/content-display';
import {OfficialPostProfile} from '../components/thread-comment';
import '../ThreadStyles.css';
import {DirectMessages} from './direct-messages';
import './ThreadHome.css';

const getHourBuckets = () => {
  const now = new Date();
  const buckets = [];

  for (let i = 23; i >= 0; i--) {
    const bucketDate = new Date(now);
    bucketDate.setHours(now.getHours() - i, 0, 0, 0);

    const nextHour = new Date(bucketDate);
    nextHour.setHours(bucketDate.getHours() + 1);

    buckets.push({
      startTimestamp: dateToTimestamp(bucketDate),
      endTimestamp: dateToTimestamp(nextHour),
      hour: bucketDate.getHours(),
      count: 0,
    });
  }
  return buckets;
};

const ActivityTimeline = ({content}) => {
  const timelineBuckets = useMemo(() => {
    const buckets = getHourBuckets();
    content?.forEach(item => {
      const bucket = buckets.find(
        b => item.created >= b.startTimestamp && item.created < b.endTimestamp,
      );
      if (bucket) bucket.count++;
    });

    const maxCount = Math.max(...buckets.map(b => b.count));
    return buckets.map(bucket => ({
      hour: bucket.hour,
      count: bucket.count,
      label: `${bucket.hour % 12 || 12}${bucket.hour < 12 ? 'am' : 'pm'}`,
      height: bucket.count
        ? Math.max(15, Math.min((bucket.count / maxCount) * 100, 100))
        : 0,
    }));
  }, [content]);

  const labelHours = [0, 6, 12, 18];
  const currentHour = new Date().getHours();
  const labelPositions = labelHours.map(hour => {
    const bucketIndex = (24 - currentHour + hour) % 24;
    return (
      timelineBuckets[bucketIndex]?.label ||
      `${hour % 12 || 12}${hour < 12 ? 'am' : 'pm'}`
    );
  });

  return (
    <div className="home-timeline">
      <div className="home-timeline-hours">
        {timelineBuckets.map((bucket, i) => (
          <div
            key={i}
            className="home-timeline-bar"
            style={{height: `${bucket.height}%`}}>
            <div className="home-timeline-tooltip">
              {bucket.count} update{bucket.count !== 1 ? 's' : ''} at{' '}
              {bucket.label}
            </div>
          </div>
        ))}
      </div>
      <div className="home-timeline-labels">
        {labelPositions.map((label, i) => (
          <span key={i}>{label}</span>
        ))}
      </div>
    </div>
  );
};

const ThreadBreakdown = ({threads, setIndex, setCurrent, setContent}) => {
  const navigate = useNavigate();

  return (
    <div className="home-breakdown">
      {threads.map((thread, i) => (
        <div key={i} className="home-breakdown-item">
          <div
            className="home-breakdown-title"
            onClick={() => {
              let threadId = thread?.id;
              setIndex('thread-detail');
              setCurrent(threadId);
              navigate(`/threads/${threadId}`);
            }}>
            {thread.logo} {thread.title}
          </div>
          <div className="home-breakdown-bar-container">
            <div
              className="home-breakdown-bar"
              style={{width: `${thread.percentage}%`}}
            />
            <p className="text-14">{thread.count} new</p>
          </div>
        </div>
      ))}
    </div>
  );
};

const UnreadStats = ({unreadCount, totalThreads}) => (
  <div className="home-unread-stats">
    <div
      className="home-unread-circle"
      style={{
        '--size': `${Math.min((unreadCount / totalThreads) * 100, 100)}%`,
      }}>
      <div className="home-unread-count">{unreadCount}</div>
      <div className="home-unread-label">Unread</div>
    </div>
  </div>
);

const HomeMentionItem = ({item, thread, onClick, isUnread = true}) => {
  const navigate = useNavigate();
  const {prettyName} = useStringFormatter();
  const {loadProfiles} = useLoader();
  const {encryptString} = useCrypto();
  const {
    state: {profiles},
  } = useAuth();

  const {owner_id, content, created, media, version} = item || {};

  // Add check for assistant/official post
  const user =
    owner_id === 'assistant'
      ? OfficialPostProfile()
      : profiles?.[owner_id] ?? {};

  const profile_image = user?.profile_image;
  const {title: threadTitle, logo, type} = thread || {};
  let title = type !== 'direct' ? logo + ' ' + threadTitle : 'Direct Message';

  useEffect(() => {
    // Only load profile if it's not an assistant post and profile isn't loaded
    if (owner_id && owner_id !== 'assistant' && !profiles?.[owner_id]) {
      loadProfiles([owner_id]);
    }
  }, [owner_id, profiles, loadProfiles]);

  const navigateToProfile = e => {
    e.stopPropagation();
    // Only navigate if not an assistant post
    if (owner_id !== 'assistant') {
      const parsed = encryptString(owner_id);
      navigate(`/feed/profiles/detail/${parsed}`);
    }
  };

  return (
    <div className={`home-mention-item ${isUnread ? 'unread' : ''}`}>
      <div className="home-mention-header">
        <div
          className="home-mention-avatar"
          onClick={navigateToProfile}
          style={owner_id === 'assistant' ? {cursor: 'default'} : {}}>
          <ProfileImage
            data={profile_image}
            style={{
              height: '32px',
              width: '32px',
              ...(owner_id === 'assistant' &&
                {
                  // Uncomment if you want special styling for assistant
                  // padding: '4px',
                  // backgroundColor: 'var(--background)',
                  // border: '1px solid var(--primary-button)',
                }),
            }}
          />
        </div>
        <div className="home-mention-info">
          <div className="home-mention-title">
            <span className="home-mention-name">{prettyName(user)}</span>
            <span className="home-mention-separator">in</span>
            <span className="home-mention-thread">{title}</span>
          </div>
          <div className="home-mention-metadata">
            <span className="home-mention-time">
              <RxClock />
              {timeSince(created)}
            </span>
          </div>
        </div>
      </div>
      <div className="home-mention-content">
        <ContentDisplay item={item} />
      </div>
      {media?.length > 0 && (
        <div className="home-mention-media">
          <FileViewerSimple files={media} />
        </div>
      )}
      <div className="home-mention-footer">
        <div className="home-mention-action" onClick={onClick}>
          <span>{type === 'direct' ? 'View message' : 'View thread'}</span>
          <RxChevronRight />
        </div>
      </div>
    </div>
  );
};

const ThreadHomeGrid = ({threadIds, setCurrent, setContent, setIndex}) => {
  const navigate = useNavigate();
  const {prettyName, prettyPosition, prettyTag} = useStringFormatter();
  const {loadProfiles, loadOrgs} = useLoader();
  const {encryptString} = useCrypto();

  const {
    state: {id: userId, profile, profiles, organizations},
  } = useAuth();
  const {
    state: {threads, thread_ids, thread_token},
    updateThread,
    defaultUpdate,
  } = useThreads();

  const handleJoin = async thread => {
    const {id, permissions} = thread;
    const now = dateToTimestamp();
    const updated_permissions = [
      ...permissions,
      {user_id: userId, role: 'collaborator', created: now, updated: now},
    ];
    const updated_members = updated_permissions.map(item => item.user_id);

    await updateThread({
      id,
      permissions: updated_permissions,
      members: updated_members,
    });

    // Update local thread list
    const new_threads = [...thread_ids];
    if (!new_threads?.includes(id)) {
      new_threads.push(id);
    }
    defaultUpdate({thread_ids: new_threads});

    setIndex('thread-detail');
    setCurrent(id);
    navigate(`/threads/${id}`);
  };

  useEffect(() => {
    // Load owner profiles and their organizations
    threadIds?.forEach(id => {
      const thread = threads[id];
      if (thread?.owner_id && !profiles?.[thread.owner_id]) {
        loadProfiles([thread.owner_id]);
      } else {
        const owner = profiles?.[thread.owner_id];
        const org_ids = owner?.organization_ids || [];
        if (owner && org_ids.length && !organizations?.[org_ids[0]]) {
          loadOrgs(org_ids);
        }
      }
    });
  }, [threadIds, threads, profiles, organizations]);
  const navigateToDiscover = () => {
    navigate(`/threads`, {
      state: {tab: 'feed'},
    });
  };
  return (
    <div className="home-discover-grid">
      {threadIds?.slice(0, 9).map(id => {
        const thread = threads[id] || {};
        const {
          title,
          content,
          security_level,
          custom_logo,
          logo,
          owner_id,
          members,
          permissions,
          tags = [],
        } = thread;

        const owner = profiles?.[owner_id] || {};
        const {position, profile_image, organization_ids} = owner;
        const pretty_name = prettyName(owner);
        const org_id = organization_ids?.[0];
        const pretty_position = prettyPosition(organizations?.[org_id]);
        const isPublicMindSponsored = owner_id?.includes('@publicmind.ai');

        const active_member = members?.includes(userId);
        const joinable = security_level === 'open' && !active_member;

        return (
          <div
            key={id}
            className={`home-grid-item ${
              isPublicMindSponsored ? 'home-grid-item--sponsored' : ''
            }`}>
            <div className="home-grid-item__header">
              {isPublicMindSponsored ? (
                <div className="home-grid-item__sponsored">
                  <RxInfoCircled /> PublicMind Official
                </div>
              ) : (
                <div className="home-grid-item__owner-info">
                  <ProfileImage
                    data={profile_image}
                    style={{height: '28px', width: '28px'}}
                    onClick={() => {
                      const parsed = encryptString(owner_id);
                      navigate(`/feed/profiles/detail/${parsed}`);
                    }}
                  />
                  <div className="home-grid-item__owner-details">
                    <p className="home-grid-item__owner-name">{pretty_name}</p>
                    <p className="home-grid-item__owner-position">
                      {position}
                      {pretty_position ? ', ' + pretty_position : null}
                    </p>
                  </div>
                </div>
              )}
              {security_level === 'secure' ? (
                <RxLockClosed
                  className="security-icon-small secure"
                  // onClick={() => setIsInfoPopupOpen(true)}
                />
              ) : security_level === 'private' ? (
                <RxLockOpen1
                  className="security-icon-small private"
                  // onClick={() => setIsInfoPopupOpen(true)}
                />
              ) : (
                <RxLockOpen2
                  className="security-icon-small open"
                  // onClick={() => setIsInfoPopupOpen(true)}
                />
              )}
            </div>

            <div className="home-grid-item__title">
              {custom_logo && (
                <img
                  src={custom_logo?.url}
                  alt={`${title} Logo`}
                  className="home-grid-item__logo"
                />
              )}
              <h3 className="home-grid-item__title-text">
                {logo} {title}
              </h3>
            </div>

            <p className="home-grid-item__content">
              {content?.length > 100 ? content.slice(0, 100) + '...' : content}
            </p>
            {tags && tags.length > 0 && (
              <div className="home-grid-item__tags">
                {tags.slice(0, 8).map(tag => (
                  <span key={tag} className="feed-item__tag">
                    {prettyTag(tag)}
                  </span>
                ))}
                {tags.length > 12 && (
                  <span className="feed-item__tag feed-item__tag--more">
                    +{tags.length - 12} more
                  </span>
                )}
              </div>
            )}
            <div className="home-grid-item__actions">
              {active_member ? (
                <button
                  className="home-grid-item__button home-grid-item__button--enter"
                  onClick={() => {
                    setIndex('thread-detail');
                    setCurrent(id);
                    navigate(`/threads/${id}`);
                  }}>
                  <RxEnter /> Enter
                </button>
              ) : security_level === 'private' ? (
                <button
                  className="home-grid-item__button home-grid-item__button--request"
                  onClick={() => navigate(`/threads/request/${id}`)}>
                  Request to Join
                </button>
              ) : joinable ? (
                <button
                  className="home-grid-item__button home-grid-item__button--join"
                  onClick={() => handleJoin(thread)}>
                  Join
                </button>
              ) : null}
            </div>
          </div>
        );
      })}
      <button className="basic-button" onClick={navigateToDiscover}>
        Discover More Threads
      </button>
    </div>
  );
};

// Local storage key for tracking last seen timestamp
const LAST_SEEN_KEY = 'thread_last_seen';

// Helper functions for last seen tracking
const getLastSeen = userId => {
  try {
    const stored = localStorage.getItem(`${LAST_SEEN_KEY}_${userId}`);
    return stored ? JSON.parse(stored) : {};
  } catch (err) {
    console.error('Error reading last seen from localStorage:', err);
    return {};
  }
};

const setLastSeen = (userId, updates) => {
  try {
    const current = getLastSeen(userId);
    localStorage.setItem(
      `${LAST_SEEN_KEY}_${userId}`,
      JSON.stringify({...current, ...updates}),
    );
  } catch (err) {
    console.error('Error setting last seen in localStorage:', err);
  }
};

// Component for mark all as read button
const MarkAllReadButton = ({onMarkAllRead, unreadCount}) => {
  const [loading, setLoading] = useState(false);

  const handleClick = async () => {
    setLoading(true);
    await onMarkAllRead();
    setLoading(false);
  };

  if (!unreadCount) return null;

  return (
    <button
      className="mark-all-read-button"
      onClick={handleClick}
      disabled={loading}>
      {loading ? (
        <span className="loading-spinner" />
      ) : (
        <>
          <RxCheckCircled />
          Mark all as read
        </>
      )}
    </button>
  );
};

const ThreadsHome = ({setCurrent, setIndex, setContent}) => {
  const {
    state: {id: currentUserId, profile},
  } = useAuth();

  const {
    state: {
      thread_search,
      threads,
      thread_token,
      thread_ids,
      thread_subs,
      thread_content,
      thread_filter,
      threads_search_loaded,
    },
    searchThreads,
    updateThreadSubscription,
    createThreadSubscription,
  } = useThreads();

  const [unreadPosts, setUnread] = useState([]);

  const [loadingUpdates, setLoadingUpdates] = useState(true);
  const [loadingDiscover, setLoadingDiscover] = useState(false);

  const [showDM, setShowDMs] = useState(false);
  const [directID, setDirectID] = useState(null);

  const [markingRead, setMarkingRead] = useState(false);
  const [activityStats, setActivityStats] = useState({
    content: [],
    totalUnread: 0,
    todayCount: 0,
    activeThreads: 0,
    totalThreads: 0,
    threadBreakdown: [],
    activityTimeline: [],
  });

  const [activeTab, setActiveTab] = useState('discover');

  const getUnreadContent = useCallback(() => {
    const lastSeen = getLastSeen(currentUserId);
    const sessionStart = dateToTimestamp(new Date());

    return Object.values(thread_content)
      .filter(content => {
        if (
          content?.status !== 'active' ||
          content?.owner_id === currentUserId
        ) {
          return false;
        }

        const sub = thread_subs[content.thread_id];
        const threadLastSeen = lastSeen[content.thread_id];

        return (
          content.created > (sub?.last_read || 0) ||
          (threadLastSeen ? content.created > threadLastSeen : false) ||
          (sessionStart ? content.created > sessionStart : false)
        );
      })
      .sort((a, b) => b.created - a.created);
  }, [thread_content, thread_subs, currentUserId]);

  const handleMarkAllRead = useCallback(async () => {
    setMarkingRead(true);
    const now = dateToTimestamp();
    const updates = {};

    try {
      thread_ids.forEach(threadId => {
        updates[threadId] = now;
      });
      setLastSeen(currentUserId, updates);

      await Promise.all(
        thread_ids.map(t_id => {
          const sub = thread_subs?.[t_id];
          if (sub) {
            const {id} = sub;
            return updateThreadSubscription({
              id,
              thread_id: t_id,
              last_read: now,
            });
          } else {
            return createThreadSubscription({
              id: v4(),
              user_id: currentUserId,
              thread_id: t_id,
              status: null,
              last_read: dateToTimestamp(),
            });
          }
        }),
      );

      await fetchData();
    } catch (err) {
      console.error('Error marking all as read:', err);
    } finally {
      setMarkingRead(false);
    }
  }, [thread_ids]);

  const fetchData = async () => {
    setLoadingUpdates(true);

    try {
      const oneDayAgo = new Date();
      oneDayAgo.setHours(oneDayAgo.getHours() - 24);
      const oneDayTimestamp = dateToTimestamp(oneDayAgo);

      const recentActivity = Object.values(thread_content)
        .filter(
          content =>
            content?.status === 'active' && content.created >= oneDayTimestamp,
        )
        .sort((a, b) => b.created - a.created);

      const unreadContent = getUnreadContent();
      setUnread(unreadContent);

      if (unreadContent.length > 0 && activeTab === 'discover') {
        setActiveTab('updates');
      }

      const sevenDaysAgo = new Date();
      sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);
      const sevenDaysTimestamp = dateToTimestamp(sevenDaysAgo);

      // Calculate thread activity stats
      const threadActivity = Object.values(thread_content)
        .filter(content => {
          const {status, created, thread_id} = content;
          const thread = threads?.[thread_id] ?? {};
          return (
            thread.type !== 'direct' &&
            status === 'active' &&
            created >= sevenDaysTimestamp
          );
        })
        .reduce((acc, content) => {
          const thread = threads[content.thread_id];
          if (!acc[content.thread_id]) {
            acc[content.thread_id] = {
              id: content.thread_id,
              title: thread?.title || 'Unknown',
              logo: thread?.logo,
              count: 0,
            };
          }
          acc[content.thread_id].count++;
          return acc;
        }, {});

      const breakdownData = Object.values(threadActivity)
        .sort((a, b) => b.count - a.count)
        .slice(0, 5)
        .map(thread => ({
          ...thread,
          percentage:
            (thread.count /
              Object.values(threadActivity).reduce(
                (sum, t) => sum + t.count,
                0,
              )) *
            100,
        }));

      setActivityStats({
        content: recentActivity,
        totalUnread: unreadContent.length,
        todayCount: recentActivity.length,
        activeThreads: userThreads.filter(
          id => threads[id]?.updated > (thread_subs[id]?.last_read || 0),
        ).length,
        totalThreads: userThreads.length,
        threadBreakdown: breakdownData,
      });
    } catch (err) {
      console.error('Error fetching thread data:', err);
    } finally {
      setLoadingUpdates(false);
    }
  };

  const userThreads = useMemo(
    () =>
      thread_ids.filter(
        id => threads[id]?.status === 'active' && thread_subs[id],
      ),
    [thread_ids, threads, thread_subs],
  );

  const search = async (filter, options) => {
    setLoadingDiscover(true);
    const {success, error, data} = await searchThreads(filter, options);
    setLoadingDiscover(false);
    track('discover_feed_loaded_home', {source: 'threads', filter, options});
    return {success, error, data};
  };

  useEffect(() => {
    if (!threads_search_loaded) {
      search(
        {...thread_filter, not_members: [currentUserId]},
        {
          limit: constants.org_search_limit,
          nextToken: thread_token,
        },
      );
    }
  }, [threads_search_loaded]);

  useEffect(() => {
    fetchData();
  }, [thread_content]);

  return (
    <div className="home-container">
      <div className="home-welcome">
        <div className="home-welcome-header">
          <h1 className="home-welcome-title">
            Welcome back, {profile?.first_name || 'friend!'}
          </h1>
          <MarkAllReadButton
            onMarkAllRead={handleMarkAllRead}
            unreadCount={activityStats.totalUnread}
            loading={markingRead}
          />
        </div>
        <p className="home-welcome-subtitle">
          Your workspace has {activityStats.totalUnread} updates since your last
          visit
        </p>
      </div>

      <div className="home-stats-container">
        <div className="home-stat-row">
          <div className="home-stat-card unread">
            <h3 className="home-stat-header">New Updates</h3>
            <p className="home-stat-header-p">Total new posts/comments</p>
            <UnreadStats
              unreadCount={activityStats.totalUnread}
              totalThreads={activityStats.totalThreads}
            />
            <div className="home-stat-footer">
              <span>{activityStats.activeThreads} threads with updates</span>
            </div>
          </div>

          <div className="home-stat-card activity">
            <h3 className="home-stat-header">Last 24 Hour Activity</h3>
            <p className="home-stat-header-p">
              <span>{activityStats.todayCount} posts/comments in last 24h</span>
            </p>
            <ActivityTimeline content={activityStats.content} />
          </div>

          <div className="home-stat-card threads">
            <h3 className="home-stat-header">
              Top Active Threads (Last 7 days)
            </h3>
            <p className="home-stat-header-p">Number of new posts/comments</p>
            <ThreadBreakdown
              threads={activityStats.threadBreakdown.slice(0, 3)}
              setCurrent={setCurrent}
              setIndex={setIndex}
              setContent={setContent}
            />
          </div>
        </div>
      </div>

      <div className="home-main-content">
        <div className="home-tabs">
          <button
            className={`home-tab ${activeTab === 'discover' ? 'active' : ''}`}
            onClick={() => setActiveTab('discover')}>
            <MdRssFeed />
            Discover
          </button>
          <button
            className={`home-tab ${activeTab === 'updates' ? 'active' : ''}`}
            onClick={() => setActiveTab('updates')}>
            <RxBell />
            New Updates
            {unreadPosts.length > 0 && (
              <span className="home-update-count">{unreadPosts.length}</span>
            )}
          </button>
        </div>

        <div className="home-tab-content">
          {activeTab === 'discover' && loadingDiscover && <SpinningIndicator />}
          {activeTab === 'updates' && loadingUpdates && <SpinningIndicator />}

          {activeTab === 'discover' && !loadingDiscover ? (
            thread_search.length > 0 ? (
              <div className="home-discover">
                <ThreadHomeGrid
                  threadIds={thread_search}
                  setCurrent={setCurrent}
                  setContent={setContent}
                  setIndex={setIndex}
                />
              </div>
            ) : (
              <div className="home-empty-state">
                <RxClock className="home-empty-icon" />
                <p>No threads discovered yet</p>
                <p>Create your first thread or join existing ones</p>
              </div>
            )
          ) : activeTab === 'updates' && !loadingUpdates ? (
            unreadPosts.length > 0 ? (
              <div className="home-updates-content">
                {unreadPosts.map(content => (
                  <HomeMentionItem
                    key={content.id}
                    item={content}
                    thread={threads[content.thread_id]}
                    onClick={() => {
                      if (threads[content.thread_id].type !== 'direct') {
                        setIndex('thread-detail');
                        setCurrent(content.thread_id);
                        setContent(content.id);
                      } else {
                        setDirectID(content?.thread_id);
                        setShowDMs(true);
                      }
                    }}
                    isUnread={true}
                  />
                ))}
              </div>
            ) : (
              <div className="home-empty-state">
                <RxClock className="home-empty-icon" />
                <p>You're all caught up!</p>
                <p>No new updates since your last visit</p>
              </div>
            )
          ) : null}
        </div>
      </div>
      <DirectMessages
        active={showDM}
        setActive={setShowDMs}
        directID={directID}
      />
    </div>
  );
};

export default ThreadsHome;
