import Lottie from 'lottie-react';
import React from 'react';
import loadingLottie from '../../assets/lottie/loading.json';
import './styles.css';

export const AppleActivityIndicator = () => {
  return (
    <div className="apple-activity-indicator">
      <div className="spinner">
        <div className="dot" />
        <div className="dot" />
        <div className="dot" />
        <div className="dot" />
        <div className="dot" />
        <div className="dot" />
        <div className="dot" />
        <div className="dot" />
        <div className="dot" />
        <div className="dot" />
        <div className="dot" />
        <div className="dot" />
      </div>
    </div>
  );
};

export const SpinningIndicator = ({}) => {
  return (
    <div className="flex align-center justify-center">
      <div className="loader" />
    </div>
  );
};

export const BouncingIndicator = ({size}) => {
  return (
    <Lottie
      className={`bouncing-${size || 'normal'}`}
      animationData={loadingLottie}
      loop={true}
    />
  );
};

export const FindingMatchesWave = () => (
  <div className="ai-background">
    <div>
      <div className="wave-container">
        <svg width="100" height="40">
          <path
            className="wave wave1"
            d="M10,20 Q20,30 30,20 T50,20 T70,20 T90,20"
          />
          <path
            className="wave wave2"
            d="M10,20 Q20,25 30,20 T50,20 T70,20 T90,20"
          />
          <path
            className="wave wave3"
            d="M10,20 Q20,35 30,20 T50,20 T70,20 T90,20"
          />
        </svg>
      </div>
      <p className="animated-text">Thinking...</p>
    </div>
  </div>
);
