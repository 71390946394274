import constants from '../../components/constants';

const generateSharePriorities = props => {
  const {profile, tags, link, type} = props || {};
  const {email, first_name, last_name, sub} = profile || {};
  const pretty_name =
    first_name || last_name ? `${first_name || ''} ${last_name || ''}` : email;
  const public_link = constants.public_profile + `/${sub}`;

  return `
    <html>
      <head>
        <link href="https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap" rel="stylesheet">
      </head>
      <body style="font-family: 'Montserrat', sans-serif; color: #000000; letter-spacing: 0.1em;">
        <div style="text-align: center;">
          <img src="${
            constants.publicmind_logo
          }" alt="PublicMind Logo" height="64" width="64" />
          <h2 style="font-family: 'Montserrat', sans-serif; font-weight: 300;">PUBLICMIND</h2>
        </div>
        <br />
        <div style="margin: 8px 20%;">
          <p style="text-align: left; font-size: 28px; color: black; font-weight: bold;"><a href="${link}">Join</a> ${
    pretty_name || 'Us'
  } on PublicMind</p>  
          <p style="white-space: pre-line; text-align: left; color: #75757a;">
            ${
              pretty_name || 'A colleague'
            } has shared their new priorities on PublicMind and is seeking your expertise. <a href="${link}">Join</a> PublicMind to discover their projects and connect with other industry professionals.
          </p>    
          <div style="text-align: left; border: 1px solid #ccc; padding: 16px; border-radius: 16px; margin: 8px 0px; background-color: #f4f4f4;">
            <p style="font-size: 24px; color: #333; font-weight: bold;">New ${type}</p>
            <br />
            ${tagItems(tags)}
          </div>
          <br/>
          <div style="text-align: center;">
            <a href="${public_link}" style="background-color: #6e8efb; color: white; padding: 10px 20px; text-decoration: none; border-radius: 5px; font-weight: bold; font-size: 16px; border: 1px solid #333333;">
              View Profile
            </a>
          </div>
          <br/>
          <p style="text-align: center;">Sent from PublicMind</p>
        </div>
      </body>
    </html>
  `;
};

const tagItems = similar => {
  return similar?.length
    ? `<div style="display: flex; flex-wrap: wrap; word-break: keep-all;">
         ${similar
           .slice(0, 4)
           .map(
             tag => `
               <p style="display: inline-block; font-weight: light; background-color: #4743c5; border: 1px solid #75757a; color: white; padding: 6px 10px; margin: 4px; border-radius: 24px; box-sizing: border-box; word-break: keep-all;">
                 ${tag}
               </p>
             `,
           )
           .join('')}
       </div>`
    : '';
};

export default generateSharePriorities;
