var dayjs = require('dayjs');
var Parser = require('../parser').Parser;
var ParsedResult = require('../../result').ParsedResult;

var PATTERN = new RegExp(
    '(\\W|^)(' +
        'jetzt|' +
        '(?:heute|diesen)\\s*(morgen|vormittag|mittag|nachmittag|abend)|' +
        '(?:heute|diese)\\s*nacht|' +
        'heute|' +
        '(?:(?:ü|ue)ber)?morgen(?:\\s*(morgen|vormittag|mittag|nachmittag|abend|nacht))?|' +
        '(?:vor)?gestern(?:\\s*(morgen|vormittag|mittag|nachmittag|abend|nacht))?|' +
        'letzte\\s*nacht' +
    ')(?=\\W|$)', 'i');

exports.Parser = function DECasualDateParser() {

    Parser.apply(this, arguments);

    this.pattern = function() { return PATTERN; }

    this.extract = function(text, ref, match, opt) {
        text = match[0].substr(match[1].length);
        
        const index = match.index + match[1].length;
        const result = new ParsedResult({
            index: index,
            text: text,
            ref: ref,
        });

        const refMoment = dayjs(ref);
        const lowerText = text.toLowerCase();

        var startMoment = refMoment;

        if (/(?:heute|diese)\s*nacht/.test(lowerText)) {
            // Normally means this coming midnight
            result.start.imply('hour', 22);
            result.start.imply('meridiem', 1);
        } else if (/^(?:ü|ue)bermorgen/.test(lowerText)) {
            startMoment = startMoment.add(refMoment.hour() > 1 ? 2 : 1, 'day');
        } else if (/^morgen/.test(lowerText)) {
            // Check not "Tomorrow" on late night
            if (refMoment.hour() > 1) {
                startMoment = startMoment.add(1, 'day');
            }
        } else if (/^gestern/.test(lowerText)) {
            startMoment = startMoment.add(-1, 'day');
        } else if (/^vorgestern/.test(lowerText)) {
            startMoment = startMoment.add(-2, 'day');
        } else if (/letzte\s*nacht/.test(lowerText)) {
            result.start.imply('hour', 0);
            if (refMoment.hour() > 6) {
                startMoment = startMoment.add(-1, 'day');
            }
        } else if (lowerText === 'jetzt') {
            result.start.imply('hour', refMoment.hour());
            result.start.imply('minute', refMoment.minute());
            result.start.imply('second', refMoment.second());
            result.start.imply('millisecond', refMoment.millisecond());
        }

        var secondMatch = match[3] || match[4] || match[5];
        if (secondMatch) {
            switch (secondMatch.toLowerCase()) {
                case 'morgen':
                    result.start.imply('hour', 6);
                    break;
                case 'vormittag':
                    result.start.imply('hour', 9);
                    break;
                case 'mittag':
                    result.start.imply('hour', 12);
                    break;
                case 'nachmittag':
                    result.start.imply('hour', 15);
                    result.start.imply('meridiem', 1);
                    break;
                case 'abend':
                    result.start.imply('hour', 18);
                    result.start.imply('meridiem', 1);
                    break;
                case 'nacht':
                    result.start.imply('hour', 0);
                    break;
            }
        }

        result.start.assign('day', startMoment.date())
        result.start.assign('month', startMoment.month() + 1)
        result.start.assign('year', startMoment.year())
        result.tags['DECasualDateParser'] = true;
        return result;
    }
}
