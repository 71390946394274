// ModalPortal.js
import ReactDOM from 'react-dom';

const ModalPortal = ({children, zIndex = 1000}) => {
  return ReactDOM.createPortal(
    <div style={{position: 'relative', zIndex}}>{children}</div>,
    document.body,
  );
};
export const ModalPortalAbsolute = ({children}) => {
  return ReactDOM.createPortal(<div>{children}</div>, document.body);
};

export default ModalPortal;
