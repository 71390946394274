import React from 'react';
import './MemberActivityTooltip.css';

export const MemberActivityTooltip = ({
  children,
  memberActivities,
  prettyName,
  timeSince,
}) => {
  const hasActivities = memberActivities && memberActivities.length > 0;

  return (
    <span className="tooltip-container member-activity-tooltip">
      <div className="tooltip-trigger-no-shadow" tabIndex={0}>
        {children}
      </div>
      <div
        className="member-activity-content"
        role="tooltip"
        aria-hidden="true">
        <h4 className="member-activity-title">Member Activity</h4>
        {hasActivities ? (
          <ul className="member-activity-list">
            {memberActivities.map(({member, lastActive, activityStatus}) => (
              <li className="member-activity-item" key={member}>
                <span className="member-name">
                  {prettyName(member) || 'Unknown Member'}
                </span>
                <div className="member-activity-details">
                  <span className={`member-status status-${activityStatus}`}>
                    {activityStatus === 'lastHour'
                      ? 'Active'
                      : activityStatus === 'today'
                      ? 'Today'
                      : activityStatus === 'last24Hours'
                      ? 'Yesterday'
                      : 'Inactive'}
                  </span>
                  <span className="member-last-active">
                    {lastActive ? timeSince(lastActive) : 'Never active'}
                  </span>
                </div>
              </li>
            ))}
          </ul>
        ) : (
          <p className="no-activity-message">No recent member activity</p>
        )}
      </div>
    </span>
  );
};
