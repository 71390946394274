import React from 'react';
import './styles.css';

export const FormikBubbleInput = ({
  formik,
  name,
  items,
  header,
  mappings,
  disabled,
  maxWidth,
}) => {
  const {key = 'key', value = 'value'} = mappings || {};

  const {onBlur, onChange, value: values} = formik.getFieldProps(name) || {};

  return (
    <div className={maxWidth ? 'bubble-container-max' : 'bubble-container'}>
      {header && <p className="text-bold text-left margin-v16">{header}</p>}
      {formik.touched[name] && formik.errors[name] && (
        <p className="input-error">{formik.errors[name]}</p>
      )}
      <div className="bubble-wrap">
        {items.map(item => {
          const active = values.includes(item[value]);
          return (
            <Bubble
              key={item.key}
              item={{key: item[key], value: item[value]}}
              active={active}
              onClick={() => {
                if (disabled) {
                  return;
                }
                if (active) {
                  formik.setFieldValue(
                    name,
                    [...values].filter(val => val !== item[value]),
                  );
                } else {
                  formik.setFieldValue(name, [...values, item[value]]);
                }
              }}
            />
          );
        })}
      </div>
      <br />
    </div>
  );
};

export const BubbleInput = ({
  name,
  items,
  header,
  mappings,
  disabled,
  values,
  setValue,
}) => {
  const {key = 'key', value = 'value'} = mappings || {};

  return (
    <div className="bubble-container">
      {header && (
        <label className="text-secondary text-14 padding-bottom4">
          {header}
        </label>
      )}
      <br />
      <div className="bubble-wrap">
        {items.map(item => {
          const active = values.includes(item[value]);
          return (
            <Bubble
              key={item.key}
              item={{key: item[key], value: item[value]}}
              active={active}
              onClick={() => {
                if (disabled) {
                  return;
                }
                if (active) {
                  setValue([...values].filter(val => val !== item[value]));
                } else {
                  setValue([...values, item[value]]);
                }
              }}
            />
          );
        })}
      </div>
      <br />
    </div>
  );
};

const Bubble = ({item, active, onClick}) => {
  const {key, value} = item;

  return (
    <div className={`bubble ${active ? 'active' : ''}`} onClick={onClick}>
      <p className={active ? 'text-inverted' : ''}>{key}</p>
    </div>
  );
};
