import React, {useEffect, useRef, useState} from 'react';
import {useParams} from 'react-router-dom';
import constants from '../../../components/constants';
import {SpinningIndicator} from '../../../components/loading/loading-indicator';
import {useAuth} from '../../../hooks/use-auth';
import {useThreads} from '../../../hooks/use-threads';
import {
  ThreadContent,
  ThreadHeader,
  useSortedContent,
} from '../overview-tabs/thread-detail';

const PublicThreadDetail = ({}) => {
  const {id} = useParams();
  const {
    state: {threads, content_ids, thread_content},
    getThread,
    getContents,
  } = useThreads();
  const {
    state: {id: user_id},
  } = useAuth();

  const message_ids = content_ids?.[id] ?? [];

  const [loading, setLoading] = useState(false);

  const [view, setView] = useState('default');
  const [sort, setSort] = useState({value: 'recent', label: 'Recent'});
  const [filter, setFilter] = useState({value: 'all', label: 'All'});
  const [search, setSearch] = useState('');
  const [activeMenuItem, setActiveMenuItem] = useState('activity');
  const [index, setIndex] = useState('content');
  const [collapse, setCollapse] = useState(null);

  const [page, setPage] = useState(1);
  const page_size = 10;

  const containerRef = useRef(null);

  useEffect(() => {
    const load = async () => {
      setLoading(true);
      await getThread(id);
      setLoading(false);
    };
    if (id && threads[id] === undefined) {
      load();
    }
  }, [id]);

  useEffect(() => {
    if (id && content_ids[id] === undefined) {
      const query = {
        thread_id: id,
        filter: {status: {eq: 'active'}},
        limit: constants.all_items,
      };
      return getContents(query, id);
    }
  }, []);

  const sortedContent = useSortedContent(
    message_ids,
    thread_content,
    filter,
    activeMenuItem,
    view,
    search,
    sort,
    user_id,
  );

  const handleScroll = () => {
    if (!containerRef.current) {
      return;
    }

    const {scrollTop, scrollHeight, clientHeight} = containerRef.current;
    const scrollPercentage = (scrollTop + clientHeight) / scrollHeight;

    // Load more when user has scrolled to 80% of the container
    if (scrollPercentage > 0.8 && sortedContent?.length > page * page_size) {
      setPage(prev => prev + 1);
    }
  };

  useEffect(() => {
    const currentContainer = containerRef.current;
    if (currentContainer) {
      currentContainer.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (currentContainer) {
        currentContainer.removeEventListener('scroll', handleScroll);
      }
    };
  }, [page, sortedContent?.length, id]);

  // RESET PAGE ON NAVIGATE AND FILTER
  useEffect(() => {
    setPage(1);
  }, [id]);

  return (
    <div
      className="page-container"
      style={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        alignItems: 'center',
        justifyItems: 'center',
        height: '100%',
        padding: '16px',
      }}>
      <div
        style={{
          width: '60vw',
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          height: '100%',
          border: '1px solid var(--divider)',
          borderRadius: '8px',
        }}>
        {/* <VerticalMenuBar
          id={id}
          activeItem={activeMenuItem}
          setActiveItem={setActiveMenuItem}
          setIndex={setIndex}
        /> */}
        <ThreadHeader
          id={id}
          setCurrent={() => {}}
          setFocusedContent={() => {}}
          search={search}
          setSearch={setSearch}
          filter={filter}
          setFilter={setFilter}
          sort={sort}
          setSort={setSort}
          setCollapse={setCollapse}
          collapse={collapse}
          view={view}
          setView={setView}
          isPublic={true}
        />
        <div className="thread-message-container-public" ref={containerRef}>
          {loading && <SpinningIndicator />}
          {index === 'content' && (
            <ThreadContent
              key={id}
              id={id}
              focused_content={null}
              sortedContent={sortedContent?.slice(0, page * page_size)}
              activeMenuItem={activeMenuItem}
              setReply={() => {}}
              setQuote={() => {}}
              initialSub={{}}
              setFocusedContent={() => {}}
              collapse={collapse}
              view={view}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default PublicThreadDetail;
