import {
  confirmResetPassword,
  confirmSignUp,
  fetchAuthSession,
  getCurrentUser,
  resendSignUpCode,
  resetPassword,
  signIn,
  signInWithRedirect,
  signOut,
  signUp,
  updatePassword,
  updateUserAttributes,
} from 'aws-amplify/auth';
import {Hub} from 'aws-amplify/utils';
import {track} from './analytics';

const authListener = callback => {
  try {
    Hub.listen('auth', async ({payload}) => {
      callback(payload);
    });
  } catch (err) {
    throw err;
  }
};

const getCurrentAccount = async () => {
  try {
    const user = await getCurrentUser();

    return user;
  } catch (err) {
    throw err;
  }
};

const getCurrentSession = async () => {
  try {
    const session = await fetchAuthSession();

    return session;
  } catch (err) {
    throw err;
  }
};

const createAccount = async (email, password, userAttributes) => {
  try {
    const response = await signUp({
      username: email,
      password,
      options: {userAttributes},
    });
    return response;
  } catch (err) {
    track('api_error', {type: 'auth', function: 'signUp', err});
    throw err;
  }
};

const confirmUser = async (username, confirmationCode) => {
  try {
    const response = await confirmSignUp({username, confirmationCode});

    return response;
  } catch (err) {
    track('api_error', {type: 'auth', function: 'confirmSignUp', err});
    throw err;
  }
};

const resendConfirmation = async username => {
  try {
    const response = await resendSignUpCode({username});
    return response;
  } catch (err) {
    track('api_error', {type: 'auth', function: 'resendSignUpCode', err});
    throw err;
  }
};

// const updateMFApreference = async (code, type) => {
//   try {
//     const user = await getCurrentUser();
//     await setPreferredMFA(user, type);
//     await confirmSignIn(user, code, type);
//   } catch (err) {
//     throw err;
//   }
// };

// const confirmMFAChallenge = async (code, type) => {
//   try {
//     const user = await getCurrentUser();
//     await confirmSignIn(user, code, type);
//   } catch (err) {
//     throw err;
//   }
// };

const updateAttributes = async attributes => {
  try {
    const user = await getCurrentUser();

    await updateUserAttributes(user, attributes);
  } catch (err) {
    track('api_error', {type: 'auth', function: 'updateUserAttributes', err});
    throw err;
  }
};

const providerLogin = async options => {
  try {
    const response = await signInWithRedirect(
      options || {
        provider: 'Google',
        scopes: ['https://www.googleapis.com/auth/gmail.metadata'],
      },
    );

    return response;
  } catch (err) {
    throw err;
  }
};

const login = async (username, password) => {
  try {
    const response = await signIn({username, password});

    return response;
  } catch (err) {
    track('api_error', {type: 'auth', function: 'signIn', err});
    const {name, recoverySuggestion} = err;
    if (
      name === 'UserAlreadyAuthenticatedException' ||
      recoverySuggestion === 'Call signOut before calling signIn again.'
    ) {
      await logout(); // Assuming logout is asynchronous
    }
    throw err;
  }
};

const logout = async () => {
  try {
    await signOut();
  } catch (err) {
    track('api_error', {type: 'auth', function: 'signOut', err});
    throw err;
  }
};

const sendForgotPasswordCode = async username => {
  try {
    const response = await resetPassword({username});
    return response;
  } catch (err) {
    track('api_error', {type: 'auth', function: 'resetPassword', err});
    throw err;
  }
};

const confirmForgotPassword = async (
  username,
  confirmationCode,
  newPassword,
) => {
  try {
    const response = await confirmResetPassword({
      username,
      confirmationCode,
      newPassword,
    });
    return response;
  } catch (err) {
    track('api_error', {type: 'auth', function: 'confirmResetPassword', err});
    throw err;
  }
};

const changePassword = async (oldPassword, newPassword) => {
  try {
    await updatePassword({
      oldPassword,
      newPassword,
    });
  } catch (err) {
    track('api_error', {type: 'auth', function: 'updatePassword', err});
    throw err;
  }
};

export {
  authListener,
  changePassword,
  confirmForgotPassword,
  confirmUser,
  createAccount,
  getCurrentAccount,
  getCurrentSession,
  login,
  logout,
  providerLogin,
  resendConfirmation,
  sendForgotPasswordCode,
  updateAttributes,
};
