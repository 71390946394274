/* eslint-disable import/no-anonymous-default-export */
export default () => {
  const removeMarkdown = text => {
    return (
      text
        // Remove headers
        .replace(/^#{1,6}\s+/gm, '')
        // Remove bold/italic
        .replace(/(\*\*|__)(.*?)\1/g, '$2')
        .replace(/(\*|_)(.*?)\1/g, '$2')
        // Remove links
        .replace(/\[([^\]]+)\]\([^\)]+\)/g, '$1')
        // Remove code blocks
        .replace(/`{3}[\s\S]*?`{3}/g, '')
        // Remove inline code
        .replace(/`(.+?)`/g, '$1')
        // Remove blockquotes
        .replace(/^>\s+/gm, '')
        // Remove horizontal rules
        .replace(/^-{3,}\s*$/gm, '')
        // Remove unordered list markers
        .replace(/^[\*\-+]\s+/gm, '')
        // Remove ordered list markers
        .replace(/^\d+\.\s+/gm, '')
        // Remove images
        .replace(/!\[([^\]]+)\]\([^\)]+\)/g, '$1')
        // Remove any remaining Markdown syntax (customize as needed)
        .replace(/[#*_\[\]`>-]/g, '')
    );
  };

  const copyString = (string, sanitize) => {
    if (string && sanitize) {
      string = removeMarkdown(string);
    }
    if (navigator.clipboard) {
      try {
        navigator.clipboard.writeText(string);
        console.log('URL copied to clipboard successfully!');
      } catch (err) {
        console.error('Failed to copy: ', err);
      }
    } else {
      // Fallback for older browsers
      const textArea = document.createElement('textarea');
      textArea.value = string;
      // Avoid scrolling to bottom
      textArea.style.position = 'fixed';
      textArea.style.top = 0;
      textArea.style.left = 0;
      textArea.style.width = '2em';
      textArea.style.height = '2em';
      textArea.style.padding = 0;
      textArea.style.border = 'none';
      textArea.style.outline = 'none';
      textArea.style.boxShadow = 'none';
      textArea.style.background = 'transparent';
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      try {
        document.execCommand('copy');
        console.log('Fallback: URL copied to clipboard successfully!');
      } catch (err) {
        console.error('Fallback: Failed to copy: ', err);
      }
      document.body.removeChild(textArea);
    }
  };

  return {copyString};
};
