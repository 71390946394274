import React, {useEffect, useState} from 'react';
import {GoGitBranch} from 'react-icons/go';
import {RxChevronDown, RxChevronRight} from 'react-icons/rx';
import {useNavigate} from 'react-router-dom';
import {useAuth} from '../../../hooks/use-auth';
import {useThreads} from '../../../hooks/use-threads';
import './ThreadChildren.css';

const ThreadChildren = ({children}) => {
  const {
    state: {id: user_id},
  } = useAuth();
  const {
    state: {threads},
    getThread,
  } = useThreads();
  const [isExpanded, setIsExpanded] = useState(false);
  const navigate = useNavigate();
  const [active, setActive] = useState(0);

  useEffect(() => {
    if (children) {
      children.forEach(id => {
        if (threads[id] === undefined) {
          getThread(id);
        }
      });
    }
  }, [children]);

  useEffect(() => {
    const count =
      children?.filter(id => threads[id] && threads[id].status !== 'archived')
        .length || 0;
    setActive(count);
  }, [children, threads]);

  const toggleExpanded = () => setIsExpanded(prev => !prev);

  const handleChildClick = thread => {
    const {id, members} = thread;
    // IF A MEMBER, NAVIGATE THEM
    if (members.includes(user_id)) {
      navigate(`/threads/${id}`);
    }
  };

  if (!active) {
    return null;
  }

  return (
    <div className="thread-children">
      <button className="thread-children__toggle" onClick={toggleExpanded}>
        {isExpanded ? (
          <RxChevronDown className="thread-children__icon" />
        ) : (
          <RxChevronRight className="thread-children__icon" />
        )}
        <GoGitBranch />
        {active} Breakout Thread{active !== 1 ? 's' : ''}
      </button>
      {isExpanded && (
        <span>
          <ul className="thread-children__list">
            {children.map(childId => {
              const childThread = threads[childId];
              if (!childThread || childThread.status === 'archived')
                return null;
              return (
                <li
                  key={childThread.id}
                  className={`thread-children__item ${
                    childThread.members.includes(user_id)
                      ? ''
                      : 'thread-children__item--disabled'
                  }`}
                  onClick={() => handleChildClick(childThread)}>
                  <span className="thread-children__title">
                    {childThread.logo} {childThread.title}
                  </span>
                  {!childThread.members.includes(user_id) && (
                    <span className="thread-children__lock-icon">
                      - Not a Member
                    </span>
                  )}
                </li>
              );
            })}
          </ul>
        </span>
      )}
    </div>
  );
};

export default ThreadChildren;
