/*


*/

var dayjs = require('dayjs');
var Parser = require('../parser').Parser;
var ParsedResult = require('../../result').ParsedResult;
var util  = require('../../utils/DE');

var PATTERN = new RegExp('(\\W|^)' +
    '(in|nach)\\s*' +
    '('+ util.INTEGER_WORDS_PATTERN + '|[0-9]+|einigen|eine[rm]\\s*halben|eine[rm])\\s*' +
    '(sekunden?|min(?:ute)?n?|stunden?|tag(?:en)?|wochen?|monat(?:en)?|jahr(?:en)?)\\s*' +
    '(?=\\W|$)', 'i'
);

var STRICT_PATTERN = new RegExp('(\\W|^)' +
    '(in|nach)\\s*' +
    '('+ util.INTEGER_WORDS_PATTERN + '|[0-9]+|eine(?:r|m)?)\\s*' +
    '(sekunden?|minuten?|stunden?|tag(?:en)?)\\s*' +
    '(?=\\W|$)', 'i'
);

exports.Parser = function DEDeadlineFormatParser(){
    Parser.apply(this, arguments);

    this.pattern = function() {
        return this.isStrictMode()? STRICT_PATTERN : PATTERN;
    };

    this.extract = function(text, ref, match, opt){

        var index = match.index + match[1].length;
        var text  = match[0];
        text  = match[0].substr(match[1].length, match[0].length - match[1].length);

        var result = new ParsedResult({
            index: index,
            text: text,
            ref: ref
        });

        var num = match[3].toLowerCase();
        if (util.INTEGER_WORDS[num] !== undefined) {
            num = util.INTEGER_WORDS[num];
        } else if (num === 'einer' || num === 'einem') {
            num = 1;
        } else if (num === 'einigen') {
            num = 3;
        } else if (/halben/.test(num)) {
            num = 0.5;
        } else {
            num = parseInt(num);
        }

        var date = dayjs(ref);
        if (/tag|woche|monat|jahr/i.test(match[4])) {

            if (/tag/i.test(match[4])) {
                date = date.add(num, 'd');
            } else if (/woche/i.test(match[4])) {
                date = date.add(num * 7, 'd');
            } else if (/monat/i.test(match[4])) {
                date = date.add(num, 'month');
            } else if (/jahr/i.test(match[4])) {
                date = date.add(num, 'year');
            }

            result.start.assign('year', date.year());
            result.start.assign('month', date.month() + 1);
            result.start.assign('day', date.date());
            return result;
        }

        if (/stunde/i.test(match[4])) {

            date = date.add(num, 'hour');

        } else if (/min/i.test(match[4])) {

            date = date.add(num, 'minute');

        } else if (/sekunde/i.test(match[4])) {

            date = date.add(num, 'second');
        }

        result.start.imply('year', date.year());
        result.start.imply('month', date.month() + 1);
        result.start.imply('day', date.date());
        result.start.assign('hour', date.hour());
        result.start.assign('minute', date.minute());
        result.start.assign('second', date.second());
        result.tags['DEDeadlineFormatParser'] = true;
        return result;
    };
};
