const dayjs = require('dayjs');
var Parser = require('../parser').Parser;
var ParsedResult = require('../../result').ParsedResult;

var PATTERN = /(\W|^)(maintenant|aujourd'hui|ajd|cette\s*nuit|la\s*veille|(demain|hier)(\s*(matin|soir|aprem|après-midi))?|ce\s*(matin|soir)|cet\s*(après-midi|aprem))(?=\W|$)/i;

exports.Parser = function FRCasualDateParser(){

    Parser.apply(this, arguments);

    this.pattern = function() { return PATTERN; }

    this.extract = function(text, ref, match, opt){

        var text = match[0].substr(match[1].length);
        var index = match.index + match[1].length;
        var result = new ParsedResult({
            index: index,
            text: text,
            ref: ref,
        });

        var refMoment = dayjs(ref);
        var startMoment = refMoment;
        var lowerText = text.toLowerCase();

        if(lowerText.match(/demain/)){
            // Check not "Tomorrow" on late night
            if(refMoment.hour() > 1) {
                startMoment = startMoment.add(1, 'day');
            }
        } 

        if(lowerText.match(/hier/)) {
            startMoment = startMoment.add(-1, 'day');
        }

        if(lowerText.match(/cette\s*nuit/)){
            // Normally means this coming midnight
            result.start.imply('hour', 22);
            result.start.imply('meridiem', 1);

        } else if(lowerText.match(/la\s*veille/)) {

            result.start.imply('hour', 0);
            if (refMoment.hour() > 6) {
                startMoment = startMoment.add(-1, 'day');
            }

        } else if (lowerText.match(/(après-midi|aprem)/)) {

            result.start.imply('hour', 14);

        } else if (lowerText.match(/(soir)/)) {

            result.start.imply('hour', 18);

        } else if (lowerText.match(/matin/)) {

            result.start.imply('hour', 8);

        }  else if (lowerText.match("maintenant")) {

          result.start.imply('hour', refMoment.hour());
          result.start.imply('minute', refMoment.minute());
          result.start.imply('second', refMoment.second());
          result.start.imply('millisecond', refMoment.millisecond());

        }

        result.start.assign('day', startMoment.date())
        result.start.assign('month', startMoment.month() + 1)
        result.start.assign('year', startMoment.year())
        result.tags['FRCasualDateParser'] = true;
        return result;
    }
}
