import React from 'react';

const OrganizationError = ({}) => {
  return (
    <div>
      <h1>Organization Error</h1>
    </div>
  );
};
export default OrganizationError;
