/* eslint-disable import/no-anonymous-default-export */
import tagsJSON from '../assets/schemas/tags-schema.json';
import constants from '../components/constants';

// To consistently format strings
export default () => {
  const capitalize = str => {
    if (!str || typeof str !== 'string') {
      return '';
    }
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const formatMessageWURLS = str => {
    if (!str || !str.length) {
      return '';
    }
    const urlRegex = constants.all_links;
    const urls = str.match(urlRegex) || [];

    let result = [];

    str.split(' ').forEach((part, index) => {
      if (urls.includes(part)) {
        const url =
          part.startsWith('http://') || part.startsWith('https://')
            ? part
            : `http://${part}`;
        result.push(
          <a href={url} target="_blank" rel="noopener noreferrer" key={index}>
            {part}
          </a>,
        );
      } else {
        result.push(part + ' ');
      }
    });

    return result;
  };

  const parseTextForLinks = str => {
    if (!str || !str.length) {
      return '';
    }

    const result = [];
    const urlRegex = constants.https_url;
    urlRegex.lastIndex = 0;

    str.split(urlRegex).forEach((part, index) => {
      if (index % 2 === 0) {
        result.push(part);
      } else {
        result.push(
          <a href={part} target="_blank" rel="noopener noreferrer" key={index}>
            {part}
          </a>,
        );
      }
    });

    return result;
  };

  const generateOfficialURL = domain => {
    // Check if the domain already starts with http://, https://, or contains www.
    if (!/^https?:\/\//i.test(domain)) {
      // Only add 'https://www.' if it doesn't already contain 'www.'
      if (!/^www\./i.test(domain)) {
        domain = 'https://www.' + domain;
      } else {
        // If it contains 'www.', just prepend 'https://'
        domain = 'https://' + domain;
      }
    }

    return domain;
  };

  const prettyTag = key => {
    const value = tagsJSON[key];

    if (value) {
      return value.pretty;
    }
    return reverseFormatString(key);
  };

  const reverseFormatString = inputString => {
    // Split the inputString into an array of words using underscores as the delimiter
    const wordsArray = inputString.split('_');

    // Capitalize the first letter of each word in the array
    const capitalizedWords = wordsArray.map(
      word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase(),
    );

    // Join the capitalized words back into a string with spaces as separators
    const formattedString = capitalizedWords.join(' ');

    // Return the formatted string
    return formattedString;
  };

  const prettyHeader = (field, short) => {
    if (short) {
      switch (field) {
        case 'business_entrepreneurship':
          return 'Business';
        case 'community_social':
          return 'Community';
        case 'collaborative_projects':
          return 'Collaborative';
        case 'education_skill':
          return 'Education';
        case 'research_development':
          return 'R&D';
        case 'networking_professional':
          return 'Network';
        case 'resources_infrastructure':
          return 'Resources';
        default:
          return 'Other';
      }
    }
    switch (field) {
      case 'collaborative_projects':
        return 'Collaborative Projects';
      case 'education_skill':
        return 'Education & Skill';
      case 'research_development':
        return 'Research & Development';
      case 'business_entrepreneurship':
        return 'Business & Entrepreneurship';
      case 'community_social':
        return 'Community & Social';
      case 'networking_professional':
        return 'Networking & Professional';
      case 'resources_infrastructure':
        return 'Resources & Infrastructure';
      case 'educator':
        return 'Educator';
      case 'employer':
        return 'Employer';
      case 'hard':
        return 'Hard Skills';
      case 'soft':
        return 'Soft Skills';
      case 'skill':
        return 'Skills';
      case 'opportunity':
        return 'Opportunities';
      case 'service':
        return 'Services';
      case 'degree':
        return 'Degrees';
      case 'major':
        return 'Majors';
      case 'collaborator':
        return 'Partners';
      case 'operational_efficiency':
        return 'Operational Efficiency';
      case 'software_solutions':
        return 'Software Solutions';
      case 'consulting_services':
        return 'Consulting Services';
      case 'marketing_communications':
        return 'Marketing and Communications';
      case 'technology_development':
        return 'Technology Development and Integration';
      case 'sales_customer_support':
        return 'Sales and Customer Support';
      case 'creative_design':
        return 'Creative and Design Services';
      default:
        return 'Other';
    }
  };

  const prettyName = profile => {
    if (!profile) {
      return '';
    }
    const {first_name, last_name, email, id} = profile;

    if (first_name || last_name) {
      return `${first_name || ''} ${last_name || ''}`.trim();
    }
    if (!id) {
      return email;
    }

    if (email) {
      const parts = email.split('@');
      const username = parts?.[0] ?? null;
      return username;
    }

    return 'Incomplete Profile';
  };

  const prettyPosition = org => {
    if (!org) {
      return '';
    }
    const {position, name} = org;

    if (position && name) {
      return `${position} at ${name}`;
    }

    return name || position;
  };

  const prettyContentType = type => {
    switch (type) {
      case 'milestone':
        return 'Milestone';
      case 'question':
        return 'Question';
      case 'decision_point':
        return 'Decision Point';
      case 'poll':
        return 'Poll';
      case 'meeting':
        return 'Meeting';
      case 'form':
        return 'Form';
      default:
        return 'Comment';
    }
  };

  const prettyType = type => {
    switch (type) {
      case 'comment':
        return 'Post';
      case 'milestone':
        return 'Milestone';
      case 'question':
        return 'Question';
      case 'decision_point':
        return 'Decision Point';
      case 'poll':
        return 'Poll';
      case 'meeting':
        return 'Meeting';
      case 'task':
        return 'Task';
      case 'form':
        return 'Form';
      default:
        return 'Post';
    }
  };

  return {
    capitalize,
    parseTextForLinks,
    generateOfficialURL,
    prettyTag,
    prettyHeader,
    formatMessageWURLS,
    prettyName,
    prettyPosition,
    prettyContentType,
    prettyType,
  };
};
