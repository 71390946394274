/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateGroup = /* GraphQL */ `
  subscription OnCreateGroup($filter: ModelSubscriptionGroupFilterInput) {
    onCreateGroup(filter: $filter) {
      id
      created
      updated
      type
      name
      bio
      image {
        key
        type
        size
        __typename
      }
      admins
      members
      tags
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateGroup = /* GraphQL */ `
  subscription OnUpdateGroup($filter: ModelSubscriptionGroupFilterInput) {
    onUpdateGroup(filter: $filter) {
      id
      created
      updated
      type
      name
      bio
      image {
        key
        type
        size
        __typename
      }
      admins
      members
      tags
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteGroup = /* GraphQL */ `
  subscription OnDeleteGroup($filter: ModelSubscriptionGroupFilterInput) {
    onDeleteGroup(filter: $filter) {
      id
      created
      updated
      type
      name
      bio
      image {
        key
        type
        size
        __typename
      }
      admins
      members
      tags
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateMessageChain = /* GraphQL */ `
  subscription OnCreateMessageChain(
    $filter: ModelSubscriptionMessageChainFilterInput
  ) {
    onCreateMessageChain(filter: $filter) {
      id
      type
      members
      name
      last_timestamp
      last_message
      last_sender
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateMessageChain = /* GraphQL */ `
  subscription OnUpdateMessageChain(
    $filter: ModelSubscriptionMessageChainFilterInput
  ) {
    onUpdateMessageChain(filter: $filter) {
      id
      type
      members
      name
      last_timestamp
      last_message
      last_sender
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteMessageChain = /* GraphQL */ `
  subscription OnDeleteMessageChain(
    $filter: ModelSubscriptionMessageChainFilterInput
  ) {
    onDeleteMessageChain(filter: $filter) {
      id
      type
      members
      name
      last_timestamp
      last_message
      last_sender
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateMessage = /* GraphQL */ `
  subscription OnCreateMessage($filter: ModelSubscriptionMessageFilterInput) {
    onCreateMessage(filter: $filter) {
      id
      message_chain
      content
      timestamp
      sender_id
      sender_org
      read
      hyperlinks {
        text
        url
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateMessage = /* GraphQL */ `
  subscription OnUpdateMessage($filter: ModelSubscriptionMessageFilterInput) {
    onUpdateMessage(filter: $filter) {
      id
      message_chain
      content
      timestamp
      sender_id
      sender_org
      read
      hyperlinks {
        text
        url
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteMessage = /* GraphQL */ `
  subscription OnDeleteMessage($filter: ModelSubscriptionMessageFilterInput) {
    onDeleteMessage(filter: $filter) {
      id
      message_chain
      content
      timestamp
      sender_id
      sender_org
      read
      hyperlinks {
        text
        url
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateEvent = /* GraphQL */ `
  subscription OnCreateEvent($filter: ModelSubscriptionEventFilterInput) {
    onCreateEvent(filter: $filter) {
      id
      timestamp
      name
      source_id
      user_id
      target_ids
      meta_data
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateEvent = /* GraphQL */ `
  subscription OnUpdateEvent($filter: ModelSubscriptionEventFilterInput) {
    onUpdateEvent(filter: $filter) {
      id
      timestamp
      name
      source_id
      user_id
      target_ids
      meta_data
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteEvent = /* GraphQL */ `
  subscription OnDeleteEvent($filter: ModelSubscriptionEventFilterInput) {
    onDeleteEvent(filter: $filter) {
      id
      timestamp
      name
      source_id
      user_id
      target_ids
      meta_data
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateOrganization = /* GraphQL */ `
  subscription OnCreateOrganization(
    $filter: ModelSubscriptionOrganizationFilterInput
  ) {
    onCreateOrganization(filter: $filter) {
      id
      name
      query_name
      status
      type
      specific_type
      website
      address
      bio
      members
      profile_image {
        key
        type
        size
        __typename
      }
      last_seen
      children
      parent
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateOrganization = /* GraphQL */ `
  subscription OnUpdateOrganization(
    $filter: ModelSubscriptionOrganizationFilterInput
  ) {
    onUpdateOrganization(filter: $filter) {
      id
      name
      query_name
      status
      type
      specific_type
      website
      address
      bio
      members
      profile_image {
        key
        type
        size
        __typename
      }
      last_seen
      children
      parent
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteOrganization = /* GraphQL */ `
  subscription OnDeleteOrganization(
    $filter: ModelSubscriptionOrganizationFilterInput
  ) {
    onDeleteOrganization(filter: $filter) {
      id
      name
      query_name
      status
      type
      specific_type
      website
      address
      bio
      members
      profile_image {
        key
        type
        size
        __typename
      }
      last_seen
      children
      parent
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateUser = /* GraphQL */ `
  subscription OnCreateUser($filter: ModelSubscriptionUserFilterInput) {
    onCreateUser(filter: $filter) {
      id
      email
      status
      sub
      organization_ids
      outer_tags
      inner_tags
      properties
      first_name
      last_name
      social_links {
        text
        url
        type
        __typename
      }
      notifications {
        id
        enabled
        schedule
        __typename
      }
      in_app_notifications {
        type
        timestamp
        __typename
      }
      phone
      position
      bio
      profile_image {
        key
        type
        size
        __typename
      }
      post_created
      referral_org
      referral_user
      signup_timestamp
      last_seen
      expiration
      subscription
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateUser = /* GraphQL */ `
  subscription OnUpdateUser($filter: ModelSubscriptionUserFilterInput) {
    onUpdateUser(filter: $filter) {
      id
      email
      status
      sub
      organization_ids
      outer_tags
      inner_tags
      properties
      first_name
      last_name
      social_links {
        text
        url
        type
        __typename
      }
      notifications {
        id
        enabled
        schedule
        __typename
      }
      in_app_notifications {
        type
        timestamp
        __typename
      }
      phone
      position
      bio
      profile_image {
        key
        type
        size
        __typename
      }
      post_created
      referral_org
      referral_user
      signup_timestamp
      last_seen
      expiration
      subscription
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteUser = /* GraphQL */ `
  subscription OnDeleteUser($filter: ModelSubscriptionUserFilterInput) {
    onDeleteUser(filter: $filter) {
      id
      email
      status
      sub
      organization_ids
      outer_tags
      inner_tags
      properties
      first_name
      last_name
      social_links {
        text
        url
        type
        __typename
      }
      notifications {
        id
        enabled
        schedule
        __typename
      }
      in_app_notifications {
        type
        timestamp
        __typename
      }
      phone
      position
      bio
      profile_image {
        key
        type
        size
        __typename
      }
      post_created
      referral_org
      referral_user
      signup_timestamp
      last_seen
      expiration
      subscription
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateWaitlist = /* GraphQL */ `
  subscription OnCreateWaitlist($filter: ModelSubscriptionWaitlistFilterInput) {
    onCreateWaitlist(filter: $filter) {
      id
      email
      timestamp
      referred_by
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateWaitlist = /* GraphQL */ `
  subscription OnUpdateWaitlist($filter: ModelSubscriptionWaitlistFilterInput) {
    onUpdateWaitlist(filter: $filter) {
      id
      email
      timestamp
      referred_by
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteWaitlist = /* GraphQL */ `
  subscription OnDeleteWaitlist($filter: ModelSubscriptionWaitlistFilterInput) {
    onDeleteWaitlist(filter: $filter) {
      id
      email
      timestamp
      referred_by
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreatePageView = /* GraphQL */ `
  subscription OnCreatePageView($filter: ModelSubscriptionPageViewFilterInput) {
    onCreatePageView(filter: $filter) {
      id
      views
      share_count
      refer_count
      opportunity_count
      last_viewed
      type
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdatePageView = /* GraphQL */ `
  subscription OnUpdatePageView($filter: ModelSubscriptionPageViewFilterInput) {
    onUpdatePageView(filter: $filter) {
      id
      views
      share_count
      refer_count
      opportunity_count
      last_viewed
      type
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeletePageView = /* GraphQL */ `
  subscription OnDeletePageView($filter: ModelSubscriptionPageViewFilterInput) {
    onDeletePageView(filter: $filter) {
      id
      views
      share_count
      refer_count
      opportunity_count
      last_viewed
      type
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateReferral = /* GraphQL */ `
  subscription OnCreateReferral($filter: ModelSubscriptionReferralFilterInput) {
    onCreateReferral(filter: $filter) {
      id
      status
      referral_org
      referral_user
      referral_group
      timestamp
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateReferral = /* GraphQL */ `
  subscription OnUpdateReferral($filter: ModelSubscriptionReferralFilterInput) {
    onUpdateReferral(filter: $filter) {
      id
      status
      referral_org
      referral_user
      referral_group
      timestamp
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteReferral = /* GraphQL */ `
  subscription OnDeleteReferral($filter: ModelSubscriptionReferralFilterInput) {
    onDeleteReferral(filter: $filter) {
      id
      status
      referral_org
      referral_user
      referral_group
      timestamp
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreatePost = /* GraphQL */ `
  subscription OnCreatePost($filter: ModelSubscriptionPostFilterInput) {
    onCreatePost(filter: $filter) {
      id
      status
      type
      timestamp
      org_id
      user_id
      group_id
      group_ids
      title
      description
      time_zone
      start_date
      start_time
      end_date
      end_time
      location
      media {
        key
        type
        size
        __typename
      }
      tags
      outer_tags
      inner_tags
      properties
      asks {
        title
        description
        emoji
        __typename
      }
      links {
        text
        url
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdatePost = /* GraphQL */ `
  subscription OnUpdatePost($filter: ModelSubscriptionPostFilterInput) {
    onUpdatePost(filter: $filter) {
      id
      status
      type
      timestamp
      org_id
      user_id
      group_id
      group_ids
      title
      description
      time_zone
      start_date
      start_time
      end_date
      end_time
      location
      media {
        key
        type
        size
        __typename
      }
      tags
      outer_tags
      inner_tags
      properties
      asks {
        title
        description
        emoji
        __typename
      }
      links {
        text
        url
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeletePost = /* GraphQL */ `
  subscription OnDeletePost($filter: ModelSubscriptionPostFilterInput) {
    onDeletePost(filter: $filter) {
      id
      status
      type
      timestamp
      org_id
      user_id
      group_id
      group_ids
      title
      description
      time_zone
      start_date
      start_time
      end_date
      end_time
      location
      media {
        key
        type
        size
        __typename
      }
      tags
      outer_tags
      inner_tags
      properties
      asks {
        title
        description
        emoji
        __typename
      }
      links {
        text
        url
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateOpportunity = /* GraphQL */ `
  subscription OnCreateOpportunity(
    $filter: ModelSubscriptionOpportunityFilterInput
  ) {
    onCreateOpportunity(filter: $filter) {
      id
      status
      timestamp
      members
      content_ids
      content {
        user_id
        content_id
        type
        inner_tags
        outer_tags
        __typename
      }
      data {
        user_id
        notes
        status
        updated
        email_timestamp
        __typename
      }
      tags
      groups
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateOpportunity = /* GraphQL */ `
  subscription OnUpdateOpportunity(
    $filter: ModelSubscriptionOpportunityFilterInput
  ) {
    onUpdateOpportunity(filter: $filter) {
      id
      status
      timestamp
      members
      content_ids
      content {
        user_id
        content_id
        type
        inner_tags
        outer_tags
        __typename
      }
      data {
        user_id
        notes
        status
        updated
        email_timestamp
        __typename
      }
      tags
      groups
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteOpportunity = /* GraphQL */ `
  subscription OnDeleteOpportunity(
    $filter: ModelSubscriptionOpportunityFilterInput
  ) {
    onDeleteOpportunity(filter: $filter) {
      id
      status
      timestamp
      members
      content_ids
      content {
        user_id
        content_id
        type
        inner_tags
        outer_tags
        __typename
      }
      data {
        user_id
        notes
        status
        updated
        email_timestamp
        __typename
      }
      tags
      groups
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateRolodex = /* GraphQL */ `
  subscription OnCreateRolodex($filter: ModelSubscriptionRolodexFilterInput) {
    onCreateRolodex(filter: $filter) {
      id
      source_user
      updated
      created
      first_name
      last_name
      emails
      job_title
      company
      locations
      inner_tags
      outer_tags
      description
      social_links
      notes {
        created
        updated
        note
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateRolodex = /* GraphQL */ `
  subscription OnUpdateRolodex($filter: ModelSubscriptionRolodexFilterInput) {
    onUpdateRolodex(filter: $filter) {
      id
      source_user
      updated
      created
      first_name
      last_name
      emails
      job_title
      company
      locations
      inner_tags
      outer_tags
      description
      social_links
      notes {
        created
        updated
        note
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteRolodex = /* GraphQL */ `
  subscription OnDeleteRolodex($filter: ModelSubscriptionRolodexFilterInput) {
    onDeleteRolodex(filter: $filter) {
      id
      source_user
      updated
      created
      first_name
      last_name
      emails
      job_title
      company
      locations
      inner_tags
      outer_tags
      description
      social_links
      notes {
        created
        updated
        note
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateInteraction = /* GraphQL */ `
  subscription OnCreateInteraction(
    $filter: ModelSubscriptionInteractionFilterInput
  ) {
    onCreateInteraction(filter: $filter) {
      id
      source_user
      target_user
      timestamp
      url
      content_id
      content_type
      action
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateInteraction = /* GraphQL */ `
  subscription OnUpdateInteraction(
    $filter: ModelSubscriptionInteractionFilterInput
  ) {
    onUpdateInteraction(filter: $filter) {
      id
      source_user
      target_user
      timestamp
      url
      content_id
      content_type
      action
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteInteraction = /* GraphQL */ `
  subscription OnDeleteInteraction(
    $filter: ModelSubscriptionInteractionFilterInput
  ) {
    onDeleteInteraction(filter: $filter) {
      id
      source_user
      target_user
      timestamp
      url
      content_id
      content_type
      action
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateGroupInvitation = /* GraphQL */ `
  subscription OnCreateGroupInvitation(
    $filter: ModelSubscriptionGroupInvitationFilterInput
  ) {
    onCreateGroupInvitation(filter: $filter) {
      id
      status
      admin
      sender_id
      invitee_id
      group_id
      created
      updated
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateGroupInvitation = /* GraphQL */ `
  subscription OnUpdateGroupInvitation(
    $filter: ModelSubscriptionGroupInvitationFilterInput
  ) {
    onUpdateGroupInvitation(filter: $filter) {
      id
      status
      admin
      sender_id
      invitee_id
      group_id
      created
      updated
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteGroupInvitation = /* GraphQL */ `
  subscription OnDeleteGroupInvitation(
    $filter: ModelSubscriptionGroupInvitationFilterInput
  ) {
    onDeleteGroupInvitation(filter: $filter) {
      id
      status
      admin
      sender_id
      invitee_id
      group_id
      created
      updated
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateThread = /* GraphQL */ `
  subscription OnCreateThread($filter: ModelSubscriptionThreadFilterInput) {
    onCreateThread(filter: $filter) {
      id
      owner_id
      status
      type
      security_level
      ai_assisted
      permissions {
        user_id
        role
        created
        updated
        __typename
      }
      members
      media {
        key
        type
        size
        __typename
      }
      tags
      title
      logo
      custom_logo {
        key
        type
        size
        __typename
      }
      content
      parent
      expiration
      created
      updated
      last_sender
      last_content
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateThread = /* GraphQL */ `
  subscription OnUpdateThread($filter: ModelSubscriptionThreadFilterInput) {
    onUpdateThread(filter: $filter) {
      id
      owner_id
      status
      type
      security_level
      ai_assisted
      permissions {
        user_id
        role
        created
        updated
        __typename
      }
      members
      media {
        key
        type
        size
        __typename
      }
      tags
      title
      logo
      custom_logo {
        key
        type
        size
        __typename
      }
      content
      parent
      expiration
      created
      updated
      last_sender
      last_content
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteThread = /* GraphQL */ `
  subscription OnDeleteThread($filter: ModelSubscriptionThreadFilterInput) {
    onDeleteThread(filter: $filter) {
      id
      owner_id
      status
      type
      security_level
      ai_assisted
      permissions {
        user_id
        role
        created
        updated
        __typename
      }
      members
      media {
        key
        type
        size
        __typename
      }
      tags
      title
      logo
      custom_logo {
        key
        type
        size
        __typename
      }
      content
      parent
      expiration
      created
      updated
      last_sender
      last_content
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateThreadContent = /* GraphQL */ `
  subscription OnCreateThreadContent(
    $filter: ModelSubscriptionThreadContentFilterInput
  ) {
    onCreateThreadContent(filter: $filter) {
      id
      thread_id
      owner_id
      type
      sub_type
      index
      status
      priority
      content
      content_html
      version
      media {
        key
        type
        size
        __typename
      }
      mentions
      metrics {
        goal
        progress
        description
        unit
        created
        updated
        __typename
      }
      tasks {
        id
        description
        status
        assignee_ids
        due_date
        updated
        __typename
      }
      dates {
        name
        date
        __typename
      }
      reactions {
        reaction
        user_id
        __typename
      }
      choices
      responses {
        user_id
        response
        __typename
      }
      form_items {
        id
        order
        type
        question
        required
        choices
        validation {
          type
          min
          max
          pattern
          error_message
          __typename
        }
        __typename
      }
      form_responses {
        user_id
        timestamp
        answers {
          item_id
          value
          values
          __typename
        }
        __typename
      }
      parent
      children
      expiration
      created
      updated
      scheduled
      reminder
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateThreadContent = /* GraphQL */ `
  subscription OnUpdateThreadContent(
    $filter: ModelSubscriptionThreadContentFilterInput
  ) {
    onUpdateThreadContent(filter: $filter) {
      id
      thread_id
      owner_id
      type
      sub_type
      index
      status
      priority
      content
      content_html
      version
      media {
        key
        type
        size
        __typename
      }
      mentions
      metrics {
        goal
        progress
        description
        unit
        created
        updated
        __typename
      }
      tasks {
        id
        description
        status
        assignee_ids
        due_date
        updated
        __typename
      }
      dates {
        name
        date
        __typename
      }
      reactions {
        reaction
        user_id
        __typename
      }
      choices
      responses {
        user_id
        response
        __typename
      }
      form_items {
        id
        order
        type
        question
        required
        choices
        validation {
          type
          min
          max
          pattern
          error_message
          __typename
        }
        __typename
      }
      form_responses {
        user_id
        timestamp
        answers {
          item_id
          value
          values
          __typename
        }
        __typename
      }
      parent
      children
      expiration
      created
      updated
      scheduled
      reminder
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteThreadContent = /* GraphQL */ `
  subscription OnDeleteThreadContent(
    $filter: ModelSubscriptionThreadContentFilterInput
  ) {
    onDeleteThreadContent(filter: $filter) {
      id
      thread_id
      owner_id
      type
      sub_type
      index
      status
      priority
      content
      content_html
      version
      media {
        key
        type
        size
        __typename
      }
      mentions
      metrics {
        goal
        progress
        description
        unit
        created
        updated
        __typename
      }
      tasks {
        id
        description
        status
        assignee_ids
        due_date
        updated
        __typename
      }
      dates {
        name
        date
        __typename
      }
      reactions {
        reaction
        user_id
        __typename
      }
      choices
      responses {
        user_id
        response
        __typename
      }
      form_items {
        id
        order
        type
        question
        required
        choices
        validation {
          type
          min
          max
          pattern
          error_message
          __typename
        }
        __typename
      }
      form_responses {
        user_id
        timestamp
        answers {
          item_id
          value
          values
          __typename
        }
        __typename
      }
      parent
      children
      expiration
      created
      updated
      scheduled
      reminder
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateTemplate = /* GraphQL */ `
  subscription OnCreateTemplate($filter: ModelSubscriptionTemplateFilterInput) {
    onCreateTemplate(filter: $filter) {
      id
      name
      owner_id
      type
      sub_type
      priority
      content
      content_html
      media {
        key
        type
        size
        __typename
      }
      mentions
      metrics {
        goal
        progress
        description
        unit
        created
        updated
        __typename
      }
      tasks {
        id
        description
        status
        assignee_ids
        due_date
        updated
        __typename
      }
      dates {
        name
        date
        __typename
      }
      choices
      expiration
      created
      updated
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateTemplate = /* GraphQL */ `
  subscription OnUpdateTemplate($filter: ModelSubscriptionTemplateFilterInput) {
    onUpdateTemplate(filter: $filter) {
      id
      name
      owner_id
      type
      sub_type
      priority
      content
      content_html
      media {
        key
        type
        size
        __typename
      }
      mentions
      metrics {
        goal
        progress
        description
        unit
        created
        updated
        __typename
      }
      tasks {
        id
        description
        status
        assignee_ids
        due_date
        updated
        __typename
      }
      dates {
        name
        date
        __typename
      }
      choices
      expiration
      created
      updated
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteTemplate = /* GraphQL */ `
  subscription OnDeleteTemplate($filter: ModelSubscriptionTemplateFilterInput) {
    onDeleteTemplate(filter: $filter) {
      id
      name
      owner_id
      type
      sub_type
      priority
      content
      content_html
      media {
        key
        type
        size
        __typename
      }
      mentions
      metrics {
        goal
        progress
        description
        unit
        created
        updated
        __typename
      }
      tasks {
        id
        description
        status
        assignee_ids
        due_date
        updated
        __typename
      }
      dates {
        name
        date
        __typename
      }
      choices
      expiration
      created
      updated
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateThreadSubscriptions = /* GraphQL */ `
  subscription OnCreateThreadSubscriptions(
    $filter: ModelSubscriptionThreadSubscriptionsFilterInput
  ) {
    onCreateThreadSubscriptions(filter: $filter) {
      id
      user_id
      thread_id
      status
      last_read
      notification_settings
      priority
      color
      group
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateThreadSubscriptions = /* GraphQL */ `
  subscription OnUpdateThreadSubscriptions(
    $filter: ModelSubscriptionThreadSubscriptionsFilterInput
  ) {
    onUpdateThreadSubscriptions(filter: $filter) {
      id
      user_id
      thread_id
      status
      last_read
      notification_settings
      priority
      color
      group
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteThreadSubscriptions = /* GraphQL */ `
  subscription OnDeleteThreadSubscriptions(
    $filter: ModelSubscriptionThreadSubscriptionsFilterInput
  ) {
    onDeleteThreadSubscriptions(filter: $filter) {
      id
      user_id
      thread_id
      status
      last_read
      notification_settings
      priority
      color
      group
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateThreadRequest = /* GraphQL */ `
  subscription OnCreateThreadRequest(
    $filter: ModelSubscriptionThreadRequestFilterInput
  ) {
    onCreateThreadRequest(filter: $filter) {
      id
      thread_id
      requester_id
      sender_id
      status
      reason
      created
      updated
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateThreadRequest = /* GraphQL */ `
  subscription OnUpdateThreadRequest(
    $filter: ModelSubscriptionThreadRequestFilterInput
  ) {
    onUpdateThreadRequest(filter: $filter) {
      id
      thread_id
      requester_id
      sender_id
      status
      reason
      created
      updated
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteThreadRequest = /* GraphQL */ `
  subscription OnDeleteThreadRequest(
    $filter: ModelSubscriptionThreadRequestFilterInput
  ) {
    onDeleteThreadRequest(filter: $filter) {
      id
      thread_id
      requester_id
      sender_id
      status
      reason
      created
      updated
      createdAt
      updatedAt
      __typename
    }
  }
`;
