import React, {useEffect, useState} from 'react';
import {
  PolarAngleAxis,
  PolarGrid,
  Radar,
  RadarChart,
  ResponsiveContainer,
} from 'recharts';
import useCollaborator from '../../hooks/use-collaborator';
import useStringFormatter from '../../hooks/use-string-formatter';

export const OpportunitiesRadarChart = ({tags, style}) => {
  const {tagToObject} = useCollaborator();
  const {prettyHeader} = useStringFormatter();

  const [data, setData] = useState([]);

  useEffect(() => {
    const sorted = {
      collaborative_projects: 0,
      education_skill: 0,
      research_development: 0,
      business_entrepreneurship: 0,
      community_social: 0,
      networking_professional: 0,
      resources_infrastructure: 0,
    };
    const opp_tags = tags.filter(tag => {
      const obj = tagToObject(tag);
      const type = obj?.type ?? null;
      return type === 'opportunity';
    });
    opp_tags.forEach(tag => {
      const obj = tagToObject(tag);
      const sub_type = obj?.sub_type ?? null;

      if (sorted[sub_type] === undefined) {
        sorted[sub_type] = 0;
      }
      sorted[sub_type] += 1;
    });
    const total = opp_tags.length;
    const data = Object.keys(sorted).map(key => {
      const value = sorted[key];
      const percent = (value / total).toFixed(1);
      return {key: prettyHeader(key, true), value: percent};
    });
    setData(data);
  }, [tags]);

  return (
    <div style={{height: '500px', ...style}}>
      <ResponsiveContainer width="100%" height="100%">
        <RadarChart cx="50%" cy="50%" outerRadius="70%" data={data}>
          <PolarGrid />
          <PolarAngleAxis
            dataKey="key"
            style={{fontSize: '12px'}}
            tick={{
              width: 50,
              wordWrap: 'break-word',
            }}
          />
          <Radar
            name="Mike"
            dataKey="value"
            stroke="#4657c0"
            fill="#4657c0"
            fillOpacity={0.8}
          />
        </RadarChart>
      </ResponsiveContainer>
    </div>
  );
};

export const TagsRadarChart = ({tags, style}) => {
  const {tagToObject} = useCollaborator();
  const {prettyHeader} = useStringFormatter();

  const [data, setData] = useState([]);

  useEffect(() => {
    const sorted = {
      opportunity: 0,
      collaborator: 0,
      skill: 0,
      degree: 0,
      major: 0,
    };
    tags.forEach(tag => {
      const obj = tagToObject(tag);
      const type = obj?.type ?? null;
      if (sorted[type] === undefined) {
        sorted[type] = 0;
      }
      sorted[type] += 1;
    });
    const total = tags.length;
    const data = Object.keys(sorted).map(key => {
      const value = sorted[key];
      const percent = (value / total).toFixed(1);
      return {key: prettyHeader(key), value: percent};
    });
    setData(data);
  }, [tags]);

  return (
    <div style={{height: '500px', ...style}}>
      <ResponsiveContainer width="100%" height="100%">
        <RadarChart cx="50%" cy="50%" outerRadius="70%" data={data}>
          <PolarGrid />
          <PolarAngleAxis
            dataKey="key"
            style={{fontSize: '12px'}}
            tick={{width: 80, wordWrap: 'break-word'}}
          />
          <Radar
            name="Mike"
            dataKey="value"
            stroke="#4657c0"
            fill="#4657c0"
            fillOpacity={0.8}
          />
        </RadarChart>
      </ResponsiveContainer>
    </div>
  );
};

export const CustomRadarChart = ({data}) => {
  // Step 1: Calculate the maximum value for each category
  const maxValues = {};
  for (const item of data) {
    for (const key in item) {
      if (key !== 'name') {
        maxValues[key] = Math.max(maxValues[key] || 0, item[key]);
      }
    }
  }

  // Step 2: Normalize the data
  const normalizedData = data.map(item => {
    const normalizedItem = {name: item.name};
    for (const key in item) {
      if (key !== 'name') {
        normalizedItem[key] = item[key] / maxValues[key];
      }
    }
    return normalizedItem;
  });

  // Step 3: Apply scaling factor if necessary
  const scalingFactor = 1; // Adjust as needed
  for (const item of normalizedData) {
    for (const key in item) {
      if (key !== 'name') {
        item[key] *= scalingFactor;
      }
    }
  }

  return (
    <div style={{height: '500px'}}>
      <ResponsiveContainer width="100%" height="100%">
        <RadarChart cx="50%" cy="50%" outerRadius="70%" data={normalizedData}>
          <PolarGrid />
          <PolarAngleAxis dataKey="name" />
          {/* <Radar
            name="Mike"
            dataKey="org1"
            stroke="#8884d8"
            fill="#8884d8"
            fillOpacity={0.3}
          />
          <Radar
            name="Mike"
            dataKey="org2"
            stroke="#c7327c"
            fill="#c7327c"
            fillOpacity={0.3}
          /> */}
          <Radar
            name="Mike"
            dataKey="similar"
            stroke="#4657c0"
            fill="#4657c0"
            fillOpacity={0.8}
          />
        </RadarChart>
      </ResponsiveContainer>
    </div>
  );
};
