import React from 'react';
import {useNavigate} from 'react-router-dom';
import constants from '../constants';
import {Modal} from './modal';

export const PaymentMeetingModal = ({active, setActive}) => {
  const navigate = useNavigate();

  return (
    <Modal
      active={active}
      setActive={val => {
        navigate('/payment-pending');
        setActive(val);
      }}>
      <p className="text-bold">
        Schedule a meeting with the following link to continue.
      </p>
      <br />
      <div className="flex justify-center">
        <a
          href={constants.jacks_calendly}
          target="_blank"
          rel="noopener noreferrer">
          Schedule
        </a>
      </div>
    </Modal>
  );
};
