import React from 'react';
import {PublicMindLogoLightNoTextSCBioSize} from '../../components/images/logos';
import './style.css'; // Import the CSS

const DesktopOnlyPage = () => {
  return (
    <div className="mobile-container">
      <div className="logo-container">
        {/* <SCBioLogo /> */}
        {/* <BsPlusLg className="logo-container-svg" /> */}
        <PublicMindLogoLightNoTextSCBioSize />
      </div>
      <div className="mobile-content">
        <h1 className="mobile-text">Welcome to PublicMind!</h1>
        <br />
        <p className="mobile-text">Looking for something?</p>
        <br />

        <h4 className="mobile-text">We're built for desktop so:</h4>

        <ul className="mobile-content-list">
          <li className="mobile-content-item">Turn on your computer.</li>

          <li className="mobile-content-item">
            Paste the link that took you here into your favorite browser
          </li>

          <li className="mobile-content-item">
            Explore PublicMind, a secure workspace for government, academia, and
            industry.
          </li>
        </ul>
      </div>
    </div>
  );
};

export default DesktopOnlyPage;
