import {
  default as React,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import ReactMarkdown from 'react-markdown';
import {fetchPreview} from '../../api/previews';
import {convertUrlsToMarkdown} from '../../utils/utils';
import './MagicMarkdown.css';

const MagicMarkdown = ({content, startExpanded = false}) => {
  const [links, setLinks] = useState([]);
  const [expanded, setExpanded] = useState(startExpanded);
  const [shouldCollapse, setShouldCollapse] = useState(false);
  const processedContent = useMemo(
    () => convertUrlsToMarkdown(content),
    [content],
  );

  useEffect(() => {
    const newLinks = new Set();
    // Regex to match both markdown links and raw URLs
    const regex = /(?:\[([^\]]+)\]\(([^)]+)\))|(\bhttps?:\/\/\S+\b)/g;

    const isValidUrl = url => {
      try {
        new URL(url);
        return true;
      } catch (e) {
        return false;
      }
    };

    let match;
    while ((match = regex.exec(processedContent)) !== null) {
      let url = match[2] || match[3]; // match[2] for markdown links, match[3] for raw URLs

      if (url && !url.startsWith('/feed/profiles/detail/')) {
        if (isValidUrl(url)) {
          newLinks.add(url);
        }
      }
    }

    setLinks(Array.from(newLinks));
    const wordCount = processedContent.split(/\s+/).length;
    setShouldCollapse(wordCount > 100);
  }, [processedContent]);

  const toggleExpand = () => setExpanded(!expanded);

  const NewTabLink = useCallback(({href, children}) => {
    const isExternal = /^https?:\/\//.test(href);
    const isMention = href.startsWith('/feed/profiles/detail/');

    if (isExternal && !isMention) {
      return (
        <a
          href={href}
          target="_blank"
          rel="noopener noreferrer"
          className="inline-link">
          {children}
        </a>
      );
    } else if (isExternal || isMention) {
      return (
        <a href={href} target="_blank" rel="noopener noreferrer">
          {children}
        </a>
      );
    } else {
      return <a href={href}>{children}</a>;
    }
  }, []);

  const components = useMemo(() => ({a: NewTabLink}), [NewTabLink]);
  return (
    <div className="magic-markdown-container">
      <div
        className={`markdown-content ${
          shouldCollapse && !expanded ? 'collapsed' : ''
        }`}>
        <ReactMarkdown components={components}>
          {processedContent}
        </ReactMarkdown>
      </div>
      {shouldCollapse && (
        <button className="expand-toggle" onClick={toggleExpand}>
          {expanded ? 'Show less' : 'Show more'}
        </button>
      )}
      {links.length > 0 && (
        <div className="link-previews-container">
          {links.map((url, index) => (
            <LinkPreview key={index} url={url} />
          ))}
        </div>
      )}
    </div>
  );
};

const LinkPreview = ({url}) => {
  const [preview, setPreview] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [expanded, setExpanded] = useState(true);

  useEffect(() => {
    const fetch = async () => {
      try {
        // Unescape the URL
        const unescapedUrl = url.replace(/\\/g, '');

        // Updated Loom regex to handle the new URL format
        const loomMatch = unescapedUrl.match(
          /loom\.com\/(share|embed)\/([a-zA-Z0-9]+)/,
        );
        if (loomMatch) {
          setPreview({type: 'loom', id: loomMatch[2]});
          setLoading(false);
          return;
        }

        const youtubeMatch = unescapedUrl.match(
          /(?:youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?\/\s]{11})/,
        );
        if (youtubeMatch) {
          setPreview({type: 'youtube', id: youtubeMatch[1]});
          setLoading(false);
          return;
        }

        const {success, error, preview} = await fetchPreview({
          url: unescapedUrl,
        });

        if (success) {
          setPreview({type: 'generic', data: preview});
        } else {
          setError(error);
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };
    if (url.includes('youtube') || url.includes('loom')) {
      setLoading(true);
      fetch();
    }
  }, [url]);

  const toggleExpand = () => setExpanded(!expanded);

  if (loading) {
    return <div className="link-preview-skeleton" />;
  }

  if (error || !preview) {
    return null;
  }

  switch (preview.type) {
    case 'loom':
      return (
        <LoomPreview
          videoId={preview.id}
          expanded={expanded}
          onToggle={toggleExpand}
        />
      );
    case 'youtube':
      return (
        <YouTubePreview
          videoId={preview.id}
          expanded={expanded}
          onToggle={toggleExpand}
        />
      );
    case 'generic':
      return null;
      return (
        <GenericLinkPreview
          url={url}
          preview={preview.data}
          expanded={expanded}
          onToggle={toggleExpand}
        />
      );
    default:
      return null;
  }
};

const LoomPreview = ({videoId, expanded, onToggle}) => (
  <div
    className={`embed-preview loom-preview ${expanded ? 'expanded' : ''}`}
    onClick={onToggle}>
    {expanded ? (
      <iframe
        title={`Loom ${videoId}`}
        src={`https://www.loom.com/embed/${videoId}`}
        allowFullScreen
      />
    ) : (
      <div className="preview-placeholder">Loom Video - Click to Watch</div>
    )}
  </div>
);

const YouTubePreview = ({videoId, expanded, onToggle}) => (
  <div
    className={`embed-preview youtube-preview ${expanded ? 'expanded' : ''}`}
    onClick={onToggle}>
    {expanded ? (
      <iframe
        title={`YouTube ${videoId}`}
        src={`https://www.youtube.com/embed/${videoId}`}
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />
    ) : (
      <div className="preview-placeholder">YouTube Video - Click to Watch</div>
    )}
  </div>
);

const GenericLinkPreview = ({url, preview, expanded, onToggle}) => (
  <div
    className={`link-preview ${expanded ? 'expanded' : ''}`}
    onClick={onToggle}>
    {preview.image && (
      <div className="link-preview-image">
        <img src={preview.image} alt={preview.title} />
      </div>
    )}
    <div className="link-preview-content">
      <h4>{preview.title}</h4>
      {expanded && <p>{preview.description}</p>}
      <a
        href={url}
        target="_blank"
        rel="noopener noreferrer"
        className="link-preview-url"
        onClick={e => e.stopPropagation()}>
        {new URL(url).hostname}
      </a>
    </div>
  </div>
);

export default React.memo(MagicMarkdown);
