import React, {useCallback, useState} from 'react';
import {BadgeDot} from '../badge/badge';
import './styles.css';

export const Tab = ({items, setIndex, index, style}) => {
  const [isThrottled, setIsThrottled] = useState(false);

  const throttledSetIndex = useCallback(
    i => {
      if (!isThrottled) {
        setIndex(i);
        setIsThrottled(true);
        setTimeout(() => {
          setIsThrottled(false);
        }, 300);
      }
    },
    [isThrottled, setIndex],
  );

  return (
    <div className="sleek-tab-container" style={style}>
      {items.map((item, i) => {
        const {tab, notifications} = item;

        return (
          <button
            key={i}
            className={`sleek-tab ${i === index ? 'sleek-tab-active' : ''}`}
            onClick={() => throttledSetIndex(i)}>
            {tab}
            {notifications > 0 && (
              <BadgeDot
                active={notifications}
                className="sleek-tab-notification"
              />
            )}
          </button>
        );
      })}
    </div>
  );
};
