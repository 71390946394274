import React, {useEffect, useState} from 'react';
import {RxChatBubble, RxCheckbox, RxPencil1} from 'react-icons/rx';
import {useNavigate} from 'react-router-dom';
import {
  dateToTimestamp,
  daysBetweenTodayAndTimestamp,
  formatMMDDYYYY,
  timeSince,
} from '../../../api/dates';
import {DateTimeInput} from '../../../components/inputs/date-input';
import {ArraySearchableDropDown} from '../../../components/inputs/drop-down';
import {Textfield} from '../../../components/inputs/textfields';
import {ProfileImage} from '../../../components/inputs/uploader';
import MagicMarkdown from '../../../components/markdown/magic-markdown';
import {Modal} from '../../../components/modal/modal';
import {useAuth} from '../../../hooks/use-auth';
import useCrypto from '../../../hooks/use-crypto';
import useLoader from '../../../hooks/use-loader';
import useStringFormatter from '../../../hooks/use-string-formatter';
import {useThreads} from '../../../hooks/use-threads';
import './TaskStyles.css';

export const TaskDisplayGroup = ({item}) => {
  const {updateContent} = useThreads();
  const {
    state: {id: user_id},
  } = useAuth();

  const {id, thread_id, owner_id, tasks, content, updated} = item;

  const [edit, setEdit] = useState(false);

  const updateTask = async (taskId, newStatus, newDescription) => {
    const updatedTasks = tasks.map(task =>
      task.id === taskId
        ? {
            ...task,
            status: newStatus || task.status,
            description: newDescription || task.description,
            updated: dateToTimestamp(),
          }
        : task,
    );
    const update = {
      id: id,
      tasks: updatedTasks,
      updated: dateToTimestamp(),
    };

    const {success, error} = await updateContent(update);
  };

  return (
    <div className="task-display-container">
      <div className="task-display-header">
        <p className="text-secondary text-12">
          Last Updated: {timeSince(updated)}
        </p>
        <span className="task-display-count">{tasks?.length} tasks</span>
      </div>
      <div className="task-display-table">
        <div className="task-display-table-header">
          <div className="task-display-cell task-display-description">
            Description
          </div>
          <div className="task-display-cell task-display-owners">Assignee</div>
          <div className="task-display-cell task-display-status">Status</div>
          <div className="task-display-cell task-display-due-date">
            Due Date
          </div>
          {owner_id === user_id && (
            <div className="task-display-cell task-display-actions">
              Actions
            </div>
          )}
        </div>
        {tasks &&
          tasks.map(task => (
            <TaskDisplayItem
              key={task.id}
              item={task}
              owner_id={owner_id}
              updateTask={updateTask}
              onEdit={() => {
                setEdit(task);
              }}
            />
          ))}
      </div>
      {!!edit && (
        <EditIndividualTaskModal
          active={edit}
          setActive={setEdit}
          item={item}
        />
      )}
    </div>
  );
};

export const TaskDisplayItem = ({item, owner_id, onEdit, updateTask}) => {
  const navigate = useNavigate();
  const {prettyName} = useStringFormatter();
  const {loadProfiles} = useLoader();
  const {encryptString} = useCrypto();
  const [overdue, setOverdue] = useState(false);

  const {
    state: {id: currentUserId, profiles},
  } = useAuth();

  const {id, description, due_date, status, assignee_ids} = item || {};

  const isOwner = owner_id === currentUserId;
  const isAssignee = assignee_ids?.includes(currentUserId);
  const canEditStatus = isOwner || isAssignee;

  useEffect(() => {
    if (assignee_ids && assignee_ids.length) {
      loadProfiles(assignee_ids);
    }
  }, [assignee_ids]);

  useEffect(() => {
    if (owner_id) {
      loadProfiles([owner_id]);
    }
  }, [owner_id]);

  useEffect(() => {
    if (!due_date) return;
    if (
      daysBetweenTodayAndTimestamp(due_date) < 0 &&
      (status === 'todo' || status === 'in_progress')
    ) {
      setOverdue(true);
    }
  }, [due_date, status]);

  const handleTaskStatusChange = newStatus => {
    if (canEditStatus) {
      updateTask(item.id, newStatus, null);
    }
  };

  const formatDueDate = date => {
    if (!date) return 'No due date';
    return formatMMDDYYYY(new Date(date * 1000));
  };

  return (
    <div className={`task-display-row ${overdue ? 'overdue' : ''}`}>
      <div
        className="task-display-cell task-display-description"
        onClick={() => {
          if (isOwner) {
            onEdit();
          }
        }}>
        <MagicMarkdown content={description} />
      </div>
      <div className="task-display-cell task-display-owners">
        {assignee_ids.map((assigneeId, index) => {
          const profile = profiles?.[assigneeId];
          const {profile_image} = profile || {};
          return (
            <span
              key={`${id}-${index}`}
              className="task-display-owner"
              onClick={() => {
                if (isOwner) {
                  onEdit();
                }
              }}>
              <ProfileImage
                data={profile_image}
                style={{height: '24px', width: '24px'}}
                onClick={() => {
                  const parsed = encryptString(assigneeId);
                  navigate(`/feed/profiles/detail/${parsed}`);
                }}
              />
              <span className="task-display-owner-name">
                {prettyName(profile)}
              </span>
            </span>
          );
        })}
      </div>
      <div className="task-display-cell task-display-status">
        <select
          value={status}
          onChange={e => handleTaskStatusChange(e.target.value)}
          disabled={!canEditStatus}
          className={`task-display-status-text task-display-${status
            ?.toLowerCase()
            ?.replace(' ', '-')}`}>
          <option value="todo">To Do</option>
          <option value="in-progress">In Progress</option>
          <option value="complete">Complete</option>
          <option value="rejected">Rejected</option>
        </select>
      </div>
      <div
        className="task-display-cell task-display-due-date"
        onClick={() => {
          if (isOwner) {
            onEdit();
          }
        }}>
        {formatDueDate(due_date)}
      </div>
      {isOwner && (
        <div className="task-display-cell task-display-actions">
          <RxPencil1
            className="clickable"
            onClick={() => {
              onEdit();
            }}
          />
        </div>
      )}
    </div>
  );
};

const EditIndividualTaskModal = ({active, setActive, item}) => {
  const {prettyName} = useStringFormatter();
  const {
    state: {threads},
    updateContent,
  } = useThreads();
  const {
    state: {profiles},
  } = useAuth();

  const {description, due_date, assignee_ids} = active || {};
  const {thread_id, tasks} = item;
  const thread = threads?.[thread_id] ?? {};
  const {members} = thread;

  const [desc, setDesc] = useState(description || '');
  const [assignees, setAssignees] = useState(assignee_ids || []);
  const [chosenDate, setDate] = useState(
    due_date ? new Date(due_date * 1000) : null,
  );
  const [timestamp, setTimestamp] = useState(due_date);

  useEffect(() => {
    if (chosenDate) {
      setTimestamp(dateToTimestamp(chosenDate));
    } else {
      setTimestamp(null);
    }
  }, [chosenDate]);

  return (
    <Modal active={!!active} setActive={setActive}>
      <div className="pretty-form-group">
        <h4>Edit Task</h4>
        <Textfield
          value={desc}
          type="text"
          onChange={e => setDesc(e.target.value)}
          header="Description"
        />

        <ArraySearchableDropDown
          header="Assignees"
          setValues={setAssignees}
          values={assignees}
          items={members?.map(email => {
            const profile = profiles?.[email] ?? {id: email, email};
            return {value: email, key: prettyName(profile)};
          })}
        />
        <DateTimeInput
          date={chosenDate}
          setDate={setDate}
          label="Due Date"
          future_only={true}
        />
        <br />
        <div className="flex justify-center">
          <button
            onClick={async () => {
              const mapped = tasks.map(task => {
                if (task.id === active.id) {
                  return {
                    ...task,
                    description: desc,
                    due_date: timestamp,
                    assignee_ids: assignees,
                  };
                }
                return task;
              });
              const update = {id: item.id, tasks: mapped};
              await updateContent(update);
              setActive(null);
            }}>
            Save
          </button>
        </div>
      </div>
    </Modal>
  );
};

const TaskGroup = ({thread, tasks, setCurrent, setIndex, setContent}) => {
  const [isExpanded, setIsExpanded] = useState(!thread.isCompleted);

  return (
    <div className="task-display-container">
      <div className="task-display-header">
        <h4 className="task-group-title">
          {thread?.logo} {thread.title}
        </h4>
        <span className="task-display-count">
          {tasks.reduce((acc, task) => acc + task.tasks.length, 0)} tasks
        </span>
      </div>
      <div className="task-display-table">
        <div className="task-display-table-header">
          <div className="task-display-cell task-display-description">
            Description
          </div>
          <div className="task-display-cell task-display-owners">Assignee</div>
          <div className="task-display-cell task-display-status">Status</div>
          <div className="task-display-cell task-display-due-date">
            Due Date
          </div>
          <div className="task-display-cell task-display-actions">Actions</div>
        </div>
        {isExpanded &&
          tasks.map(item => (
            <TaskItem
              key={item.id}
              item={item}
              thread={thread}
              onClick={() => {
                setIndex('thread-detail');
                setCurrent(thread.id);
                setContent(item.id);
              }}
            />
          ))}
      </div>
    </div>
  );
};

const TaskItem = ({item, thread, onClick}) => {
  const navigate = useNavigate();
  const {prettyName} = useStringFormatter();
  const {loadProfiles} = useLoader();
  const {encryptString} = useCrypto();
  const {updateContent} = useThreads();
  const [isEditing, setIsEditing] = useState(false);
  const [editedContent, setEditedContent] = useState(item.content);

  const {
    state: {id: currentUserId, profiles},
  } = useAuth();

  const {id, owner_id, content, created, status, mentions, tasks} = item || {};

  const isOwner = owner_id === currentUserId;
  const isAssignee = mentions?.includes(currentUserId);
  const canEditStatus = isOwner || isAssignee;

  useEffect(() => {
    if (owner_id) {
      loadProfiles([owner_id]);
    }
    if (mentions && mentions.length) {
      loadProfiles(mentions);
    }
  }, [owner_id, mentions, loadProfiles]);

  const handleEdit = () => {
    if (isOwner) {
      setIsEditing(true);
      setEditedContent(content);
    }
  };

  const handleSave = taskId => {
    if (isOwner) {
      const updatedTasks = tasks.map(task =>
        task.id === taskId
          ? {...task, description: editedContent, updated: dateToTimestamp()}
          : task,
      );

      updateContent({id, tasks: updatedTasks, updated: dateToTimestamp()});
      setIsEditing(false);
    }
  };

  const handleTaskStatusChange = (taskId, newStatus) => {
    if (canEditStatus) {
      const updatedTasks = tasks.map(task =>
        task.id === taskId
          ? {...task, status: newStatus, updated: dateToTimestamp()}
          : task,
      );

      updateContent({id, tasks: updatedTasks, updated: dateToTimestamp()});
    }
  };

  const formatDueDate = date => {
    if (!date) return 'No due date';
    return formatMMDDYYYY(new Date(date * 1000));
  };

  return (
    <>
      {tasks.map(task => (
        <div key={task.id} className="task-display-row">
          <div className="task-display-cell task-display-description">
            {isEditing ? (
              <span className="pretty-form-group">
                <input
                  value={editedContent}
                  onChange={e => setEditedContent(e.target.value)}
                />
              </span>
            ) : (
              <MagicMarkdown content={task.description} />
            )}
          </div>
          <div className="task-display-cell task-display-owners">
            {task.assignee_ids.map((assigneeId, index) => {
              const profile = profiles?.[assigneeId];
              const {profile_image} = profile || {};
              return (
                <span
                  key={`${task.id}-${index}`}
                  className="task-display-owner">
                  <ProfileImage
                    data={profile_image}
                    style={{height: '24px', width: '24px'}}
                    onClick={() => {
                      const parsed = encryptString(assigneeId);
                      navigate(`/feed/profiles/detail/${parsed}`);
                    }}
                  />
                  <span className="task-display-owner-name">
                    {prettyName(profile)}
                  </span>
                </span>
              );
            })}
          </div>
          <div className="task-display-cell task-display-status">
            <select
              value={task.status}
              onChange={e => handleTaskStatusChange(task.id, e.target.value)}
              disabled={!canEditStatus}
              className={`task-display-status-text task-display-${task.status
                ?.toLowerCase()
                ?.replace(' ', '-')}`}>
              <option value="todo">To Do</option>
              <option value="in-progress">In Progress</option>
              <option value="complete">Complete</option>
              <option value="rejected">Rejected</option>
            </select>
          </div>
          <div className="task-display-cell task-display-due-date">
            {formatDueDate(task.due_date)}
          </div>
          <div className="task-display-cell task-display-actions">
            <span className="flex-row">
              {isOwner &&
                (isEditing ? (
                  <button
                    onClick={() => {
                      handleSave(task.id);
                    }}>
                    Save
                  </button>
                ) : (
                  <button onClick={handleEdit}>
                    <RxPencil1 className="clickable" />
                  </button>
                ))}
              <button onClick={onClick}>
                <RxChatBubble />
              </button>
              {/* <button disabled>
                <RxTrash />
              </button> */}
            </span>
          </div>
        </div>
      ))}
    </>
  );
};

const TasksTab = ({setCurrent, setIndex, setContent}) => {
  const {
    state: {id: user_id},
  } = useAuth();
  const {
    state: {threads, thread_content},
  } = useThreads();

  const [groupedTasks, setGroupedTasks] = useState({});

  useEffect(() => {
    const tasksByThread = {};

    Object.values(thread_content).forEach(content => {
      if (
        content?.type === 'task' &&
        (content?.mentions?.includes(user_id) ||
          content?.owner_id === user_id ||
          content.tasks?.some(task => task.assignee_ids?.includes(user_id)))
      ) {
        if (!tasksByThread[content.thread_id]) {
          tasksByThread[content.thread_id] = [];
        }
        tasksByThread[content.thread_id].push(content);
      }
    });

    Object.keys(tasksByThread).forEach(threadId => {
      tasksByThread[threadId].sort((a, b) => b.created - a.created);
    });

    setGroupedTasks(tasksByThread);
  }, [thread_content, user_id]);

  return (
    <div className="tasks-container">
      <div className="tasks-header">
        <h3 className="tasks-title">All Tasks</h3>
        <p className="text-secondary">
          Tasks grouped by threads, listed chronologically
        </p>
      </div>

      <div className="tasks-list">
        {Object.keys(groupedTasks).length ? (
          Object.entries(groupedTasks).map(([threadId, tasks]) => (
            <TaskGroup
              key={threadId}
              thread={threads[threadId]}
              tasks={tasks}
              setCurrent={setCurrent}
              setIndex={setIndex}
              setContent={setContent}
            />
          ))
        ) : (
          <div className="tasks-empty">
            <RxCheckbox className="tasks-empty-icon" />
            <p>No tasks yet</p>
            <p>All tasks across threads will appear here</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default TasksTab;
