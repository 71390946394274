import React, {useEffect} from 'react';
import {
  RxEnter,
  RxInfoCircled,
  RxLockClosed,
  RxLockOpen1,
  RxLockOpen2,
} from 'react-icons/rx';
import {useNavigate} from 'react-router-dom';
import {dateToTimestamp} from '../../../api/dates';
import {ProfileImage} from '../../../components/inputs/uploader';
import MagicMarkdown from '../../../components/markdown/magic-markdown';
import {useAuth} from '../../../hooks/use-auth';
import useLoader from '../../../hooks/use-loader';
import useStringFormatter from '../../../hooks/use-string-formatter';
import {useThreads} from '../../../hooks/use-threads';
import '../overview-tabs/ThreadFeed.css';

// FEED ITEM DISPLAYED IN THE THREAD-FEED OVERVIEW PAGE
export const ThreadFeedItem = ({item, setIndex, setCurrent, setContent}) => {
  const navigate = useNavigate();
  const {loadProfiles, loadOrgs} = useLoader();
  const {prettyTag, prettyPosition, prettyName} = useStringFormatter();
  const {
    state: {id: user_id, profiles, organizations},
  } = useAuth();
  const {
    state: {thread_ids},
    defaultUpdate,
    updateThread,
  } = useThreads();

  const {
    id,
    title,
    content,
    permissions,
    members,
    tags,
    security_level,
    logo,
    owner_id,
    custom_logo,
  } = item;
  const active_member = members?.includes(user_id);
  const joinable = security_level === 'open' && !active_member;

  const owner = profiles?.[owner_id] || {};
  const {position, profile_image, organization_ids} = owner;
  const pretty_name = prettyName(owner);
  const org_id = organization_ids?.[0];
  const pretty_position = prettyPosition(organizations?.[org_id]);
  const isPublicMindSponsored = owner_id?.includes('@publicmind.ai');

  const handleJoin = async () => {
    const now = dateToTimestamp();
    const updated_permissions = [
      ...permissions,
      {user_id, role: 'collaborator', created: now, updated: now},
    ];
    const updated_members = updated_permissions.map(item => item.user_id);
    await updateThread({
      id,
      permissions: updated_permissions,
      members: updated_members,
    });
    const new_threads = [...thread_ids];
    if (!new_threads?.includes(id)) {
      new_threads.push(id);
    }
    defaultUpdate({thread_ids: new_threads});
    setIndex('thread-detail');
    setCurrent(id);
    navigate(`/threads/${id}`);
  };

  useEffect(() => {
    if (!profiles?.[owner_id]) {
      loadProfiles([owner_id]);
    } else {
      let org_id = organization_ids?.[0];
      if (!org_id) return;
      if (!organizations?.[org_id]) {
        loadOrgs(organization_ids);
      }
    }
  }, [
    owner_id,
    profiles,
    loadProfiles,
    organization_ids,
    organizations,
    loadOrgs,
  ]);

  return (
    <div
      className={`feed-item ${
        isPublicMindSponsored ? 'feed-item--sponsored' : ''
      }`}>
      <div className="feed-item__header">
        {isPublicMindSponsored ? (
          <div className="feed-item__sponsored-tag">
            <RxInfoCircled className="feed-item__sponsored-icon" />
            PublicMind Official
          </div>
        ) : (
          <div className="feed-item__owner-info">
            <ProfileImage
              data={profile_image}
              style={{height: '28px', width: '28px'}}
              onClick={() => navigate(`/feed/profiles/detail/${owner_id}`)}
            />
            <div className="feed-item__owner-details">
              <p className="feed-item__owner-name">{pretty_name}</p>
              <p className="feed-item__owner-position">
                {position}
                {pretty_position ? ', ' + pretty_position : null}
              </p>
            </div>
          </div>
        )}
        <div className="feed-item__actions">
          {active_member && (
            <button
              className="feed-item__button feed-item__button--enter"
              onClick={() => {
                setIndex('thread-detail');
                setCurrent(id);
              }}>
              <RxEnter /> Enter
            </button>
          )}
          {!active_member && security_level === 'private' && (
            <button
              className="feed-item__button feed-item__button--request"
              onClick={() => navigate(`/threads/request/${id}`)}>
              Request to Join
            </button>
          )}
          {joinable && (
            <button
              className="feed-item__button feed-item__button--join"
              onClick={handleJoin}>
              Join
            </button>
          )}
        </div>
      </div>
      <div className="feed-item__title-group">
        {custom_logo && (
          <img
            src={custom_logo?.url}
            alt={`${title} Logo`}
            style={{
              height: '16px',
              maxWidth: '32px',
              width: 'auto',
              marginRight: '4px',
            }}
          />
        )}
        <h3
          className="feed-item__title clickable"
          onClick={() => {
            if (active_member) {
              setIndex('thread-detail');
              setCurrent(id);
            } else if (!active_member && security_level === 'private') {
              navigate(`/threads/request/${id}`);
            } else if (joinable) {
              handleJoin();
            }
          }}>
          {logo} {title}
        </h3>
        {security_level === 'secure' ? (
          <RxLockClosed
            className="security-icon-small secure"
            // onClick={() => setIsInfoPopupOpen(true)}
          />
        ) : security_level === 'private' ? (
          <RxLockOpen1
            className="security-icon-small private"
            // onClick={() => setIsInfoPopupOpen(true)}
          />
        ) : (
          <RxLockOpen2
            className="security-icon-small open"
            // onClick={() => setIsInfoPopupOpen(true)}
          />
        )}
      </div>
      <p className="feed-item__content">
        <MagicMarkdown
          content={
            content?.length > 150 ? `${content.slice(0, 150)}...` : content
          }
        />
      </p>
      {tags && tags.length > 0 && (
        <div className="feed-item__tags">
          {tags.slice(0, 12).map(tag => (
            <span key={tag} className="feed-item__tag">
              {prettyTag(tag)}
            </span>
          ))}
          {tags.length > 12 && (
            <span className="feed-item__tag feed-item__tag--more">
              +{tags.length - 12} more
            </span>
          )}
        </div>
      )}
    </div>
  );
};
