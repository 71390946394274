import {useFormik} from 'formik';
import React, {useEffect, useState} from 'react';
import * as Yup from 'yup';
import {track} from '../../api/analytics';
import {
  dateToTimestamp,
  daysBetweenTodayAndTimestamp,
  formatMMDDYYYY,
} from '../../api/dates';
import ActionPopup from '../../components/action-feedback-popup/action-feedback-popup';
import constants from '../../components/constants';
import {TagInput} from '../../components/inputs/tag-picker';
import {
  FormikPhoneInput,
  FormikTextfield,
  MultilineFormikTextfield,
} from '../../components/inputs/textfields';
import {ProfileImageUpload} from '../../components/inputs/uploader';
import {SharePrioritiesModal} from '../../components/modal/external-engagement-modals';
import {InviteModal} from '../../components/modal/invite-modal';
import {ReferralBox} from '../../components/referral/referral';
import {Tab} from '../../components/tab/tab';
import {useAuth} from '../../hooks/use-auth';
import useCollaborator from '../../hooks/use-collaborator';
import {useMounted} from '../../hooks/use-mounted';

const ProfileSettings = ({}) => {
  const [index, setIndex] = useState(0);
  const tabs = [
    {tab: 'Personal', index: 0},
    {tab: 'Request Tags', index: 1},
    {tab: 'Resource Tags', index: 2},
    // {tab: 'Membership', index: 3},
  ];

  return (
    <div className="page-container">
      <Tab items={tabs} index={index} setIndex={setIndex} />
      <div className="padding-top8" />
      {index === 0 && (
        <div className="grid-container">
          <div className="grid-7">
            <ProfileImage />
            <BasicInfo />
          </div>
          <div className="grid-5">
            <ChangePassword />
          </div>
        </div>
      )}
      {index === 1 && <OuterTagsCard />}
      {index === 2 && <InnerTagsCard />}
      {/* {index === 3 && <PostsCard />} */}
      {index === 3 && <MembershipCard />}

      {/* <NotificationSettings /> */}
    </div>
  );
};

const ProfileImage = () => {
  return (
    <div className="flex justify-center">
      <ProfileImageUpload types={constants.image_mime_types} type="profile" />
    </div>
  );
};

const BasicInfo = () => {
  const isMounted = useMounted();

  const {
    state: {id, profile},
    updateProfile,
  } = useAuth();

  const handleError = (helpers, err) => {
    if (isMounted()) {
      helpers.setStatus({success: false});
      helpers.setErrors({submit: err.message});
      helpers.setSubmitting(false);
    }
  };
  const [isPopupVisible, setPopupVisible] = useState({
    on: false,
    message: '',
  });
  const formik = useFormik({
    initialValues: {
      first_name: profile?.first_name ?? '',
      last_name: profile?.last_name ?? '',
      position: profile?.position ?? '',
      bio: profile?.bio ?? '',
      phone: profile?.phone ?? '',
      properties: profile?.properties ?? [],
    },
    validationSchema: Yup.object({
      first_name: Yup.string().trim(),
      last_name: Yup.string().trim(),
      position: Yup.string().trim(),
      bio: Yup.string().trim(),
      phone: Yup.number('Phone number must be a number.'),
    }),
    onSubmit: async (values, helpers) => {
      try {
        const {first_name, last_name, position, bio, phone, properties} =
          values;
        const update = {
          id,
          first_name,
          last_name,
          position,
          phone,
          bio,
          properties,
        };

        const {success, error} = await updateProfile(update);

        if (success && isMounted()) {
          setPopupVisible({on: true, message: 'Success!'});
          track('profile_update', update);
        }
        if (error) {
          setPopupVisible({on: true, message: 'Failed to save.'});
          handleError(helpers, error);
        }
      } catch (err) {
        setPopupVisible({
          on: true,
          message: 'Error. Please notify the PublicMind team.',
        });
        handleError(helpers, err);
      }
    },
  });

  return (
    <div className="card pretty-form-group">
      <h4>Info</h4>
      <div className="flex-row">
        <div className="flex flex-column padding-h8">
          <FormikTextfield
            formik={formik}
            placeholder=""
            name="first_name"
            type="text"
            header="First Name"
          />
          <FormikTextfield
            formik={formik}
            placeholder=""
            name="position"
            type="text"
            header="Position"
          />
        </div>
        <div className="flex flex-column padding-h8">
          <FormikTextfield
            formik={formik}
            placeholder=""
            name="last_name"
            type="text"
            header="Last Name"
          />
          <FormikPhoneInput
            formik={formik}
            placeholder=""
            name="phone"
            header="Phone Number"
            autoComplete="tel"
          />
        </div>
      </div>
      <div className="flex flex-column padding-h8">
        <MultilineFormikTextfield
          className="multiline-input"
          formik={formik}
          placeholder="Share who you are, what you do, and what you're looking for on PublicMind"
          name="bio"
          type="text"
          header="What should other people know about you?"
          rows={3}
        />
      </div>
      {/* <div className="flex flex-column padding-h8">
        <FormikArraySearchableDropDown
          formik={formik}
          header="Location"
          name="properties"
          items={propertiesJSON}
          mappings={{key: 'pretty', value: 'key'}}
        />
      </div> */}
      <div className="flex justify-center padding-top8">
        <button
          className="basic-button"
          disabled={formik.isSubmitting}
          type="submit"
          onClick={() => {
            formik.handleSubmit();
          }}>
          Save
        </button>
      </div>
      {isPopupVisible.on && (
        <ActionPopup
          message={isPopupVisible.message}
          setOff={setPopupVisible}
          on={isPopupVisible}
        />
      )}
    </div>
  );
};

const ChangePassword = () => {
  const isMounted = useMounted();
  const {
    state: {id, profile},
    updatePassword,
  } = useAuth();
  const [isPopupVisible, setPopupVisible] = useState({
    on: false,
    message: '',
  });
  const handleError = (helpers, err) => {
    if (isMounted()) {
      helpers.setStatus({success: false});
      helpers.setErrors({submit: err});
      helpers.setSubmitting(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      email: profile?.email ?? '',
      current_password: '',
      new_password: '',
    },
    validationSchema: Yup.object({
      current_password: Yup.string()
        .min(8)
        .max(255)
        .required('Password is required'),
      new_password: Yup.string()
        .min(8)
        .max(255)
        .required('Password is required'),
    }),
    onSubmit: async (values, helpers) => {
      try {
        const {email, current_password, new_password} = values;
        const {success, error} = await updatePassword(
          current_password,
          new_password,
        );

        if (success && isMounted()) {
          setPopupVisible({on: true, message: 'Success!'});
          track('password_update');
        }
        if (error) {
          setPopupVisible({on: true, message: 'Failed to save.'});
          handleError(helpers, error);
        }
      } catch (err) {
        handleError(helpers, err);
      }
    },
  });

  return (
    <div className="card pretty-form-group">
      <h4>Change password</h4>
      <div className="flex flex-column padding-h8">
        <FormikTextfield
          disabled={true}
          formik={formik}
          placeholder=""
          name="email"
          type="email"
          header="Email"
        />
      </div>
      <div className="flex-row">
        <div className="flex flex-column padding-h8">
          <FormikTextfield
            formik={formik}
            placeholder=""
            name="current_password"
            type="password"
            header="Current Password"
          />
        </div>
        <div className="flex flex-column padding-h8">
          <FormikTextfield
            formik={formik}
            placeholder=""
            name="new_password"
            type="password"
            header="New Password"
          />
        </div>
      </div>
      <p className="text-secondary padding-top16">
        Use 10+ characters, one special character, and at least one number.
        Update regularly.
      </p>
      {formik.errors.submit && <p className="error">{formik.errors.submit}</p>}
      <div className="flex justify-center">
        <button
          className="basic-button"
          disabled={formik.isSubmitting}
          type="button"
          onClick={() => {
            formik.handleSubmit();
          }}>
          Submit
        </button>
      </div>
      {isPopupVisible.on && (
        <ActionPopup
          message={isPopupVisible.message}
          setOff={setPopupVisible}
          on={isPopupVisible}
        />
      )}{' '}
    </div>
  );
};

const NotificationSettings = ({}) => {
  return (
    <div className="card">
      <h4>Email Notifications</h4>
      <div className="flex-row justify-between align-center">
        <p>New Messages</p>
        <button>Enable</button>
      </div>
      <div className="flex-row justify-between align-center">
        <p>New Collaborations</p>
        <button>Enable</button>
      </div>
      <div className="flex-row justify-between align-center">
        <p>New Matches</p>
        <button>Enable</button>
      </div>
      <div className="flex-row justify-between align-center">
        <p>Weekly Digest</p>
        <button>Enable</button>
      </div>
    </div>
  );
};

const InnerTagsCard = ({}) => {
  const {compareUpdatedTags} = useCollaborator();

  const {
    state: {id, profile},
    updateProfile,
  } = useAuth();

  const [add, setAdd] = useState(false);
  const [tags, setTags] = useState(null);
  const [popup, setPopup] = useState({
    on: false,
    message: '',
  });

  const formik = useFormik({
    initialValues: {
      opportunity:
        profile?.inner_tags?.filter(tag => tag.includes('opp_')) ?? [],
      collaborator:
        profile?.inner_tags?.filter(tag => tag.includes('collab_')) ?? [],
      skill: profile?.inner_tags?.filter(tag => tag.includes('skill_')) ?? [],
      degree: profile?.inner_tags?.filter(tag => tag.includes('deg_')) ?? [],
      major: profile?.inner_tags?.filter(tag => tag.includes('maj_')) ?? [],
      service: profile?.inner_tags?.filter(tag => tag.includes('serv_')) ?? [],
    },
    validationSchema: Yup.object({}),
    onSubmit: async (values, helpers) => {
      try {
        const {opportunity, collaborator, skill, degree, major, service} =
          values;

        const inner_tags = [
          ...opportunity,
          ...collaborator,
          ...skill,
          ...degree,
          ...major,
          ...service,
        ];

        const status = 'complete';

        const {outer_tags} = profile;

        const updated = {id, status, inner_tags};

        const {success, error} = await updateProfile(updated);

        if (success) {
          setPopup({on: true, message: 'Success!'});
          track('profile_update', updated);

          const {inner_added, inner_removed, outer_added, outer_removed} =
            compareUpdatedTags(inner_tags, outer_tags);
          if (inner_added.length > 0) {
            setTags(inner_added);
            setAdd(!add);
          }
        }

        if (error) {
          setPopup({
            on: true,
            message: 'Error. Please try again later.',
          });

          helpers.setStatus({success: false});
          helpers.setErrors({submit: error});
          helpers.setSubmitting(false);
        }
      } catch (err) {
        helpers.setStatus({success: false});
        helpers.setErrors({submit: err.message});
        helpers.setSubmitting(false);
      }
    },
  });

  return (
    <div className="card">
      <h4>Your Resources</h4>
      <p className="text-left text-secondary">
        PublicMind will find opportunities for requests related to these
      </p>
      <TagInput formik={formik} />
      <div className="flex justify-center">
        <button
          className="button-container"
          disabled={formik.isSubmitting}
          type="submit"
          onClick={async () => {
            formik.handleSubmit();
          }}>
          SAVE
        </button>
      </div>
      <ActionPopup message={popup.message} setOff={setPopup} on={popup.on} />
      <SharePrioritiesModal
        active={add}
        setActive={setAdd}
        priorities={tags}
        type={'Resources'}
      />
    </div>
  );
};

const OuterTagsCard = ({}) => {
  const {compareUpdatedTags} = useCollaborator();

  const {
    state: {id, profile},
    updateProfile,
  } = useAuth();

  const [add, setAdd] = useState(false);
  const [tags, setTags] = useState(null);

  const [popup, setPopup] = useState({
    on: false,
    message: '',
  });

  const formik = useFormik({
    initialValues: {
      opportunity:
        profile?.outer_tags?.filter(tag => tag.includes('opp_')) ?? [],
      collaborator:
        profile?.outer_tags?.filter(tag => tag.includes('collab_')) ?? [],
      skill: profile?.outer_tags?.filter(tag => tag.includes('skill_')) ?? [],
      degree: profile?.outer_tags?.filter(tag => tag.includes('deg_')) ?? [],
      major: profile?.outer_tags?.filter(tag => tag.includes('maj_')) ?? [],
      service: profile?.outer_tags?.filter(tag => tag.includes('serv_')) ?? [],
    },
    validationSchema: Yup.object({}),
    onSubmit: async (values, helpers) => {
      try {
        const {opportunity, collaborator, skill, degree, major, service} =
          values;

        const outer_tags = [
          ...opportunity,
          ...collaborator,
          ...skill,
          ...degree,
          ...major,
          ...service,
        ];

        const status = 'complete';

        const {inner_tags} = profile;

        const updated = {id, status, outer_tags};

        const {success, error} = await updateProfile(updated);

        if (success) {
          setPopup({on: true, message: 'Success!'});
          track('profile_update', updated);

          const {inner_added, inner_removed, outer_added, outer_removed} =
            compareUpdatedTags(inner_tags, outer_tags);
          if (outer_added.length > 0) {
            setTags(outer_added);
            setAdd(!add);
          }
        }

        if (error) {
          setPopup({
            on: true,
            message: 'Error. Please try again later.',
          });

          helpers.setStatus({success: false});
          helpers.setErrors({submit: error});
          helpers.setSubmitting(false);
        }
      } catch (err) {
        helpers.setStatus({success: false});
        helpers.setErrors({submit: err.message});
        helpers.setSubmitting(false);
      }
    },
  });

  return (
    <div className="card">
      <h4>Your Requests</h4>
      <p className="text-left text-secondary">
        PublicMind will find opportunities for resources related to these
      </p>
      <TagInput formik={formik} />
      <div className="flex justify-center">
        <button
          className="button-container"
          disabled={formik.isSubmitting}
          type="submit"
          onClick={async () => {
            formik.handleSubmit();
          }}>
          Save
        </button>
      </div>
      <ActionPopup message={popup.message} setOff={setPopup} on={popup.on} />
      <SharePrioritiesModal
        active={add}
        setActive={setAdd}
        priorities={tags}
        type={'Requests'}
      />
    </div>
  );
};

const MembershipCard = ({}) => {
  const {
    state: {profile},
  } = useAuth();

  const {subscription, expiration} = profile;

  return (
    <div className="grid-container">
      <div className="grid-7">
        <div className="card">
          <MembershipDetails />
        </div>
      </div>
      <div className="grid-5">
        <div className="card">
          <ReferralBox inProfile={true} />
        </div>
      </div>
    </div>
  );
};

const MembershipDetails = sub => {
  const {
    state: {id, profile, referrals},
    updateProfile,
  } = useAuth();

  const {subscription, expiration} = profile;

  const daysLeft = daysBetweenTodayAndTimestamp(expiration) || 'N/A';

  const pretty_date = expiration
    ? formatMMDDYYYY(new Date(expiration * 1000))
    : formatMMDDYYYY(new Date(dateToTimestamp() + constants.years_1));

  const [referral, setRef] = useState([]);
  const [active, setActive] = useState(false);

  useEffect(() => {
    if (expiration) {
      const threshold = expiration - constants.days_30;

      const filt = referrals.filter(item => {
        const {timestamp} = item;
        if (timestamp > threshold) {
          return true;
        }
        return false;
      });
      setRef(filt);
    }
  }, [referrals]);

  switch (subscription) {
    case '3 month':
      return (
        <div>
          <p className="text-secondary text-left">Current Membership</p>
          <h4>
            <span style={{}}>Beta Trial</span>
          </h4>
          <div className="border-bottom padding-top8" />

          <p className="text-secondary text-left">
            Thank you to everyone who participated in our trial period and
            helped us develop the product. You can now switch to a paid or
            growth membership below.
          </p>
          <br />
          <p className="text-left text-bold">Next Actions</p>
          <p className="text-left text-secondary">
            Choose one of the following plans to keep access to PublicMind.
          </p>

          <div className="border-bottom margin-v8" />
          <h4>
            <span style={{color: '#a777e3'}}>Linear and Exponential</span>
          </h4>

          <p className="text-secondary text-left">
            Support the growth and maintenance of PublicMind with a paid
            membership. Enjoy premium features, priority support, and multiple
            membership plans tailored to your needs.
          </p>
          <br />
          <a
            href={constants.jacks_calendly}
            onClick={async () => {
              await updateProfile({
                id,
                subscription: 'yearly_payment',
                expiration: null,
              });
            }}
            className="button-container"
            style={{textDecoration: 'none', color: 'white'}}
            target="_blank"
            rel="noopener noreferrer">
            SCHEDULE YOUR MEETING
          </a>
          <br />
          <br />
          <div className="border-bottom margin-v8" />
          <h4>
            <span style={{color: '#ff416c'}}>Growth</span>
          </h4>

          <p className="text-secondary text-left">
            Help expand the community by vouching for new members. Contribute to
            PublicMind’s development by inviting vetted, trusted members.
          </p>
          <div className="flex-row align-center justify-between">
            <p className="text-left text-secondary">
              Current Invites: {referrals?.length}
            </p>
            {referrals?.length >= 3 ? (
              <button
                className="update-container-special-add"
                onClick={async () => {
                  await updateProfile({
                    id,
                    subscription: '3 month',
                    expiration: dateToTimestamp() + constants.days_30,
                  });
                }}>
                Accept
              </button>
            ) : (
              <button
                className="update-container-special-add"
                onClick={() => {
                  setActive(!active);
                }}>
                Invite
              </button>
            )}
          </div>
          <InviteModal active={active} setActive={setActive} />
        </div>
      );
    case 'monthly_vouches':
      return (
        <div>
          <p className="text-secondary text-left text-14">Current Membership</p>
          <h4>
            <span style={{color: '#ff416c'}}>Growth</span> ({daysLeft} days
            remaining)
          </h4>
          <div className="border-bottom padding-top8" />

          <p className="text-left text-secondary padding-top8">
            {referral?.length < 3 ? (
              <strong>Invitations Sent: </strong>
            ) : (
              <strong>Status: </strong>
            )}{' '}
            {referral?.length >= 3
              ? `You're all set for the month.`
              : `${referral?.length} out of 3 new members invited.`}
          </p>
          <p className="text-left text-secondary ">
            {referral?.length < 3 ? (
              <strong>Deadline: </strong>
            ) : (
              <strong>Next Month Start: </strong>
            )}
            {referral?.length >= 3
              ? `Your new month begins on ${pretty_date}, but you’re covered until then.`
              : `You have until ${pretty_date} to invite 3 new members to keep your
            membership in good standing.`}
          </p>
          <button
            className="invite-external-button"
            onClick={() => {
              setActive(!active);
            }}>
            Invite
          </button>
          <InviteModal active={active} setActive={setActive} />
        </div>
      );
    case 'yearly_payment':
      return (
        <div>
          <p className="text-secondary text-left">Current Membership</p>
          <h4>
            <span style={{color: '#a777e3'}}>Linear and Exponential</span>
          </h4>
          <div className="border-bottom padding-top8" />

          <p className="text-secondary text-left">
            Support the growth and maintenance of PublicMind with a paid
            membership. Enjoy premium features, priority support, and multiple
            membership plans tailored to your needs.
          </p>
          <br />
          <p className="text-left text-bold">Next Actions</p>
          <p className="text-left text-secondary">
            Renew your yearly subscription by {pretty_date} to keep your access
            to PublicMind.
          </p>
          <br />
          <a
            href={constants.jacks_calendly}
            className="button-container"
            style={{textDecoration: 'none', color: 'white'}}
            target="_blank"
            rel="noopener noreferrer">
            SCHEDULE YOUR MEETING
          </a>
          <br />
          <br />
        </div>
      );
    default:
      return null;
  }
};

export default ProfileSettings;
