import {useEffect, useMemo, useState} from 'react';
import {BsArrowReturnRight} from 'react-icons/bs';
import {useThreads} from '../../../hooks/use-threads';
import FullScreenThreadModal from '../modals/full-screen-mode';
import ThreadComment from './thread-comment';
import {ThreadReply} from './thread-reply';

// GROUPS MESSAGES BY PARENT
const ThreadMessageGroup = ({
  contentId,
  messageIds,
  onReply,
  onQuote,
  focusedContent,
  sub,
  collapse,
  dm,
}) => {
  const {
    state: {thread_content},
  } = useThreads();

  const [hide, setHide] = useState(false);
  const [all, setAll] = useState(false);
  const [fullScreenContent, setFullScreenContent] = useState(null);

  const isNewContent = useMemo(() => {
    const comment = thread_content?.[contentId];
    if (!comment || !sub || !messageIds) return false;

    // Check if the comment itself is new
    if (comment.created > sub.last_read) return true;

    // Check if any replies are new
    return messageIds?.some(replyId => {
      const reply = thread_content?.[replyId];
      return reply && reply.created > sub.last_read;
    });
  }, [contentId, messageIds, thread_content, sub]);

  useEffect(() => {
    if (collapse) {
      setHide(true);
    } else {
      setHide(false);
    }
  }, [collapse]);

  useEffect(() => {
    if (isNewContent) {
      setAll(true);
      setHide(false);
    }
  }, [isNewContent]);

  useEffect(() => {
    if (messageIds?.includes(focusedContent)) setAll(true);
  }, [focusedContent]);

  if (!messageIds || !contentId) {
    return null;
  }

  return (
    <div className="message-group">
      <div className={`comment-wrapper ${isNewContent ? 'new-content' : ''}`}>
        {isNewContent && <span className="new-indicator">New</span>}
        <ThreadComment
          item={thread_content?.[contentId] ?? {}}
          onReply={e => {
            e.stopPropagation();
            onReply(prev => (prev ? null : contentId));
          }}
          focused={contentId === focusedContent}
          onClick={() => {
            if (messageIds?.length) {
              setHide(!hide);
            }
          }}
          sub={sub}
          setFullScreenContent={setFullScreenContent}
          dm={dm}
        />
      </div>
      {hide && messageIds?.length > 0 ? (
        <div
          className="flex-row align-center"
          style={{marginLeft: '68px'}}
          onClick={() => {
            setHide(!hide);
          }}>
          <BsArrowReturnRight className="text-secondary" fontSize={'18px'} />
          <p className="text-secondary text-12 margin-h8 clickable">
            {messageIds?.length} replies
          </p>
        </div>
      ) : (
        <div className="nested-messages">
          {messageIds?.slice(0, all ? messageIds?.length : 3).map(nestedId => {
            return (
              <ThreadReply
                key={nestedId}
                item={thread_content?.[nestedId] ?? {}}
                onReply={() => onReply(prev => (prev ? null : contentId))}
                onQuote={onQuote}
                focused={nestedId === focusedContent}
                setFullScreenContent={setFullScreenContent}
                dm={dm}
              />
            );
          })}
          {messageIds?.length > 3 && !all ? (
            <p
              className="text-link clickable"
              style={{marginLeft: '68px'}}
              onClick={() => {
                setAll(true);
              }}>
              See all
            </p>
          ) : null}
        </div>
      )}
      <FullScreenThreadModal
        content_id={fullScreenContent}
        replys={messageIds}
        onClose={() => setFullScreenContent(null)}
        sub={sub}
      />
    </div>
  );
};
export default ThreadMessageGroup;
