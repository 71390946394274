import React, {useEffect} from 'react';
import './style.css'; // Import CSS for styling

const ActionPopup = ({message, on, setOff, location}) => {
  useEffect(() => {
    const timeout = setTimeout(() => {
      setOff({on: false, message: ''});
    }, 4000);

    // Clear the timeout when the component unmounts
    return () => {
      clearTimeout(timeout);
    };
  }, [on]);

  return (
    <div
      className={`popup ${on ? 'show' : ''} ${
        location === 'top' ? 'popup-top' : ''
      }`}>
      <div className="popup-message">{message}</div>
    </div>
  );
};

export default ActionPopup;
