import React from 'react';
import './styles.css';

const TermsOfService = () => {
  return (
    <div className="legal-container">
      <h1>PublicMind™ Terms of Service</h1>
      <p>Last Modified: October 26, 2023</p>
      <p>
        Subject to the terms and conditions of this agreement (this
        “Agreement”), we at FinSiteful, Inc., a Delaware corporation d/b/a
        (“FinSiteful” or “we”), provide subscriptions to:
      </p>
      <ul>
        <li>our PublicMind™ workforce development software (“PublicMind”);</li>
        <li>
          a website (the “Website” and, together with PublicMind, the
          “Software”) through which you may access and use PublicMind; and
        </li>
        <li>the Services described in further detail below.</li>
      </ul>
      <p>
        “You” are the business or other legal entity accepting this Agreement as
        part of your procurement of a subscription to our Software and Services.
      </p>
      <h2>1. Acceptance</h2>
      <p>
        YOU SHOULD READ THIS AGREEMENT CAREFULLY FOR THE TERMS AND CONDITIONS
        THAT GOVERN YOUR ACCESS TO AND USE OF THE SOFTWARE AND YOUR RECEIPT OF
        THE SERVICES. BY ACCESSING AND/OR USING THE SOFTWARE AND OUR SERVICES,
        OR BY CHECKING OR CLICKING THE “AGREE,” “I CONSENT” OR SIMILAR BUTTON OR
        BOX IF AND WHEN THIS OPTION IS PRESENTED TO YOU, YOU: (A) ACKNOWLEDGE
        THAT YOU HAVE READ AND UNDERSTAND THIS AGREEMENT, AND (B) AGREE TO
        ACCEPT THIS AGREEMENT, AND (C) AGREE THAT YOU ARE LEGALLY BOUND BY (AND
        THAT YOU WILL ADHERE TO) ALL TERMS AND CONDITIONS SET FORTH IN THIS
        AGREEMENT. THE INDIVIDUAL REGISTERING TO USE PUBLICMIND ON YOUR BEHALF
        REPRESENTS AND WARRANTS TO US THAT SUCH INDIVIDUAL IS FULLY AND DULY
        AUTHORIZED TO AGREE TO BE BOUND BY THIS AGREEMENT ON YOUR BEHALF. IF YOU
        DO NOT AGREE WITH THE TERMS AND CONDITIONS OF THIS AGREEMENT, DO NOT
        ACCESS OR USE THE SOFTWARE OR OUR SERVICES.
      </p>
      <h2>2. Changes to this Agreement</h2>
      <p>
        We may revise and update this Agreement from time to time in our sole
        discretion; provided, however, that, during the Term, we will not
        without your prior written consent make any revisions, updates or other
        changes to this Agreement that will in a manner that is materially
        adverse to you either (a) reduce our express obligations to you under
        this Agreement, or (b) alter our express representations, warranties,
        indemnities or limitations of liability. Via a conspicuous posting
        within the Website itself or via notice by e-mail to the e-mail address
        we have on file for you, we will notify you of any material changes to
        this Agreement. Changes to this Agreement are effective immediately when
        we post them, and your continued use of the Software or Services
        following the posting of a revised Agreement means that you accept and
        agree to the changes. You must immediately discontinue access or use of
        the Software and Services if you do not want to agree to the revised
        Agreement.
      </p>
      <h2>3. PublicMind Right to Access and Restrictions</h2>
      <h3>a. PublicMind Authorization</h3>
      <p>
        We authorize you, your Affiliates and your Authorized Users, during the
        Term, and on a non-exclusive and non-transferable (except as described
        in Section 19.e) basis, to access and use PublicMind on a “software as a
        service” basis through the Website, in each case provided that such
        access and use is (i) by and through your Authorized Users only and (ii)
        solely for the Permitted Use. This authorization also permits you, your
        Affiliates and your Authorized Users to access and use the Documentation
        during the Term in support of your Permitted Use of the Software.
      </p>
      <h3>b. Limitations and Restrictions</h3>
      <p>
        You must not, and you must not permit any other person or entity
        (including your Affiliates and Authorized Users) to, access or use the
        Software except as we’ve specifically allowed in this Agreement. Without
        limiting the preceding sentence, you, your Affiliates and your
        Authorized Users must not do any of the following:
      </p>
      <ul>
        <li>
          copy, modify, adapt, translate or create derivative works or
          improvements of the Software;
        </li>
        <li>
          rent, lease, lend, sell, sublicense, assign, distribute, publish,
          transfer or otherwise make available the Software or any features or
          functionality of the Software to any other person or entity for any
          reason, including by making the Software available through any
          time-sharing, service bureau or software as a service arrangement;
        </li>
        <li>
          reverse engineer, disassemble, decompile, decode, adapt or otherwise
          attempt to derive, gain access to or discover the source code of the
          Software or the underlying structure, ideas, know-how, algorithms or
          methodology relevant to the Software;
        </li>
        <li>
          input, upload, transmit or otherwise provide to or through the
          Software any information or materials that are unlawful or that
          contain, transmit or activate any Harmful Code;
        </li>
        <li>
          bypass, breach or disable any security device, copy control, digital
          rights management tool or other protection used by the Software;
        </li>
        <li>
          attempt to gain unauthorized access to, damage, destroy, disrupt,
          disable, impair, interfere with or otherwise impede or harm in any
          manner (A) the Software, (B) the server on which the Software is
          hosted and stored, (C) any server, computer or database connected to
          the Software, or (D) our ability to provide our Software or services
          to any other person or entity;
        </li>
        <li>
          access or use the Software in any way that infringes, misappropriates
          or otherwise violates any intellectual property right, privacy right
          or other right of any third party, or that violates any applicable law
          or regulation;
        </li>
        <li>
          access or use the Software for purposes of (A) developing, marketing,
          distributing, licensing or selling any product or service that may
          compete with the Software, or (B) disclosing to our competitors, for
          any purpose, otherwise non-public information about the Software;
        </li>
        <li>threaten, harass, abuse, slander or defame others;</li>
        <li>
          publish, distribute, promote or disseminate any inappropriate,
          profane, vulgar, defamatory, discriminatory, sexually explicit,
          infringing, obscene, tortious, indecent, unlawful, offensive, immoral
          or otherwise objectionable material or information;
        </li>
        <li>
          use the Software to impersonate or attempt to impersonate any person
          or organization, or misrepresent your identity or affiliation with any
          person or organization or impersonate another person for the purpose
          of misleading others as to the identity of the sender or the origin of
          a message, including, but not limited to, providing misleading
          information to any feedback system employed through the Software,
          falsifying your identity in connection with any public posting through
          the Services or committing trademark or service mark infringement;
        </li>
        <li>
          access or use the Software contrary to any additional limitations or
          restrictions that may be set forth in your Order; or
        </li>
        <li>
          knowingly aid or assist any Affiliate, Authorized User or other person
          or entity in taking any of the actions prohibited by this Section 3.b.
        </li>
      </ul>
      <h3>c. Responsibility for Affiliates and Authorized Users</h3>
      <p>
        You will ensure your Affiliates’ and Authorized Users’ compliance with
        this Agreement and be responsible and liable to us for any act or
        omission of an Affiliate or Authorized User (or any other employee,
        contractor or agent under your control or direction or acting on your
        behalf) that would be a breach or violation of this Agreement had you
        performed the act or omission yourself.
      </p>
      <h2>4. Our Services</h2>
      <h3>a. Services – Generally</h3>
      <p>
        We will provide to you the following services (the “Services”) during
        the Term: (i) the hosting, management and back-end operation of
        PublicMind to make the same available for remote electronic access and
        use by you, your Affiliates and your Authorized Users through the
        Website in accordance with the authorizations granted above; (ii) the
        Support Services described in Section 5 below; and (iii) any other
        Services we expressly agree to provide in your Order.
      </p>
      <h3>b. Changes to the Software</h3>
      <p>
        We may make any changes to the Software (including, without limitation,
        the design, look and feel, functionality, content, material, information
        and/or services provided via the Software) that we deem necessary or
        useful to improve the Software or for any other reason, from time to
        time in our discretion; provided that we will not during the Term make
        any changes to the Software that will materially adversely affect the
        features and functionality of the Software that you use. Such changes
        may include upgrades, bug fixes, patches, error corrections,
        modifications, enhancements, improvements and/or new features
        (collectively, “Updates”). All Updates shall be deemed a part of the
        Software governed by all the provisions of this Agreement pertaining
        thereto. If we make a change to the Software that has a material adverse
        effect on your operations, then you may notify us in writing of the
        material adverse effect and you may, immediately upon written notice to
        us, terminate this Agreement if we do not cure this condition within 30
        days following receipt of your notice.
      </p>
      <h3>c. Subcontractors</h3>
      <p>
        You understand and agree that we may, in our discretion, engage
        subcontractors to aid us in providing the Software and performing our
        Services under this Agreement, but we will remain liable to you for any
        act or omission by such subcontractors that would be a breach or
        violation of this Agreement. For example, we may use Amazon Web
        Services, Microsoft Azure, Google Cloud Platform and/or such other
        reputable provider that implements and maintains commercially reasonable
        security programs, policies, procedures, controls and technologies (the
        “Hosting Services Provider”) for cloud-based infrastructure, hosting and
        storage services for PublicMind, and such Hosting Services Provider will
        host and store certain portions of Your Data that are processed through
        PublicMind. You hereby specifically approve and consent to our use of a
        Hosting Services Provider in the manner described. Notwithstanding
        anything to the contrary in this Agreement, you further agree that we
        cannot guarantee or ensure the performance of any Hosting Services
        Provider to the terms of this Agreement, and remediation of a breach by
        a Hosting Services Provider is limited to the remedies specified in the
        Hosting Services Provider’s standard service agreement.
      </p>
      <h3>d. Suspension of Software Access</h3>
      <p>
        We may suspend or deny your (and/or one or more of your Affiliates’ or
        Authorized Users’) access to or use of all or any part of the Software
        and suspend our performance of our Services, without any liability to
        you or others, if (i) we’re required to do so by law or court order; or
        (ii) you have, your Affiliate has or your Authorized User (or any other
        employee, contractor or agent under your control or direction or acting
        on your behalf) has (A) accessed or used our Services or Software in
        violation of this Agreement, (B) been involved in any fraudulent or
        unlawful activities relating to or in connection with our Services or
        Software, or (C) otherwise failed to comply with this Agreement and have
        failed to cure such breach within 10 days after we provide written
        notice to you. Unless we have exercised our right to terminate this
        Agreement pursuant to Section 15.b, we will promptly restore access to
        the Software and resume performance of our Services as soon as the
        applicable legal requirement or court order is lifted or the applicable
        breach or violation is cured. Our remedies in this Section are in
        addition to, and not in lieu of, our termination rights in Section 15.b
        or any other rights or remedies under this Agreement, at law or in
        equity.
      </p>
      <h3>e. “Free Trial” and Access and Use</h3>
      <p>
        Notwithstanding anything to the contrary in this Agreement, if your
        Order indicates that you will be obtaining a subscription to the
        Software on a “free trial,” or similar basis or otherwise without
        payment of subscription Fees for the Software (a “Free Trial Order”),
        then you acknowledge and agree that UNDER ANY FREE TRIAL ORDER ALL
        SERVICES AND PUBLICMIND IP (INCLUDING BUT NOT LIMITED TO THE SOFTWARE)
        ARE PROVIDED TO YOU “AS IS” AND “AS AVAILABLE” WITHOUT ANY SUPPORT
        SERVICES OR ANY WARRANTY OR INDEMNITY OF ANY KIND FROM US, EXPRESS OR
        IMPLIED, AND THE AGGREGATE LIABILITY OF PUBLICMIND UNDER OR IN
        CONNECTION WITH ANY FREE TRIAL ORDERS SHALL NOT EXCEED $100 UNDER ANY
        CIRCUMSTANCES. Either party may cancel a Free Trial Order at any time
        immediately upon written notice to the other party, for any reason or
        for no reason, and upon such termination your right to access and use
        the Software will terminate and you must immediately cease accessing and
        using the Software.
      </p>
      <h2>5. Support Services</h2>
      <p>
        a. During the Term we will provide customer support services to you and
        your Authorized Users via e-mail, telephone and other channels that we
        may make available from time to time, during our standard support hours
        (9:00 a.m. to 5:00 p.m., Eastern time, Monday through Friday, but
        excluding federal holidays) (“Support Services”).
      </p>
      <h3>b. Support Services may include the following:</h3>
      <p>
        access to knowledge-based content, FAQs, training videos and community
        forums in each case that are hosted and made available generally to
        customers by us from time to time through the Website or otherwise;
        technical and operational assistance for the use of the Software,
        including responses to general, short-duration questions about the
        documented features and functionality of the Software and usage thereof,
        management of user accounts for Authorized Users, assistance with
        interpretation and use of the Documentation, and assistance with
        interpretation of error or warning messages appearing in dashboards or
        alerts; good faith attempts to respond to and resolve, promptly, any
        reproducible failure of the Software to perform in accordance with the
        Documentation (each, an “Error”); and periodically providing to you all
        Updates to the Software that we make generally available to all of our
        customers free of additional charge.
      </p>
      <h3>c. Support Services do not include:</h3>
      <p>
        support for software or hardware that is not part of the Software
        (including support for any part of your equipment, products or
        technology infrastructure that is separate and apart from PublicMind
        itself), on-site dispatch of our personnel, assistance with any
        First-Level Technical Support (as defined below) issues that are the
        responsibility of your User Contacts, formal, comprehensive training of
        Authorized Users (or any other person or entity) on use of the Software,
        on-site or remote support to configure or customize the Software for
        you, or performance of any other professional, implementation,
        onboarding, configuration, customization, consulting or advisory
        services (provided that items (4) through (6) may be separately provided
        Services to the extent expressly agreed to in your Order).
      </p>
      <p>
        d. You must provide all information and assistance that we reasonably
        request in connection with providing such Support Services. You will
        identify one or more system administrators or other employees (each, a
        “User Contact”) trained in use of the Software who will provide
        First-Level Technical Support to your Authorized Users. “First-Level
        Technical Support” involves assisting Authorized Users with basic help
        and general FAQs concerning Software usage, understanding Software
        features and functionality, and verifying Software Errors reported by
        Authorized Users. We reserve the right to charge you at an hourly rate
        (on a time-and-materials basis) for support services provided (x)
        outside of our normal support hours, or (y) in connection with a request
        we reasonably determine is outside the scope of the Support Services
        described above, provided that we will provide you with a quote and
        obtain your approval before incurring any charges for any such out of
        scope support services.
      </p>
      <h2>6. User Contributions</h2>
      <p>
        The Software may contain message boards, chat rooms, personal web pages
        or profiles, forums, bulletin boards and other interactive features that
        allow users to post, submit, publish, display or transmit to other users
        or other persons content or materials (collectively, “User
        Contributions”) on or through the Software. You must own or control all
        rights in and to your User Contributions, and all of your User
        Contributions must comply with the content standards set out in this
        Agreement. Any User Contribution you post to the Software will be
        considered non-confidential and non-proprietary. By providing any User
        Contribution on the Software, you grant us and our affiliates and
        service providers the right to use, reproduce, modify, perform, display,
        distribute and otherwise disclose to third parties any such material for
        any purpose. You understand and acknowledge that you are responsible for
        any User Contributions you submit or contribute and that you have full
        responsibility for such content, including its legality, reliability,
        accuracy and appropriateness. We are not responsible for the content or
        accuracy of any User Contributions posted by you or any other user of
        the Software. We reserve the right to take any actions that we consider
        to be appropriate to ensure that the Software is operated in an
        appropriate manner. We may remove or refuse to post any User
        Contributions for any or no reason in our sole discretion, and we may
        take any action with respect to any User Contribution that we deem
        necessary or appropriate in our sole discretion, including if we believe
        that such User Contribution violates this Agreement, infringes any
        intellectual property right or other right of any person or entity,
        threatens the personal safety of users of the Software or the public or
        could create liability for us. We may disclose your identity or other
        information about you to any third party who claims that material posted
        by you violates their rights, including their intellectual property
        rights or their right to privacy. Notwithstanding the foregoing, we do
        not undertake to review all material before it is posted to the
        Software, and cannot ensure prompt removal of objectionable material
        after it has been posted. Accordingly, we assume no liability for any
        action or inaction regarding transmissions, communications or content
        provided by any user or third party. We have no liability or
        responsibility to anyone for performance or nonperformance of the
        activities described in this Section.
      </p>
      <h2>7. Copyright Infringement</h2>
      <p>
        We take claims of copyright infringement seriously and will respond to
        notices of alleged copyright infringement that comply with applicable
        law. If you believe that any User Contributions violate your copyright,
        please see our{' '}
        <a href="https://www.publicmind.ai/copyright-policy">
          Copyright Policy
        </a>{' '}
        for instructions on sending us a notice of copyright infringement. It is
        our policy to terminate the user accounts of repeat infringers.
      </p>
      <h2>8. Third-Party Materials</h2>
      <p>
        The Software may display, include or make available certain third-party
        content (including data, information, applications and other products,
        services and/or materials) or provide links to third-party websites,
        social media platforms or other services, including through third-party
        advertising (“Third-Party Materials”). You acknowledge and agree that we
        are not responsible for Third-Party Materials, including their accuracy,
        completeness, timeliness, validity, copyright compliance, legality,
        decency, quality or any other aspect thereof. We do not assume and will
        not have any liability or responsibility to you or any other person or
        entity for any Third-Party Materials. Third-Party Materials and any
        links or other access thereto are provided solely as a convenience to
        you, and you access and use them entirely at your own risk and subject
        to such third parties’ terms and conditions. We reserve the right to
        change, suspend, remove, disable or impose access restrictions or limits
        on any Third-Party Materials at any time without notice or liability to
        you.
      </p>
      <h2>9. Reliance on Information Posted</h2>
      <p>
        The information presented on or through the Software is made available
        solely for general information purposes. We do not warrant the accuracy,
        completeness or usefulness of this information, and any reliance you
        place on such information is strictly at your own risk. We disclaim all
        liability and responsibility arising from any reliance placed on such
        materials by you or any other user of the Software, or by anyone who may
        be informed of any of its contents. All statements and/or opinions
        expressed in these materials, and all articles and responses to
        questions and other content, other than the content provided by us, are
        solely the opinions and the responsibility of the person or entity
        providing those materials. These materials do not necessarily reflect
        our opinions. We are not responsible, or liable to you or any third
        party, for the content or accuracy of any materials provided by any
        third parties.
      </p>
      <h2>10. Facilitation Tool</h2>
      <p>
        The Software is solely a strategic planning tool for educational
        institutions, businesses and economic development organizations to
        access workforce and education data and communicate with each other. We
        do not take part in the interactions between educational institutions,
        businesses and economic development organizations, except solely as
        necessary to facilitate our provision of the Software and Services. We
        are not responsible for such interactions. You are solely responsible
        for such interactions. We do not in any way undertake to vet or validate
        the qualifications, background or statements made by any educational
        institutions, businesses or economic development organizations that use
        the Software; therefore, we cannot and do not control, are not
        responsible for, and make no representations, warranties, guarantees,
        indemnities or commitments of any kind or nature regarding (i) the
        quality, timing, availability, suitability, reliability, safety or
        legality of any products or services promoted by educational
        institutions, businesses or economic development organizations on or
        through the Software, (ii) the truth or accuracy of any representations,
        statements or promotional materials supplied by educational
        institutions, businesses or economic development organizations, or (iii)
        the skills, talents, reputation, experience, presence and/or
        qualifications of any educational institutions, businesses or economic
        development organizations. The availability of any educational
        institutions, businesses or economic development organizations to
        connect and/or provide services of any kind on or through the Software
        does not imply our endorsement of such educational institutions,
        businesses or economic development organizations or their respective
        products or services.
      </p>
      <h2>11. Confidentiality</h2>
      <h3>a. General</h3>
      <p>
        In connection with receiving or providing the Software and Services
        during the Term, each party (each, a “Discloser”) may disclose to the
        other party (the “Recipient”) the Discloser’s proprietary or
        confidential information (collectively, “Confidential Information”).
        During the Term and thereafter the Recipient will not without the
        Discloser’s written consent disclose Discloser’s Confidential
        Information to any third party (other than our subcontractors as
        permitted in Section 4.c above) nor use the Discloser’s Confidential
        Information for any purpose except for carrying out its obligations or
        exercising its rights under this Agreement. All non-public information
        related to our Software and Services and the features, functionality and
        performance thereof are all our Confidential Information, Your Data
        (including, for the avoidance of doubt, any of Your Data that consists
        of Personal Information (as defined below) of your employees or other
        personnel) is your Confidential Information, and the terms of this
        Agreement and your Order are the Confidential Information of both of us.
      </p>
      <h3>b. Exceptions</h3>
      <p>
        These restrictions will not restrict the use or disclosure of
        information disclosed by one party to the other that (i) is or becomes
        publicly known other than as a result of any act or omission by the
        Recipient or its employees or agents, (ii) is lawfully received by the
        Recipient from a third party not in a confidential relationship with the
        Discloser, or (iii) was already rightfully known by the Recipient prior
        to receipt thereof from the Discloser. Additionally, Recipient may
        disclose Discloser’s Confidential Information to the extent it is
        legally compelled to do so pursuant to applicable law or the valid order
        of a court or governmental agency, provided that Recipient must first
        give the Discloser reasonable prior written notice to permit the
        Discloser to challenge or limit such required disclosure.
      </p>
      <h2>12. Data Security and Privacy</h2>
      <h3>a. Security Program</h3>
      <p>
        We will maintain and implement a written data security program that
        contains commercially reasonable administrative, technical and physical
        safeguards to protect against anticipated threats or hazards to the
        security, confidentiality or integrity of Your Data (including, for the
        avoidance of doubt, any of Your Data that consists of Personal
        Information of your employees or other personnel) that we process on
        your behalf, including the unauthorized or accidental acquisition,
        destruction, loss, alteration or use of, and the unauthorized access to,
        Your Data. We will review and, as appropriate, revise our data security
        program at least annually or whenever there is a material change in our
        business practices that may reasonably affect the security or integrity
        of Your Data.
      </p>
      <h3>b. Security Incidents</h3>
      <p>
        We will notify you of any Security Incidents without undue delay after
        becoming aware of the Security Incident. The notice will summarize in
        reasonable detail, to the extent known, the nature and scope of the
        Security Incident (including a description of any Personal Information
        affected) and the corrective action already taken or to be taken by us.
        The notice will be timely supplemented to the extent possible as
        reasonably requested by you. Promptly following our notification to you,
        the parties will coordinate with each other as needed to investigate the
        Security Incident, and, without undue delay, we will deliver to you a
        root cause assessment and future incident mitigation plan with regard to
        the Security Incident. We will use diligent efforts to contain and
        counteract the Security Incident in a timely manner and prevent a
        recurrence of any such Security Incident. We will maintain and preserve
        all documents, records and other data related to the Security Incident
        as required by applicable law and good industry practice. We will
        perform or take any other actions required of us to comply with Data
        Privacy Laws (as defined below) as a result of the Security Incident and
        reasonably cooperate with you in your efforts to do the same.
      </p>
      <h3>c. Data Privacy</h3>
      <p>
        We will store, use and otherwise process any of Your Data that
        constitutes “personal information,” “personal data” or “personally
        identifiable information” as defined in applicable laws (collectively
        “Personal Information”) in all material respects in accordance with all
        applicable laws relating to the privacy and protection of Personal
        Information (“Data Privacy Laws”), including, in each case to the extent
        applicable, the California Consumer Privacy Act of 2018 and its
        implementing regulations (as amended, restated or supplemented from time
        to time, including by the California Privacy Rights Act of 2020, “CCPA”)
        and the other state privacy laws listed on Appendix 1 attached hereto
        below (the “Other State Laws”). We will not access, use, handle,
        maintain, process, dispose of or disclose Personal Information other
        than as permitted or required under this Agreement or Data Privacy Laws.
        We will limit dissemination of Personal Information to our employees and
        subcontractors who (i) need to know the information to enable us to
        perform our obligations or exercise our rights under this Agreement, and
        (ii) are bound by confidentiality obligations substantially equivalent
        to those provided for in this Agreement. We will cooperate with you as
        may be reasonably required to enable you to comply with Data Privacy
        Laws, including by reasonably assisting you in complying with
        individuals’ rights in regards to their Personal Information under Data
        Privacy Laws. In furtherance of the foregoing, based on the data that
        you will process using the Software or otherwise provide to us, if and
        to the extent Data Privacy Laws require additional clauses to be
        executed by us beyond those set forth in this Agreement, then you must
        notify us in writing of such requirement and we will in good faith
        review, negotiate and consider adding such clauses as an addendum to
        this Agreement. In the absence of such notice you represent and warrant
        that no additional clauses are required.
      </p>
      <h3>d. CCPA and Other State Laws Compliance</h3>
      <p>
        You and we both agree that you are a business and we are a service
        provider under the CCPA, and that you are a controller and we are a
        processor under the Other State Laws. The specific purpose for which we
        are processing Personal Information under this Agreement (and the only
        purpose for which you disclose Personal Information to us under this
        Agreement) is for us to provide the Software and Services as
        specifically set forth in this Agreement.
      </p>
      <ul>
        <li>
          During the Term and thereafter, we will: (A) not retain, use or
          disclose Personal Information for any purpose (including any
          commercial purpose) other than for the specific purpose of providing
          the Software and Services contemplated by this Agreement; (B) not
          retain, use or disclose Personal Information outside of the direct
          business relationship between you and us; (C) not sell or (where CCPA
          applies) share the Personal Information to or with any third parties;
          (D) not combine the Personal Information that we receive from you, or
          on your behalf, with Personal Information that we receive from, or on
          behalf of, another person or persons, or collect from our own
          interaction with the consumer, provided that we may combine such
          Personal Information (1) for the specific purpose of providing the
          Software and Services contemplated by the Agreement or (2) to perform
          any other permitted business purpose under CCPA and/or the Other State
          Laws, as applicable; (E) taking into account the nature of processing
          and the information available to us, by appropriate technical and
          organizational measures and insofar as this is reasonably practical,
          promptly comply with your reasonable written instructions associated
          with responding to any consumer’s request to exercise the consumer’s
          rights under CCPA or the Other State Laws, as applicable; (F) taking
          into account the nature of processing and the information available to
          us, reasonably assist you in meeting your obligations in relation to
          the security of processing Personal Information and in relation to
          providing for legally-required notifications of breaches involving
          Personal Information; (G) at your direction, delete or return to you
          all Personal Information as requested at the end of the Term, unless
          retention of the Personal Information is permitted or required by law;
          and (H) notify you after we make a determination that we can no longer
          meet our obligations under Section Error! Reference source not found.
          or this Section 12.d. You have the right, upon notice to us, to take
          reasonable and appropriate steps to stop and remediate our
          unauthorized use of Personal Information. We certify that we
          understand and will comply with the restrictions, duties and
          obligations set forth in this Section 12.d.
        </li>
        <li>
          In the event that any consumer makes a request directly to us with
          respect to exercising its privacy rights under the CCPA and/or an
          Other State Law, we will promptly notify you and provide you with a
          copy of the consumer request, inform the consumer that the consumer’s
          request cannot be acted upon because the request has been sent to a
          service provider or processor (as applicable), provide you with a copy
          of such response, and reasonably cooperate with you in your efforts to
          respond and act on the consumer’s request in accordance with the
          requirements of the CCPA and/or Other State Law, as applicable, in
          each case unless legally prohibited from doing so.
        </li>
        <li>
          Where permitted and provided by CCPA or Other State Law (as
          applicable), nothing in Section Error! Reference source not found. or
          this Section 12.d will prohibit us from retaining, using or disclosing
          the personal information in connection with: (z) retaining or
          employing another service provider or processor as a subcontractor,
          provided the subcontractor meets the requirements for a service
          provider or processor under the CCPA and/or Other State Law (as
          applicable); (y) our internal use to build or improve the quality of
          our Software or Services, provided that the use does not include
          building or modifying household or consumer profiles for use in
          providing services to another business, or correcting or augmenting
          data acquired from another source; (x) detecting data security
          incidents, or protecting against fraudulent or illegal activity; (w)
          complying with applicable laws; (v) complying with a civil, criminal
          or regulatory inquiry, investigation, subpoena or summons by
          governmental authorities; (u) cooperating with law enforcement
          agencies concerning conduct or activity that you, we or a third party
          reasonably and in good faith believe may violate applicable law; or
          (t) exercising or defending legal claims.
        </li>
        <li>
          If we authorize any subcontractor to process, retain or use any
          Personal Information received from you, accessed in connection with
          our provision of the Software or the Services or collected on your
          behalf in connection with our provision of the Software or the
          Services, then prior to any disclosure of such Personal Information to
          such subcontractor, we will enter into a written agreement with such
          subcontractor that (A) includes all required or necessary terms to
          ensure that such subcontractor is deemed a service provider within the
          meaning of the CCPA or a subcontractor or subprocessor within the
          meaning of any applicable Other State Law, and (B) requires the
          subcontractor to be bound by terms that are substantially equivalent
          to the restrictions, duties and obligations under Section Error!
          Reference source not found. and this Section 12.d.
        </li>
        <li>
          Upon your reasonable written request, and at your expense, we will
          make available to you all information in our possession necessary to
          demonstrate our compliance with the obligations in Section Error!
          Reference source not found. and this Section 12.d and (solely to the
          extent required by applicable law) to enable you to conduct and
          document data protection assessments. Additionally, at your expense,
          we will allow for, and cooperate with, reasonable assessments by you
          or your designated assessor; alternatively, we may (at no additional
          charge to you) arrange for a qualified and independent assessor to
          conduct an assessment of our policies and technical and organizational
          measures in support of the obligations under Section Error! Reference
          source not found. and this Section 12.d using an appropriate and
          accepted control standard or framework and assessment procedure for
          such assessments and provide a report of such assessment to you upon
          request. You acknowledge and agree that any information, reports or
          assessments made available to you under this paragraph shall be our
          Confidential Information and shall be subject to all confidentiality
          obligations set forth in this Agreement.
        </li>
        <li>
          For the purposes of this Section 12.d, where CCPA applies, the terms
          “business,” “combine,” “commercial purpose,” “consumer,” “contractor,”
          “personal information,” “processing,” “sell”, “share” and “service
          provider” shall have the meanings given to such terms in CCPA; and
          where the Other State Laws apply, the terms “consumer,” “controller,”
          “processing,” “processor” and “sell” and shall have the meanings given
          to such terms in the applicable Other State Law.
        </li>
      </ul>
      <h3>e. No Sensitive Data</h3>
      <p>
        You understand and accept that the Software is not designed or intended
        for the storage, processing or protection of Sensitive Data and may not
        provide adequate or legally-required security, privacy or other
        protections for Sensitive Data. Therefore, notwithstanding anything to
        the contrary in this Agreement, we will have no responsibility or
        liability for or in any way related to any Sensitive Data that you, your
        Affiliates, your Authorized Users or any other employee, contractor or
        agent under your control or direction or acting on your behalf may
        voluntarily choose to input into the Software or otherwise provide to us
        in connection with the Software, Services or our performance under this
        Agreement. For the purposes of this Agreement, “Sensitive Data” means
        the following: “protected health information” within the meaning of the
        Health Insurance Portability and Accountability Act or any other
        information concerning an individual’s health; financial account
        information of any kind; identity numbers issued by any government
        agency such as driver’s license number, Social Security number or
        passport number; an individual’s precise geolocation; an individual’s
        racial or ethnic origin, religious or philosophical beliefs, or union
        membership; information concerning an individual’s sex life or sexual
        orientation; the contents of any individual’s criminal history or the
        results of background checks or drug screenings; passwords or other
        access credentials that would or could be used to access any personal
        accounts (other than passwords and/or access credentials used by
        Authorized Users to log in to and access the Software which shall not be
        excluded as a result of this provision); biometric information; genetic
        data; or any Student Data (as defined below).
      </p>
      <h3>f. No Student Data</h3>
      <p>
        FOR THE AVOIDANCE OF DOUBT, AND WITHOUT LIMITATION OF ANY OF THE
        FOREGOING, YOU UNDERSTAND AND ACCEPT THAT THE SOFTWARE IS NOT INTENDED
        TO COLLECT, PROCESS OR OTHERWISE INGEST ANY INFORMATION CONCERNING OR
        RELATING TO ANY STUDENT OF AN EDUCATIONAL INSTITUTION (“STUDENT DATA”).
        YOU HEREBY AGREE NOT TO POST, PUBLISH, UPLOAD, CONTRIBUTE OR OTHERWISE
        MAKE AVAILABLE ON OR TO THE SOFTWARE ANY STUDENT DATA, INCLUDING BY
        MEANS OF ANY MESSAGING OR PUBLIC POSTING FEATURE OFFERED AS PART OF THE
        SOFTWARE.
      </p>
      <h2>13. Fees and Payment</h2>
      <h3>a. Fees</h3>
      <p>
        Unless otherwise specified in your Order, you will pay to us the fees
        and charges listed on our standard pricing page on the Website (the
        “Fees”), in accordance with this Section 13. In addition, all Software
        subscription Fees may, in our discretion, be increased if we notify you
        of the pending Fee increase at least 30 days prior to the Fee increase
        taking effect. All purchases are final, all payment obligations are
        non-cancelable and (except as otherwise expressly provided in this
        Agreement or in your Order) all Fees once paid are non-refundable.
      </p>

      <h3>b. Payment Terms</h3>
      <p>
        You will make all payments in US dollars, using the payment method and
        in accordance with the payment schedule set forth on your Order or the
        applicable invoice (and if no payment method is specified in your Order
        or an applicable invoice then payment shall be made by charging a
        payment account set up by you via our payments processing service
        provider and if no payment schedule is specified in your Order or an
        applicable invoice then payment is due on a monthly basis, in arrears,
        each month on the billing date set forth in your account). Unless
        otherwise specified in your Order or the applicable invoice, any
        invoiced amounts are due 30 days from the invoice date. You are
        responsible for providing complete and accurate billing information and
        notifying us of any changes to that information.
      </p>
      <h3>c. Taxes</h3>
      <p>
        Our Fees do not include taxes and similar assessments. You are
        responsible for all sales, use, and excise taxes, and any other similar
        taxes, duties, and charges of any kind imposed by any federal, state,
        local or other governmental or regulatory authority on any amounts
        payable by you hereunder, other than any taxes imposed on FinSiteful’s
        income.
      </p>
      <h2>14. Ownership and IP</h2>
      <h3>a. FinSiteful IP</h3>
      <p>
        As between you and us, we are and will remain the sole and exclusive
        owner of all right, title and interest in and to the Software and
        Documentation (for the avoidance of doubt excluding any of Your Data or
        Confidential Information), including all associated features,
        functionality, software, content, materials and services made available
        thereon by us, including all new versions, Updates, configurations,
        revisions, derivative works, improvements and modifications of any of
        the foregoing, the look and feel, ideas, algorithms, methods and
        concepts underlying or embedded in any of the foregoing and all related
        intellectual property rights (collectively, the “FinSiteful IP”). We are
        not granting you any right, license or authorization with respect to the
        FinSiteful IP except as we’ve specifically provided in Section 3 above.
        For the avoidance of doubt, nothing in this Agreement grants to you any
        rights whatsoever in or relating to the source code of the Software. We
        reserve all other rights in and to the FinSiteful IP.
      </p>
      <h3>b. Your Data</h3>
      <p>
        As between you and us, you are and will remain the sole and exclusive
        owner of all right, title and interest in and to all of Your Data,
        including all intellectual property rights relating to Your Data,
        subject to the rights you grant to us in this Section 14.b. You grant to
        us and our subcontractors all such rights and permissions in or relating
        to Your Data as are necessary or useful to: (i) perform the Services and
        provide the Software; (ii) enforce this Agreement and exercise our
        rights and perform our obligations under this Agreement; and (iii) build
        or improve the quality of our Software or Services, internally, provided
        that the use does not include building or modifying household or
        consumer profiles for use in providing services to another business, or
        correcting or augmenting data acquired from another source.
      </p>
      <h3>c. Usage Data</h3>
      <p>
        We may collect diagnostic, metadata, telemetry, technical and other
        statistical information regarding your, your Affiliates’ and your
        Authorized Users’ use of and the performance of the Software (“Usage
        Data”), such as data on what features and functions are being used and
        to what extent, and information about users’ computers, systems and
        software used to access the Software. We may use Usage Data for
        monitoring, enhancing and improving the Software. Usage Data is not and
        will not be deemed to be Your Data under this Agreement.
      </p>
      <h3>d. Feedback</h3>
      <p>
        You may provide us with information and feedback concerning errors,
        suggestions for improvements, ideas, problems, complaints and other
        matters related to our Software (“Feedback”). You acknowledge and agree
        that: (i) you shall not retain, acquire or assert any intellectual
        property right or other right, title or interest in or to the Feedback;
        (ii) we may have development ideas similar to the Feedback; and (iii)
        FinSiteful is not under any obligation of confidentiality with respect
        to the Feedback. In the event the transfer of ownership to the Feedback
        from you to FinSiteful is not possible due to applicable mandatory laws,
        you grant FinSiteful and its affiliates an exclusive, transferable,
        irrevocable, free-of-charge, sub-licensable, unlimited and perpetual
        right to use (including copy, modify, create derivative works of,
        publish, distribute and commercialize) Feedback in any manner and for
        any purpose.
      </p>
      <h2>15. Term and Termination</h2>
      <h3>a. Term</h3>
      <p>
        Unless otherwise specified in your Order, the term of this Agreement and
        your subscription to the Software and Services (the “Term”) commences on
        the date you accept this Agreement in accordance with Section 1 above
        and continues, on a “month to month” basis, until it is terminated in
        accordance with this Agreement. If your Order specifies a subscription
        term other than on a “month to month” basis, then, at the conclusion of
        the then-current subscription term, your subscription and the Term of
        this Agreement will automatically renew for an additional subscription
        period equal to the duration of the then-current subscription term,
        unless one party notifies the other in writing that it will cancel at
        the end of the then-current subscription term at least 30 days prior to
        the end of the then-current subscription term.
      </p>
      <h3>b. Termination</h3>
      <p>
        In addition to any other termination rights described in this Agreement,
        this Agreement may be terminated prior to the end of the Term at any
        time by either party, effective when that party provides written notice
        to the other, if the other party materially breaches this Agreement and
        such breach (i) remains uncured 10 days after the non-breaching party
        provides the breaching party with written notice regarding such breach,
        or (ii) is the second (or higher ordinal) breach of the limitations and
        restrictions in Section 3.b.
      </p>
      <h3>c. Effect of Termination</h3>
      <p>
        The exercise of any right of termination under this Agreement will not
        affect any rights of either party (e.g., rights to payment) that have
        accrued prior to the effective date of termination and will be without
        prejudice to any other legal or equitable remedies to which a party may
        be entitled. If this Agreement is terminated or expires, then: (i) all
        rights, licenses and authorizations granted by one party to the other
        will immediately terminate (other than any license of Feedback to
        FinSiteful pursuant to Section 14.d), (ii) we may disable your, your
        Affiliates’ and your Authorized Users’ access to the Software, and (iii)
        we each will cease all use of the other party’s Confidential Information
        and (upon written request) promptly destroy or return all of the other
        party’s Confidential Information.
      </p>
      <h3>d. Surviving Terms</h3>
      <p>
        This Section 15.d and Sections 11 (Confidentiality), 12 (Data Security
        and Privacy), 14 (Ownership and IP), 15.d (Effect of Termination), 17
        (Indemnification), 18 (Limitation of Liability), 19 (Miscellaneous) and
        20 (Definitions) will survive any expiration or termination of this
        Agreement.
      </p>
      <h2>16. Representations and Warranties</h2>
      <h3>a. By You Regarding Your Data</h3>
      <p>
        You represent and warrant that: (i) your, your Affiliates’ and your
        Authorized Users’ collection and use of all of Your Data (including your
        choice to upload and process Your Data to and through the Software as
        contemplated in this Agreement) and your Confidential Information is
        consistent with your own privacy policy and your license agreements and
        other agreements with third parties; (ii) you either own, or have all
        rights, permissions and consents that are necessary to permit us, our
        subcontractors and the Software to store, use and otherwise process as
        contemplated in this Agreement, all of Your Data and your Confidential
        Information; (iii) our and our subcontractors’ access to and storage,
        use and other processing of Your Data and your Confidential Information
        (including all Personal Information included in Your Data and your
        Confidential Information) in accordance with this Agreement does not and
        will not violate any applicable law, rule or regulation or infringe,
        misappropriate or otherwise violate any intellectual property right,
        privacy right or other right of any person or entity. You will defend us
        from and against any Claims brought by a third party, and you will
        indemnify and hold us harmless from any Losses associated with such
        third party Claims, in each case to the extent the same are based on
        allegations that you, your Affiliate or your Authorized Users (or any
        other employee, contractor or agent under your control or direction or
        acting on your behalf) have breached any representation or warranty in
        this Section 16.a.
      </p>
      <h3>b. By Us Regarding Our Services</h3>
      <p>
        We warrant that we will perform the Support Services in a professional
        and workmanlike manner in accordance with generally recognized industry
        standards for similar services. If you provide written notice of any
        such Services not performed in accordance with this Section 16.b within
        30 days after completion thereof, we will re-perform such non-conforming
        Services at no additional cost to you. THE PROMPT RE-PERFORMANCE OF
        SUPPORT SERVICES SHALL BE YOUR SOLE AND EXCLUSIVE REMEDY, AND
        FINSITEFUL’S ONLY AND ENTIRE OBLIGATION AND LIABILITY, FOR ANY BREACH OF
        THE WARRANTY IN THIS SECTION 16.b.
      </p>
      <h3>c. By Us Regarding Our Software</h3>
      <p>
        We warrant that the Software will at all times during the Term
        substantially conform in all material respects with its Documentation
        and the written specifications expressly set forth by you and us (if
        any) in your Order. However, the warranty in this Section does not apply
        to any non-conformance resulting from: (x) use of the Software in a
        manner inconsistent with this Agreement or its Documentation, (y) the
        availability of, operation of or access to your, your Affiliate’s, your
        Authorized User’s or a third party’s software, systems or networks, or
        (z) Your Data or any Third-Party Materials. If we breach the warranty
        set forth in this Section 16.c, we will, at our sole option and expense,
        take any of the following steps to remedy such breach: (i) modify, fix
        or correct the Software to remedy such non-conformity; (ii) replace the
        non-conforming portion of the Software, as applicable, with functionally
        equivalent software; or (iii) if the remedies in clauses (i) and (ii)
        are not feasible by commercially reasonable standards, terminate this
        Agreement and promptly refund to you on a pro rata basis the share of
        any Software subscription fees prepaid by you for the future portion of
        the applicable subscription term that would have remained but for such
        termination (a “Refund of Fees”). If we do not cure a warranty breach or
        terminate this Agreement as permitted by the immediately preceding
        sentence within 30 days after our receipt of written notice of such
        breach, you will have the right to terminate this Agreement and we will
        promptly provide to you a Refund of Fees. THE REMEDIES SET FORTH IN THIS
        SECTION 16.c SHALL BE YOUR SOLE AND EXCLUSIVE REMEDY, AND FINSITEFUL’S
        ONLY AND ENTIRE OBLIGATION AND LIABILITY, FOR ANY BREACH OF THE WARRANTY
        IN THIS SECTION 16.c.
      </p>
      <h3>d. Disclaimer of Warranties</h3>
      <p>
        EXCEPT FOR THE EXPRESS LIMITED WARRANTIES IN SECTIONS 16.b AND 16.c, ALL
        SERVICES AND FINSITEFUL IP ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE”
        BASIS AND WE HEREBY DISCLAIM ALL OTHER WARRANTIES, WHETHER EXPRESS,
        IMPLIED, STATUTORY OR OTHER, INCLUDING ALL IMPLIED WARRANTIES OF
        MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND
        NON-INFRINGEMENT. WE GIVE NO (AND WE HEREBY DISCLAIM ANY AND ALL)
        WARRANTIES, EXPRESS, IMPLIED, STATUTORY OR OTHER, IN ANY WAY IN REGARDS
        TO OR RELATING TO ANY THIRD-PARTY MATERIALS.
      </p>
      <h2>17. Indemnification</h2>
      <h3>a. By Us</h3>
      <p>
        We will defend you from and against any Claims brought by a third party,
        and will indemnify and hold you harmless from any Losses associated with
        such third party Claims, in each case to the extent the same are based
        on allegations that the Software or your use thereof (excluding Your
        Data, your Confidential Information or any Third-Party Materials)
        infringe any U.S. patent, copyright or trademark of such third party, or
        misappropriate the trade secret of such third party (each, an
        “Infringement Claim”). Notwithstanding the foregoing, we will have no
        liability or obligation with respect to any Infringement Claim to the
        extent based upon or arising out of: (i) access to or use of the
        Software in combination with any hardware, system, software, network or
        other materials or service not provided by us (or authorized in the
        Documentation or otherwise in writing by us); (ii) modifications or
        configurations made to the Software by anyone other than us or a party
        acting under our direction without our prior written consent; or (iii)
        any action taken by you, your Affiliate or any Authorized relating to
        the use of the Software that violates this Agreement.
      </p>
      <h3>b. Mitigation for Infringement Claims</h3>
      <p>
        If the Software is, or in our opinion is likely to be, the subject of an
        Infringement Claim, or if your, your Affiliate’s or any Authorized
        User’s use of the Software is enjoined or threatened to be enjoined, we
        will, at our option and our sole cost and expense: (i) obtain the right
        for you to continue to use the allegedly infringing Software as
        contemplated by this Agreement, (ii) modify or replace the allegedly
        infringing Software to make such Software (as so modified or replaced)
        non-infringing, without causing a material loss of features or
        functionality, or (iii) if the remedies in clauses (i) and (ii) are not
        feasible within commercially reasonable standards, then we may terminate
        this Agreement upon written notice and without any liability to you and
        we will promptly provide a Refund of Fees.
      </p>
      <h3>c. Indemnification Procedures</h3>
      <p>
        If a party reasonably believes it is entitled to indemnification under
        this Agreement, such party (the “Indemnified Party”) promptly must give
        the other party (the “Indemnifying Party”) written notice of the claim
        of indemnification, provided that an Indemnified Party’s failure to
        notify the Indemnifying Party will not diminish the Indemnifying Party’s
        indemnification obligations except to the extent the Indemnifying Party
        is materially prejudiced as a result of such failure. Any such notice
        shall set forth in reasonable details the facts, circumstances and basis
        of the applicable Claim. Upon receipt of notice of the assertion of a
        Claim, the Indemnifying Party will have the right to control the defense
        or settlement of the matter at its own expense and with counsel of its
        choice, provided that the Indemnifying Party shall not enter into any
        settlement of the relevant Claim without written consent of the
        Indemnified Party (not to be unreasonably withheld). The Indemnified
        Party must cooperate reasonably with the Indemnifying Party, at the
        Indemnifying Party’s expense, to facilitate the defense, compromise or
        settlement of any Claims. The Indemnified Party may employ separate
        counsel and participate in any indemnified Claim, but the fees and
        expenses of such counsel will be at the expense of the Indemnified
        Party.
      </p>
      <h2>18. Limitation of Liability</h2>
      <p>
        IN NO EVENT WILL WE BE LIABLE TO YOU, YOUR AFFILIATES, YOUR AUTHORIZED
        USERS OR TO ANY OTHER PERSON OR ENTITY FOR DAMAGES OF ANY KIND OR NATURE
        (INCLUDING, FOR THE AVOIDANCE OF DOUBT, DIRECT DAMAGES) IN EXCESS OF THE
        AMOUNT OF FEES ACTUALLY PAID BY YOU TO US UNDER THIS AGREEMENT DURING
        THE 12-MONTH PERIOD IMMEDIATELY PRECEDING YOUR FIRST CLAIM AGAINST US
        HEREUNDER. ADDITIONALLY, UNDER NO CIRCUMSTANCES WILL WE HAVE ANY
        LIABILITY WITH RESPECT TO OUR OBLIGATIONS UNDER THIS AGREEMENT OR
        OTHERWISE FOR LOSS OF PROFITS OR FOR CONSEQUENTIAL, SPECIAL, INDIRECT,
        EXEMPLARY, INCIDENTAL OR PUNITIVE DAMAGES, EVEN IF WE HAVE BEEN ADVISED
        OF THE POSSIBILITY OF SUCH DAMAGES OCCURRING, AND WHETHER SUCH LIABILITY
        IS BASED ON CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY, PRODUCTS
        LIABILITY OR OTHERWISE. WE WILL HAVE NO LIABILITY TO YOU, YOUR
        AFFILIATES, YOUR AUTHORIZED USERS OR TO ANY OTHER PERSON OR ENTITY FOR
        DAMAGES, LOSSES OR LIABILITIES OF ANY KIND OR NATURE IN ANY WAY IN
        REGARDS TO OR RELATING TO ANY THIRD-PARTY MATERIALS. THE FOREGOING
        LIMITATIONS OF LIABILITY SHALL APPLY TO THE MAXIMUM EXTENT PERMITTED BY
        APPLICABLE LAW AND NOTWITHSTANDING THE FAILURE OF ANY AGREED OR OTHER
        REMEDY OF ITS ESSENTIAL PURPOSE.
      </p>
      <h2>19. Miscellaneous</h2>
      <h3>a. Entire Agreement</h3>
      <p>
        This Agreement and each Order together constitute the entire agreement
        between the parties on the subject matter hereof, and supersede all
        prior negotiations, understandings or agreements (oral or written) and
        all past dealing or industry custom. We are not and will not be bound by
        (and we hereby expressly reject throughout the Term) any of the terms
        and conditions of (or that may be incorporated by reference in) any
        other purchase order, receipt, acceptance, confirmation or other
        correspondence provided by you.
      </p>
      <h3>b. Amendment, Severability and Waiver</h3>
      <p>
        Except as expressly set forth in Sections 2 and 13.a above, no change,
        consent or waiver under this Agreement will be effective unless in
        writing and signed by the party against which enforcement is sought. Any
        delay or failure of either party to enforce its rights, powers or
        privileges under this Agreement, at any time or for any period, will not
        be construed as a waiver of such rights, powers and privileges, and the
        exercise of one right or remedy will not be deemed a waiver of any other
        right or remedy. If any provision of this Agreement is determined to be
        illegal or unenforceable, that provision will be limited or eliminated
        to the minimum extent necessary so that this Agreement will otherwise
        remain in full force and effect and enforceable.
      </p>
      <h3>c. Governing Law</h3>
      <p>
        This Agreement will be governed by and construed in accordance with the
        laws of the State of Delaware, without regard to its conflicts of law
        provisions.
      </p>
      <h3>d. Notices</h3>
      <p>
        {' '}
        Any notice, request, consent, claim, demand, waiver or other
        communication under this Agreement (each, a “Notice”) must be in writing
        and addressed to the parties at the addresses set forth on your Order or
        to such other address that may be designated by the receiving party in
        writing. All Notices must be delivered by personal delivery, nationally
        recognized overnight courier (with all fees pre-paid), or certified or
        registered mail (in each case, return receipt requested, postage
        pre-paid). Except as otherwise provided in this Agreement, a Notice is
        effective only (i) upon receipt by the receiving party, and (ii) if the
        party giving the Notice has complied with the requirements of this
        Section.
      </p>
      <h3>e. Assignment</h3>
      <p>
        You may not assign or otherwise transfer any of your rights or
        obligations under this Agreement without our prior written consent,
        which consent we will not unreasonably withhold or delay.
        Notwithstanding the foregoing, either party may, upon prior written
        notice to the other party, assign this Agreement in its entirety to an
        Affiliate or to an entity acquiring all or substantially all of such
        party’s assets or equity, provided that (A) such entity is not a direct
        competitor of the other party, and (B) the assigning party remains
        liable for the assignee’s compliance with this Agreement. Any purported
        assignment or transfer in violation of this Section is void. Subject to
        the foregoing, this Agreement will inure to the benefit of and be
        binding upon the parties and their respective permitted successors and
        assigns.
      </p>
      <h3>f. Force Majeure</h3>
      <p>
        We will not be liable for any delay or failure to perform our
        obligations under this Agreement if such delay or failure is due to
        circumstances beyond our reasonable control, including acts of God,
        fire, explosion, labor disputes, war, terrorism, civil disturbance,
        government actions, or other similar causes.
      </p>
      <h3>g. Relationship of the Parties</h3>
      <p>
        The parties are independent contractors, and nothing in this Agreement
        will create a partnership, joint venture, agency, fiduciary or other
        relationship between the parties.
      </p>
      <h3>h. No Third-Party Beneficiaries</h3>
      <p>
        Except as expressly set forth in Section 17, this Agreement is for the
        sole benefit of the parties hereto and their respective successors and
        permitted assigns and nothing herein, express or implied, is intended to
        or shall confer upon any other person or entity any legal or equitable
        right, benefit or remedy of any nature whatsoever under or by reason of
        this Agreement.
      </p>
      <h3>i. Counterparts</h3>
      <p>
        This Agreement may be executed in counterparts, each of which will be
        deemed an original, but all of which together will constitute one and
        the same instrument. A signed copy of this Agreement delivered by
        facsimile, e-mail or other means of electronic transmission will be
        deemed to have the same legal effect as delivery of an original signed
        copy of this Agreement.
      </p>
      <h2>20. Definitions</h2>
      <p>
        Capitalized terms not otherwise defined in this Agreement will have the
        respective meanings assigned to them in this Section 20. For the
        purposes of this Agreement:
      </p>
      <ul>
        <li>
          “Affiliate” means, with respect to a party, any entity that directly
          or indirectly controls, is controlled by or is under common control
          with such party.
        </li>
        <li>
          “Authorized User” means your employees and other individuals who are
          authorized by you to use the Software.
        </li>
        <li>
          “Claim” means any claim, action, suit, proceeding or investigation.
        </li>
        <li>
          “Documentation” means the user manuals, handbooks, and guides, whether
          in electronic, printed or other form, that we make generally available
          to our customers.
        </li>
        <li>
          “Harmful Code” means any software, hardware or other technology
          device, the purpose or effect of which is to permit unauthorized
          access to or use of the Software, disable, damage or erase the
          Software, or otherwise materially and adversely affect the
          functionality of the Software.
        </li>
        <li>
          “Losses” means all losses, damages, liabilities, costs, penalties,
          fines, assessments and expenses (including reasonable attorneys’
          fees).
        </li>
        <li>
          “Order” means our mutually agreed upon written order, proposal, work
          order, statement of work, quote or other written agreement for your
          subscription to our Software and Services, which may include, among
          other things, the Subscription Plan you have selected.
        </li>
        <li>
          “Permitted Use” means your internal business use of the Software in
          accordance with this Agreement and the Documentation.
        </li>
        <li>
          “Personal Information” has the meaning set forth in Section 12.c
          above.
        </li>
        <li>
          “Security Incident” means any unauthorized or accidental acquisition,
          destruction, loss, alteration or use of, and any unauthorized access
          to, Your Data that is processed by us.
        </li>
        <li>“Services” has the meaning set forth in Section 4.a above.</li>
        <li>“Software” has the meaning set forth in the preamble above.</li>
        <li>“Term” has the meaning set forth in Section 15.a above.</li>
        <li>
          “Third-Party Materials” has the meaning set forth in Section 8 above.
        </li>
        <li>“Usage Data” has the meaning set forth in Section 14.c above.</li>
        <li>
          “Your Data” means all data, information and other content submitted to
          or otherwise made available to us by you or on your behalf in
          connection with your use of the Software or the provision of Services.
        </li>
      </ul>
      <p>
        <strong>PublicMind™ Terms of Service</strong>
        <br />
        <strong>Effective Date: October 26, 2023</strong>
      </p>
    </div>
  );
};

export default TermsOfService;
