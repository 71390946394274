import React, {useEffect, useRef} from 'react';
import './styles.css';

export const SideDrawer = ({show, setShow, children, size}) => {
  const modalRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (modalRef.current && event.target === modalRef.current) {
        setShow(false);
      }
    }

    window.addEventListener('click', handleClickOutside);

    return () => {
      window.removeEventListener('click', handleClickOutside);
    };
  }, [setShow]);

  return (
    <div ref={modalRef} className={`sidedrawer-container ${show && 'show'}`}>
      <div className={`sidedrawer ${show && (size || 'medium')}`}>
        {children}
      </div>
    </div>
  );
};
