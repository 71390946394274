/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cloud_logic_custom": [
        {
            "name": "externalRestAPI",
            "endpoint": "https://6zgfi75bt3.execute-api.us-east-1.amazonaws.com/prod",
            "region": "us-east-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://w3n7ezgti5brzj5u7pxybi33o4.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-4rr3k54zwnhazbnan2pod42ku4",
    "aws_cognito_identity_pool_id": "us-east-1:e137e936-fe39-4c54-978d-ec76a535f73b",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_IDbgT9Mwc",
    "aws_user_pools_web_client_id": "3q0ukaba1osea2n2te22gq91bg",
    "oauth": {
        "domain": "publicmind2a44a8a9-2a44a8a9-prod.auth.us-east-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "http://localhost:3000/",
        "redirectSignOut": "http://localhost:3000/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "publicmind-filesystem-dev104754-prod",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
