import React from 'react';
import {useNavigate} from 'react-router-dom';

const PageNotFound = ({}) => {
  const navigate = useNavigate();
  return (
    <div className="page-container">
      <h2>Page Not Found</h2>
      <button
        onClick={() => {
          navigate(-1);
        }}>
        GO BACK
      </button>
    </div>
  );
};
export default PageNotFound;
