import React, {useCallback, useEffect} from 'react';
import {RxCross2, RxInfoCircled} from 'react-icons/rx';
import ModalPortal from '../../../components/modal/modal-portal';
import {useAuth} from '../../../hooks/use-auth';
import {useThreads} from '../../../hooks/use-threads';
import ThreadComment from '../components/thread-comment';
import {ThreadReply} from '../components/thread-reply';
import {CreateReply} from '../inputs/create-reply-inputs';
import './FullScreenMode.css';

const FullScreenThreadModal = ({content_id, onClose, replys, sub}) => {
  const {
    state: {profiles},
  } = useAuth();
  const {
    state: {thread_content, threads},
  } = useThreads();

  const handleKeyDown = useCallback(e => {
    if (e.key === 'Escape') {
      onClose();
    }
  }, []);

  const mainContent = thread_content?.[content_id];
  const thread = threads[mainContent?.thread_id];
  useEffect(() => {
    if (thread) {
      document.addEventListener('keydown', handleKeyDown);
      return () => {
        document.removeEventListener('keydown', handleKeyDown);
      };
    }
  }, [thread, handleKeyDown]);
  if (!content_id) return null;

  let reversedReplys = replys.reverse();
  return (
    <ModalPortal>
      <div className="full-screen-modal__overlay">
        <div className="full-screen-modal__container">
          <div className="full-screen-modal__header">
            <h2 className="full-screen-modal__title">
              Comment from {thread?.logo}
              {thread?.title}
            </h2>
            <button
              className="full-screen-modal__close-button"
              onClick={onClose}>
              <RxCross2 />
            </button>
          </div>
          <div className="full-screen-modal__content">
            <div className="full-screen-modal__main-content">
              <div className="full-screen-modal__comment-wrapper">
                <ThreadComment
                  item={mainContent ?? {}}
                  viewOnly={true}
                  sub={sub}
                />
              </div>
              <div className="full-screen-modal__create-comment">
                <CreateReply
                  thread_id={mainContent?.thread_id}
                  parent={content_id}
                />
              </div>
            </div>
            <div className="full-screen-modal__replies-sidebar">
              <div className="full-screen-modal__replies-list padding-top8">
                {reversedReplys?.length === 0 && (
                  <div className="fs-reply-empty">
                    <RxInfoCircled className="fs-reply-empty-icon" />
                    <p>No replies yet</p>
                    <p> Add reply in screen's bottom center</p>
                  </div>
                )}
                {reversedReplys?.map(nestedId => (
                  <ThreadReply
                    key={nestedId}
                    item={thread_content?.[nestedId] ?? {}}
                    sub={sub}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </ModalPortal>
  );
};

export default FullScreenThreadModal;
