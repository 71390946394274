import {post} from 'aws-amplify/api';
import {track} from './analytics';

const apiName = 'externalRestAPI';

const fetchPreview = async body => {
  try {
    const path = '/fetchPreview';
    const options = {
      headers: {}, // Optional
      response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
      body,
    };
    const postOpp = post({apiName, path, options});
    const {body: data, headers, statusCode} = await postOpp.response;
    const json = await data.json();

    return json;
  } catch (err) {
    track('api_error', {type: 'lambda', function: 'fetchPreview', err});
    console.log(err, body);

    throw err;
  }
};

export {fetchPreview};
