const dayjs = require('dayjs');
var Parser = require('../parser').Parser;
var ParsedResult = require('../../result').ParsedResult;
var util  = require('../../utils/FR');

var PATTERN = new RegExp('(\\W|^)' +
    '(dans|en)\\s*' +
    '('+ util.INTEGER_WORDS_PATTERN + '|[0-9]+|une?|(?:\\s*quelques)?|demi(?:\\s*|-?)?)\\s*' +
    '(secondes?|min(?:ute)?s?|heures?|jours?|semaines?|mois|années?)\\s*' +
    '(?=\\W|$)', 'i'
);

var STRICT_PATTERN = new RegExp('(\\W|^)' +
    '(dans|en)\\s*' +
    '('+ util.INTEGER_WORDS_PATTERN + '|[0-9]+|un?)\\s*' +
    '(secondes?|minutes?|heures?|jours?)\\s*' +
    '(?=\\W|$)', 'i'
);

exports.Parser = function FRDeadlineFormatParser(){
    Parser.apply(this, arguments);

    this.pattern = function() {
        return this.isStrictMode()? STRICT_PATTERN : PATTERN;
    };

    this.extract = function(text, ref, match, opt){

        var index = match.index + match[1].length;
        var text  = match[0];
        text  = match[0].substr(match[1].length, match[0].length - match[1].length);

        var result = new ParsedResult({
            index: index,
            text: text,
            ref: ref
        });

        var num = match[3];
        if (util.INTEGER_WORDS[num] !== undefined) {
            num = util.INTEGER_WORDS[num];
        } else if (num === 'un' || num === 'une'){
            num = 1;
        } else if (num.match(/quelques?/i)){
            num = 3;
        } else if (num.match(/demi-?/i)) {
            num = 0.5;
        } else {
            num = parseInt(num);
        }

        var date = dayjs(ref);
        if (match[4].match(/jour|semaine|mois|année/i)) {

            if (match[4].match(/jour/)) {
                date = date.add(num, 'd');
            } else if (match[4].match(/semaine/i)) {
                date = date.add(num * 7, 'd');
            } else if (match[4].match(/mois/i)) {
                date = date.add(num, 'month');
            } else if (match[4].match(/année/i)) {
                date = date.add(num, 'year');
            }

            result.start.assign('year', date.year());
            result.start.assign('month', date.month() + 1);
            result.start.assign('day', date.date());
            return result;
        }

        if (match[4].match(/heure/i)) {

            date = date.add(num, 'hour');

        } else if (match[4].match(/min/i)) {

            date = date.add(num, 'minutes');

        } else if (match[4].match(/secondes/i)) {

            date = date.add(num, 'second');
        }

        result.start.imply('year', date.year());
        result.start.imply('month', date.month() + 1);
        result.start.imply('day', date.date());
        result.start.assign('hour', date.hour());
        result.start.assign('minute', date.minute());
        result.start.assign('second', date.second());
        result.tags['FRDeadlineFormatParser'] = true;
        return result;
    };
};
