import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import useLoader from '../../../hooks/use-loader';
import {useThreads} from '../../../hooks/use-threads';

const PollResults = ({}) => {
  const {id} = useParams();
  const {loadProfiles} = useLoader();

  const {
    state: {thread_content},
  } = useThreads();

  const content = thread_content?.[id] ?? {};
  const {responses, type, choices} = content;

  const [aggregate, setAggregate] = useState({});

  useEffect(() => {
    const user_ids = responses?.map(response => response.user_id);
    loadProfiles(user_ids);

    if (type === 'choice_poll') {
      const sum = {};
      responses.forEach(choice => {
        const {response, user_id} = choice;
        if (sum[response] === undefined) {
          sum[response] = [];
        }
        sum[response].push(user_id);
      });
      setAggregate(sum);
    }
  }, [responses]);

  return (
    <div className="page-container">
      <h4>{content.content}</h4>
      {type === 'choice_poll' && (
        <div className="card">
          {choices.map(option => {
            const ids = aggregate?.[option] ?? [];
            return (
              <p key={option}>
                {option}: {ids?.length ?? 0}
              </p>
            );
          })}
        </div>
      )}
      <div className="card">
        {responses?.map(response => {
          const {user_id, response: res} = response;
          return (
            <div key={user_id} className="flex-row ">
              <p>
                {user_id}: {res}
              </p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default PollResults;
