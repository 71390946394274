import iconDark from '../../assets/logos/icon-dark.png';
import iconLight from '../../assets/logos/icon-light.png';
import scbio from '../../assets/logos/scbio.png';
import './styles.css';

export const PublicMindLogoLight = () => {
  return (
    <div className="flex-row align-center">
      <img alt="Finsiteful" className="square-small margin-h8" src={iconDark} />
      <h3>PUBLICMIND</h3>
    </div>
  );
};
export const PublicMindLogoLightNoText = () => {
  return (
    <div className="flex-row align-center">
      <img
        alt="PublicMind"
        className="square-small margin-h8 container-logo"
        src={iconDark}
      />
    </div>
  );
};

export const PublicMindLogoDarkNoText = () => {
  return (
    <div className="flex-row align-center">
      <img
        alt="PublicMind"
        className="square-small margin-h8 container-logo"
        src={iconLight}
      />
    </div>
  );
};
export const PublicMindLogoLightNoTextLarge = () => {
  return (
    <div className="flex-row align-center">
      <img alt="PublicMind" className="square-large margin-h8" src={iconDark} />
    </div>
  );
};
export const PublicMindLogo = () => {
  return (
    <div className="flex-row align-center">
      <img
        alt="PublicMind"
        className="square-xsmall padding-right8"
        src={iconDark}
      />
    </div>
  );
};
export const PublicMindLogoBig = () => {
  return (
    <div className="flex-row align-center">
      <img alt="PublicMind" className="square-large" src={iconDark} />
    </div>
  );
};
export const PublicMindLogoText = () => {
  return (
    <div className="flex-row align-center justify-center">
      <img alt="PublicMind" className="square-small margin-h8" src={iconDark} />
      <h2>PUBLICMIND</h2>
    </div>
  );
};

export const SCBioLogo = () => {
  return (
    <div className="flex-row align-center justify-center">
      <img
        alt="PublicMind"
        className="margin-h8"
        src={scbio}
        style={{height: '64px'}}
      />
    </div>
  );
};
export const PublicMindLogoLightNoTextSCBioSize = () => {
  return (
    <div className="flex-row align-center">
      <img
        alt="PublicMind"
        className="square-large margin-h8"
        src={iconLight}
        style={{height: '64px', width: '64px'}}
      />
      <h4
        style={{
          fontFamily: "'Montserrat', sans-serif",
          fontWeight: 300,
          letterSpacing: '0',
          color: 'black',
        }}>
        PUBLICMIND
      </h4>
    </div>
  );
};
