import React, {lazy, Suspense, useEffect, useState} from 'react';
import {RxInfoCircled} from 'react-icons/rx';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import {track} from '../../../api/analytics';
import {dateToTimestamp} from '../../../api/dates';
import constants from '../../../components/constants';
import {SpinningIndicator} from '../../../components/loading/loading-indicator';
import {NPSModal} from '../../../components/modal/feedback-modals';
import {TooltipRight} from '../../../components/tooltip/tooltip';
import {useAuth} from '../../../hooks/use-auth';
import {useThreads} from '../../../hooks/use-threads';
import {ThreadList} from '../components/thread-list';
import {DirectMessages} from '../overview-tabs/direct-messages';
import {Templates} from '../overview-tabs/templates';
import ThreadsHome from '../overview-tabs/thread-home';
import '../ThreadStyles.css';

const ContentDrafts = lazy(() => import('../overview-tabs/content-drafts'));
const ThreadFeed = lazy(() => import('../overview-tabs/thread-feed'));
const TasksTab = lazy(() => import('../overview-tabs/tasks'));
const ChronologicalContent = lazy(() =>
  import('../overview-tabs/chronological-content'),
);
const ThreadDetail = lazy(() => import('../overview-tabs/thread-detail'));
const Mentions = lazy(() => import('../overview-tabs/mentions'));

const ThreadsOverview = () => {
  const {thread_id, content_id} = useParams();
  const {state} = useLocation();

  const navigate = useNavigate();

  const {
    state: {id: userId, profile},
  } = useAuth();
  const {
    state: {
      thread_ids,
      threads,
      subscriptions,
      templates_loaded,
      threads_loaded,
    },
    attachThreadContentListener,
    defaultUpdate,
    getTemplates,
  } = useThreads();

  const [current, setCurrent] = useState(thread_id ? thread_id : null);
  const [focusedContent, setFocusedContent] = useState(
    content_id ? content_id : null,
  );

  const [index, setIndex] = useState(thread_id ? 'thread-detail' : 'home');

  const [TOTAL_PAID_THREADS, setPaidThreads] = useState(null);
  const [showFeedback, setShowFeedback] = useState(
    (profile?.signup_timestamp ?? 0) + constants.days_7 <= dateToTimestamp() &&
      !profile?.in_app_notifications?.length,
  );

  // CALC PAID THREAD COUNT
  useEffect(() => {
    if (threads && thread_ids) {
      const ownedThreadsCount = thread_ids.filter(threadId => {
        const thread = threads[threadId];
        const {permissions, status} = thread;

        if (status !== 'active') {
          return false;
        }

        return permissions.find(p => p.user_id === userId)?.role === 'owner';
      }).length;
      setPaidThreads(ownedThreadsCount);
    }
  }, [threads, thread_ids, userId]);

  //GET THREADS AND SUBs
  useEffect(() => {
    if (!templates_loaded) {
      const query = {owner_id: userId};
      getTemplates(query);
    }
  }, []);

  useEffect(() => {
    if (thread_id) {
      track('thread_viewed', {
        thread_id,
        thread_title: threads[thread_id]?.title,
      });
      setIndex('thread-detail');
      setCurrent(thread_id);
    }
  }, [thread_id]);

  useEffect(() => {
    if (content_id) {
      setIndex('thread-detail');
      setFocusedContent(content_id);
    }
  }, [content_id]);

  useEffect(() => {
    if (state?.tab) {
      setIndex(state?.tab);
    }
  }, [state?.tab, state?.timestamp]);

  // ATTACH LISTENERS
  useEffect(() => {
    const filtered = thread_ids.filter(
      t_id => threads?.[t_id]?.status === 'active',
    );

    if (!filtered?.length) {
      return;
    }

    if (subscriptions?.length) {
      subscriptions.forEach(sub => {
        sub.create.unsubscribe();
        sub.update.unsubscribe();
      });
      defaultUpdate({subscriptions: []});
    }

    let i = 0;
    let index = 0;
    const page = 5;

    while (index <= filtered?.length && i < 49) {
      const ids = filtered.slice(index, index + page);
      attachThreadContentListener(
        {
          filter: {
            thread_id: {in: ids},
          },
        },
        userId,
      );
      i += 1;
      index += page;
    }
  }, [thread_ids]);

  useEffect(() => {
    if (index !== 'thread-detail') {
      setCurrent(null);
    }
  }, [index]);

  const isProUser = () => {
    return (
      userId === 'jack@publicmind.ai' ||
      userId === 'charlie@publicmind.ai' ||
      userId === 'kirtan@publicmind.ai' ||
      userId === 'shsimmons@dew.sc.gov' ||
      userId === 'andy.bernardin@sc.edu'
    );
  };

  return (
    <>
      <div className="page-container">
        <div className="threads-overview">
          <div className="threads-container">
            <div className="threads-list">
              <div className="threads-header">
                <TooltipRight
                  text={
                    'Threads are secure workspaces for you and your partners'
                  }>
                  <span className="flex-row">
                    <h4>Home</h4>
                    <RxInfoCircled />
                  </span>
                </TooltipRight>

                <button
                  className="add-thread-button"
                  disabled={
                    (!isProUser && TOTAL_PAID_THREADS >= 2) ||
                    TOTAL_PAID_THREADS >= 200
                  }
                  onClick={() => navigate('/threads/create')}>
                  <TooltipRight
                    text={
                      isProUser()
                        ? `${TOTAL_PAID_THREADS} out of 200 Threads Used`
                        : `${TOTAL_PAID_THREADS} out of 2 Free Threads Used`
                    }>
                    <p className=" text-10">New Thread</p>
                  </TooltipRight>
                </button>
              </div>

              <div className="thread-items">
                {!threads_loaded ? (
                  <SpinningIndicator />
                ) : (
                  <ThreadList
                    current={current}
                    setCurrent={setCurrent}
                    setIndex={setIndex}
                  />
                )}
              </div>
            </div>

            <div className="thread-detail">
              <Suspense fallback={<SpinningIndicator />}>
                {index === 'home' && (
                  <ThreadsHome
                    setCurrent={setCurrent}
                    setIndex={setIndex}
                    setContent={setFocusedContent}
                  />
                )}
                {index === 'thread-detail' && (
                  <ThreadDetail
                    id={current}
                    focused_content={focusedContent}
                    setFocusedContent={setFocusedContent}
                    setCurrent={setCurrent}
                  />
                )}
                {index === 'feed' && (
                  <ThreadFeed
                    setCurrent={setCurrent}
                    setIndex={setIndex}
                    setContent={setFocusedContent}
                    disabled={
                      (!isProUser && TOTAL_PAID_THREADS >= 2) ||
                      TOTAL_PAID_THREADS >= 200
                    }
                  />
                )}
                {index === 'activity' && (
                  <ChronologicalContent
                    setCurrent={setCurrent}
                    setIndex={setIndex}
                    setContent={setFocusedContent}
                  />
                )}
                {index === 'tasks' && (
                  <TasksTab
                    setCurrent={setCurrent}
                    setIndex={setIndex}
                    setContent={setFocusedContent}
                  />
                )}
                {index === 'mentions' && (
                  <Mentions
                    setCurrent={setCurrent}
                    setIndex={setIndex}
                    setContent={setFocusedContent}
                  />
                )}
                {index === 'templates' && <Templates />}

                {index === 'direct-messages' && <DirectMessages />}
                {index === 'drafts' && (
                  <ContentDrafts setIndex={setIndex} setCurrent={setCurrent} />
                )}
              </Suspense>
            </div>
          </div>
        </div>
      </div>
      <NPSModal active={showFeedback} setActive={setShowFeedback} />
    </>
  );
};

export default ThreadsOverview;
