import {useState} from 'react';
import tagsJSON from '../../assets/lists/tags.json';
import useStringFormatter from '../../hooks/use-string-formatter';
import {Tab} from '../tab/tab';
import {FormikBubbleInput} from './bubble-input';
import {Checkbox} from './checkbox';
import {FormikArraySearchableDropDown} from './drop-down';

export const TagInput = ({formik}) => {
  const [index, setIndex] = useState(0);

  const tabs = [
    {tab: 'Opportunities', type: 'opportunity', index: 0},
    // {tab: 'Collaborators', type: 'collaborator', index: 1},
    {tab: 'Skills', type: 'skill', index: 1},
    {tab: 'Degrees', type: 'degree', index: 2},
    {tab: 'Majors', type: 'major', index: 3},
    {tab: 'Services', type: 'service', index: 4},
  ];

  return (
    <div>
      <div className="padding-top8" />
      <Tab items={tabs} index={index} setIndex={setIndex} />
      <div className="padding-top8" />
      <TagPicker formik={formik} tab={tabs[index]} />
    </div>
  );
};

const TagPicker = ({formik, tab}) => {
  const {prettyHeader} = useStringFormatter();
  const tags = tagsJSON.filter(tag => tag.type === tab.type);
  const mapped = tags.map(tag => tag.key);
  const {values} = formik;
  const active = values[tab.type]?.length ?? false;

  switch (tab.type) {
    case 'major':
      return (
        <div>
          {!!active && (
            <Checkbox
              checked={active}
              onChange={e => {
                const val = e.target.checked;
                if (val) {
                  formik.setFieldValue(tab.type, mapped);
                } else {
                  formik.setFieldValue(tab.type, []);
                }
              }}
              label={active ? 'Deselect all' : 'Select all'}
            />
          )}
          <FormikArraySearchableDropDown
            formik={formik}
            header={tab.tab}
            name={tab.type}
            items={tags}
            mappings={{key: 'pretty', value: 'key'}}
          />
        </div>
      );
    case 'opportunity':
    case 'collaborator':
    case 'skill':
    case 'service':
      const divided = divideSubtype(tags);
      const fields = Object.keys(divided);
      const value = !!values[tab.type]?.length ?? false;
      return (
        <div>
          <Checkbox
            checked={value}
            onChange={e => {
              const val = e.target.checked;
              if (val) {
                formik.setFieldValue(tab.type, mapped);
              } else {
                formik.setFieldValue(tab.type, []);
              }
            }}
            label={value ? 'Deselect all' : 'Select all'}
          />
          {fields.map(field => {
            const items = divided[field];
            return (
              <FormikBubbleInput
                key={field}
                formik={formik}
                name={tab.type}
                header={prettyHeader(field)}
                items={items}
                mappings={{key: 'pretty', value: 'key'}}
                maxWidth={true}
              />
            );
          })}
        </div>
      );
    default:
      return (
        <div>
          <Checkbox
            checked={active}
            onChange={e => {
              const val = e.target.checked;
              if (val) {
                formik.setFieldValue(tab.type, mapped);
              } else {
                formik.setFieldValue(tab.type, []);
              }
            }}
            label={active ? 'Deselect all' : 'Select all'}
          />
          <FormikBubbleInput
            formik={formik}
            name={tab.type}
            header={tab.tab}
            items={tags}
            mappings={{key: 'pretty', value: 'key'}}
            maxWidth={true}
          />
        </div>
      );
  }
};

const divideSubtype = array => {
  const divided = {};
  array.forEach(item => {
    const {sub_type} = item;
    if (divided[sub_type] === undefined) {
      divided[sub_type] = [];
    }
    divided[sub_type].push(item);
  });
  return divided;
};
