
exports.Refiner = function Refiner() { 

    this.refine = function(text, results, opt) { return results; };
}

exports.Filter = function Filter() { 
    
    exports.Refiner.call(this);

    this.isValid = function(text, result, opt) { return true; }
    this.refine = function(text, results, opt) { 

        var filteredResult = [];
        for (var i=0; i < results.length; i++) {

            var result = results[i];
            if (this.isValid(text, result, opt)) {
                filteredResult.push(result);
            }
        }

        return filteredResult;
    }
}


// Common refiners
exports.OverlapRemovalRefiner = require('./OverlapRemovalRefiner').Refiner;
exports.ExtractTimezoneOffsetRefiner = require('./ExtractTimezoneOffsetRefiner').Refiner;
exports.ExtractTimezoneAbbrRefiner = require('./ExtractTimezoneAbbrRefiner').Refiner;
exports.ForwardDateRefiner = require('./ForwardDateRefiner').Refiner;
exports.UnlikelyFormatFilter = require('./UnlikelyFormatFilter').Refiner;

// en refiners
exports.ENMergeDateTimeRefiner = require('./en/ENMergeDateTimeRefiner').Refiner;
exports.ENMergeDateRangeRefiner = require('./en/ENMergeDateRangeRefiner').Refiner;
exports.ENPrioritizeSpecificDateRefiner = require('./en/ENPrioritizeSpecificDateRefiner').Refiner;

// ja refiners
exports.JPMergeDateRangeRefiner = require('./ja/JPMergeDateRangeRefiner').Refiner;

// fr refiners
exports.FRMergeDateRangeRefiner = require('./fr/FRMergeDateRangeRefiner').Refiner;
exports.FRMergeDateTimeRefiner = require('./fr/FRMergeDateTimeRefiner').Refiner;

// de refiners
exports.DEMergeDateRangeRefiner = require('./de/DEMergeDateRangeRefiner').Refiner;
exports.DEMergeDateTimeRefiner = require('./de/DEMergeDateTimeRefiner').Refiner;

// nl refiners
exports.NLMergeDateRangeRefiner = require('./nl/NLMergeDateRangeRefiner').Refiner;
exports.NLMergeDateTimeRefiner = require('./nl/NLMergeDateTimeRefiner').Refiner;
