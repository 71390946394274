import {useCallback, useState} from 'react';
import {GoGitBranch} from 'react-icons/go';
import {RiPushpinFill, RiPushpinLine} from 'react-icons/ri';
import {RxEnterFullScreen, RxLink2, RxPencil1, RxTrash} from 'react-icons/rx';
import {track} from '../../../api/analytics';
import constants from '../../../components/constants';
import {EmojiInput} from '../../../components/inputs/emoji-input';
import ModalPortal from '../../../components/modal/modal-portal';
import {
  BreakoutThreadModal,
  EditSpecialContentModal,
} from '../../../components/modal/thread-modals';
import {useAuth} from '../../../hooks/use-auth';
import useCollaborator from '../../../hooks/use-collaborator';
import {useThreads} from '../../../hooks/use-threads';
import useUtilities from '../../../hooks/use-utilities';
import {EditCommentReply} from './create-reply-inputs';

// ACTIONS FOR INTERACTING WITH A CREATED COMMENT/REPLY
export const ContentActions = ({
  item,
  onQuote,
  onReply,
  setFullScreenContent,
  allowEdit = true,
  allowReactions = true,
  dm,
}) => {
  const {
    id,
    thread_id,
    content,
    parent,
    owner_id,
    reactions,
    type,
    mentions,
    priority,
  } = item;
  const {threadActions} = useCollaborator();
  const {
    state: {threads},
    deleteThreadContent,
    updateContent,
  } = useThreads();
  const {
    state: {id: user_id, profiles},
  } = useAuth();

  const user = profiles?.[owner_id] ?? {};
  const [showEmoji, setShowEmoji] = useState(false);
  const [breakout, setBreakout] = useState(false);
  const [edit, setEdit] = useState(false);
  const [editContent, setEditContent] = useState(false);
  const [shared, setShared] = useState(null);

  const {first_name, last_name, position} = user;

  const thread = threads?.[thread_id] ?? {};
  const {editable, writeable} = threadActions(thread);
  // TODO Improve this logic
  const owner =
    owner_id === user_id ||
    thread?.permissions?.find(p => p.user_id === user_id)?.role === 'owner';

  const complete = thread?.status === 'complete';
  const assigned = type === 'task' && mentions?.includes(user_id);

  const {copyString} = useUtilities();

  const handleLink = useCallback(
    e => {
      e.stopPropagation();
      copyString(`${constants.root_url}/threads/${thread_id}/${id}`);
      setShared('Copied!');
      track('link_copied', {content_id: id, thread_id});
    },
    [thread_id, id, copyString],
  );

  const handlePin = async () => {
    await updateContent({
      id: item.id,
      priority: !priority,
    });
  };

  const handleDelete = () => {
    if (window.confirm('Are you sure you want to delete this reply?')) {
      track('content_deleted', {
        content_id: id,
        thread_id: thread_id,
      });
      deleteThreadContent({id: item.id});
    }
  };

  if (!user_id || complete) {
    return null;
  }

  if (dm) {
    return (
      <div className="content-actions border-secondary-button ">
        <EmojiInput
          setEmoji={async emoji => {
            const cleaned = [...(reactions || [])].filter(
              reaction => reaction.user_id !== user_id,
            );
            cleaned.push({reaction: emoji, user_id});
            await updateContent({id, reactions: cleaned});
          }}
          setShowPicker={setShowEmoji}
          showPicker={showEmoji}
        />
        {owner && (
          <button
            title="Delete comment"
            className="action-button"
            onClick={handleDelete}>
            <RxTrash />
          </button>
        )}
      </div>
    );
  }

  return (
    <>
      <div className="content-actions border-secondary-button">
        {writeable && onReply && (
          <>
            {!parent && (
              <button
                title="Comment on post"
                className="action-button"
                type="submit"
                onClick={onReply}>
                Comment
              </button>
            )}
            {parent && (
              <button
                title="Reply and tag member"
                className="action-button"
                type="submit"
                onClick={() => {
                  onQuote(
                    item.parent,
                    `@[${first_name} ${last_name}](/feed/profiles/detail/${owner_id}) `,
                  );
                }}>
                Reply
              </button>
            )}
          </>
        )}
        {editable && (
          <button
            title="Create breakout thread"
            className="action-button"
            type="submit"
            onClick={() => {
              setBreakout(true);
            }}>
            <GoGitBranch />
          </button>
        )}
        {allowReactions && (
          <EmojiInput
            setEmoji={async emoji => {
              const cleaned = [...(reactions || [])].filter(
                reaction => reaction.user_id !== user_id,
              );
              cleaned.push({reaction: emoji, user_id});
              await updateContent({id, reactions: cleaned});
            }}
            setShowPicker={setShowEmoji}
            showPicker={showEmoji}
          />
        )}
        {owner && !parent && (
          <button
            title="Pin comment"
            className="action-button"
            onClick={handlePin}>
            {priority ? <RiPushpinFill /> : <RiPushpinLine />}
          </button>
        )}
        {!parent && (
          <button
            title="Full screen mode"
            className="action-button"
            onClick={() => {
              setFullScreenContent(id);
            }}>
            <RxEnterFullScreen />
          </button>
        )}
        <button
          title="Copy link to comment "
          className="action-button"
          onClick={e => handleLink(e)}>
          {shared ? shared : <RxLink2 />}
        </button>
        {(owner || assigned) && allowEdit && type !== 'comment' && (
          <>
            <button
              title="Edit comment"
              className="action-button"
              onClick={e => {
                e.stopPropagation();
                if (type === 'comment') {
                  setEdit(!edit);
                } else {
                  setEditContent(!editContent);
                }
              }}>
              <RxPencil1 />
            </button>
          </>
        )}

        {owner && (
          <button
            title="Delete comment"
            className="action-button"
            onClick={handleDelete}>
            <RxTrash />
          </button>
        )}
        {breakout && (
          <ModalPortal zIndex={1100}>
            <BreakoutThreadModal
              active={breakout}
              setActive={setBreakout}
              content={item}
            />
          </ModalPortal>
        )}
      </div>
      {edit && (
        <EditCommentReply
          comment={item}
          onClose={() => {
            setEdit(!edit);
          }}
          isOpen={edit}
        />
      )}
      {editContent && (
        <EditSpecialContentModal
          setActive={setEditContent}
          active={editContent}
          content={item}
        />
      )}
    </>
  );
};
