import {filterSuggestionItems} from '@blocknote/core';
import {BlockNoteView} from '@blocknote/mantine';
import {
  getDefaultReactSlashMenuItems,
  SuggestionMenuController,
  useCreateBlockNote,
} from '@blocknote/react';
import {useFormik} from 'formik';
import React from 'react';
import {RxTrash} from 'react-icons/rx';
import {v4} from 'uuid';
import * as Yup from 'yup';
import {dateToTimestamp} from '../../../api/dates';
import {
  insertDividerLineItem,
  setupBlocks,
} from '../../../components/blocks/divider';
import {blockInputTheme, schema} from '../../../components/inputs/block-input';
import {FormikTextfield} from '../../../components/inputs/textfields';
import {useAuth} from '../../../hooks/use-auth';
import {useThreads} from '../../../hooks/use-threads';
import './templates.css';

export const Templates = ({setCurrent, setIndex, setContent}) => {
  const {
    state: {template_ids, templates, templates_loaded},
    createTemplate,
  } = useThreads();
  const {
    state: {id},
  } = useAuth();

  return (
    <div className="templates-container">
      <div className="templates-header">
        <h3>Templates</h3>
        <button
          className="create-template-btn"
          onClick={async () => {
            const now = dateToTimestamp();
            const template = {
              id: v4(),
              name: 'New template',
              owner_id: id,
              type: 'comment',
              sub_type: null,
              priority: false,
              content: '',
              content_html: null,
              media: [],
              mentions: [],
              metrics: [],
              tasks: [],
              dates: [],
              choices: [],
              expiration: null,
              created: now,
              updated: now,
            };
            await createTemplate(template);
          }}>
          {' '}
          Create Template
        </button>
      </div>
      {template_ids.map(t_id => {
        return <TemplateItem key={t_id} id={t_id} />;
      })}
    </div>
  );
};

const TemplateItem = ({id}) => {
  const {
    state: {templates},
    createTemplate,
    updateTemplate,
    deleteTemplate,
  } = useThreads();

  const template = templates?.[id] ?? {};

  const editor = useCreateBlockNote(
    setupBlocks({
      initialContent: template?.content
        ? JSON.parse(template?.content ?? '')
        : null,
      schema: schema,
      trailingBlock: false,
    }),
  );

  const getCurrentTheme = () => {
    const savedTheme = localStorage.getItem('theme');
    return savedTheme === 'dark' ? blockInputTheme.dark : blockInputTheme.light;
  };

  const formik = useFormik({
    initialValues: {
      id,
      ...template,
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email('Must be a valid email')
        .max(255)
        .required('Email is required'),
      password: Yup.string().max(255).required('Password is required'),
    }),
    onSubmit: async (values, helpers) => {
      try {
        const {email, password} = values;

        //   if (error) {
        //     helpers.setStatus({success: false});
        //     helpers.setErrors({submit: error});
        //     helpers.setSubmitting(false);
        //   }
      } catch (err) {
        helpers.setStatus({success: false});
        helpers.setErrors({submit: err.message});
        helpers.setSubmitting(false);
      }
    },
  });

  return (
    <div className="template-item">
      <div className="template-header">
        <FormikTextfield
          className="template-name-input"
          formik={formik}
          name="name"
          // header="Template Name"
          placeholder="Template..."
          onBlur={async () => {
            if (template.name !== formik.values.name) {
              await updateTemplate({
                id,
                name: formik.values.name,
                updated: dateToTimestamp(),
              });
            }
          }}
        />
        <RxTrash
          className="delete-template-btn"
          style={{margin: '0px 8px'}}
          onClick={async () => {
            if (
              window.confirm('Are you sure you want to delete this template?')
            ) {
              await deleteTemplate({id});
            }
          }}
        />
      </div>
      <BlockNoteView
        editor={editor}
        slashMenu={false}
        theme={getCurrentTheme()}
        onChange={async () => {
          const stringy = JSON.stringify(editor.document);
          const content_html = await editor.blocksToHTMLLossy();
          const mentions = [];
          editor.forEachBlock(block => {
            const {content} = block;
            if (!Array.isArray(content)) {
              return;
            }
            content.forEach(item => {
              const {type, props} = item;
              if (item.type === 'mention' && !mentions?.includes(props.email))
                mentions.push(props.email);
            });
          });
          formik.setFieldValue('content', stringy);
          formik.setFieldValue('content_html', content_html);
          formik.setFieldValue('mentions', mentions);
        }}
        onBlur={async () => {
          const stringy = JSON.stringify(editor.document);
          const content_html = await editor.blocksToHTMLLossy();
          const mentions = [];

          editor.forEachBlock(block => {
            const {content} = block;
            if (!Array.isArray(content)) return;
            content.forEach(item => {
              const {type, props} = item;
              if (type === 'mention' && !mentions?.includes(props.email))
                mentions.push(props.email);
            });
          });

          await updateTemplate({
            id,
            content: stringy,
            content_html,
            mentions,
            updated: dateToTimestamp(),
          });
        }}>
        <SuggestionMenuController
          triggerCharacter={'/'}
          getItems={async query => {
            const defaultItems = getDefaultReactSlashMenuItems(editor);
            const dividerItem = insertDividerLineItem(editor);
            return filterSuggestionItems([...defaultItems, dividerItem], query);
          }}
          preventBackgroundScroll={true}
        />
      </BlockNoteView>
    </div>
  );
};
