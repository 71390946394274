import React, {useEffect, useState} from 'react';
import {useSearchParams} from 'react-router-dom';
import {track} from '../../api/analytics';
import {PublicMindLogoLightNoText} from '../../components/images/logos';

const Triggers = ({}) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [data, setData] = useState({type: null});

  useEffect(() => {
    // PARSE SIGN IN LINK
    const params = {};
    for (const [key, value] of searchParams.entries()) {
      params[key] = value;
    }
    setData(params);
  }, []);

  useEffect(() => {
    const {type, ...rest} = data || {};
    switch (type) {
      case 'email_campaign_like':
        track(type, rest);
        setData({...data, type: 'complete'});
        return;
      case 'email_campaign_dislike':
        track(type, rest);
        setData({...data, type: 'complete'});
        return;
      case 'complete':
      default:
        return;
    }
  }, [data.type]);

  switch (data.type) {
    case 'complete':
    default:
      return (
        <div className="page-container flex-column justify-center align-center">
          <PublicMindLogoLightNoText />
          <br />
          <p>Thank you for your input. You can close this window now.</p>
        </div>
      );
  }
};

export default Triggers;
