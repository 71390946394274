import React from 'react';
import ReactConfetti from 'react-confetti';

const Confetti = ({active, setActive}) => {
  return active ? (
    <div className="fixed inset-0 z-50 pointer-events-none">
      <ReactConfetti
        width={window.innerWidth}
        height={window.innerHeight}
        recycle={false}
        numberOfPieces={200}
        gravity={0.3}
        onConfettiComplete={() => {
          setActive(false);
        }}
      />
    </div>
  ) : null;
};

export default Confetti;
