import React, {useEffect, useState} from 'react';
import {IoMdArrowBack} from 'react-icons/io';
import {useNavigate, useParams} from 'react-router-dom';
import {v4} from 'uuid';
import {track} from '../../api/analytics';
import {convertToDate, dateToTimestamp, formatMMDDYYYY} from '../../api/dates';
import ActionPopup from '../../components/action-feedback-popup/action-feedback-popup';
import {TagsRadarChart} from '../../components/charts/radar-chart';
import {ProfileImage} from '../../components/inputs/uploader';
import {OrganizationItemPreview} from '../../components/item-details/organization-item';
import {SpinningIndicator} from '../../components/loading/loading-indicator';
import {SubscribeModal} from '../../components/modal/schedule-modal';
import {YourReferer} from '../../components/referral/yourReferer';
import {Tab} from '../../components/tab/tab';
import {
  DisplayField,
  ProfileTagDisplay,
} from '../../components/text/display-fields';
import {useAuth} from '../../hooks/use-auth';
import useCrypto from '../../hooks/use-crypto';
import useLoader from '../../hooks/use-loader';
import useStringFormatter from '../../hooks/use-string-formatter';
import {useThreads} from '../../hooks/use-threads';
import {isValidEmail} from '../../utils/utils';
import {DirectMessages} from '../threads/overview-tabs/direct-messages';
import './styles.css';

const ProfileDetail = ({}) => {
  const {id} = useParams();
  const {loadProfiles, loadOrgs} = useLoader();
  const navigate = useNavigate();
  const {decryptString} = useCrypto();

  const {
    state: {profiles},
  } = useAuth();

  const [loading, setLoading] = useState(false);
  const [parsedId, setParsedID] = useState(null);

  const profile = profiles?.[parsedId] ?? {};
  const {organization_ids, calendar_link} = profile;
  const [index, setIndex] = useState(0);

  const tabs = [
    {tab: 'Requests', index: 0},
    {tab: 'Resources', index: 1},
  ];

  useEffect(() => {
    if (!id) {
      return;
    }

    const load = async () => {
      let valid = null;

      if (isValidEmail(id)) {
        valid = id;
      } else {
        const parsed = decryptString(id);
        if (isValidEmail(parsed)) {
          valid = parsed;
        }
      }
      setParsedID(valid);
      setLoading(true);
      if (valid) {
        await loadProfiles([valid], {authMode: 'apiKey'});
      }

      setLoading(false);
    };

    load();
  }, [id]);

  useEffect(() => {
    loadOrgs(organization_ids, {authMode: 'apiKey'});
  }, [organization_ids]);

  if (!parsedId || !profile?.id) {
    return (
      <div className="page-container">
        {loading ? (
          <SpinningIndicator />
        ) : (
          <p>What you're looking for doesn't exist.</p>
        )}
      </div>
    );
  }

  return (
    <div className="page-container">
      {loading ? (
        <SpinningIndicator />
      ) : (
        <div className="padding-16">
          <IoMdArrowBack
            className="clickable"
            onClick={() => {
              navigate(-1);
            }}
          />
          <Header id={parsedId} />
          <div className="margin-l16 padding-top8">
            <Tab items={tabs} index={index} setIndex={setIndex} />
          </div>
          {index === 0 && (
            <RequestProfileDetail
              id={parsedId}
              profile={profile}
              loading={loading}
            />
          )}
          {index === 1 && (
            <ResourceProfileDetail
              id={parsedId}
              profile={profile}
              loading={loading}
            />
          )}
        </div>
      )}
    </div>
  );
};

export const RequestProfileDetail = ({id, profile, loading}) => {
  const type = 'outer';
  return (
    <div className="">
      {loading ? (
        <SpinningIndicator />
      ) : (
        <>
          <div className="grid-container padding-top8">
            <div className="grid-7">
              <Priorities id={id} type={type} />
            </div>
            <div className="grid-5">
              {/* <ConnectCard id={id} /> */}
              <div className="card">
                <h4>Requests Breakdown</h4>
                <TagsRadarChart
                  tags={profile.outer_tags || []}
                  style={{height: '200px'}}
                />
              </div>
              <BasicInfo id={id} />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export const ResourceProfileDetail = ({
  id,
  profile,
  loading,
  isPublic = false,
}) => {
  const type = 'inner';

  return (
    <div className="">
      {loading ? (
        <SpinningIndicator />
      ) : (
        <>
          <div className="grid-container padding-top8">
            <div className="grid-7">
              <Priorities id={id} type={type} />
              {/* <Posts id={id} type={'resource'} isPublic={isPublic} /> */}
            </div>
            <div className="grid-5">
              {/* <ConnectCard id={id} /> */}
              <div className="card">
                <h4>Resources Breakdown</h4>
                <TagsRadarChart
                  tags={profile.inner_tags || []}
                  style={{height: '200px'}}
                />
              </div>

              <BasicInfo id={id} />
              {/* <Organizations id={id} /> */}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export const ConnectCard = ({id}) => {
  const {
    state: {id: current_id, profiles},
  } = useAuth();

  const profile = profiles?.[id] ?? {};
  const {calendar_link, first_name} = profile;

  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);

  if (id === current_id) {
    return null;
  }

  return (
    <div>
      <div className="card">
        {loading ? (
          <SpinningIndicator />
        ) : (
          <>
            <div>
              <h4>Stay up-to-date on my priorities and projects</h4>
              <div className="flex justify-center">
                <button
                  className="create-opportunity-button"
                  onClick={() => {
                    setShow(!show);
                  }}>
                  Subscribe
                </button>
              </div>
              {current_id && calendar_link ? (
                <div className="flex-row justify-between align-center">
                  <p className="text-left text-bold">
                    User their personal link.
                  </p>
                  <a
                    href={calendar_link}
                    target="_blank"
                    rel="noopener noreferrer">
                    connect
                  </a>
                </div>
              ) : null}
            </div>
          </>
        )}
      </div>
      <SubscribeModal profile={profile} active={show} setActive={setShow} />
    </div>
  );
};
export const Header = ({id, isPublic = false}) => {
  const navigate = useNavigate();
  const {prettyName} = useStringFormatter();
  const {
    state: {id: user_id, profiles, organizations},
  } = useAuth();
  const {
    state: {thread_ids, threads},
    createThread,
  } = useThreads();

  const [dm, setDM] = useState(false);
  const [showDM, setShowDMs] = useState(false);
  const [directID, setDMID] = useState(null);
  const [popup, setPopup] = useState({on: false, message: ''});
  const [completedProjects, setCompletedProjects] = useState(0);

  const profile = profiles?.[id] ?? {};
  const {organization_ids, position, status, signup_timestamp} = profile;

  const active_since = signup_timestamp
    ? formatMMDDYYYY(convertToDate(signup_timestamp * 1000))
    : null;

  const current_org = organization_ids?.length
    ? organizations?.[organization_ids[0]]
    : null;

  const pretty_name = prettyName(profile);

  // Find existing DM thread between current user and profile
  useEffect(() => {
    let completed_projects = 0;
    let existingDM = null;
    thread_ids.forEach(thread_id => {
      const thread = threads[thread_id];
      if (
        thread?.type === 'direct' &&
        thread?.members?.includes(user_id) &&
        thread?.members?.includes(id) &&
        thread?.members?.length === 2
      ) {
        existingDM = thread_id;
      }

      if (thread.status === 'complete' && thread.members.includes(id)) {
        completed_projects += 1;
      }
    });

    setCompletedProjects(completed_projects);

    if (existingDM) {
      setDM(true);
      setDMID(existingDM);
    } else {
      setDM(false);
      setDMID(null);
    }
  }, [thread_ids, threads, user_id, id]);

  const createDM = async () => {
    const now = dateToTimestamp();

    const permissions = [
      {user_id, role: 'admin', created: now, updated: now},
      {user_id: id, role: 'admin', created: now, updated: now},
    ];
    const members = [user_id, id];

    const thread = {
      id: v4(),
      owner_id: user_id, // Changed to current user as owner
      status: 'active',
      type: 'direct',
      security_level: 'secure',
      permissions,
      members,
      title: permissions
        .map(permission =>
          prettyName(
            profiles?.[permission.user_id] ?? {
              id: permission.user_id,
              email: permission.user_id,
            },
          ),
        )
        .join(', '),
      media: [],
      tags: [],
      content: '',
      logo: '',
      parent: null,
      expiration: null,
      ai_assisted: false,
      created: now,
      updated: now,
    };

    const {success, error} = await createThread(thread);
    if (success) {
      setDMID(thread.id); // Set the new thread ID
      setDM(true);
      setShowDMs(true);
      track('dm_thread_created', {thread, user_id: id});
    }
  };

  const handleOpenDM = () => {
    if (directID) {
      setShowDMs(true);
    }
  };

  return (
    <div className="flex-row align-center justify-between margin-l16">
      <div className="flex-column">
        <div className="flex-row align-center">
          <ProfileImage data={profile.profile_image} />
          <div>
            {active_since && (
              <p className="text-left text-secondary margin-h16 text-14">
                Active since {active_since}
              </p>
            )}
            <div className="flex-row align-center margin-h16 ">
              <h2>{pretty_name}</h2>
            </div>
            <div className="flex-row align-center">
              {current_org && (
                <p
                  className={`text-left text-secondary margin-l16 text-14 ${
                    !isPublic && ' clickable'
                  }`}
                  onClick={() => {
                    navigate(`/feed/organizations/detail/${current_org.id}`);
                  }}>
                  {current_org.name}
                </p>
              )}
              {position && (
                <p className="text-left text-secondary margin-l16 text-14">
                  {position}
                </p>
              )}
            </div>
            <p className="text-left text-secondary margin-l16 text-14">
              {completedProjects} projects completed together
            </p>
            <YourReferer cur_id={id} />
            {!dm && (
              <div className="margin-l8">
                <button className="button-container" onClick={createDM}>
                  Message
                </button>
              </div>
            )}
            {dm && directID && (
              <div className="margin-l8">
                <button className="button-container" onClick={handleOpenDM}>
                  Open Messages
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
      <ActionPopup message={popup.message} setOff={setPopup} on={popup.on} />
      {directID && (
        <DirectMessages
          active={showDM}
          setActive={setShowDMs}
          directID={directID}
        />
      )}
    </div>
  );
};

export const BasicInfo = ({id}) => {
  const navigate = useNavigate();
  const {
    state: {id: user_id, profiles, organizations},
  } = useAuth();

  const profile = profiles?.[id] ?? {};
  const {email, bio} = profile;
  if (!bio) {
    return null;
  }

  return (
    <div className="card">
      <h4>About</h4>
      <DisplayField header="Bio" content={bio} />
      {/* <DisplayField header="Contact" content={user_id ? email : null} /> */}
    </div>
  );
};

export const Priorities = ({id, type}) => {
  const {
    state: {profiles, organizations},
  } = useAuth();

  const profile = profiles?.[id] ?? {};
  const {email, bio, inner_tags, outer_tags} = profile;

  return (
    <div>
      <ProfileTagDisplay
        inner_tags={inner_tags}
        outer_tags={outer_tags}
        type={type}
      />
    </div>
  );
};

export const Organizations = ({id}) => {
  const {loadOrgs} = useLoader();
  const {
    state: {id: user, profiles, organizations},
  } = useAuth();

  const {organization_ids} = profiles?.[id] ?? {};

  useEffect(() => {
    loadOrgs(organization_ids, {authMode: 'apiKey'});
  }, [organization_ids]);

  return (
    <div className="card">
      <h4>Organizations</h4>
      {organization_ids?.length
        ? organization_ids.map(id => {
            return (
              <OrganizationItemPreview
                key={id}
                disabled={!user}
                item={organizations[id] || {}}
              />
            );
          })
        : null}
    </div>
  );
};

export default ProfileDetail;
