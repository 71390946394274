import constants from '../../components/constants';

const generatePlatformInvite = props => {
  const {link = null, profile, note} = props || {};
  const {email, first_name, last_name} = profile || {};
  const pretty_name =
    first_name || last_name
      ? `${first_name || ''} ${last_name || ''}`.trim()
      : email;

  return `
    <!DOCTYPE html>
    <html lang="en">
    <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <link href="https://fonts.googleapis.com/css2?family=Inter:wght@400;600&display=swap" rel="stylesheet">
        <style>
            body { font-family: 'Inter', sans-serif; color: #333; line-height: 1.6; margin: 0; padding: 0; background-color: #f7f7f7; }
            .container { max-width: 600px; margin: 0 auto; padding: 20px; background-color: #ffffff; }
            .logo { text-align: center; margin-bottom: 20px; }
            .content { background-color: #f4f4f4; padding: 24px; border-radius: 8px; }
            .button { display: inline-block; background-color: #4743c5; color: white; padding: 12px 24px; text-decoration: none; border-radius: 4px; font-weight: 600; }
            .secondary-button { display: inline-block; background-color: #ffffff; color: #333; padding: 10px 20px; text-decoration: none; border-radius: 4px; font-weight: 600; border: 1px solid #333; }
            .footer { text-align: center; margin-top: 20px; color: #666; font-size: 14px; }
            .footer a { margin: 0 8px; color: #666; text-decoration: none; }
            .note { white-space: pre-line; border: 1px solid #ccc; padding: 16px; border-radius: 8px; margin-bottom: 20px; }
            .value-prop { background-color: #ffffff; padding: 16px; margin: 16px 0; border-radius: 4px; border-left: 4px solid #4743c5; }
        </style>
    </head>
    <body>
        <div class="container">
            <div class="logo">
                <img src="${
                  constants.publicmind_logo
                }" alt="PublicMind Logo" height="48" width="48" />
                <h2 style="font-weight: 600; margin-top: 12px;">PUBLICMIND</h2>
            </div>
            <div class="content">
                <h3 style="text-align: center; margin-bottom: 24px;">Welcome to Your Official Communication Channel</h3>
                ${note ? `<div class="note">${note}</div>` : ''}
                
                <p>${pretty_name} has invited you to join PublicMind, the secure central platform where government, academia, and industry collaborate on official projects.</p>

                <div class="value-prop">
                    <h4 style="margin-top: 0;">Why PublicMind is Different:</h4>
                    <ul style="margin-bottom: 0;">
                        <li>Project-centric collaboration across organizations</li>
                        <li>Works seamlessly regardless of your organization's internal systems</li>
                        <li>Preserves project history and decisions indefinitely</li>
                        <li>Enables discovery of relevant projects and partnerships</li>
                    </ul>
                </div>

                <p style="font-weight: 600;">Ready to start collaborating?</p>
                <div style="text-align: center; margin: 24px 0;">
                    <a href="${link}" class="button">Join Your Project Space</a>
                </div>

                <p>Want to learn more about how PublicMind can enhance your cross-organizational projects?</p>
                <div style="text-align: center; margin: 24px 0;">
                    <a href="https://calendly.com/jack-publicmind" class="secondary-button">Schedule a Quick Demo</a>
                </div>

                <p>Questions? Reply to this email or reach out to help@publicmind.ai</p>
            </div>
            <div class="footer">
                <p>PublicMind - Secure Collaboration for Official Projects</p>
                <div>
                    <a href="${constants.copyright_policy}">Copyright Policy</a>
                    <a href="${constants.privacy_policy}">Privacy Policy</a>
                    <a href="${constants.terms_of_service}">Terms of Service</a>
                </div>
            </div>
        </div>
    </body>
    </html>
  `;
};

export default generatePlatformInvite;
