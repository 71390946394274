import {useCreateBlockNote} from '@blocknote/react';
import {useFormik} from 'formik';
import React, {useCallback, useEffect, useRef, useState} from 'react';
import {RxCross2, RxEnterFullScreen, RxExitFullScreen} from 'react-icons/rx';
import {v4} from 'uuid';
import * as Yup from 'yup';
import {dateToTimestamp} from '../../../api/dates';
import {sendPriorityThreadContent} from '../../../api/email';
import ActionPopup from '../../../components/action-feedback-popup/action-feedback-popup';
import {setupBlocks} from '../../../components/blocks/divider';
import {schema} from '../../../components/inputs/block-input';
import {SimpleDisplayModal} from '../../../components/modal/modal';
import {useAuth} from '../../../hooks/use-auth';
import {useThreads} from '../../../hooks/use-threads';
import useUtilities from '../../../hooks/use-utilities';
import {ContentInputField} from './content-input-field';
import {ReplyActionButtons} from './create-content-action-buttons';
import './ReplyToComment.css';

// HIGHEST LEVEL COMPONENT FOR REPLY - INCLUDES INPUT AND BUTTONS
export const ReplyToComment = ({id, parent, onClose, isOpen, quoteText}) => {
  const {
    state: {id: user_id, profile},
  } = useAuth();
  const {
    state: {threads, thread_subs, thread_content},
    createThreadContent,
    updateThreadSubscription,
    createThreadSubscription,
  } = useThreads();

  const inputRef = useRef(null);
  const [isExpanded, setIsExpanded] = useState(false);
  const {copyString} = useUtilities();
  const [isFullScreen, setIsFullScreen] = useState(false); // New state for full-screen mode
  const [showFileUploader, setShowFileUploader] = useState(false);
  const [files, setFiles] = useState([]);

  const editor = useCreateBlockNote(
    setupBlocks(
      {
        schema: schema,
        trailingBlock: false,
      },
      threads,
      thread_content,
    ),
  );

  const formik = useFormik({
    initialValues: {
      owner_id: user_id,
      type: 'comment',
      status: 'active',
      priority: false,
      content: '',
      content_html: '',
      version: 'block',
      mentions: [],
      media: [],
      scheduled: null,
      reminder: false,
      thread_id: id,
    },
    validationSchema: Yup.object({
      content: Yup.string().required('Content is required'),
    }),
    onSubmit: async (values, helpers) => {
      try {
        const {status, scheduled, reminder, mentions} = values;

        const content = {
          id: v4(),
          ...values,
          parent,
          thread_id: id,
        };

        if (files?.length) {
          content.media = files;
        }

        if (reminder) {
          content.reminder = scheduled - 3600;
          content.created = scheduled;
          content.updated = scheduled;
        } else {
          const now = dateToTimestamp();
          content.reminder = null;
          content.created = now;
          content.updated = now;
        }

        const {success, error} = await createThreadContent(content);

        if (success) {
          // IF SCHEDULED SEND TO SCHEDULER
          // if (status === 'scheduled' && !!scheduled) {
          //   const response = await scheduleContent(content);
          // }
          helpers.resetForm();
          setFiles([]);
          onClose();

          if (mentions?.length) {
            await sendPriorityThreadContent({
              emails: mentions,
              content,
              profile,
              thread: threads?.[id],
            });
          }
          // UPDATE THE SUB WITH LAST SEEN
          const sub = thread_subs?.[id] ?? null;
          if (sub) {
            const update = {
              id: sub.id,
              thread_id: id,
              last_read: dateToTimestamp(),
            };
            updateThreadSubscription(update);
          } else {
            createThreadSubscription({
              id: v4(),
              user_id,
              thread_id: id,
              status: null,
              last_read: dateToTimestamp(),
            });
          }
        }
        if (error) {
          // TODO: Handle error
        }
      } catch (err) {
        helpers.setStatus({success: false});
        helpers.setErrors({submit: err.message});
        helpers.setSubmitting(false);
      }
    },
  });

  useEffect(() => {
    const focusEditor = setTimeout(() => {
      if (isOpen && editor && editor.focus) {
        setIsExpanded(true);
        editor.focus();
        if (quoteText) {
          const emailRegex = /@\[[^\]]+\]\(\/feed\/profiles\/detail\/([^)]+)\)/;
          const match = quoteText.match(emailRegex);
          if (match) {
            const email = match[1];
            formik.setFieldValue('mentions', [
              ...formik.values.mentions,
              email,
            ]);
          }
          formik.setFieldValue('content', `${quoteText}\n`);
        }
      }
    }, 100); // Small delay to ensure editor is ready

    return () => clearTimeout(focusEditor);
  }, [isOpen, quoteText, editor, isFullScreen]);

  const toggleFullScreen = () => {
    setIsFullScreen(!isFullScreen);
  };
  const handleKeyDown = useCallback(
    event => {
      if ((event.metaKey || event.ctrlKey) && event.key === 'Enter') {
        event.preventDefault();
        formik.handleSubmit();
      }
    },
    [formik],
  );
  const extractNameFromMention = mentionText => {
    const match = mentionText.match(/@\[(.*?)\]/);
    return match ? match[1] : 'Thread';
  };
  const replyContent = (
    <div
      className={`${
        isFullScreen ? 'modal-reply-thread' : 'content-reply-popup'
      } ${isExpanded ? 'expanded' : ''}`}>
      <div className="popup-header">
        <h3>{quoteText ? extractNameFromMention(quoteText) : 'Comment'}</h3>
        <div className="flex-row">
          <button className="expand-button" onClick={toggleFullScreen}>
            {isFullScreen ? <RxExitFullScreen /> : <RxEnterFullScreen />}
          </button>
          {!isFullScreen && (
            <button className="close-button" onClick={onClose}>
              <RxCross2 />
            </button>
          )}
        </div>
      </div>
      <div className="popup-content">
        <ContentInputField
          thread_id={id}
          formik={formik}
          editor={editor}
          inputRef={inputRef}
          isExpanded={isExpanded}
          setIsExpanded={setIsExpanded}
          quoteText={quoteText}
          showFileUploader={showFileUploader}
          files={files}
          setFiles={setFiles}
          isReply={true}
          isFullScreen={isFullScreen}
          onKeyDown={handleKeyDown} // Add this prop
        />
      </div>
      <ReplyActionButtons
        formik={formik}
        editor={editor}
        handleSendMessage={formik.handleSubmit}
        inputMessage={formik.values.content}
        setInputMessage={input => {
          formik.setFieldValue('content', input.trim());
        }}
        copyString={copyString}
        setShowFileUploader={setShowFileUploader}
      />
    </div>
  );

  return (
    <>
      {!isFullScreen && replyContent}
      <SimpleDisplayModal active={isFullScreen} setActive={setIsFullScreen}>
        {replyContent}
      </SimpleDisplayModal>
    </>
  );
};

// HIGHEST LEVEL COMPONENT FOR REPLY - INCLUDES INPUT AND BUTTONS
// USED IN FULLSCREEN MODE ONLY
export const CreateReply = ({thread_id, parent}) => {
  const createComment = useRef(null);

  const inputRef = useRef(null);
  const {copyString} = useUtilities();

  const {
    state: {id: user_id, profile},
  } = useAuth();

  const [isExpanded, setIsExpanded] = useState(false);
  const [showFileUploader, setShowFileUploader] = useState(false);
  const [files, setFiles] = useState([]);
  const {
    state: {threads, thread_subs, thread_content},
    createThreadContent,
    updateThreadSubscription,
    createThreadSubscription,
  } = useThreads();

  const editor = useCreateBlockNote(
    setupBlocks(
      {
        schema: schema,
        trailingBlock: false,
      },
      threads,
      thread_content,
    ),
  );
  const handleEditorCleanup = () => {
    try {
      const blocks = editor.document;
      // Then remove them all at once
      if (blocks.length > 0) {
        editor.removeBlocks(blocks);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const formik = useFormik({
    initialValues: {
      owner_id: user_id,
      type: 'comment',
      status: 'active',
      priority: false,
      content: '',
      content_html: '',
      version: 'block',
      mentions: [],
      media: [],
      scheduled: null,
      reminder: false,
      thread_id: thread_id,
    },

    validationSchema: Yup.object()
      .shape({
        content: Yup.string(),
        media: Yup.array(),
      })
      .test(
        'content-or-media-required',
        'Either content or at least one media item is required',
        function (values) {
          return (
            (values.content && values.content.trim().length > 0) ||
            (values.media && values.media.length > 0)
          );
        },
      ),
    onSubmit: async (values, helpers) => {
      try {
        const {type, scheduled, reminder, mentions} = values;

        const content = {
          id: v4(),
          ...values,
          thread_id,
          parent,
        };

        if (files?.length) {
          content.media = files;
        }

        if (reminder) {
          content.reminder = scheduled - 3600;
          content.created = scheduled;
          content.updated = scheduled;
        } else {
          const now = dateToTimestamp();
          content.reminder = null;
          content.created = now;
          content.updated = now;
        }

        const {success, error} = await createThreadContent(content);

        if (success) {
          // IF SCHEDULED SEND TO SCHEDULER
          // if (status === 'scheduled' && !!scheduled) {
          //   const response = await scheduleContent(content);
          // }
          helpers.resetForm();
          setFiles([]);
          setIsExpanded(false);
          setShowFileUploader(false);
          handleEditorCleanup();

          if (mentions?.length) {
            await sendPriorityThreadContent({
              emails: mentions,
              content,
              profile,
              thread: threads?.[thread_id],
            });
          }
          // UPDATE THE SUB WITH LAST SEEN
          const sub = thread_subs?.[thread_id] ?? null;
          if (sub) {
            const update = {
              id: sub.id,
              thread_id: thread_id,
              last_read: dateToTimestamp(),
            };
            updateThreadSubscription(update);
          } else {
            createThreadSubscription({
              id: v4(),
              user_id,
              thread_id: thread_id,
              status: null,
              last_read: dateToTimestamp(),
            });
          }
        }
        if (error) {
          // Handle error
        }
      } catch (err) {
        helpers.setStatus({success: false});
        helpers.setErrors({submit: err.message});
        helpers.setSubmitting(false);
      }
    },
  });

  useEffect(() => {
    const handleClickOutside = event => {
      if (
        createComment.current &&
        !createComment.current.contains(event.target)
      ) {
        setIsExpanded(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleKeyDown = useCallback(
    event => {
      if ((event.metaKey || event.ctrlKey) && event.key === 'Enter') {
        event.preventDefault();
        formik.handleSubmit();
      }
    },
    [formik],
  );
  useEffect(() => {
    const focusEditor = setTimeout(() => {
      if (editor && editor.focus) {
        editor.focus();
      }
    }, 100); // Small delay to ensure editor is ready

    return () => clearTimeout(focusEditor);
  }, [editor]); // Run when editor changes
  return (
    <div ref={createComment} className="thread-reply-container">
      <ContentInputField
        thread_id={thread_id}
        formik={formik}
        editor={editor}
        inputRef={inputRef}
        isExpanded={isExpanded}
        setIsExpanded={setIsExpanded}
        showFileUploader={showFileUploader}
        files={files}
        setFiles={setFiles}
        isReply={true}
        onKeyDown={handleKeyDown} // Add this prop
      />
      <ReplyActionButtons
        formik={formik}
        editor={editor}
        files={files}
        handleSendMessage={formik.handleSubmit}
        inputMessage={formik.values.content}
        setInputMessage={input => {
          formik.setFieldValue('content', input.trim());
        }}
        copyString={copyString}
        setShowFileUploader={setShowFileUploader}
        isReply={true}
      />
    </div>
  );
};

export const EditCommentReply = ({comment, onClose, isOpen}) => {
  const {
    state: {threads, thread_content},
    updateContent,
  } = useThreads();

  const inputRef = useRef(null);
  const [isExpanded, setIsExpanded] = useState(false);
  const {copyString} = useUtilities();
  const [isFullScreen, setIsFullScreen] = useState(false); // New state for full-screen mode
  const [showFileUploader, setShowFileUploader] = useState(false);
  const [files, setFiles] = useState(comment.media ?? []);

  const [popup, setPopup] = useState({on: false, message: ''});

  const editor = useCreateBlockNote(
    setupBlocks(
      {
        initialContent: JSON.parse(comment?.content ?? ''),

        schema: schema,
        trailingBlock: false,
      },
      threads,
      thread_content,
    ),
  );
  const formik = useFormik({
    initialValues: {
      content: comment.content ?? '',
      content_html: comment?.content_html ?? '',
      media: comment.media ?? [],
      priority: comment.priority ?? false,
      // mentions: comment.mentions,
    },
    validationSchema: Yup.object()
      .shape({
        content: Yup.string(),
        media: Yup.array(),
      })
      .test(
        'content-or-media-required',
        'Either content or at least one media item is required',
        function (values) {
          return (
            (values.content && values.content.trim().length > 0) ||
            (values.media && values.media.length > 0)
          );
        },
      ),

    onSubmit: async (values, helpers) => {
      try {
        const {id} = comment;
        const update = {
          id,
          ...values,
          updated: dateToTimestamp(),
        };

        update.media = files;

        const {success, error} = await updateContent(update);

        if (success) {
          helpers.resetForm();
          setFiles([]);
          setIsExpanded(false);
          onClose();

          setPopup({
            on: true,
            message: 'Success!',
          });
        }
        if (error) {
          setPopup({
            on: true,
            message: error,
          });
        }
      } catch (err) {
        console.log(err);
        helpers.setStatus({success: false});
        helpers.setErrors({submit: err.message});
        helpers.setSubmitting(false);
      }
    },
  });

  useEffect(() => {
    const focusEditor = setTimeout(() => {
      if (isOpen && editor && editor.focus) {
        setIsExpanded(true);
        editor.focus();
      }
    }, 100); // Small delay to ensure editor is ready

    return () => clearTimeout(focusEditor);
  }, [isOpen, editor]);

  const toggleFullScreen = () => {
    setIsFullScreen(!isFullScreen);
  };
  const handleKeyDown = useCallback(
    event => {
      if ((event.metaKey || event.ctrlKey) && event.key === 'Enter') {
        event.preventDefault();
        formik.handleSubmit();
      }
    },
    [formik],
  );
  const replyContent = (
    <div
      className={`${
        isFullScreen ? 'modal-reply-thread' : 'content-reply-popup'
      } ${isExpanded ? 'expanded' : ''}`}>
      <div className="popup-header">
        <h3>Edit</h3>
        <div className="flex-row">
          <button
            className="expand-button"
            onClick={toggleFullScreen}
            title="Expand reply box">
            {isFullScreen ? <RxExitFullScreen /> : <RxEnterFullScreen />}
          </button>
          {!isFullScreen && (
            <button
              className="close-button"
              onClick={onClose}
              title="Close reply box">
              <RxCross2 />
            </button>
          )}
        </div>
      </div>
      <div className="popup-content">
        <ContentInputField
          thread_id={comment.thread_id}
          formik={formik}
          editor={editor}
          inputRef={inputRef}
          isExpanded={isExpanded}
          setIsExpanded={setIsExpanded}
          quoteText={''}
          showFileUploader={showFileUploader}
          files={files}
          setFiles={setFiles}
          isReply={true}
          isFullScreen={isFullScreen}
          onKeyDown={handleKeyDown} // Add this prop
        />
      </div>
      <ReplyActionButtons
        formik={formik}
        editor={editor}
        handleSendMessage={formik.handleSubmit}
        inputMessage={formik.values.content}
        setInputMessage={input => {
          formik.setFieldValue('content', input.trim());
        }}
        copyString={copyString}
        setShowFileUploader={setShowFileUploader}
        isEdit={true}
        files={files}
      />
    </div>
  );

  return (
    <>
      {!isFullScreen && replyContent}
      <SimpleDisplayModal active={isFullScreen} setActive={setIsFullScreen}>
        {replyContent}
      </SimpleDisplayModal>
      <ActionPopup message={popup.message} setOff={setPopup} on={popup.on} />
    </>
  );
};
