/* eslint-disable import/no-anonymous-default-export */
import {useNavigate} from 'react-router-dom';
import {reset, track} from '../api/analytics';
import {useAuth} from './use-auth';
import {useThreads} from './use-threads';

export default () => {
  const {
    state: {id},
    signOut,
  } = useAuth();
  const {resetThreads} = useThreads();

  const navigate = useNavigate();

  const logout = async () => {
    const {success, error} = await signOut();
    // RESET CONTEXTS
    if (success) {
      resetThreads();
      navigate('/signin');
      reset();
      track('sign_out', {email: id});
    }
    if (error) {
      console.log('FAIL SIGNING OUT');
    }
  };

  return logout;
};
