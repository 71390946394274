import {debounce} from 'lodash';
import Papa from 'papaparse';
import React, {useCallback, useEffect, useRef, useState} from 'react';
import {useDropzone} from 'react-dropzone';
import {IoMdClose} from 'react-icons/io';
import {dateToTimestamp} from '../../api/dates';
import {fetchProfiles} from '../../api/graphql';
import useStringFormatter from '../../hooks/use-string-formatter';
import {isValidEmailList} from '../../utils/utils';
import {SpinningIndicator} from '../loading/loading-indicator';

export const EmailInput = props => {
  const {prettyName} = useStringFormatter();
  const {
    formik,
    name,
    header,
    onEnter,
    type = 'text',
    autoComplete,
    ...rest
  } = props;

  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const inputRef = useRef(null);

  let blurTimeout = null;

  const handleBlur = () => {
    blurTimeout = setTimeout(() => {
      setShow(false);
    }, 150);
    const trimmed = formik?.values?.[name]?.trim();
    if (trimmed) {
      formik.setFieldValue(name, trimmed);
    }
  };

  const handleClick = v => {
    if (blurTimeout) {
      clearTimeout(blurTimeout);
    }
    formik.setFieldValue(name, v);
    setShow(false);
  };

  const value = formik?.values?.[name];

  const debouncedFetch = useCallback(
    debounce(async searchValue => {
      setLoading(true);
      try {
        const {items, nextToken} = await fetchProfiles({
          filter: {email: {contains: searchValue}},
          limit: 2000,
        });
        let all_items = [...items];
        let token = nextToken;
        let i = 0;
        while (all_items.length < 8 && token && i < 100) {
          const {items, nextToken} = await fetchProfiles({
            filter: {email: {contains: searchValue}},
            limit: 2000,
            nextToken: token,
          });
          all_items = [...all_items, ...items];
          token = nextToken;
          i++;
        }
        setResults(all_items);
      } catch (error) {
        console.error('Error fetching profiles:', error);
      } finally {
        setLoading(false);
      }
    }, 300),
    [],
  );

  useEffect(() => {
    if (value?.length > 4) {
      debouncedFetch(value);
    } else {
      setResults([]);
    }
  }, [value, debouncedFetch]);

  const handleKeyDown = e => {
    if (e.key === 'ArrowDown') {
      e.preventDefault();
      setSelectedIndex(prevIndex =>
        prevIndex < results.length - 1 ? prevIndex + 1 : prevIndex,
      );
    } else if (e.key === 'ArrowUp') {
      e.preventDefault();
      setSelectedIndex(prevIndex => (prevIndex > 0 ? prevIndex - 1 : -1));
    } else if (e.key === 'Enter' && selectedIndex !== -1) {
      e.preventDefault();
      handleClick(results[selectedIndex]?.email);
    }
  };

  return (
    <div className="dropdown">
      {header && <label htmlFor={name}>{header}</label>}
      <input
        {...rest}
        ref={inputRef}
        id={name}
        type={type}
        {...formik.getFieldProps(name)}
        onFocus={() => setShow(true)}
        autoComplete={autoComplete}
        onBlur={handleBlur}
        onKeyDown={handleKeyDown}
        aria-autocomplete="list"
        aria-controls="email-results"
        aria-expanded={show}
        maxLength={100}
      />
      <div
        id="email-results"
        className={`dropdown-content ${show ? 'show' : ''}`}
        role="listbox">
        {loading && <SpinningIndicator />}
        {results.length === 0 && !loading && (
          <p className="text-secondary text-12 margin-8">No results found</p>
        )}
        {results.map((item, index) => {
          const {email} = item;
          const name = prettyName(item);
          return (
            <div
              key={email}
              onClick={() => handleClick(email)}
              onMouseEnter={() => setSelectedIndex(index)}
              className={index === selectedIndex ? 'selected' : ''}
              role="option"
              aria-selected={index === selectedIndex}>
              <p>{email}</p>
              {name && <p className="text-secondary text-12">{name}</p>}
            </div>
          );
        })}
      </div>
      {formik.touched[name] && formik.errors[name] && (
        <p className="input-error" id={`${name}-error`}>
          {formik.errors[name]}
        </p>
      )}
    </div>
  );
};

export const PermissionsInput = props => {
  const {formik, name, header, ...rest} = props;
  const [permission, setPermission] = useState({user_id: '', role: null});
  const [error, setError] = useState(null);
  const {user_id, role} = permission;

  useEffect(() => {
    if (user_id && role) {
      handleAddPermission();
    }
  }, [user_id, role]);

  const handleAddPermission = () => {
    const {valid, results} = isValidEmailList(user_id);
    if (!valid || !role) return;

    const current = [...formik?.values?.[name]];
    const now = dateToTimestamp();

    const newPermissions = results
      .map(item => ({
        user_id: item.email,
        role,
        created: now,
        updated: now,
      }))
      .filter(
        newPerm =>
          !current.some(
            existingPerm => existingPerm.user_id === newPerm.user_id,
          ),
      );

    formik.setFieldValue(name, [...newPermissions, ...current]);
    setPermission({user_id: '', role: null});
  };

  return (
    <div>
      <div className="grid-container">
        <div className="grid-6 dropdown">
          <PermissionEmailInput
            permission={permission}
            setPermission={setPermission}
          />
        </div>
        <div className="grid-5 dropdown">
          <div className="margin-h8">
            <PermissionRoleInput
              permission={permission}
              setPermission={setPermission}
            />
          </div>
        </div>
      </div>
      {error && <p className="input-error">{error}</p>}
    </div>
  );
};

export const PermissionRoleInput = ({permission, setPermission}) => {
  const roles = [
    {key: 'admin', value: 'Admin'},
    {key: 'collaborator', value: 'Collaborator'},
    {key: 'subscriber', value: 'Subscriber'},
  ];
  const [show, setShow] = useState(false);
  const dropdownRef = useRef(null);

  const handleSelect = (key, value) => {
    setPermission({...permission, role: key});
    setShow(false);
  };

  useEffect(() => {
    const handleClickOutside = event => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShow(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div ref={dropdownRef} className="dropdown">
      <label>Role</label>
      <div onClick={() => setShow(!show)}>
        <input
          type="text"
          value={
            permission.role
              ? roles.find(r => r.key === permission.role)?.value
              : ''
          }
          readOnly
          placeholder="Select a role"
        />
      </div>
      {show && (
        <div className="dropdown-content show">
          {roles.map(({key, value}) => (
            <div key={key} onClick={() => handleSelect(key, value)}>
              <p>{value}</p>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

const PermissionEmailInput = ({permission, setPermission, setError}) => {
  const {prettyName} = useStringFormatter();
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const inputRef = useRef(null);
  let blurTimeout = null;

  const handleBlur = () => {
    blurTimeout = setTimeout(() => {
      setShow(false);
    }, 150);
    const trimmed = permission?.user_id?.trim();
    if (trimmed) {
      setPermission({...permission, user_id: trimmed});
    }
  };

  const handleClick = v => {
    if (blurTimeout) {
      clearTimeout(blurTimeout);
    }
    setPermission({...permission, user_id: v});
    setShow(false);
  };

  const debouncedFetch = useCallback(
    debounce(async searchValue => {
      setLoading(true);
      try {
        const {items, nextToken} = await fetchProfiles({
          filter: {email: {contains: searchValue}},
          limit: 2000,
        });
        let all_items = [...items];
        let token = nextToken;
        let i = 0;
        while (all_items.length < 8 && token && i < 100) {
          const {items, nextToken} = await fetchProfiles({
            filter: {email: {contains: searchValue}},
            limit: 2000,
            nextToken: token,
          });
          all_items = [...all_items, ...items];
          token = nextToken;
          i++;
        }
        setResults(all_items);
      } catch (error) {
        console.error('Error fetching profiles:', error);
      } finally {
        setLoading(false);
      }
    }, 300),
    [],
  );

  const value = permission?.user_id;
  useEffect(() => {
    if (value?.length > 2) {
      debouncedFetch(value);
    } else {
      setResults([]);
    }
  }, [value, debouncedFetch]);

  const handleKeyDown = e => {
    if (e.key === 'ArrowDown') {
      e.preventDefault();
      setSelectedIndex(prevIndex =>
        prevIndex < results.length - 1 ? prevIndex + 1 : prevIndex,
      );
    } else if (e.key === 'ArrowUp') {
      e.preventDefault();
      setSelectedIndex(prevIndex => (prevIndex > 0 ? prevIndex - 1 : -1));
    } else if (e.key === 'Enter' && selectedIndex !== -1) {
      e.preventDefault();
      handleClick(results[selectedIndex].user_id);
    }
  };

  return (
    <div className="dropdown">
      <label>Email</label>
      <input
        ref={inputRef}
        placeholder="Add comma-separated emails"
        type="text"
        value={permission.user_id}
        onChange={e => {
          setPermission({...permission, user_id: e.target.value});
        }}
        onFocus={() => setShow(true)}
        onBlur={handleBlur}
        onKeyDown={handleKeyDown}
        maxLength={100}
      />
      <div className={`dropdown-content ${show ? 'show' : ''}`}>
        {loading && <SpinningIndicator />}
        {
          results.length === 0 && !loading && null
          // <p className="text-secondary text-12 margin-8">No results found</p>
        }
        {results.map((item, index) => {
          const {email} = item;
          const name = prettyName(item);
          return (
            <div
              key={email}
              onClick={() => handleClick(email)}
              onMouseEnter={() => setSelectedIndex(index)}
              className={index === selectedIndex ? 'selected' : ''}
              role="option"
              aria-selected={index === selectedIndex}>
              <p>{email}</p>
              {name && <p className="text-secondary text-12">{name}</p>}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export const BulkEmailUploader = ({formik}) => {
  const [bulkEmails, setBulkEmails] = useState('');
  const [csvEmails, setCsvEmails] = useState([]);
  const [csvFileName, setCsvFileName] = useState(null);
  const [fileError, setFileError] = useState(null);

  const MAX_EMAILS = 1000;

  const onDrop = useCallback((acceptedFiles, rejectedFiles) => {
    if (rejectedFiles && rejectedFiles.length > 0) {
      setFileError('Please upload a valid CSV file.');
      return;
    }

    setFileError(null);
    const file = acceptedFiles[0];
    setCsvFileName(file.name);

    Papa.parse(file, {
      complete: results => {
        const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
        const allItems = results.data.flat().map(item => item.trim());
        const validEmails = allItems.filter(item => emailRegex.test(item));

        if (validEmails.length === 0) {
          setFileError('No valid email addresses found in the CSV file.');
          setCsvFileName(null);
        } else if (validEmails.length > MAX_EMAILS) {
          setFileError(
            `Too many email addresses (${validEmails.length}). Please limit to ${MAX_EMAILS}.`,
          );
          setCsvFileName(null);
        } else {
          setCsvEmails(validEmails);
          setFileError(null);
          if (validEmails.length < allItems.length) {
            console.warn(
              `Parsed ${allItems.length} items, found ${validEmails.length} valid emails.`,
            );
          }
        }
      },
      error: error => {
        console.error('Error parsing CSV:', error);
        setFileError('Error parsing CSV file. Please check the file format.');
        setCsvFileName(null);
      },
    });
  }, []);

  const deleteCsv = () => {
    setCsvFileName(null);
    setCsvEmails([]);
  };

  const addBulkEmails = () => {
    const emailList = bulkEmails
      .split(',')
      .map(email => email.trim())
      .filter(email => email !== '');

    const existingPermissions = formik.values.permissions;
    const existingEmails = new Set(existingPermissions.map(p => p.user_id));

    const newEmails = [...new Set([...emailList, ...csvEmails])].filter(
      email => !existingEmails.has(email),
    );

    const newPermissions = newEmails.map(email => ({
      user_id: email,
      role: 'collaborator',
      created: dateToTimestamp(),
      updated: dateToTimestamp(),
    }));

    const updatedPermissions = [...existingPermissions, ...newPermissions];

    formik.setFieldValue('permissions', updatedPermissions);
    setBulkEmails('');
    setCsvEmails([]);
  };

  const {getRootProps, getInputProps, isDragActive} = useDropzone({
    onDrop,
    accept: {
      'text/csv': ['.csv'],
      'application/vnd.ms-excel': ['.csv'],
    },
    multiple: false,
  });

  return (
    <div className="bulk-invite-section">
      <div
        {...getRootProps()}
        className={`dropzone ${isDragActive ? 'active' : ''}`}>
        <input {...getInputProps()} />
        {isDragActive ? (
          <p>Drop the CSV file here ...</p>
        ) : csvFileName ? (
          <div className="csv-feedback">
            <div className="csv-file-info">
              <p>File uploaded: {csvFileName}</p>
              <p>{csvEmails.length} email(s) found</p>
            </div>
            <button onClick={deleteCsv} className="csv-delete-button">
              <IoMdClose />
            </button>
          </div>
        ) : (
          <p>Drag 'n' drop a CSV file here, or click to select a file</p>
        )}
      </div>
      {/* <textarea
            placeholder="Or type/paste comma-separated email addresses here"
            value={bulkEmails}
            onChange={handleBulkEmailsChange}
            rows={3}
          /> */}
      <button
        onClick={addBulkEmails}
        className="bulk-invite-button"
        disabled={bulkEmails?.length === 0 && csvEmails?.length === 0}>
        Add Collaborators
      </button>
      {fileError && <p className="file-error">{fileError}</p>}

      {(csvEmails.length > 0 || bulkEmails.trim() !== '') && (
        <p className="bulk-invite-count">
          {csvEmails.length +
            bulkEmails.split(',').filter(email => email.trim() !== '')
              .length}{' '}
          email(s) ready to be added
        </p>
      )}
    </div>
  );
};
