import React from 'react';
import {Checkbox} from '../../components/inputs/checkbox';
import {OptionsDropdown} from '../../components/inputs/drop-down';
import {useAuth} from '../../hooks/use-auth';

const GeneralSettings = ({}) => {
  const {
    state: {id, profile},
  } = useAuth();

  const {notifications} = profile || {};

  const notis = [{id: 'weekly_thread_summary', enabled: true, schedule: 'FRI'}];

  return (
    <div className="page-container">
      <div className="create-thread">
        <div className="create-thread__header">
          <h4>Thread Settings</h4>
          <p className="text-secondary">
            Edit your thread notification settings
          </p>
        </div>
        <div className="margin-16">
          {notis.map(noti => {
            const current = notifications?.find(item => item.id === noti.id);
            return (
              <NotificationItem
                key={noti.id}
                item={{...noti, ...current}}
                notis={notis}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

const NotificationItem = ({item, notis}) => {
  const {id, enabled, schedule} = item;

  const {
    state: {id: user_id},
    updateProfile,
  } = useAuth();

  const days = {
    SUN: 'Sunday',
    MON: 'Monday',
    TUE: 'Tuesday',
    WED: 'Wednesday',
    THU: 'Thursday',
    FRI: 'Friday',
    SAT: 'Saturday',
  };

  const handleUpdate = async update => {
    const mapped = notis.map(noti => {
      if (noti.id === update.id) {
        return update;
      }
      return noti;
    });
    await updateProfile({id: user_id, notifications: mapped});
  };

  const prettyNoti = id => {
    switch (id) {
      case 'weekly_thread_summary':
        return {
          title: 'Weekly Thread Summary',
          description:
            'Stay updated with your threads and discover trending discussions in your weekly digest.',
        };
      default:
        return null;
    }
  };

  const pretty = prettyNoti(id);

  if (!pretty) {
    return null;
  }

  return (
    <div className="flex-row justify-between align-center">
      <div>
        <p className="bold">{pretty.title}</p>
        <p className="text-secondary text-16">{pretty.description}</p>
      </div>
      <Checkbox
        checked={enabled}
        onChange={async e => {
          const val = e.target.checked;
          await handleUpdate({id, enabled: val, schedule});
        }}
        label="Enabled"
      />
      <OptionsDropdown
        value={{value: schedule, label: days[schedule]}}
        setValue={async val => {
          await handleUpdate({id, enabled, schedule: val.value});
        }}
        items={[
          {value: 'SUN', label: 'Sunday'},
          {value: 'MON', label: 'Monday'},
          {value: 'TUE', label: 'Tuesday'},
          {value: 'WED', label: 'Wednesday'},
          {value: 'THU', label: 'Thursday'},
          {value: 'FRI', label: 'Friday'},
          {value: 'SAT', label: 'Saturday'},
        ]}
      />
    </div>
  );
};

export default GeneralSettings;
