import {useFormik} from 'formik';
import React, {useEffect, useState} from 'react';
import {isMobile, isTablet} from 'react-device-detect';
import {useNavigate} from 'react-router-dom';
import * as Yup from 'yup';
import {track} from '../../api/analytics';
import {dateToTimestamp} from '../../api/dates';
import {LinkButton} from '../../components/buttons/buttons';
import constants from '../../components/constants';
import {
  PublicMindLogoDarkNoText,
  PublicMindLogoLightNoText,
} from '../../components/images/logos';
import {FormikCheckbox} from '../../components/inputs/checkbox';
import {FormikTextfield} from '../../components/inputs/textfields';
import {Modal} from '../../components/modal/modal';
import {useAuth} from '../../hooks/use-auth';
import useLoader from '../../hooks/use-loader';
import {useMounted} from '../../hooks/use-mounted';
import useSearchParameters from '../../hooks/use-search-parameters';

const Signup = ({}) => {
  const navigate = useNavigate();
  const isMounted = useMounted();
  const {paramsToData} = useSearchParameters();
  const {loadProfiles, loadOrgs} = useLoader();
  const {updateReferral} = useAuth();
  const {
    state: {auth, profiles, organizations},
    signup,
    signOut,
    addWaitlist,
  } = useAuth();
  const [darkMode, setTheme] = useState(false);

  useEffect(() => {
    // Get the data-theme attribute from the documentElement
    const currentTheme = document.documentElement.getAttribute('data-theme');
    setTheme(currentTheme === 'dark' ? true : false);
  }, []);
  const mobile = isMobile || isTablet;

  const [status, setStatus] = useState('waitlist');
  const [show, setShow] = useState(false);
  const [referral, setRef] = useState(null);
  const [disable, setDisable] = useState(false);

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      confirmPassword: '',
      referred_by: null,
      policy: false,
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email('Must be a valid email')
        .max(255)
        .lowercase()
        .required('Email is required'),
      password:
        status === 'active'
          ? Yup.string()
              .min(10)
              .max(255)
              .required('10+ character password is required')
          : Yup.string().notRequired(),
      confirmPassword:
        status === 'active'
          ? Yup.string()
              .oneOf([Yup.ref('password'), null], 'Passwords must match')
              .required('Confirm Password is required')
          : Yup.string().notRequired(),
      policy:
        status === 'active'
          ? Yup.boolean().oneOf([true], 'This field must be checked')
          : Yup.boolean().notRequired(),
    }),
    onSubmit: async (values, helpers) => {
      try {
        const {email, password, referred_by} = values;
        const lowered = email.toLowerCase();
        // IF ALLOWED IN
        if (status === 'active') {
          if (auth) {
            await signOut();
          }
          const {success, error} = await signup(lowered, password);

          if (success && isMounted()) {
            navigate('/confirm', {state: {email: lowered, password}});

            track('sign_up', {email: lowered});

            // TRACK REFERRALS IF NEEDED
            if (referral) {
              updateReferral(
                {id: lowered, status: 'signed_up', ...referral},
                true,
              );
            } else {
              updateReferral({id: lowered, status: 'signed_up'}, true);
            }
          }
          if (error) {
            helpers.setStatus({success: false});
            helpers.setErrors({submit: error});
            helpers.setSubmitting(false);
          }
        } else {
          // IF WAITLISTED
          const waitlist = {
            id: lowered,
            email,
            timestamp: dateToTimestamp(),
            referred_by,
          };
          const {success, error} = await addWaitlist(waitlist);
          if (success) {
            setShow(true);
            formik.resetForm();
            track('waitlist_sign_up', {email: lowered});
          }
          if (error) {
            helpers.setStatus({success: false});
            helpers.setErrors({submit: error});
            helpers.setSubmitting(false);
          }
        }
      } catch (err) {
        if (isMounted()) {
          helpers.setStatus({success: false});
          helpers.setErrors({submit: err.message});
          helpers.setSubmitting(false);
        }
      }
    },
  });

  useEffect(() => {
    // PARSE SIGN IN LINK
    const params = paramsToData();

    const {id, referral_user = null, status} = params || {};

    if (status) {
      setStatus(status);
    }

    if (referral_user) {
      setRef({referral_user});
    }

    // IF EMAIL IS INCLUDED IN THE LINK FORCE IT TO BE USED
    if (id) {
      setDisable(true);
      formik.setFieldValue('email', id);
    }
    formik.setFieldValue('referred_by', referral_user);
  }, []);

  useEffect(() => {
    if (referral) {
      const {referral_org, referral_user} = referral;

      // if (referral_user && profiles[referral_user] === undefined) {
      //   loadProfiles([referral_user], {authMode: 'apiKey'});
      // }

      if (referral_org && organizations[referral_org] === undefined) {
        loadOrgs([referral_org], {authMode: 'apiKey'});
      }
    }
  }, [referral]);

  const handleSubmit = e => {
    e.preventDefault();
    formik.handleSubmit();
  };

  return (
    <>
      <span className="padding-top-25vh" />
      <div className="grid-container">
        <div
          className={`${
            mobile ? 'grid-12' : 'grid-7'
          } flex-column align-center justify-center`}>
          {!darkMode && <PublicMindLogoLightNoText />}
          {darkMode && <PublicMindLogoDarkNoText />}
          <h3
            style={{
              fontFamily: "'Montserrat', sans-serif",
              fontWeight: 200,
              fontSize: mobile ? 14 : 20,
              letterSpacing: 0.1,
              paddingTop: 16,
              paddingBottom: 16,
            }}>
            PUBLICMIND
          </h3>
          <p
            className="text-secondary padding-bottom16"
            style={{
              fontFamily: "'Montserrat', sans-serif",
              fontWeight: 300,
              fontSize: mobile ? 12 : 20,
              letterSpacing: 0.1,
            }}>
            {status === 'active'
              ? 'Welcome to your secure network'
              : 'A secure, private network'}
          </p>
          <p
            className="text-secondary padding-bottom16"
            style={{
              fontFamily: "'Montserrat', sans-serif",
              fontWeight: 300,
              fontSize: mobile ? 12 : 20,
              letterSpacing: 0.1,
            }}>
            {status === 'active'
              ? 'Your official communication channel for projects'
              : 'Discover and host government projects'}
          </p>
          <p
            className="text-secondary padding-bottom16"
            style={{
              fontFamily: "'Montserrat', sans-serif",
              fontWeight: 300,
              fontSize: mobile ? 12 : 20,
              letterSpacing: 0.1,
            }}>
            {status === 'active'
              ? 'Enter a secure password in the form to the right to begin your journey'
              : 'Find a current member willing to vouch for you to join'}
          </p>
        </div>
        <div
          className={
            mobile ? 'grid-12 pretty-form-group' : 'grid-4 pretty-form-group'
          }>
          <div className="card pretty-form-group">
            <FormikTextfield
              disabled={disable}
              formik={formik}
              header="Email"
              type="text"
              name="email"
              placeholder="example@1.us.gov"
            />
            {status === 'active' && (
              <div>
                <FormikTextfield
                  formik={formik}
                  header="Password"
                  type={'password'}
                  name="password"
                />
                <FormikTextfield
                  formik={formik}
                  header="Confirm Password"
                  type={'password'}
                  name="confirmPassword"
                />
                <div className="padding-top16">
                  <FormikCheckbox formik={formik} name="policy">
                    <p className="text-left">
                      I agree to the{' '}
                      <a
                        href={constants.terms_of_service}
                        target="_blank"
                        rel="noreferrer">
                        Terms of Service
                      </a>{' '}
                      and{' '}
                      <a
                        href={constants.privacy_policy}
                        target="_blank"
                        rel="noreferrer">
                        Privacy Policy
                      </a>
                    </p>
                  </FormikCheckbox>
                </div>
              </div>
            )}
            {formik.errors.submit && (
              <p className="error">{formik.errors.submit}</p>
            )}
            <span className="padding-top8" />
            <button
              type="submit"
              className="basic-button"
              disabled={formik.isSubmitting}
              onClick={handleSubmit}>
              {status === 'active' ? 'CONTINUE' : 'REQUEST MEMBERSHIP'}
            </button>
            <LinkButton
              type="button"
              onClick={() => {
                navigate('/signin');
              }}>
              Have an account? Log in here
            </LinkButton>
          </div>
        </div>
        <Modal active={show} setActive={setShow}>
          <div className="card align-center">
            <PublicMindLogoLightNoText />
            <br />
            <h3>You're on the list</h3>
            <p>We will email you periodic updates</p>
            <br />
            <div className="flex justify-center">
              <button
                onClick={() => {
                  setShow(false);
                }}>
                DONE
              </button>
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default Signup;
