import {BiPoll} from 'react-icons/bi';
import {CiSignpostR1} from 'react-icons/ci';

import {
  BlockNoteSchema,
  defaultBlockSpecs,
  defaultInlineContentSpecs,
} from '@blocknote/core';
import '@blocknote/core/fonts/inter.css';
import {darkDefaultTheme, lightDefaultTheme} from '@blocknote/mantine';
import '@blocknote/mantine/style.css';
import {createReactInlineContentSpec} from '@blocknote/react';
import {GoGitBranch} from 'react-icons/go';
import {ImBlocked} from 'react-icons/im';
import {MdLabelImportantOutline} from 'react-icons/md';
import {
  RxCheckbox,
  RxLink2,
  RxQuestionMarkCircled,
  RxSewingPinFilled,
} from 'react-icons/rx';
import {useNavigate} from 'react-router-dom';
import {useAuth} from '../../hooks/use-auth';
import useStringFormatter from '../../hooks/use-string-formatter';
import {useThreads} from '../../hooks/use-threads';
import {Divider} from '../blocks/divider';

const MentionComponent = ({user, email}) => {
  const navigate = useNavigate();

  return (
    <span
      style={{
        color: 'var(--secondary-text)',
        cursor: 'pointer',
      }}
      onClick={() => {
        if (navigate) {
          navigate(`/feed/profiles/detail/${email}`);
        }
      }}>
      @{user}
    </span>
  );
};

export const Mention = createReactInlineContentSpec(
  {
    type: 'mention',
    propSchema: {
      user: {
        default: 'Unknown',
      },
      email: {
        default: '',
      },
    },
    content: 'none',
  },
  {
    render: props => (
      <MentionComponent
        user={props.inlineContent.props.user}
        email={props.inlineContent.props.email}
      />
    ),
  },
);

const ReferenceComponent = ({item, threads, profiles}) => {
  const navigate = useNavigate();
  const {prettyName} = useStringFormatter();

  // Early return if no item
  if (!item) {
    return (
      <span
        style={{
          color: 'var(--error)',
          display: 'inline-flex',
          alignItems: 'center',
          gap: '4px',
        }}>
        <ImBlocked />
        Not Authorized
      </span>
    );
  }

  const isThread = item.type === 'thread' || item.type === 'sub-thread';

  const getIcon = () => {
    if (!item.type) return <RxLink2 />;
    if (isThread) {
      return item.type === 'sub-thread' ? (
        <span style={{height: '1em', width: '1em'}}>
          <GoGitBranch />
        </span>
      ) : null;
    }

    const iconMap = {
      milestone: <RxSewingPinFilled />,
      question: <RxQuestionMarkCircled />,
      decision_point: <MdLabelImportantOutline />,
      poll: <BiPoll />,
      task: <RxCheckbox />,
      comment: <CiSignpostR1 />,
    };
    return (
      <span style={{height: '1em', width: '1em'}}>
        {iconMap[item.type] || <CiSignpostR1 />}
      </span>
    );
  };

  const extractTextFromBlock = block => {
    if (block.type === 'divider') return '';

    if (block.content && Array.isArray(block.content)) {
      let text = '';

      for (const contentItem of block.content) {
        if (contentItem.type === 'text') {
          text += contentItem.text;
        } else if (contentItem.type === 'mention') {
          text += `@${contentItem.props.user}`;
        } else if (contentItem.type === 'reference') {
          text += `#${contentItem.props.item?.title || 'reference'}`;
        }
        if (text.length > 0 && !text.endsWith(' ')) {
          text += ' ';
        }
      }
      return text.trim();
    }
    return '';
  };

  const getContentPreview = content => {
    if (!content) return 'No content';

    try {
      const contentObj = JSON.parse(content);
      if (!Array.isArray(contentObj)) return 'Invalid content format';

      let preview = '';
      for (const block of contentObj) {
        const blockText = extractTextFromBlock(block);
        if (blockText) {
          preview = blockText;
          break;
        }
      }

      if (!preview) return 'No text content';

      preview = preview.trim();
      return preview.length > 60 ? `${preview.slice(0, 60)}...` : preview;
    } catch (e) {
      const text = content.trim();
      return text.length > 60 ? `${text.slice(0, 60)}...` : text;
    }
  };

  const getThreadLogo = () => {
    if (item.custom_logo?.url) {
      return (
        <img
          src={item.custom_logo.url}
          alt=""
          style={{
            height: '16px',
            maxWidth: '32px',
            width: 'auto',
            marginRight: '2px',
          }}
          onError={e => (e.target.style.display = 'none')}
        />
      );
    }
    return item.logo || null;
  };

  const getDisplayText = () => {
    if (isThread) {
      return item.title || 'Untitled thread';
    }
    return getContentPreview(item.content);
  };

  const getAuthorInfo = () => {
    if (!isThread && item.owner_id && profiles) {
      const author = profiles[item.owner_id];
      if (author) {
        return `by ${prettyName(author)}`;
      }
    }
    return '';
  };

  const handleClick = () => {
    if (!navigate) return;
    if (isThread) {
      navigate(`/threads/${item.id}`);
    } else if (item.thread_id) {
      navigate(`/threads/${item.thread_id}/${item.id}`);
    }
  };

  const getThreadContext = () => {
    if (!isThread && item.thread_id && threads) {
      const parentThread = threads[item.thread_id];
      if (parentThread) {
        return (
          <span
            style={{
              color: 'var(--secondary-text)',
              // fontSize: '0.9em',
              opacity: 0.8,
              display: 'inline-flex',
              alignItems: 'center',
              gap: '2px',
            }}>
            {parentThread.custom_logo?.url ? (
              <img
                src={parentThread.custom_logo.url}
                alt=""
                style={{
                  height: '12px',
                  maxWidth: '24px',
                  width: 'auto',
                }}
                onError={e => (e.target.style.display = 'none')}
              />
            ) : (
              parentThread.logo
            )}{' '}
            {parentThread.title}
          </span>
        );
      }
    }
    return null;
  };

  return (
    <span
      style={{
        color: 'var(--secondary-text)',
        cursor: 'pointer',
        textDecoration: 'none',
        display: 'inline-flex',
        // alignItems: 'center',
        gap: '4px',
      }}
      onClick={handleClick}>
      {getIcon()}
      {isThread && getThreadLogo()}
      <span style={{textDecoration: 'underline'}}>{getDisplayText()}</span>

      {!isThread && (
        <span
          style={{
            // fontSize: '.7em',
            display: 'flex',
            alignItems: 'flex-end',
            gap: '4px',
          }}>
          <span style={{opacity: 0.8}}>{getAuthorInfo()}</span>
          <span style={{opacity: 0.6}}>in</span>
          {getThreadContext()}
        </span>
      )}
    </span>
  );
};

// Create a wrapper component that can use hooks
const ReferenceWrapper = props => {
  const {
    state: {threads},
  } = useThreads();

  const {
    state: {profiles},
  } = useAuth();

  return (
    <ReferenceComponent
      item={props.inlineContent.props.item}
      threads={threads}
      profiles={profiles}
    />
  );
};

export const Reference = createReactInlineContentSpec(
  {
    type: 'reference',
    propSchema: {
      item: {
        default: {},
      },
    },
    content: 'none',
    allowedMarks: ['reference'],
  },
  {
    render: props => <ReferenceWrapper inlineContent={props.inlineContent} />,
  },
);

export const schema = BlockNoteSchema.create({
  blockSpecs: {
    // ...defaultBlockSpecs,
    paragraph: defaultBlockSpecs.paragraph,
    heading: defaultBlockSpecs.heading,
    bulletListItem: defaultBlockSpecs.bulletListItem,
    numberedListItem: defaultBlockSpecs.numberedListItem,
    checkListItem: defaultBlockSpecs.checkListItem,
    // table: defaultBlockSpecs.table,
    divider: Divider,
  },
  inlineContentSpecs: {
    // Adds all default inline content.
    ...defaultInlineContentSpecs,
    // Adds the mention tag.
    mention: Mention,
    reference: Reference,
  },
});
// Base theme with shared styles
const baseTheme = {
  editor: {
    width: '100%',
    fontSize: '14px',
    paddingInlineStart: '48px',
    border: 'none',
    transition: 'none',
  },
  components: {
    // Toolbar styles
    toolbar: {
      transition: 'none',
      border: '1px solid var(--divider)',
      borderRadius: '4px',
    },
    // Side menu styles
    sideMenu: {
      transition: 'none',
      border: 'none',
    },
    mantineMenu: {
      dropdown: {
        border: '1px solid var(--divider)',
        boxShadow: 'none',
      },
      item: {
        border: '1px solid var(--divider)',
        boxShadow: 'none',
      },
      label: {
        border: '1px solid var(--divider)',
        boxShadow: 'none',
      },
    },
    // Formatting toolbar
    formattingToolbar: {
      borderRadius: '4px',
      border: '1px solid var(--divider)',
    },
    // Block styles
    blockquote: {
      borderLeftColor: 'var(--divider)',
      color: 'var(--secondary-text)',
    },
    codeBlock: {
      borderRadius: '4px',
    },
    table: {
      borderColor: 'var(--divider)',
    },
    tableCell: {
      borderColor: 'var(--divider)',
    },
    image: {
      maxWidth: '100%',
      height: 'auto',
    },
    fileBlock: {
      borderRadius: '4px',
      border: '1px solid var(--divider)',
    },
    listItem: {
      color: 'var(--secondary-text)',
    },
    // Menu styles
    menu: {
      border: '1px solid var(--divider)',
      borderRadius: '4px',
      boxShadow: 'none',
    },
    menuItem: {
      transition: 'none',
    },
    // Scrollbar
    scrollbar: {
      width: '8px',
      trackBackground: 'var(--background)',
      thumbBorderRadius: '4px',
    },
  },
};

// Light theme
const lightTheme = {
  ...baseTheme,
  colors: {
    editor: {
      text: 'var(--text)',
      background: 'transparent',
      transition: 'none',
    },
    menu: {
      text: 'var(--text)',
      background: 'var(--secondary-light)',
      transition: 'none',
    },
    tooltip: {
      text: 'var(--text)',
      background: 'var(--secondary-light)',
      transition: 'none',
    },
    hovered: {
      text: 'var(--text)',
      background: 'var(--highlight-light)',
      transition: 'none',
    },
    selected: {
      text: 'var(--white)',
      background: 'var(--primary-button)',
      transition: 'none',
    },
    disabled: {
      text: 'var(--secondary-text)',
      background: 'var(--divider)',
      transition: 'none',
    },
    sideMenu: 'var(--secondary-text)',
    components: {
      toolbar: {
        background: 'var(--secondary-light)',
        border: 'var(--divider)',
      },
      formattingToolbar: {
        background: 'var(--secondary-light)',
      },
      codeBlock: {
        background: 'var(--secondary-light)',
        text: 'var(--text)',
      },
      inlineCode: {
        background: 'var(--secondary-light)',
        text: 'var(--text)',
      },
      fileBlock: {
        background: 'var(--secondary-light)',
      },
      scrollbarThumb: 'var(--divider)',
      selection: {
        background: 'var(--highlight-light)',
        text: 'var(--text)',
      },
      mantineMenu: {
        dropdown: {
          background: 'var(--secondary-light)',
        },
        item: {
          background: 'var(--secondary-light)',
          text: 'var(--text)',
        },
        label: {
          background: 'var(--secondary-light)',
          text: 'var(--text)',
        },
      },
    },
  },
  styles: {
    editor: {transition: 'none'},
    blockContainer: {transition: 'none'},
    paragraph: {transition: 'none'},
    heading: {transition: 'none'},
    list: {transition: 'none'},
    nestedEditor: {transition: 'none'},
    suggestion: {transition: 'none'},
  },
};

// Dark theme
const darkTheme = {
  ...baseTheme,
  colors: {
    editor: {
      text: 'var(--text)',
      background: 'transparent',
      transition: 'none',
    },
    menu: {
      text: 'var(--text)',
      background: 'var(--secondary-dark)',
      transition: 'none',
    },
    tooltip: {
      text: 'var(--text)',
      background: 'var(--secondary-dark)',
      transition: 'none',
    },
    hovered: {
      text: 'var(--text)',
      background: 'var(--highlight-light)',
      transition: 'none',
    },
    selected: {
      text: 'var(--white)',
      background: 'var(--primary-button)',
      transition: 'none',
    },
    disabled: {
      text: 'var(--secondary-text)',
      background: 'var(--divider)',
      transition: 'none',
    },
    sideMenu: 'var(--secondary-text)',
    components: {
      toolbar: {
        background: 'var(--secondary-dark)',
        border: 'var(--divider)',
      },
      formattingToolbar: {
        background: 'var(--secondary-dark)',
      },
      codeBlock: {
        background: 'var(--secondary-dark)',
        text: 'var(--text)',
      },
      inlineCode: {
        background: 'var(--secondary-dark)',
        text: 'var(--text)',
      },
      fileBlock: {
        background: 'var(--secondary-dark)',
      },
      scrollbarThumb: 'var(--secondary-light)',
      selection: {
        background: 'var(--secondary-light)',
        text: 'var(--text)',
      },
      link: {
        color: 'var(--link)',
      },
      mantineMenu: {
        dropdown: {
          background: 'var(--secondary-dark)',
        },
        item: {
          background: 'var(--secondary-dark)',
          text: 'var(--text)',
        },
        label: {
          background: 'var(--secondary-dark)',
          text: 'var(--text)',
        },
      },
    },
  },
  styles: {
    editor: {transition: 'none'},
    blockContainer: {transition: 'none'},
    paragraph: {transition: 'none'},
    heading: {transition: 'none'},
    list: {transition: 'none'},
    nestedEditor: {transition: 'none'},
    suggestion: {transition: 'none'},
  },
};

export const blockInputTheme = {
  light: lightTheme,
  dark: darkTheme,
};

// Refined textfieldTheme
const textfieldLight = {
  colors: {
    editor: {
      text: 'var(--text)',
      background: 'var(--input-background)',
    },
    menu: {
      text: 'var(--text)',
      background: 'var(--secondary-light)',
    },
    tooltip: {
      text: 'var(--text)',
      background: 'var(--secondary-light)',
    },
    hovered: {
      text: 'var(--text)',
      background: 'var(--highlight-light)',
    },
    selected: {
      text: 'var(--white)',
      background: 'var(--primary-button)',
    },
    disabled: {
      text: 'var(--secondary-text)',
      background: 'var(--divider)',
    },
    sideMenu: 'var(--border)',
    highlights: lightDefaultTheme.colors.highlights,
  },
  borderRadius: 4,
  fontFamily:
    "'Inter', 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
  borderColor: 'var(--border)',
  borderWidth: '1px',
};

const textfieldDark = {
  ...textfieldLight,
  colors: {
    ...textfieldLight.colors,
    editor: {
      text: 'var(--text)',
      background: 'var(--input-background)',
    },
    menu: {
      text: 'var(--text)',
      background: 'var(--secondary-dark)',
    },
    tooltip: {
      text: 'var(--text)',
      background: 'var(--secondary-dark)',
    },
    hovered: {
      text: 'var(--text)',
      background: 'var(--highlight-light)',
    },
    selected: {
      text: 'var(--white)',
      background: 'var(--primary-button)',
    },
    disabled: {
      text: 'var(--secondary-text)',
      background: 'var(--divider)',
    },
    sideMenu: 'var(--border)',
    highlights: darkDefaultTheme.colors.highlights,
  },
  borderColor: 'var(--border)',
};

export const textfieldTheme = {
  light: textfieldLight,
  dark: textfieldDark,
};
