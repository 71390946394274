import {useCallback, useEffect, useRef, useState} from 'react';
import DeletedContent from '../../../components/item-details/deleted-content';
import {useAuth} from '../../../hooks/use-auth';
import useLoader from '../../../hooks/use-loader';
import {ContentActions} from '../inputs/interact-content-action-buttons';
import ThreadChildren from './thread-children';
import {BaseContent} from './thread-comment';

// DISPLAYS REPLIES
export const ThreadReply = ({item, onReply, onQuote, focused, dm}) => {
  const {loadProfiles} = useLoader();

  const {
    state: {id, profiles, organizations},
  } = useAuth();

  const contentRef = useRef(null);
  const {
    content,
    parent,
    owner_id,
    thread_id,
    created,
    media,
    reactions,
    children,
  } = item;

  const [isTarget, setIsTarget] = useState(false);

  useEffect(() => {
    if (!profiles?.[owner_id]) {
      loadProfiles([owner_id], {authMode: 'apiKey'});
    }
  }, [owner_id]);

  const scrollToContent = useCallback(() => {
    setTimeout(() => {
      if (contentRef.current) {
        contentRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
        setIsTarget(true);
      }
    }, 100);
  }, []);

  useEffect(() => {
    if (focused) {
      scrollToContent();
    }
  }, [focused, scrollToContent]);

  if (!item.id) {
    return <DeletedContent type="reply" />;
  }

  return (
    <div
      className={`content-item ${isTarget ? 'target' : ''}`}
      ref={contentRef}>
      <BaseContent item={item} />
      <ContentActions item={item} onQuote={onQuote} onReply={onReply} dm={dm} />
      {!!children?.length && <ThreadChildren children={children} />}
    </div>
  );
};
