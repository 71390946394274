import React from 'react';
import './DeletedContent.css';

const DeletedContent = ({type}) => (
  <div className="deleted-content">
    <p>This {type} has been deleted or you do not have access</p>
  </div>
);

export default DeletedContent;
