import {useFormik} from 'formik';
import debounce from 'lodash/debounce';
import React, {useCallback, useEffect, useState} from 'react';
import {HexColorInput, HexColorPicker} from 'react-colorful';
import {GoGear, GoGitBranch} from 'react-icons/go';
import {
  RxCross2,
  RxInfoCircled,
  RxLockClosed,
  RxLockOpen1,
  RxLockOpen2,
  RxPencil1,
  RxPerson,
  RxReset,
} from 'react-icons/rx';
import {useNavigate} from 'react-router-dom';
import * as Yup from 'yup';
import {track} from '../../../api/analytics';
import {dateToTimestamp} from '../../../api/dates';
import {sendAcceptJoinThread} from '../../../api/email';
import ActionPopup from '../../../components/action-feedback-popup/action-feedback-popup';
import {Badge} from '../../../components/badge/badge';
import {Checkbox} from '../../../components/inputs/checkbox';
import {TypeableSearchableDropDown} from '../../../components/inputs/drop-down';
import {ProfileImage} from '../../../components/inputs/uploader';
import MagicMarkdown from '../../../components/markdown/magic-markdown';
import {useAuth} from '../../../hooks/use-auth';
import useCollaborator from '../../../hooks/use-collaborator';
import useCrypto from '../../../hooks/use-crypto';
import useLoader from '../../../hooks/use-loader';
import useStringFormatter from '../../../hooks/use-string-formatter';
import {useThreads} from '../../../hooks/use-threads';
import '../ThreadStyles.css';
import {UpdateThread} from '../pages/create-thread';

export const ThreadInfoPopup = ({isOpen, onClose, setCurrent, thread}) => {
  const {capitalize, prettyTag, prettyName, prettyPosition} =
    useStringFormatter();
  const {encryptString} = useCrypto();
  const {threadActions} = useCollaborator();
  const navigate = useNavigate();

  const {
    state: {id, profiles, organizations},
  } = useAuth();
  const {
    state: {thread_ids, thread_request_ids},
    updateThread,
    defaultUpdate,
  } = useThreads();

  const [activeTab, setActiveTab] = useState('about');
  const [loading, setLoading] = useState(false);

  const {
    id: thread_id,
    title,
    permissions,
    members,
    owner_id,
    content,
    security_level,
    type,
    status,
  } = thread;

  const current_requests = thread_request_ids[thread.id] ?? [];

  const {editable} = threadActions(thread);
  const draft = status === 'draft';
  const complete = status === 'complete';

  const active = status === 'active';

  const {loadProfiles} = useLoader();

  useEffect(() => {
    const fetchMissingProfiles = async () => {
      const missingProfiles = members.filter(memberId => !profiles[memberId]);
      if (missingProfiles.length > 0) {
        setLoading(true);
        await loadProfiles(missingProfiles);
        setLoading(false);
      }
    };

    if (isOpen && members?.length) {
      fetchMissingProfiles();
    }
  }, [isOpen, owner_id, members, , profiles]);

  useEffect(() => {
    if (activeTab !== 'about') setActiveTab('about');
  }, [thread]);

  if (!isOpen) return null;

  const getSecurityDescription = () => {
    const subThreadNote =
      type === 'sub-thread' && security_level !== 'secure' ? (
        <p className="text-12 text-secondary border-bottom margin-h16 padding-bottom8">
          This is a breakout thread, discoverable only to parent thread members
          via the Breakout Thread menu.
        </p>
      ) : null;

    let description;
    switch (security_level) {
      case 'secure':
        description = (
          <p>
            Secure threads protect sensitive information. Only invited members
            can access, and all interactions are logged.
            {type === 'sub-thread' &&
              ' This breakout thread is hidden from the Breakout Thread menu.'}
          </p>
        );
        break;
      case 'private':
        description = (
          <p>
            Private threads are invite-only but less restricted than secure
            threads. Suitable for confidential, non-sensitive discussions.
            {type === 'sub-thread'
              ? ' This breakout thread appears in the Breakout Thread menu.'
              : ' Discoverable within the platform.'}
          </p>
        );
        break;
      case 'open':
      default:
        description = (
          <p>
            Public threads welcome all authorized users. Ideal for open
            discussions and wide collaboration.
            {type === 'sub-thread'
              ? ' This breakout thread appears in the Breakout Thread menu.'
              : ' Easily shareable and discoverable.'}
          </p>
        );
    }

    return (
      <>
        {subThreadNote}
        {description}
      </>
    );
  };
  const renderAboutTab = () => {
    const owner = profiles[owner_id] || {};
    const ownerName = prettyName(owner);

    return (
      <div className="thread-info-about">
        <div className="thread-info-description">
          <h4>Description</h4>
          <MagicMarkdown content={content} />
        </div>
        <div className="thread-info-owner">
          <h4>Thread Owner</h4>
          <div
            className="thread-info-owner-item"
            onClick={() => {
              const parsed = encryptString(owner_id);
              navigate(`/feed/profiles/detail/${parsed}`);
            }}>
            <ProfileImage
              data={owner.profile_image}
              style={{height: '32px', width: '32px'}}
            />
            <div className="thread-info-member-details">
              <span className="thread-info-member-name">{ownerName}</span>
              <span className="thread-info-member-position">
                {owner?.position}
              </span>
            </div>
          </div>
        </div>
        {!draft && !complete && (
          <div className="thread-info-description">
            <h4>
              {security_level === 'secure' ? (
                <RxLockClosed
                  title="Secure Thread"
                  className="security-icon-small secure"
                  // onClick={() => setIsInfoPopupOpen(true)}
                />
              ) : security_level === 'private' ? (
                <RxLockOpen1
                  title="Private Thread"
                  className="security-icon-small private"
                  // onClick={() => setIsInfoPopupOpen(true)}
                />
              ) : (
                <RxLockOpen2
                  title="Public Thread"
                  className="security-icon-small open"
                  // onClick={() => setIsInfoPopupOpen(true)}
                />
              )}{' '}
              Thread Security Level
            </h4>
            {getSecurityDescription()}
          </div>
        )}
      </div>
    );
  };
  const handleLeave = async () => {
    if (window.confirm('Are you sure you want to leave this thread?')) {
      const filtered_permissions = permissions?.filter(
        user => user.user_id !== id,
      );
      const filtered_members = filtered_permissions.map(item => item.user_id);
      const update = {
        id: thread_id,
        permissions: filtered_permissions,
        members: filtered_members,
      };

      await updateThread(update);

      const filtered_threads = thread_ids.filter(
        thread_id => thread_id !== thread.id,
      );
      const removed = {thread_ids: filtered_threads};
      defaultUpdate(removed);
      setCurrent(null);
      onClose();
    }
  };

  const handleArchive = async () => {
    if (
      window.confirm(
        'Are you sure you want to archive this thread? This deletes the thread permanently.',
      )
    ) {
      await updateThread({id: thread.id, status: 'archived'});
      setCurrent(null);
      onClose();
    }
  };

  return (
    <div className="thread-info-overlay" onClick={onClose}>
      <div className="thread-info-popup" onClick={e => e.stopPropagation()}>
        <div className="thread-info-header">
          <h3>{title} - Details</h3>
          <button className="thread-info-close-button" onClick={onClose}>
            <RxCross2 />
          </button>
        </div>
        <div className="thread-info-tabs">
          <button
            className={`thread-info-tab-button ${
              activeTab === 'about' ? 'active' : ''
            }`}
            onClick={() => setActiveTab('about')}>
            <RxInfoCircled />
          </button>
          {active && (
            <button
              className={`thread-info-tab-button ${
                activeTab === 'members' ? 'active' : ''
              }`}
              onClick={() => setActiveTab('members')}>
              <Badge
                count={editable ? current_requests?.length : 0}
                style={{top: '-16px', right: '-16px'}}>
                <RxPerson />
              </Badge>
            </button>
          )}
          {editable && (
            <button
              className={`thread-info-tab-button ${
                activeTab === 'edit' ? 'active' : ''
              }`}
              onClick={() => setActiveTab('edit')}>
              <RxPencil1 />
            </button>
          )}
          <button
            className={`thread-info-tab-button ${
              activeTab === 'settings' ? 'active' : ''
            }`}
            onClick={() => setActiveTab('settings')}>
            <GoGear />
          </button>
          {/* <button
            className={`thread-info-tab-button ${
              activeTab === 'files' ? 'active' : ''
            }`}
            onClick={() => setActiveTab('files')}>
            <RxFile />
          </button>
          <button
            className={`thread-info-tab-button ${
              activeTab === 'edit' ? 'active' : ''
            }`}
            onClick={() => setActiveTab('edit')}>
            <RxBell />
          </button> */}
          {/* <button
            className={`thread-info-tab-button ${
              activeTab === 'assistant' ? 'active' : ''
            }`}
            onClick={() => setActiveTab('assistant')}>
            <RxMagicWand />
          </button> */}
        </div>
        <div className="thread-info-content">
          {loading ? (
            <p>Loading information...</p>
          ) : (
            <>
              {activeTab === 'about' && renderAboutTab()}
              {activeTab === 'members' && (
                <div className="thread-info-members-list">
                  <RenderMemberContent thread={thread} />
                </div>
              )}
            </>
          )}
          {activeTab === 'edit' && <EditThread thread={thread} />}
          {activeTab === 'settings' && <SubscriptionSettings thread={thread} />}
          {activeTab === 'files' && <FileSettings thread={thread} />}
          {/* {activeTab === 'edit' && <NotificationSettings thread={thread} />} */}
          {/* {activeTab === 'assistant' && <AssistantSettings thread={thread} />} */}
        </div>
        <div className="thread-info-button-group">
          <div className="thread-info-left-buttons">
            <button onClick={onClose}>Close Details</button>
          </div>
          <div className="thread-info-right-buttons">
            {(owner_id !== id || type === 'direct') && (
              <div className="flex-row justify-end">
                <button
                  className="thread-info-button margin-h8 text-red"
                  onClick={async () => {
                    handleLeave();
                  }}>
                  Leave Thread
                </button>
              </div>
            )}
            {owner_id === id && (
              <button
                className={`thread-info-button margin-h8 text-red`}
                onClick={handleArchive}>
                Archive Thread
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export const RenderMemberContent = ({thread, button}) => {
  const {loadProfiles} = useLoader();
  const {capitalize, prettyName, prettyPosition} = useStringFormatter();
  const {threadActions} = useCollaborator();
  const {
    state: {id: cur_user_id, profiles, organizations},
  } = useAuth();

  const {id, permissions} = thread;

  const {editable} = threadActions(thread);

  useEffect(() => {
    const member_ids = permissions.map(item => item.user_id);
    loadProfiles(member_ids);
  }, [permissions]);

  return (
    <div className="thread-info-member-group">
      {permissions.length === 0 && <p className="text-secondary">No members</p>}
      {editable && <ThreadRequests id={thread?.id ?? null} />}
      {permissions?.length > 0 && (
        <p className="text-secondary">Current Members</p>
      )}
      {permissions.map(item => {
        const {user_id, role} = item;
        const member = profiles[user_id] || {email: user_id};
        const cur_pretty_name = prettyName(member);
        const {position, organization_ids} = member;
        const current_org = organization_ids?.length
          ? organizations?.[organization_ids[0]]
          : null;
        const org_name = prettyPosition(current_org);

        return (
          <div
            key={user_id}
            className="thread-info-member-item"
            //do this when state saves on return
            // onClick={() => navigate(`/feed/profiles/detail/${user_id}`)}
          >
            <div className="flex-row align-center">
              <ProfileImage
                data={member.profile_image}
                style={{height: '32px', width: '32px'}}
              />
              <div className="thread-info-member-details">
                <span className="thread-info-member-name">
                  {cur_pretty_name}
                </span>
                <span className="thread-info-member-position">
                  {position}
                  {org_name ? `, ${org_name}` : null}
                </span>
              </div>
            </div>
            <div>
              <div className="flex-column align-end">
                <p className="padding-bottom8">
                  Assigned Role: <strong>{capitalize(role)}</strong>
                </p>
                {button &&
                  editable &&
                  user_id !== cur_user_id &&
                  role !== 'owner' && (
                    <button
                      className={
                        button.text === 'Remove'
                          ? 'basic-button-secondary-critical'
                          : 'basic-button-secondary'
                      }
                      onClick={() => {
                        button.onClick(item);
                      }}>
                      {button.text}
                    </button>
                  )}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

const ThreadRequests = ({id}) => {
  const {
    state: {threads, thread_request_ids, thread_requests},
    getThreadRequests,
  } = useThreads();

  const request_ids = thread_request_ids?.[id] ?? [];
  const thread = threads?.[id];
  const active =
    request_ids?.filter(request_id => {
      const request = thread_requests[request_id];
      return request.status === 'requested';
    }) ?? [];

  useEffect(() => {
    if (id && thread_request_ids[id] === undefined) {
      const query = {thread_id: id, filter: {status: {eq: 'requested'}}};
      getThreadRequests(query);
    }
  }, [id]);

  return (
    <div>
      {active?.length > 0 && (
        <p className="text-secondary padding-bottom8">Join Requests</p>
      )}
      {active.map(request_id => {
        const request = thread_requests[request_id];
        const {id} = request;
        return <ThreadRequestItem key={id} item={request} thread={thread} />;
      })}
    </div>
  );
};

const ThreadRequestItem = ({item, thread}) => {
  const {prettyName, prettyPosition, capitalize} = useStringFormatter();
  const {loadProfiles} = useLoader();
  const {
    state: {profiles, organizations},
  } = useAuth();
  const {updateThreadRequest, updateThread} = useThreads();

  const {id, reason, requester_id, thread_id} = item;
  const {permissions, security_level} = thread;

  const member = profiles[requester_id] || {email: requester_id};
  const cur_pretty_name = prettyName(member);
  const {position, organization_ids} = member;
  const current_org = organization_ids?.length
    ? organizations?.[organization_ids[0]]
    : null;
  const org_name = prettyPosition(current_org);

  const role = security_level === 'open' ? 'collaborator' : 'subscriber';

  const handleAccept = async () => {
    await updateThreadRequest({id, thread_id, status: 'accepted'});
    const now = dateToTimestamp();
    const perms = [
      ...(permissions || []),
      {
        user_id: requester_id,
        role,
        created: now,
        updated: now,
      },
    ];
    const mems = perms.map(perm => perm.user_id);
    await updateThread({id: thread.id, permissions: perms, members: mems});

    await sendAcceptJoinThread({
      thread,
      profile: profiles?.[requester_id] ?? {first_name: requester_id},
      request: item,
    });
  };

  const handleDeny = async () => {
    await updateThreadRequest({id, thread_id, status: 'denied'});
  };

  useEffect(() => {
    if (requester_id) {
      loadProfiles([requester_id]);
    }
  }, [requester_id]);

  return (
    <div
      className="thread-info-member-item"
      //do this when state saves on return
      // onClick={() => navigate(`/feed/profiles/detail/${user_id}`)}
    >
      <div>
        <div className="flex-row align-center">
          <ProfileImage
            data={member.profile_image}
            style={{height: '32px', width: '32px'}}
          />
          <div className="thread-info-member-details">
            <span className="thread-info-member-name">{cur_pretty_name}</span>
            <span className="thread-info-member-position">
              {position}
              {org_name ? `, ${org_name}` : null}
            </span>
          </div>
        </div>
        <p className="padding-top8">
          Note: <strong>{reason}</strong>
        </p>
      </div>

      <div>
        <div className="flex-column align-end">
          <p className="padding-bottom8">
            Assigned Role: <strong>{capitalize(role)}</strong>
          </p>
          <div className="flex-row justify-center">
            <button className="error" onClick={handleDeny}>
              Deny
            </button>
            <button className="basic-button" onClick={handleAccept}>
              Accept
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const SubscriptionSettings = ({thread}) => {
  const {id, title, type, logo, custom_logo} = thread;
  const {
    state: {thread_subs, threads, thread_content},
    updateThreadSubscription,
  } = useThreads();
  const sub = thread_subs?.[id] ?? null;
  const [color, setColor] = useState(sub?.color ?? null);
  const [pinned, setPinned] = useState(!!sub?.priority);
  const [savedState, setSavedState] = useState(null);
  const [showUndo, setShowUndo] = useState(false);
  const [group, setGroup] = useState(sub?.group ?? '');

  useEffect(() => {
    if (sub) {
      setColor(sub.color ?? null);
      setPinned(!!sub.priority);
    }
  }, [sub]);

  const saveChanges = async (newColor, newPinned) => {
    if (sub?.id) {
      const obj = {
        id: sub.id,
        color: newColor,
        priority: newPinned ? dateToTimestamp() : null,
        thread_id: id,
      };
      setSavedState({color: sub.color, priority: sub.priority});
      const {success, error} = await updateThreadSubscription(obj);
      if (success) {
        setShowUndo(true);
        setTimeout(() => setShowUndo(false), 5000); // Hide undo after 5 seconds
      } else {
        console.error('Failed to save changes:', error);
      }
    }
  };

  const debouncedSaveChanges = useCallback(
    debounce((newColor, newPinned) => saveChanges(newColor, newPinned), 300),
    [sub],
  );

  const handleColorChange = newColor => {
    setColor(newColor);
    debouncedSaveChanges(newColor, pinned);
  };

  const handleUndo = async () => {
    if (savedState && sub?.id) {
      const {success, error} = await updateThreadSubscription({
        id: sub.id,
        color: savedState.color,
        priority: savedState.priority,
        thread_id: id,
      });
      if (success) {
        setColor(savedState.color);
        setPinned(!!savedState.priority);
        setShowUndo(false);
      } else {
        console.error('Failed to undo changes:', error);
      }
    }
  };

  const group_ids = [
    ...new Set(
      Object.values(thread_subs)
        .filter(item => item.group)
        .map(item => item.group),
    ),
  ].map(group => ({key: group, value: group}));

  return (
    <div className="subscription-settings">
      <span className="flex-row justify-between border-bottom align-center">
        <h3 className="subscription-settings__title">Thread Settings</h3>
        {!showUndo && <p className="text-secondary">You can undo edits</p>}
      </span>
      <div className="subscription-settings__section">
        <div className="subscription-settings__preview">
          <label className="subscription-settings__label">Preview:</label>
          <div
            className={`thread-item ${pinned ? 'pinned' : ''}`}
            style={{
              borderLeft: `4px solid ${color ?? null}`,
            }}>
            <span className="flex-row align-center">
              {type === 'sub-thread' && (
                <GoGitBranch
                  className="text-secondary padding-right4"
                  size={'14px'}
                />
              )}
              {custom_logo && (
                <img
                  src={custom_logo?.url}
                  alt="Logo"
                  style={{
                    height: '16px',
                    maxWidth: '32px',
                    width: 'auto',
                    marginRight: '2px',
                  }}
                />
              )}
              <p className="thread-item__title">
                {logo} {title}
                {type === 'sub-thread' &&
                  threads?.[thread_content?.[thread.parent]?.thread_id] && (
                    <span className="sub-thread-indicator">
                      {' '}
                      (
                      {
                        threads?.[thread_content?.[thread.parent]?.thread_id]
                          ?.title
                      }
                      )
                    </span>
                  )}
                {type === 'direct' && (
                  <span className="sub-thread-indicator"> (Direct)</span>
                )}
              </p>
            </span>
          </div>
        </div>
        <label className="subscription-settings__label">Thread Color:</label>
        <div className="subscription-settings__color-picker">
          <HexColorPicker
            color={color || '#FFFFFF'}
            onChange={handleColorChange}
          />
          <div className="subscription-settings__color-input">
            <HexColorInput
              color={color || '#FFFFFF'}
              onChange={handleColorChange}
              prefixed
              alpha
            />
          </div>
          {showUndo && (
            <div className="subscription-settings__undo">
              <p
                onClick={handleUndo}
                className="subscription-settings__undo-button">
                <RxReset />
              </p>
            </div>
          )}
        </div>
      </div>
      <div className="subscription-settings__section">
        <div className="pretty-form-group">
          <TypeableSearchableDropDown
            text={group}
            setValue={setGroup}
            items={group_ids}
            header="Group"
            onBlur={async () => {
              const obj = {
                id: sub.id,
                thread_id: sub.thread_id,
                group,
              };

              const {success, error} = await updateThreadSubscription(obj);
            }}
            onClick={async v => {
              const obj = {
                id: sub.id,
                thread_id: sub.thread_id,
                group: v,
              };

              const {success, error} = await updateThreadSubscription(obj);
            }}
          />
        </div>
      </div>
      <div className="subscription-settings__section">
        <Checkbox
          checked={pinned}
          onChange={e => {
            const newPinned = e.target.checked;
            setPinned(newPinned);
            saveChanges(color, newPinned);
          }}
          label={'Pin Thread'}
          className="subscription-settings__checkbox"
        />
      </div>
    </div>
  );
};

const FileSettings = ({thread}) => {
  const {id} = thread;
  const {
    state: {thread_subs},
  } = useThreads();

  const sub = thread_subs?.[id] ?? null;

  return (
    <div className="flex-column">
      <p className="text-secondary text-center">File aggregation coming soon</p>
    </div>
  );
};

const NotificationSettings = ({thread}) => {
  const {id} = thread;
  const {
    state: {thread_subs},
  } = useThreads();

  const sub = thread_subs?.[id] ?? null;

  return (
    <div className="flex-column">
      {/* <div className="grid-8"> */}
      <p className="text-secondary text-center">
        Notification settings coming soon
      </p>
      {/* </div> */}
      {/* <div className="grid-4"></div> */}
    </div>
  );
};

const EditThread = ({thread}) => {
  const {updateThread} = useThreads();
  const {
    state: {id: user_id},
  } = useAuth();

  const [popup, setPopup] = useState({on: false, message: ''});

  const formik = useFormik({
    initialValues: {
      id: thread.id,
      owner_id: thread.owner_id,
      status: thread?.status,
      security_level: thread?.security_level,
      members: thread?.members,
      media: thread?.media,
      title: thread?.title,
      content: thread?.content,
      parent: thread?.parent,
      expiration: thread?.expiration,
      logo: thread?.logo,
      tags: thread?.tags,
      type: thread?.type,
    },
    validationSchema: Yup.object({
      title: Yup.string().required('Title is required'),
    }),

    onSubmit: async (values, helpers) => {
      try {
        const {id} = thread;
        const update = {
          id,
          ...values,
        };

        const {success, error} = await updateThread(update);

        if (success) {
          track('thread_updated', {update, user_id});
          helpers.resetForm();
          setPopup({
            on: true,
            message: 'Success!',
          });
        }
        if (error) {
          setPopup({
            on: true,
            message: error,
          });
        }
      } catch (err) {
        console.log(err);
        helpers.setStatus({success: false});
        helpers.setErrors({submit: err.message});
        helpers.setSubmitting(false);
      }
    },
  });

  return (
    <>
      <UpdateThread formik={formik} />
      <ActionPopup message={popup.message} on={popup.on} setOff={setPopup} />
    </>
  );
};

const AssistantSettings = ({thread}) => {
  const {id} = thread;
  const {
    state: {thread_subs},
  } = useThreads();

  const sub = thread_subs?.[id] ?? null;

  return (
    <div className="flex-column">
      <p className="text-secondary text-center">
        Thread Assistants available on request.
      </p>
      <p className="text-secondary text-center">
        Reach out to the PublicMind team to enable for this thread.
      </p>
    </div>
  );
};
