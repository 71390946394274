import React, {useEffect, useState} from 'react';
import {track} from '../../api/analytics';
import {dateToTimestamp} from '../../api/dates';
import {useAuth} from '../../hooks/use-auth';
import {PublicMindLogoBig} from '../images/logos';
import {MultilineTextfield} from '../inputs/textfields';
import './feedbackmodal.css';
import {Modal} from './modal';

export const NPSModal = ({active, setActive}) => {
  if (!active) {
    return null;
  }

  return <NPSModalContent active={active} setActive={setActive} />;
};

const NPSModalContent = ({active, setActive}) => {
  const {
    state: {id, profile},
    updateProfile,
  } = useAuth();
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const updated = [
      ...(profile?.in_app_notifications || []),
      {type: 'nps', timestamp: dateToTimestamp()},
    ];
    updateProfile({id, in_app_notifications: updated});
  }, []);

  return (
    <Modal active={active} setActive={setActive}>
      <div className="nps-modal-container">
        {index === 0 && <Recommend setIndex={setIndex} />}
        {index === 1 && <ProductPreferences setActive={setActive} />}
      </div>
    </Modal>
  );
};

const Recommend = ({setIndex}) => {
  const [selectedScore, setSelectedScore] = useState(null);
  const buttons = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  const getScoreClass = num => {
    if (num <= 6) return 'score-button low-score';
    if (num <= 8) return 'score-button medium-score';
    return 'score-button high-score';
  };

  return (
    <div className="recommend-container">
      <div className="logo-container-feedback">
        <PublicMindLogoBig />
      </div>

      <h2 className="recommend-title">
        How likely are you to recommend PublicMind to a partner or colleague?
      </h2>

      <div className="score-grid">
        {buttons.map(num => (
          <button
            key={num}
            onClick={() => {
              setSelectedScore(num);
              setIndex(1);
              track('nps_feedback', {recommend_app: num});
            }}
            className={`${getScoreClass(num)} ${
              selectedScore === num ? 'selected' : ''
            }`}>
            <span className="score-text">{num}</span>
          </button>
        ))}
      </div>

      <div className="score-labels">
        <span>Not likely at all</span>
        <span>Extremely likely</span>
      </div>
    </div>
  );
};

const ProductPreferences = ({setActive}) => {
  const [response, setResponse] = useState('');

  const handleSubmit = () => {
    setActive(false);
    track('nps_feedback', {product_preferences: response});
  };

  return (
    <div className="preferences-container">
      <div className="logo-container-feedback">
        <PublicMindLogoBig />
      </div>

      <h2 className="preferences-title">
        What is the main benefit you recieve from PublicMind?
      </h2>

      <MultilineTextfield
        rows={5}
        value={response}
        type="text"
        onChange={e => setResponse(e.target.value)}
        className="feedback-textarea"
      />

      <div className="submit-container">
        <button
          onClick={handleSubmit}
          disabled={!response.trim()}
          className={`submit-button ${!response.trim() ? 'disabled' : ''}`}>
          Submit
        </button>
      </div>
    </div>
  );
};

export default NPSModal;
