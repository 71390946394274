import {useFormik} from 'formik';
import React, {useState} from 'react';
import {isMobile, isTablet} from 'react-device-detect';
import {useLocation, useNavigate} from 'react-router-dom';
import * as Yup from 'yup';
import '../../App.css';
import {track} from '../../api/analytics';
import {resendConfirmation} from '../../api/auth';
import ActionPopup from '../../components/action-feedback-popup/action-feedback-popup';
import {LinkButton} from '../../components/buttons/buttons';
import {PublicMindLogoLightNoText} from '../../components/images/logos';
import {FormikTextfield} from '../../components/inputs/textfields';
import {useAuth} from '../../hooks/use-auth';
import {useMounted} from '../../hooks/use-mounted';

const ConfirmAccount = ({}) => {
  const [isPopupVisible, setPopupVisible] = useState({
    on: false,
    message: '',
  });
  const navigate = useNavigate();
  const {state} = useLocation();
  const {
    state: {auth, profile},
    confirmAccount,
    signOut,
  } = useAuth();
  const isMounted = useMounted();

  const {email, password} = state || {};

  const [sendCode, setSent] = useState(false);
  const mobile = isMobile || isTablet;

  const formik = useFormik({
    initialValues: {
      email,
      code: '',
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email('Must be a valid email')
        .max(255)
        .lowercase()
        .required('Email is required'),
      code: Yup.string().trim().max(255).required('Code is required'),
    }),
    onSubmit: async (values, helpers) => {
      try {
        const {code, email} = values;
        if (auth) {
          await signOut();
        }
        const trimmed = code.trim();
        const {success, error, nextStep} = await confirmAccount(
          email,
          password,
          trimmed,
        );

        if (success && isMounted()) {
          navigate(nextStep);
          track('account_confirmed', {email});
        } else {
          helpers.setStatus({success: false});
          helpers.setErrors({submit: error});
          helpers.setSubmitting(false);
        }
      } catch (err) {
        if (isMounted()) {
          helpers.setStatus({success: false});
          helpers.setErrors({submit: err.message});
          helpers.setSubmitting(false);
        }
      }
    },
  });

  const handleSubmit = e => {
    e.preventDefault();
    formik.handleSubmit();
  };

  const handleResend = () => {
    try {
      setPopupVisible({
        on: true,
        message: 'Resending code now',
      });
      if (email) {
        resendConfirmation(email);
        setSent(true);
      }
    } catch (err) {
      setPopupVisible({
        on: true,
        message:
          'Error resending code. Contact us using the black circle icon at the bottom right',
      });
      console.log(err);
    }
  };

  return (
    <>
      <span className="padding-top-25vh" />

      <div className="flex-column align-center">
        <PublicMindLogoLightNoText />
        <h3 className="">Confirm Email</h3>
        <p className="text-secondary padding-bottom16 margin-h8">
          Check spam for missing emails or email us at{' '}
          <a href="mailto:help@publicmind.ai?subject=PublicMind%20Inquiry">
            help@publicmind.ai.
          </a>
        </p>
      </div>
      <form
        className={
          mobile
            ? 'mobile-form pretty-form-group'
            : 'width-50 pretty-form-group'
        }>
        {email ? (
          <div>
            <br />
            <p>Email confirmation sent to {email}</p>
          </div>
        ) : (
          <FormikTextfield
            formik={formik}
            header="Email"
            type="text"
            name="email"
          />
        )}

        <FormikTextfield
          formik={formik}
          header="Code"
          type="text"
          name="code"
        />
        <br />

        <p className="text-secondary">
          If you don't receive the email right away, please give it a few
          minutes. If you still don't receive the email, message us from the
          chat bubble in the bottom right and we'll get you squared away!
        </p>

        <div className="flex justify-center padding-top8">
          <button
            type="button"
            className="basic-button"
            disabled={formik.isSubmitting || !formik.values.code}
            onClick={handleSubmit}>
            CONTINUE
          </button>
        </div>
        <div className="flex justify-center align-center">
          <p className="text-secondary">Don't see it?</p>
          <LinkButton
            disabled={formik.isSubmitting}
            onClick={handleResend}
            type="button">
            {sendCode ? 'Send Again' : 'Resend'}
          </LinkButton>
        </div>
      </form>
      {isPopupVisible.on && (
        <ActionPopup
          message={isPopupVisible.message}
          setOff={setPopupVisible}
          on={isPopupVisible}
        />
      )}
    </>
  );
};

export default ConfirmAccount;
