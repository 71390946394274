import chrono from 'chrono-node';
import React, {useEffect, useState} from 'react';
import {Textfield} from './textfields';

export const DateInput = props => {
  const {header, ...rest} = props;

  return (
    <div>
      {header && <label>{header}</label>}
      <input {...rest} type={'date'} />
    </div>
  );
};

export const TimeInput = props => {
  const {header, ...rest} = props;

  return (
    <div>
      {header && <label>{header}</label>}
      <input {...rest} type={'time'} />
    </div>
  );
};

export const FormikDateInput = props => {
  const {formik, name, header, autoComplete, ...rest} = props;

  return (
    <div>
      {header && <label>{header}</label>}
      <input {...rest} type="date" {...formik.getFieldProps(name)} />
      {formik.touched[name] && formik.errors[name] && (
        <p className="input-error">{formik.errors[name]}</p>
      )}
    </div>
  );
};

export const FormikTimeInput = props => {
  const {formik, name, header, autoComplete, ...rest} = props;

  return (
    <div>
      {header && <label>{header}</label>}
      <input {...rest} type="time" {...formik.getFieldProps(name)} />
      {formik.touched[name] && formik.errors[name] && (
        <p className="input-error">{formik.errors[name]}</p>
      )}
    </div>
  );
};

export const DateTimeInput = ({
  date,
  setDate,
  label,
  future_only,
  past_only,
}) => {
  const [dateString, setDateString] = useState('');
  const [inputDate, setInputDate] = useState('');
  const [inputTime, setInputTime] = useState('');
  const [isUserEditing, setIsUserEditing] = useState(false);

  // Initialize the component with the date prop
  useEffect(() => {
    if (date && !isUserEditing) {
      setInputDate(date.toISOString().split('T')[0]);
      setInputTime(date.toTimeString().slice(0, 5));
      setDateString(''); // Clear the text field when date prop changes
    }
  }, [date, isUserEditing]);

  // Parse the user input and update date/time inputs
  useEffect(() => {
    if (isUserEditing) {
      const scheduledTime = chrono.parseDate(dateString);
      if (scheduledTime && !isNaN(scheduledTime.getTime())) {
        setDate(scheduledTime);
        setInputDate(scheduledTime.toISOString().split('T')[0]);
        setInputTime(scheduledTime.toTimeString().slice(0, 5));
      }
    }
  }, [dateString, isUserEditing]);

  // Update the date when date/time inputs change
  useEffect(() => {
    if (inputDate && inputTime) {
      const newDate = new Date(`${inputDate}T${inputTime}`);
      setDate(newDate);
    }
  }, [inputDate, inputTime]);

  const handleTextFieldChange = e => {
    setIsUserEditing(true);
    setDateString(e.target.value);
  };

  const handleBlur = () => {
    setIsUserEditing(false);
  };

  const handleDateFieldChange = e => {
    setIsUserEditing(true);
    setInputDate(e.target.value);
  };
  const handleTimeFieldChange = e => {
    setIsUserEditing(true);
    setInputTime(e.target.value);
  };
  const now = new Date();
  const past = date && date < now;
  const future = date && date > now;

  return (
    <div>
      {label && <label style={{marginTop: '8px'}}>{label}</label>}
      <Textfield
        value={dateString}
        onChange={handleTextFieldChange}
        onBlur={handleBlur}
        placeholder="Tomorrow at..."
      />
      {date && (
        <div className="grid-container">
          <div className="grid-6">
            <DateInput
              header="Date"
              value={inputDate}
              onChange={handleDateFieldChange}
              onBlur={handleBlur}
            />
          </div>
          <div className="grid-6">
            <TimeInput
              header="Time"
              value={inputTime}
              onChange={handleTimeFieldChange}
            />
          </div>
        </div>
      )}
      {future_only && past && (
        <p className="error">Choose a time in the future.</p>
      )}
      {past_only && future && (
        <p className="error">Choose a time in the past.</p>
      )}
    </div>
  );
};
