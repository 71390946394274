import * as Sentry from '@sentry/react';
import mixpanel from 'mixpanel-browser';

const token = process.env.REACT_APP_MIXPANEL_TOKEN;
const sentry_dsn = process.env.REACT_APP_SENTRY_TOKEN;

const disabled = process.env.NODE_ENV === 'development';

const initAnalytics = () => {
  if (disabled) return;

  mixpanel.init(token, {track_pageview: true});
  Sentry.init({
    dsn: sentry_dsn,
    integrations: [
      new Sentry.Replay({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0,
  });
  try {
  } catch (err) {}
};

const setAlias = uid => {
  try {
    if (disabled) return;

    mixpanel.alias(uid);
  } catch (err) {}
};

const reset = () => {
  try {
    if (disabled) return;

    mixpanel.reset();
  } catch (err) {}
};

const identify = (uid, options) => {
  try {
    if (disabled) return;

    mixpanel.identify(uid);
    mixpanel.people.set(options);
  } catch (err) {}
};

const track = (event, options = {}) => {
  try {
    if (!event || disabled) {
      return;
    }
    mixpanel.track(event, {...options, env: process.env.NODE_ENV});
  } catch (err) {}
};

export {identify, initAnalytics, reset, setAlias, track};
