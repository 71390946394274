import React from 'react';
import './styles.css';

const PrivacyPolicy = () => {
  return (
    <div className="legal-container">
      <h1>PublicMind™ Website Privacy Policy</h1>
      <p>
        <strong>Last Modified:</strong> October 26, 2023
      </p>
      <p>
        <strong>Introduction</strong> We at FinSiteful, Inc. as well as our
        affiliates (collectively, “we”, “us” or “our”) respect your privacy and
        are committed to protecting it through our compliance with this Policy.
        This Policy describes the types of information we may collect from you
        or that you may provide to us when you visit the website{' '}
        <a href="https://www.publicmind.ai/">https://www.publicmind.ai/</a>, any
        successor or subdomain thereof or any other website where we post this
        Policy (collectively, our “Website”) and our practices for collecting,
        using, maintaining, protecting and disclosing that information. This
        Policy applies to any information that we collect on the Website or in
        connection with your use of the Website, including any information in
        any forms or messages submitted by you to us through our Website. This
        Policy does not apply to any other information that we may collect by
        any other means. If you or your organization has registered to use one
        of our other applications or web-based services (collectively, the
        “Other FinSiteful Services”), this Policy does not apply to any
        information collected or processed by us in connection with your use of
        such Other FinSiteful Services. Please refer to the respective terms and
        policies and terms governing the Other FinSiteful Services for
        information about our collection and processing of information in
        connection with such services. Please read this Policy carefully to
        understand our policies and practices regarding your information and how
        we will treat it. If you do not agree with our policies and practices,
        you can choose not to use our Website. By accessing or using our
        Website, you agree to this Policy. This Policy may change from time to
        time. When we make changes to this Policy, we will post the updated
        Policy on our Website and update the Policy’s “Last Modified” date, as
        discussed in the section below titled “Changes to Our Privacy Policy.”
        Your continued use of the Website after we make changes is deemed to be
        acceptance of those changes.
      </p>
      <h2>Information We Collect About You and How We Collect It</h2>
      <h3>Personal Information We Collect</h3>
      <p>
        We may collect several types of information from and about users of our
        Website. This may include information by which users may be personally
        identified or information about a user that is maintained in
        personally-identifiable form (collectively, “Personal Information”).
        Specifically, we may collect the following:
      </p>
      <ul>
        <li>Your full name.</li>
        <li>Your e-mail address.</li>
        <li>Your job title.</li>
        <li>
          The name of the company or organization with which you are affiliated.
        </li>
        <li>Your IP address or mobile device ID.</li>
        <li>
          Any information you provide in connection with registering to use the
          Website, such as a username or password.
        </li>
        <li>
          Payment information such as your credit card number, debit card
          number, expiration dates and security codes, and other similar or
          related account and authentication data necessary to process payment
          transactions in connection with your use of the Website (“Payment
          Information”). Please note: the third-party payment processor(s) we
          have engaged to securely process payment transactions in connection
          with our Website collect this information directly from you and
          process this information on our behalf without sharing this
          information with us, and we do not at any time directly store or have
          access to any Payment Information.
        </li>
        <li>
          <em>
            The Technical Information described in the section below titled
            “Traffic, Usage and Computer/Device Information.”
          </em>
        </li>
        <li>
          Any other information (including Personal Information) you choose to
          provide in any forms or messages submitted by you to us or to others
          through our Website, including any Personal Information you authorize
          us to share with other users of the Website on your behalf through the
          Website’s event posting feature (the “Events Feature”), and any
          information you choose to share with other users of the Website
          through the Website’s user messaging feature (the “Messaging
          Feature”). Please note: we may access and view the contents of
          messages shared between users through the Messaging Feature as
          necessary to help ensure security and integrity of the Website and
          enforce its Terms of Service; perform services on behalf of you or
          your organization, including maintaining or servicing accounts,
          providing customer service, processing or fulfilling orders and
          transactions, verifying customer information, processing payments,
          providing financing, providing analytic services, providing storage,
          or providing similar services; and for other lawful purposes.
        </li>
      </ul>
      <h3>How We Collect Information</h3>
      <p>
        <em>
          With the exception of your Technical Information (which we collect
          automatically as you navigate through or interact with our Website, as
          described below in the section titled “Traffic, Usage and
          Computer/Device Information”), we collect this information directly
          from you when you provide it or submit it to us or our third-party
          service providers in connection with your use of the Website, such as
          by registering for a user account or subscription to the Website,
          sharing information in connection with the Events Feature, Messaging
          Feature or any other information sharing or public posting feature
          that may be provided through the Website, requesting information from
          us (such as by signing up to receive our e-mail notifications or other
          marketing and promotional information about us or our services),
          responding to a voluntary survey you have agreed to complete,
          contacting us for customer service inquiries or reporting a problem
          with our Website, submitting an inquiry to us using the contact
          methods provided on our Website, or otherwise contacting us about our
          Website or our business.
        </em>
      </p>
      <h3>Traffic, Usage and Computer/Device Information</h3>
      <p>
        Additionally, as you navigate through and interact with our Website or
        interact with e-mails or other electronic messages you receive from us,
        we may automatically collect certain traffic data, usage information,
        and information about your computer equipment or mobile device, such as
        your browser and operating system, your wireless carrier, the geographic
        region from which you are accessing the Website, time stamped logs
        regarding access times and duration of visits, the pages of our Website
        that you visit, whether you opened the e-mail message we sent to you and
        whether you clicked on any links in the message, configuration data,
        clickstream analytics and your actions and patterns when you use our
        Website or interact with our e-mail messages (collectively, “Technical
        Information”). The technologies we use for this automatic data
        collection may include cookies that are stored on the browser of your
        computer or mobile device, small embedded electronic files known as web
        beacons (also referred to as clear gifs, pixel tags and single-pixel
        gifs) and other types of embedded code. We may collect this information
        to manage our content and improve users’ use of our Website; to count
        and review the number of users of our Website and their usage patterns;
        to track key performance indicators such as pages accessed, frequency of
        access and other statistical information related to our Website and the
        performance of our e-mail messages (for example, recording the
        popularity of certain content and verifying system and server
        integrity); to identify the portion of users who access our Website via
        different web browsers or mobile operating systems; to recognize if a
        user is a return user of our Website; to store information about a
        user’s visits; to remember a user’s preferences; and for similar
        purposes in connection with monitoring, operating, maintaining and
        improving our Website and our e-mail messaging. You can set your browser
        to refuse all or some browser cookies. If you choose not to accept
        cookies, you may be unable to access certain parts or pages of our
        Website, or certain parts, pages or features of our Website may not
        function properly.
      </p>
      <h2>How We Use Your Information</h2>
      <h3>Generally</h3>
      <p>
        We may use the information we collect about you or that you provide to
        us, including Personal Information, to:
      </p>
      <ul>
        <li>Provide our Website and its contents to you.</li>
        <li>Provide you with information or services you request from us.</li>
        <li>
          Process your transactions and payments via the Website, including by
          transmitting certain of your Personal Information (including Payment
          Information) to the third-party payment processor(s) that we use for
          securely processing payment transactions, and to prevent transactional
          fraud.
        </li>
        <li>Create, maintain, customize and secure your account with us.</li>
        <li>
          Authenticate or otherwise verify your identity in connection with your
          requests via the Website or otherwise.
        </li>
        <li>
          Provide you with support and to respond to your inquiries and customer
          service and support requests, including to investigate and address
          your concerns and to monitor and improve our responses.
        </li>
        <li>
          Update and maintain subscription, e-mail or mailing lists (such as our
          e-mail lists to receive newsletters or alerts by e-mail).
        </li>
        <li>
          <em>
            Carry out the advertising, promotional and marketing purposes as
            described in the section below titled “Use of Personal Information
            for Marketing Purposes.”
          </em>
        </li>
        <li>
          Help maintain the safety, security, and integrity of our Website,
          databases and other technology assets and our business.
        </li>
        <li>
          Perform research and development activities, which only includes
          conducting data analyses in order to develop new products and services
          or to improve existing products and services, and performing quality
          control activities.
        </li>
        <li>
          <em>
            With respect to Technical Information, fulfill the purposes
            described in the section above titled “Traffic, Usage and
            Computer/Device Information.”
          </em>
        </li>
        <li>
          <em>
            With respect to aggregated and de-identified information, fulfill
            the purposes described in the section below titled “Use and
            Disclosure of Aggregated or De-Identified Data.”
          </em>
        </li>
        <li>
          Notify you about changes to our Website, our other products or
          services or this Policy.
        </li>
        <li>Develop and administer voluntary user surveys.</li>
        <li>
          Respond to lawful requests for information through court orders,
          subpoenas, warrants and other legal processes or obligations.
        </li>
        <li>
          Enforce any contracts between you and us, including for billing and
          collection, or for the establishment, exercise or defense of legal
          claims.
        </li>
        <li>
          Evaluate or conduct a merger, divestiture, restructuring,
          reorganization, dissolution or other sale or transfer of some or all
          of our assets, whether as a going concern or as part of bankruptcy,
          liquidation or similar proceeding, in which information (including
          Personal Information) held by us is among the assets transferred.
        </li>
        <li>
          Fulfill any other purpose for which you provide the information.
        </li>
        <li>
          Serve any other purpose that we describe when you provide the
          information and for any other purpose with your consent.
        </li>
      </ul>
      <h3>Use of Personal Information for Marketing Purposes</h3>
      <p>
        We may use your Personal Information to provide you with marketing and
        promotional information about our Website or our other products and
        services that we believe may be of interest to you. If you do not wish
        to have your Personal Information used by us to contact you with
        promotional content or messages, you may send us an e-mail stating your
        request to <a href="mailto:info@finsiteful.com">info@finsiteful.com</a>{' '}
        or you can opt-out by simply clicking on the “Unsubscribe”, “Updating
        your e-mail preferences” or similar link in the most recent e-mail you
        received from us and following the prompts that appear. This opt-out
        does not apply to notifications about changes to our Website or this
        Policy.
      </p>
      <h3>Use and Disclosure of Aggregated or De-Identified Data</h3>
      <p>
        We may convert or combine some Personal Information of users into
        de-identified or aggregated data that does not disclose any of the
        Personal Information of any individual user. We may use and disclose to
        third parties any such de-identified or aggregated data (including but
        not limited to traffic and usage data that does not identify you
        personally) for any lawful purpose.
      </p>
      <h3>User Contributions</h3>
      <p>
        You may provide information to be posted, published or displayed on
        public areas of our Website, or to be transmitted to other users of our
        Website, such as communications, notices, announcements, feedback and
        comments (collectively, “User Contributions”). Your User Contributions
        are posted on our Website and transmitted to others at your own risk. We
        cannot control the actions of other users of our Website with whom you
        may choose to share your User Contributions. Therefore, we cannot and do
        not guarantee that your User Contributions will not be viewed by
        unauthorized persons.
      </p>
      <h3>Tracking and “DO NOT TRACK”</h3>
      <p>
        Tracking involves the use of cookies, web beacons or other embedded code
        or tracking technologies to collect, analyze and store information on a
        user’s behavior over time on multiple sites, including information on
        the sites visited, products viewed, products purchased and other online
        interactions. Tracking information can be used to enable companies to
        make interest-based (behavioral) advertising available to users on
        multiple sites that they visit. We do not currently collect Personal
        Information about your online activities over time and across
        third-party sites for tracking purposes. In addition, we do not
        currently allow third parties to collect Personal Information through or
        using our Website that could be used by them to analyze and store
        information about your online activities over time and across
        third-party sites for tracking purposes. Therefore, our Website does not
        respond to any “DO NOT TRACK” signals sent by your browser or mobile
        device, and if your browser or mobile device sends a “DO NOT TRACK”
        signal to our Website, our Website will not treat you differently from
        users who do not send such signals.
      </p>
      <h2>Disclosure of Your Information</h2>
      <p>
        We may share your Personal Information with third parties in the
        following circumstances:
      </p>
      <ul>
        <li>
          We may disclose Personal Information to any contractors, service
          providers and other third parties who need to know such information
          (or such contractors, service providers or other third parties may
          collect Personal Information directly from you on our behalf while you
          use our Website or interact with our e-mail messages) in order to
          provide services to us that support our hosting, maintenance,
          operation and promotion of our Website and our business and our
          permitted uses of Personal Information under this Policy. For example,
          we may use a third-party cloud-hosting infrastructure service provider
          (e.g., Amazon Web Services, Google Cloud or Microsoft Azure); we may
          use a customer relationship management or an e-mail marketing software
          platform offered by a third-party service provider; we may use one or
          more third-party payments processor(s) to securely process payment
          transactions in connection with your use of the Website; we may use
          third-party service providers to assist us in offering and operating
          certain Website features, such as the Events Feature and/or Messaging
          Feature; and we may use third-party service providers to collect and
          analyze technical data derived from your use of the Website.
        </li>
        <li>
          We may disclose Personal Information to our affiliates or to a buyer
          or other successor to our business in the event of a sale of equity or
          assets, reorganization, merger, divestiture or a similar corporate
          transaction, whether as a going concern or as part of a bankruptcy,
          liquidation or similar proceeding, and any due diligence review with
          respect to any such proposed transaction.
        </li>
        <li>
          We may disclose Personal Information to comply with any court order,
          law or legal process, including to meet national security and law
          enforcement requirements or to respond to any government or regulatory
          request or audit.
        </li>
        <li>
          We may disclose Personal Information to enforce or apply any legal
          agreements between us and you, or if we believe disclosure is
          necessary or appropriate to protect the rights, property or safety of
          our users or others.
        </li>
      </ul>
      <p>
        We may also disclose your Personal Information for any purpose disclosed
        when the information is provided, and for any other purpose with your
        consent.
      </p>
      <h3>Third-Party Sites and Services</h3>
      <p>
        Our Website and e-mail messages to you may include links to blogs,
        social media and third-party websites. These third-party sites have
        their own privacy policies and terms of use and are not controlled by
        this Policy. You should carefully review any terms, conditions and
        policies of such third-party sites before visiting them or supplying
        them with any Personal Information. If you follow a link on our Website
        or e-mail messages to any third-party site, any information you provide
        to that site will be governed by its own terms of use and privacy policy
        and not this Policy. We are not responsible for the privacy or security
        of any information you provide to a third-party website or the
        information practices used by any third-party site, including links to
        any third-party site from our Website or e-mail messages. We make no
        representations, express or implied, concerning the accuracy, privacy,
        safety, security or the information practices of any third-party site.
        The inclusion of a link to a third-party site on our Website or in our
        e-mail messages does not constitute any type of endorsement of the
        linked site by us. We are not responsible for any loss or damage you may
        sustain resulting from your use of any third-party website or any
        information you share with a third-party website.
      </p>
      <h3>Accessing and Correcting Your Information</h3>
      <p>
        You may send us an e-mail at{' '}
        <a href="mailto:info@finsiteful.com">info@finsiteful.com</a> to request
        access to any Personal Information you have provided to us, or to
        correct or delete that Personal Information. For security reasons, we
        may need to request specific information from you to help us confirm
        your identity before we correct, update or delete any Personal
        Information you provide us. We will evaluate such requests on a
        case-by-case basis. In certain cases we cannot delete your Personal
        Information. For example, if we have provided you with an account to
        access certain parts of our Website, we cannot continue to maintain your
        account in connection with our Website without retaining certain
        portions of your Personal Information, so a request to delete certain
        forms of Personal Information may require the deletion of your account
        and cessation of services to you. We may not accommodate a request to
        change information if we believe the change would violate any law or
        legal requirement or cause the information to be incorrect.
      </p>
      <h3>Users Outside of the United States</h3>
      <p>
        Our Website is hosted in the United States and provided from the United
        States. It is possible that certain information will be stored on
        servers in multiple other countries on the “cloud” or other similar
        distributed hosting platforms. If you are accessing our Website from the
        European Union, Asia or any other jurisdiction with laws governing
        personal data collection, use and disclosure that differ from United
        States laws, please note that by using the Website and providing your
        Personal Information to us and others through the Website you are
        expressly and knowingly consenting to the transfer of your Personal
        Information from your home country to the United States and other
        jurisdictions as indicated above (where laws may differ from the laws of
        your jurisdiction) and you are expressly and knowingly consenting to our
        use and disclosure of such Personal Information in accordance with this
        Policy.
      </p>
      <h3>California “Shine The Light” Disclosure</h3>
      <p>
        The California Civil Code permits California residents with whom we have
        an established business relationship to request that we provide a list
        of certain categories of Personal Information that we have disclosed to
        third parties for their direct marketing purposes during the preceding
        calendar year. To make such a request, please send an e-mail to{' '}
        <a href="mailto:info@finsiteful.com">info@finsiteful.com</a>. Please
        mention that you are making a “California Shine the Light” inquiry.
        However, please know that we do not currently disclose Personal
        Information to third parties for their direct marketing purposes.
      </p>
      <h3>Nevada Residents: Sale Opt-Out Rights</h3>
      <p>
        Nevada residents who wish to exercise their sale opt-out rights under
        Nevada Revised Statutes Chapter 603A may submit a request to this
        designated address:{' '}
        <a href="mailto:info@finsiteful.com">info@finsiteful.com</a>. Please
        mention that you are making a “Nevada Sale Opt-Out Right” request.
        However, please know we do not currently sell data triggering that
        statute’s opt-out requirements.
      </p>
      <h3>Children Under the Age of 18</h3>
      <p>
        Our Website and our products and services are not intended to be used by
        children under 18 years of age, and no one under age 18 may provide any
        information to us or others on or using the Website or otherwise. We do
        not knowingly collect Personal Information from children under age 18.
        If you are under 18 years of age, do not use or provide any information
        on the Website or otherwise use our services, or provide any information
        about yourself to us, including your name, address, telephone number,
        e-mail address, IP address or mobile device ID or any screen name or
        username you may use. If we learn we have collected or received Personal
        Information from a child under 18 years of age without verification of
        parental consent, we will delete that information. If you believe we
        might have any information from or about a child under age 18, please
        contact us at{' '}
        <a href="mailto:info@finsiteful.com">info@finsiteful.com</a>.
      </p>
      <h3>Data Security</h3>
      <p>
        We have implemented measures designed to secure your Personal
        Information from accidental loss and from unauthorized access, use,
        alteration and disclosure. The safety and security of your information
        also depends on you. When we have given you (or when you have chosen) a
        password for access to certain parts of our Website, you are responsible
        for keeping that password confidential. We ask you not to share your
        password with anyone. Unfortunately, the transmission of information
        over the Internet is not completely secure. Although we have implemented
        security measures that we think are adequate, we cannot guarantee the
        security of your Personal Information transmitted to or using our
        Website. Any transmission of Personal Information is at your own risk.
        We are not responsible for the circumvention of any privacy settings or
        security measures contained on our Website.
      </p>
      <h2>Changes to Our Privacy Policy</h2>
      <p>
        We post any changes we make to our Policy on this page. If we make
        material changes to how we treat our users’ Personal Information, we
        will notify you through a notice on the Website home page. The date the
        Policy was last revised is identified at the top of the page. You are
        responsible for periodically visiting our Website and this Policy to
        check for any changes.
      </p>
      <h2>Contact Information</h2>
      <p>
        We are FinSiteful, Inc., a Delaware corporation with headquarters in
        Swansea, South Carolina. To ask questions or to submit comments about
        this Policy and our privacy practices, you may contact us via e-mail at{' '}
        <a href="mailto:info@finsiteful.com">info@finsiteful.com</a>.
      </p>
    </div>
  );
};

export default PrivacyPolicy;
