import React, {useState} from 'react';

import {RxPencil1, RxTrash} from 'react-icons/rx';
import {useNavigate} from 'react-router-dom';
import {formatMMDDYYYY} from '../../../api/dates';
import {ProfileImage} from '../../../components/inputs/uploader';
import {useAuth} from '../../../hooks/use-auth';
import useCrypto from '../../../hooks/use-crypto';
import useStringFormatter from '../../../hooks/use-string-formatter';
import './TaskDisplay.css';

const CreateTaskDisplay = ({tasks, onUpdate, editable = false}) => {
  const {
    state: {id: currentUserId, profiles},
  } = useAuth();
  const {encryptString} = useCrypto();
  const {prettyName} = useStringFormatter();
  const navigate = useNavigate();

  const [editingId, setEditingId] = useState(null);
  const [editedDescription, setEditedDescription] = useState('');

  const handleEdit = (id, description) => {
    setEditingId(id);
    setEditedDescription(description);
  };

  const handleSave = id => {
    onUpdate(id, {description: editedDescription});
    setEditingId(null);
  };

  const handleStatusChange = (id, newStatus) => {
    onUpdate(id, {status: newStatus});
  };

  const handleRemove = index => {
    const updatedTasks = [...tasks];
    updatedTasks.splice(index, 1);
    onUpdate(null, {tasks: updatedTasks});
  };
  const formatDueDate = date => {
    if (!date) return 'No due date';
    return formatMMDDYYYY(new Date(date * 1000));
  };
  return (
    <div className="task-display-container">
      <div className="task-display-header">
        <h3>Tasks</h3>
        <span className="task-display-count">{tasks.length} tasks</span>
      </div>
      <div className="task-display-table">
        <div className="task-display-table-header">
          <div className="task-display-cell task-display-description">
            Description
          </div>
          <div className="task-display-cell task-display-date">Due Date</div>
          <div className="task-display-cell task-display-owners">Assigned</div>
          <div className="task-display-cell task-display-status">Status</div>
          {editable && (
            <div className="task-display-cell task-display-actions">
              Actions
            </div>
          )}
        </div>
        {tasks?.map((task, i) => {
          const {id, description, assignee_ids, start_date, due_date, status} =
            task;
          return (
            <div key={id} className="task-display-row">
              <div className="task-display-cell task-display-description">
                {editingId === id ? (
                  <input
                    value={editedDescription}
                    onChange={e => setEditedDescription(e.target.value)}
                    className="task-edit-input"
                  />
                ) : (
                  description || (
                    <i className="text-secondary">No description provided</i>
                  )
                )}
              </div>
              <div className="task-display-cell task-display-date">
                {start_date && due_date
                  ? `${start_date} - ${due_date}`
                  : start_date || formatDueDate(due_date) || 'Not set'}
              </div>
              <div className="task-display-cell task-display-owners">
                {assignee_ids.map(assigneeId => {
                  const profile = profiles?.[assigneeId];
                  return (
                    <span key={assigneeId} className="task-display-owner">
                      <ProfileImage
                        data={profile?.profile_image}
                        style={{height: '24px', width: '24px'}}
                        onClick={() => {
                          const parsed = encryptString(assigneeId);
                          navigate(`/feed/profiles/detail/${parsed}`);
                        }}
                      />
                      <span className="task-display-owner-name">
                        {prettyName(profile)}
                      </span>
                    </span>
                  );
                })}
              </div>
              <div className="task-display-cell task-display-status">
                {editable ? (
                  <select
                    value={status}
                    onChange={e => handleStatusChange(id, e.target.value)}
                    className={`task-display-status-text task-display-${status
                      .toLowerCase()
                      .replace(' ', '-')}`}>
                    <option value="todo">To Do</option>
                    <option value="in_progress">In Progress</option>
                    <option value="complete">Complete</option>
                  </select>
                ) : (
                  <span
                    className={`task-display-status-text task-display-${status
                      .toLowerCase()
                      .replace(' ', '-')}`}>
                    {status === 'todo'
                      ? 'To Do'
                      : status === 'complete'
                      ? 'Complete'
                      : 'In Progress'}
                  </span>
                )}
              </div>
              {editable && (
                <div className="task-display-cell task-display-actions">
                  {editingId === id ? (
                    <button
                      onClick={() => handleSave(id)}
                      className="task-action-button">
                      Save
                    </button>
                  ) : (
                    <button
                      onClick={() => handleEdit(id, description)}
                      className="task-action-button">
                      <RxPencil1 />
                    </button>
                  )}
                  <button
                    onClick={() => handleRemove(i)}
                    className="task-action-button">
                    <RxTrash />
                  </button>
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default CreateTaskDisplay;
