/* eslint-disable import/no-anonymous-default-export */
import {GoogleOAuthProvider} from '@react-oauth/google';
import {ErrorBoundary} from '@sentry/react';
import {Amplify} from 'aws-amplify';
import {useEffect, useState} from 'react';
import {isMobile, isTablet} from 'react-device-detect';
import {
  BrowserRouter,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import {identify, initAnalytics} from './api/analytics';
import awsExports from './aws-exports';
import AuthGuard from './components/layout/auth-guard';
import {AuthLayout, FeedLayout, PublicLayout} from './components/layout/layout';
import {Provider as AuthProvider} from './contexts/auth-context';
import {Provider as ThreadProvider} from './contexts/thread-context';
import {useAuth} from './hooks/use-auth';
import {useThreads} from './hooks/use-threads';
import useTracker from './hooks/use-tracker';
import ConfirmAccount from './pages/auth/confirm-account';
import ConfirmSubscription from './pages/auth/confirm-subscription';
import CreateOrganization from './pages/auth/create-organization';
import CreateProfile from './pages/auth/create-profile';
import FindOrganization from './pages/auth/find-organization';
import ForgotPassword from './pages/auth/forgot-password';
import PaymentPending from './pages/auth/payment-pending';
import Pending from './pages/auth/pending';
import ProfileTagRequests from './pages/auth/profile-tag-request';
import ProfileTagResources from './pages/auth/profile-tag-resources';
import Root from './pages/auth/root';
import Signin, {LandingPage} from './pages/auth/signin';
import Signup from './pages/auth/signup';
import WelcomeAccount from './pages/auth/welcome-account';
import OrganizationDetail from './pages/data/organization-detail';
import ProfileDetail from './pages/data/profile-detail';
import Search from './pages/data/search';
import DesktopOnlyPage from './pages/desktop-only/desktop-only';
import ComingSoon from './pages/error/coming-soon';
import OrganizationError from './pages/error/organization-error';
import PageNotFound from './pages/error/page-not-found';
import PrivacyPolicy from './pages/legal/privacy-policy';
import TermsOfService from './pages/legal/terms-of-service';
import Triggers from './pages/public/triggers';
import AdminPanel from './pages/settings/admin-panel';
import Canny from './pages/settings/canny';
import EmailPreview from './pages/settings/email-preview';
import GeneralSettings from './pages/settings/general-settings';
import MasterSettings from './pages/settings/master-settings';
import OrganizationSettings from './pages/settings/organization-settings';
import ProfileSettings from './pages/settings/profile-settings';
import CreateThread from './pages/threads/pages/create-thread';
import PollResults from './pages/threads/pages/poll-results';
import PublicThreadDetail from './pages/threads/pages/public-thread-detail';
import ThreadAdminPanel from './pages/threads/pages/thread-admin-panel';
import ThreadInviteLink from './pages/threads/pages/thread-invite-link';
import ThreadJoinRequest from './pages/threads/pages/thread-request-to-join';
import ThreadsOverview from './pages/threads/pages/threads-overview';

Amplify.configure(awsExports);

const App = () => {
  const {
    state: {profile, auth},
    init,
    listener,
  } = useAuth();

  const [listening, setListening] = useState(false);
  const mobile = isMobile && !isTablet;

  useEffect(() => {
    init();
    if (!listening) {
      listener();
      setListening(true);
    }
    // IF NOT DEVELOPMENT
    if (process.env.NODE_ENV === 'production') {
      initAnalytics();
    }
  }, []);

  // UPDATE MIXPANEL WITH NEW PROPS
  useEffect(() => {
    if (profile) {
      const {
        id,
        email,
        first_name,
        last_name,
        phone,
        position,
        bio,
        organization_ids,
        status,
        createdAt = '',
        inner_tags,
        outer_tags,
      } = profile;

      // MIXPANEL
      identify(id, {
        $email: email,
        $first_name: first_name,
        $last_name: last_name,
        $created: createdAt,
        aws_sub: id,
        organization_ids,
        phone,
        position,
        bio,
        status,
      });
    }
  }, [profile]);

  if (mobile) {
    return (
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          {/* <Route
            path="/public/profile/:id"
            element={
              <div className="margin-v32">
                <PublicProfileDetail />
              </div>
            }
          /> */}
          <Route path="/" element={<LandingPage />} />
          <Route path="/home" element={<LandingPage />} />
          <Route path="/public/profile/:id" element={<SignupRedirect />} />

          <Route path="/" element={<AuthLayout />}>
            <Route index element={<Root />} />

            <Route path={'*'} element={<DesktopOnlyPage />} />
            <Route path={'/desktop-only'} element={<DesktopOnlyPage />} />
            {/* <Route path="signin" element={<Signin />} />
            <Route path="forgot-password" element={<ForgotPassword />} />
            <Route path="signup" element={<Signup />} />
            <Route path="confirm" element={<ConfirmAccount />} />
            <Route path="/" element={<AuthGuard reroute={false} />}>
              <Route path="create-profile" element={<CreateProfile />} />
              <Route path="introduce-yourself" element={<WelcomeAccount />} />
              <Route
                path="profile-tag-resources"
                element={<ProfileTagResources />}
              />
              <Route
                path="profile-tag-requests"
                element={<ProfileTagRequests />}
              />
              <Route path="find-organization" element={<FindOrganization />} />
              <Route
                path="create-organization"
                element={<CreateOrganization />}
              />
              <Route
                path="confirm-subscription"
                element={<ConfirmSubscription />}
              />
              <Route path={'/pending'} element={<Pending />} />
              <Route path={'/payment-pending'} element={<PaymentPending />} />
            </Route> */}
          </Route>
          {/* <Route
            path="/"
            element={
              <AuthGuard reroute={true}>
                <MobileLayout />
              </AuthGuard>
            }>
            <Route path={'/threads'} element={<ThreadsOverview />} />
          </Route> */}
        </Routes>
      </BrowserRouter>
    );
  }

  return (
    <BrowserRouter>
      {/* SCROLLS TO TOP WHEN NAVIGATE TO NEW PAGE */}
      <ScrollToTop />
      <NewMessageTabNotification />
      <Routes>
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-of-service" element={<TermsOfService />} />
        {/* PUBLIC */}
        <Route
          path="/"
          element={
            <PublicLayout>
              <AuthGuard reroute={false} force={true} />
            </PublicLayout>
          }>
          {/* <Route
            path="/public/profile/:id"
            element={
              <div className="flex justify-center margin-v32">
              <div className="" style={{width: '60vw'}}>
              <PublicProfileDetail />
              </div>
              </div>
              }
              /> */}
          <Route path="/threads/public/:id" element={<PublicThreadDetail />} />
          <Route path="/threads/invite/:id" element={<ThreadInviteLink />} />
          <Route path="/public/profile/:id" element={<SignupRedirect />} />
        </Route>
        <Route path="/" element={<PublicLayout />}>
          <Route path="trigger" element={<Triggers />} />
        </Route>
        {/* AUTH LAYOUT */}
        {process.env.NODE_ENV === 'development' && (
          <Route path="/email-preview" element={<EmailPreview />} />
        )}
        <Route path="/" element={<AuthLayout />}>
          <Route index element={<Root />} />
          <Route path="home" element={<LandingPage />} />
          <Route path="signin" element={<Signin />} />
          <Route path="forgot-password" element={<ForgotPassword />} />
          <Route path="signup" element={<Signup />} />
          <Route path="confirm" element={<ConfirmAccount />} />
          <Route path="/" element={<AuthGuard reroute={false} />}>
            <Route path="create-profile" element={<CreateProfile />} />
            <Route path="introduce-yourself" element={<WelcomeAccount />} />
            <Route path={'/pending'} element={<Pending />} />
            <Route path={'/payment-pending'} element={<PaymentPending />} />
          </Route>
        </Route>
        {/* DASHBOARD LAYOUT AND AUTH GUARDED */}
        <Route
          path="/"
          element={
            <AuthGuard reroute={true}>
              <FeedLayout />
            </AuthGuard>
          }>
          {/* ERROR HANDLING */}
          <Route path={'*'} element={<PageNotFound />} />
          <Route path={'/organization-error'} element={<OrganizationError />} />
          <Route path={'/coming-soon'} element={<ComingSoon />} />
          {/* THREADS */}
          <Route path={'/threads'} element={<ThreadsOverview />} />
          <Route
            path={'/threads/admin/panel/:id'}
            element={<ThreadAdminPanel />}
          />
          <Route path="/threads/request/:id" element={<ThreadJoinRequest />} />
          <Route path="/threads/poll/results/:id" element={<PollResults />} />
          <Route path={'/threads/:thread_id'} element={<ThreadsOverview />} />
          <Route
            path={'/threads/:thread_id/:content_id'}
            element={<ThreadsOverview />}
          />
          <Route path={'/threads/create'} element={<CreateThread />} />
          {/* SEARCH */}
          <Route path={'/feed'} element={<Search />} />
          <Route path={'/feed/:query'} element={<Search />} />

          <Route
            path="/feed/organizations/detail/:id"
            element={<OrganizationDetail />}
          />
          <Route path="/feed/profiles/detail/:id" element={<ProfileDetail />} />
          {/* SETTINGS */}
          <Route path="/settings/profile" element={<ProfileSettings />} />
          <Route
            path="/settings/organization"
            element={<OrganizationSettings />}
          />
          <Route path="/settings/general" element={<GeneralSettings />} />
          <Route path="/settings/feedback" element={<Canny />} />
          {process.env.NODE_ENV === 'development' && (
            <Route path="/settings/master" element={<MasterSettings />} />
          )}
          {process.env.NODE_ENV === 'development' && (
            <Route path="/settings/admin" element={<AdminPanel />} />
          )}
          {/* ONBOARDING */}
          <Route path="find-organization" element={<FindOrganization />} />
          <Route path="create-organization" element={<CreateOrganization />} />
          <Route
            path="confirm-subscription"
            element={<ConfirmSubscription />}
          />
          <Route
            path="profile-tag-resources"
            element={<ProfileTagResources />}
          />
          <Route path="profile-tag-requests" element={<ProfileTagRequests />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

const NewMessageTabNotification = ({title}) => {
  const {
    state: {id},
  } = useAuth();
  const {
    state: {threads_loaded, thread_ids, threads, thread_subs},
  } = useThreads();

  useEffect(() => {
    if (!threads_loaded) {
      return;
    }
    let new_content = 0;
    thread_ids.forEach(thread_id => {
      const thread = threads?.[thread_id] ?? {};
      const sub = thread_subs?.[thread_id] ?? {};
      const {last_sender, updated} = thread;
      const {last_read} = sub;

      const news = thread?.id && id !== last_sender && last_read < updated;

      if (news) {
        new_content += 1;
      }
    });
    if (new_content) {
      document.title = `Threads - ${new_content} Update${
        new_content > 1 ? 's' : ''
      } 🔴`;
    } else {
      document.title = 'Threads - No Updates';
    }
  }, [threads_loaded, thread_subs, threads, thread_ids]);

  return null; // This component doesn't render anything
};

const SignupRedirect = () => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate('/signup');
  }, []);
  return null;
};

const ScrollToTop = () => {
  const {trackPage} = useTracker();
  const {pathname} = useLocation();

  useEffect(() => {
    // Scroll to the top of the page when the location (pathname) changes
    window.scrollTo(0, 0);
    // IGNORE CERTAIN SCREENS, OTHERWISE TRACK ALL PAGE VIEWS
    trackPage(pathname);
  }, [pathname]);

  return null;
};

function FallbackComponent() {
  return (
    <div className="flex-column justify-center align-center">
      <br />
      <br />
      <p>An error has occurred. Try refreshing the page.</p>
    </div>
  );
}

export default () => {
  // PUBLICMIND
  const client_id =
    '574564987272-kr743dsu1n2kblbc3gihqjv91emj84rk.apps.googleusercontent.com';
  // FINSITEFUL
  const client_id2 =
    '233610274066-0c3giohk8fu9h98lvr9c4o8lt1e756r1.apps.googleusercontent.com';

  return (
    <ErrorBoundary fallback={FallbackComponent} showDialog>
      <GoogleOAuthProvider clientId={client_id}>
        <ThreadProvider>
          <AuthProvider>
            <App />
          </AuthProvider>
        </ThreadProvider>
      </GoogleOAuthProvider>
    </ErrorBoundary>
  );
};
