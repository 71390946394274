import React, {useCallback, useEffect, useState} from 'react';
import {FaConnectdevelop} from 'react-icons/fa';
import {RxExclamationTriangle, RxUpdate} from 'react-icons/rx';
import {useAuth} from '../../hooks/use-auth';
import {useThreads} from '../../hooks/use-threads';
import './SystemStatus.css';

const SystemStatus = () => {
  const {
    state: {processing: authProcessing, error: authError},
  } = useAuth();

  const {
    state: {
      threads_loaded,
      thread_subs_loaded,
      templates_loaded,
      error: threadError,
      systemStatus,
      listeners,
    },
  } = useThreads();

  const [status, setStatus] = useState('idle');
  const [message, setMessage] = useState('');
  const [showMessage, setShowMessage] = useState(false);

  const getListenerStatus = useCallback(() => {
    if (!listeners) return 'Connected';
    const activeListeners = Object.values(listeners).filter(Boolean).length;
    const totalListeners = Object.keys(listeners).length;
    return `Connected (${activeListeners}/${totalListeners})`;
  }, [listeners]);

  useEffect(() => {
    // Handle loading states with priority
    if (systemStatus?.type === 'processing') {
      setStatus('processing');
      setMessage(systemStatus.message || 'Processing...');
    } else if (systemStatus?.type === 'error') {
      setStatus('error');
      setMessage(systemStatus.message || 'Error occurred');
    } else if (systemStatus?.type === 'success') {
      setStatus('ready');
      setMessage(systemStatus.message || 'Operation successful');
    } else if (authProcessing) {
      setStatus('processing');
      setMessage('Processing authentication...');
    } else if (!threads_loaded || !thread_subs_loaded || !templates_loaded) {
      setStatus('loading');
      setMessage('Loading content...');
    } else if (authError || threadError) {
      setStatus('error');
      setMessage(authError || threadError);
    } else {
      setStatus('ready');
      setMessage('Connected');
    }

    setShowMessage(true);
    const timer = setTimeout(() => setShowMessage(false), 3000);
    return () => clearTimeout(timer);
  }, [
    authProcessing,
    threads_loaded,
    thread_subs_loaded,
    templates_loaded,
    authError,
    threadError,
    systemStatus,
    getListenerStatus,
  ]);

  return (
    <div className="system-status">
      <div className="system-status-icon">
        {status === 'ready' && (
          <FaConnectdevelop
            className="system-status-icon-ready"
            aria-label="System Ready"
          />
        )}
        {status === 'processing' && (
          <RxUpdate
            className="system-status-icon-processing"
            aria-label="Processing"
          />
        )}
        {status === 'loading' && (
          <RxUpdate
            className="system-status-icon-loading"
            aria-label="Loading"
          />
        )}
        {status === 'error' && (
          <RxExclamationTriangle
            className="system-status-icon-error"
            aria-label="Error"
          />
        )}
      </div>
      <div
        className={`system-status-message ${
          showMessage ? '' : 'translucent-25'
        }`}>
        {message}
      </div>
    </div>
  );
};

export default SystemStatus;
