import {filterSuggestionItems} from '@blocknote/core';
import {BlockNoteView} from '@blocknote/mantine';
import {SuggestionMenuController, useCreateBlockNote} from '@blocknote/react';
import {useFormik} from 'formik';
import React, {useEffect, useState} from 'react';
import {BiPoll} from 'react-icons/bi';
import {GoGitBranch} from 'react-icons/go';
import {HiOutlineDocumentText} from 'react-icons/hi';
import {MdLabelImportantOutline} from 'react-icons/md';
import {
  RxCheckbox,
  RxPencil1,
  RxPlus,
  RxQuestionMarkCircled,
  RxSewingPinFilled,
  RxTrash,
} from 'react-icons/rx';
import {useNavigate} from 'react-router-dom';
import {v4} from 'uuid';
import * as Yup from 'yup';
import {track} from '../../api/analytics';
import {dateToTimestamp} from '../../api/dates';
import {
  sendPriorityThreadContent,
  sendThreadInvitations,
} from '../../api/email';
import '../../App.css';
import {useAuth} from '../../hooks/use-auth';
import useStringFormatter from '../../hooks/use-string-formatter';
import {useThreads} from '../../hooks/use-threads';
import CreateTaskDisplay from '../../pages/threads/components/create-task-display';
import {RenderMemberContent} from '../../pages/threads/modals/ThreadInfoPopup';
import {
  TypeToggle,
  UpdateThread,
} from '../../pages/threads/pages/create-thread';
import {mergeArrays} from '../../utils/utils';
import ActionPopup from '../action-feedback-popup/action-feedback-popup';
import {setupBlocks} from '../blocks/divider';
import constants from '../constants';
import {schema, textfieldTheme} from '../inputs/block-input';
import {DateTimeInput} from '../inputs/date-input';
import {
  ArraySearchableDropDown,
  FormikSearchableDropDown,
} from '../inputs/drop-down';
import {
  BulkEmailUploader,
  PermissionRoleInput,
  PermissionsInput,
} from '../inputs/email-input';
import {
  ArrayFormikTextfield,
  FormikTextfield,
  MultilineFormikTextfield,
  Textfield,
} from '../inputs/textfields';
import {MultipleFileUploader} from '../inputs/uploader';
import {Modal} from './modal';
import './SpecialCommentModal.css';

const titles = {
  task: 'Task Group Title',
  question: 'Question',
  milestone: 'Milestone Title',
  poll: 'Poll Question',
  decision_point: 'Decision',
  form: 'Form Title',
};

export const EditThreadModal = ({active, setActive, thread}) => {
  const [popup, setPopup] = useState({on: false, message: ''});

  return (
    <>
      {active ? (
        <EditThreadContentModal
          active={active}
          setActive={setActive}
          setPopup={setPopup}
          thread={thread}
        />
      ) : null}
      <ActionPopup message={popup.message} setOff={setPopup} on={popup.on} />
    </>
  );
};

const EditThreadContentModal = ({active, setActive, setPopup, thread}) => {
  const {updateThread} = useThreads();
  const {
    state: {id: user_id},
  } = useAuth();

  const formik = useFormik({
    initialValues: {
      id: thread.id,
      owner_id: thread.owner_id,
      status: thread?.status,
      security_level: thread?.security_level,
      members: thread?.members,
      media: thread?.media,
      title: thread?.title,
      content: thread?.content,
      parent: thread?.parent,
      expiration: thread?.expiration,
      logo: thread?.logo,
      tags: thread?.tags,
      type: thread?.type,
    },
    validationSchema: Yup.object({
      title: Yup.string().required('Title is required'),
    }),

    onSubmit: async (values, helpers) => {
      try {
        const {id} = thread;
        const update = {
          id,
          ...values,
        };

        const {success, error} = await updateThread(update);

        if (success) {
          track('thread_updated', {update, user_id});
          helpers.resetForm();
          setActive(false);
          setPopup({
            on: true,
            message: 'Success!',
          });
        }
        if (error) {
          setPopup({
            on: true,
            message: error,
          });
        }
      } catch (err) {
        console.log(err);
        helpers.setStatus({success: false});
        helpers.setErrors({submit: err.message});
        helpers.setSubmitting(false);
      }
    },
  });

  return (
    <Modal active={active} setActive={setActive}>
      <UpdateThread formik={formik} />
    </Modal>
  );
};
export const EditThread = ({active, setActive, setPopup, thread}) => {
  const {updateThread} = useThreads();

  const formik = useFormik({
    initialValues: {
      id: thread.id,
      owner_id: thread.owner_id,
      status: thread.status,
      media: thread.media,
      title: thread.title,
      content: thread.content,
      parent: thread.parent,
      expiration: thread.expiration,
    },
    validationSchema: Yup.object({
      title: Yup.string().required('Title is required'),
      content: Yup.string().required('Content is required'),
    }),

    onSubmit: async (values, helpers) => {
      try {
        const {id} = thread;
        const update = {
          id,
          ...values,
        };

        const {success, error} = await updateThread(update);

        if (success) {
          helpers.resetForm();

          setPopup({
            on: true,
            message: 'Success!',
          });
        }
        if (error) {
          setPopup({
            on: true,
            message: error,
          });
        }
      } catch (err) {
        console.log(err);
        helpers.setStatus({success: false});
        helpers.setErrors({submit: err.message});
        helpers.setSubmitting(false);
      }
    },
  });

  return <UpdateThread formik={formik} isSmall={true} />;
};

export const EditContentModal = ({active, setActive, content}) => {
  const [popup, setPopup] = useState({on: false, message: ''});

  return (
    <>
      {active ? (
        <EditContentContent
          active={active}
          setActive={setActive}
          setPopup={setPopup}
          content={content}
        />
      ) : null}
      <ActionPopup message={popup.message} setOff={setPopup} on={popup.on} />
    </>
  );
};

const EditContentContent = ({active, setActive, setPopup, content}) => {
  const {updateContent} = useThreads();

  const [files, setFiles] = useState(content.media ?? []);

  const formik = useFormik({
    initialValues: {
      content: content.content ?? '',
      media: content.media ?? [],
    },
    validationSchema: Yup.object({
      content: Yup.string().required('Content is required'),
    }),

    onSubmit: async (values, helpers) => {
      try {
        const {id} = content;
        const update = {
          id,
          ...values,
          updated: dateToTimestamp(),
        };

        if (files?.length) {
          update.media = files;
        }

        const {success, error} = await updateContent(update);

        if (success) {
          helpers.resetForm();
          setFiles([]);
          setActive(false);
          setPopup({
            on: true,
            message: 'Success!',
          });
        }
        if (error) {
          setPopup({
            on: true,
            message: error,
          });
        }
      } catch (err) {
        console.log(err);
        helpers.setStatus({success: false});
        helpers.setErrors({submit: err.message});
        helpers.setSubmitting(false);
      }
    },
  });

  return (
    <Modal active={active} setActive={setActive}>
      <h4>Update Content</h4>
      <MultilineFormikTextfield
        formik={formik}
        name={'content'}
        header="Content"
      />

      <MultipleFileUploader
        files={files}
        setFiles={setFiles}
        types={[
          ...constants.image_mime_types,
          ...constants.video_mime_types,
          ...constants.document_mime_types,
          ...constants.compressed_mime_types,
          ...constants.audio_mime_types,
        ]}
        limit={constants.file_20mb}
      />

      <div className="flex justify-center">
        <button onClick={formik.handleSubmit} disabled={formik.isSubmitting}>
          Save
        </button>
      </div>
    </Modal>
  );
};

export const EditThreadPermissionsModal = ({active, setActive, thread}) => {
  const [popup, setPopup] = useState({on: false, message: ''});

  return (
    <>
      {active ? (
        <EditPermissionsContent
          active={active}
          setActive={setActive}
          setPopup={setPopup}
          thread={thread}
        />
      ) : null}
      <ActionPopup message={popup.message} setOff={setPopup} on={popup.on} />
    </>
  );
};

const EditPermissionsContent = ({active, setActive, setPopup, thread}) => {
  const {prettyName} = useStringFormatter();
  const {
    state: {id: user_uid, profile},
  } = useAuth();
  const {updateThread} = useThreads();

  const formik = useFormik({
    initialValues: {
      permissions: thread?.permissions ?? [],
    },
    validationSchema: Yup.object({}),
    onSubmit: async (values, helpers) => {
      try {
        const {permissions} = values;
        const {id} = thread;
        const members = permissions.map(item => item.user_id);
        const update = {
          id,
          permissions,
          members,
        };
        const {success, error} = await updateThread(update);
        if (success) {
          const new_members = members?.filter(mem => {
            if (!thread?.members?.includes(mem)) {
              return true;
            }
            return false;
          });
          if (new_members?.length) {
            const {success, error} = await sendThreadInvitations({
              emails: new_members,
              thread,
              profile: prettyName(profile),
            });
          }
          helpers.resetForm();
          setActive(false);
          setPopup({
            on: true,
            message: 'Success!',
          });
        }
        if (error) {
          setPopup({
            on: true,
            message: error,
          });
        }
      } catch (err) {
        console.log(err);
        helpers.setStatus({success: false});
        helpers.setErrors({submit: err.message});
        helpers.setSubmitting(false);
      }
    },
  });

  // Check if form should be disabled
  const isButtonDisabled = !formik.dirty || formik.isSubmitting;

  return (
    <Modal active={active} setActive={setActive}>
      <EditMembersContent formik={formik} thread={thread} />
      <br />
      <div className="flex justify-center">
        <button
          className={`basic-button ${
            isButtonDisabled ? 'opacity-50 cursor-not-allowed' : ''
          }`}
          onClick={formik.handleSubmit}
          disabled={isButtonDisabled}
          title={!formik.dirty ? 'No changes to save' : ''}>
          Save
        </button>
      </div>
    </Modal>
  );
};

export const EditMembersContent = ({formik, thread}) => {
  const {
    state: {id: user_uid},
  } = useAuth();
  const {updateThread} = useThreads();

  const [edit, setEdit] = useState(null);

  const {id, permissions} = thread || {};

  return (
    <span className="pretty-form-group">
      {edit ? (
        <div>
          <h4>Edit {edit.user_id}</h4>
          <div className="flex-column justify-between align-center">
            <PermissionRoleInput permission={edit} setPermission={setEdit} />
          </div>
          <br />
          <div className="flex-column align-center">
            <div className="flex-row justify-center">
              <button
                className="basic-button-secondary"
                onClick={() => {
                  setEdit(null);
                }}>
                Cancel
              </button>
              <button
                className="basic-button"
                onClick={async () => {
                  const updated_permissions = permissions.map(item => {
                    if (item.user_id === edit.user_id) {
                      return {...item, ...edit, updated: dateToTimestamp()};
                    }
                    return item;
                  });
                  const update = {
                    id,
                    permissions: updated_permissions,
                  };
                  formik.setFieldValue('permissions', updated_permissions);

                  await updateThread(update);
                  setEdit(null);
                }}>
                Save
              </button>
            </div>
            <span className="padding-top8" />
            <button
              className="basic-button-secondary-critical"
              onClick={async () => {
                const {user_id} = edit;
                const filtered_permissions = permissions?.filter(
                  user => user.user_id !== user_id,
                );
                const filtered_members = filtered_permissions.map(
                  item => item.user_id,
                );
                const update = {
                  id,
                  permissions: filtered_permissions,
                  members: filtered_members,
                };
                await updateThread(update);
                formik.setFieldValue('permissions', filtered_permissions);
                track('thread_permissions_edited', {
                  update,
                  user_id: user_uid,
                });
                setEdit(null);
              }}>
              Remove User
            </button>
          </div>
        </div>
      ) : (
        <div>
          <label>Edit Thread Members</label>
          <BulkEmailUploader formik={formik} />
          <PermissionsInput
            formik={formik}
            name="permissions"
            header="Thread Members"
          />
          <br />
          <p className="text-bold">Thread Members</p>
          <RenderMemberContent
            thread={formik.values}
            button={{
              text: 'Edit',
              onClick: item => {
                setEdit(item);
              },
            }}
          />
        </div>
      )}
    </span>
  );
};

export const ScheduleContentModal = ({active, setActive, formik}) => {
  return (
    <>
      {active ? (
        <ScheduleContentContent
          active={active}
          setActive={setActive}
          formik={formik}
        />
      ) : null}
    </>
  );
};

const ScheduleContentContent = ({active, setActive, formik}) => {
  const [chosenDate, setDate] = useState(null);

  const now = new Date();
  const past = chosenDate && chosenDate < now;
  const formattedDate = chosenDate?.toLocaleDateString('en-US', {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
  });
  const formattedTime = chosenDate?.toLocaleTimeString('en-US', {
    hour: 'numeric',
    minute: '2-digit',
    hour12: true,
  });

  return (
    <Modal active={active} setActive={setActive}>
      <span className="pretty-form-group">
        <h4>Schedule your message</h4>
        <p className="text-secondary">
          Type anything like 'August 5th at 1pm EST' or '2 weeks' or 'today at
          5pm ET'
        </p>
        <br />
        <DateTimeInput date={chosenDate} setDate={setDate} />
        <br />
        {/* <FormikCheckbox
          label="Remind me before sending"
          formik={formik}
          name="reminder"
        /> */}
        <div className="flex justify-center padding-top8">
          <button
            className="basic-button"
            disabled={!chosenDate || past || formik.isSubmitting}
            onClick={async () => {
              formik.setFieldValue('status', 'scheduled');
              formik.setFieldValue('scheduled', dateToTimestamp(chosenDate));
              await formik.handleSubmit();
              setActive(false);
            }}>
            Schedule{' '}
            {formattedDate || formattedTime ? (
              <strong>
                for {formattedDate} at {formattedTime}
              </strong>
            ) : null}
          </button>
        </div>
      </span>
    </Modal>
  );
};

export const BreakoutThreadModal = ({active, setActive, content}) => {
  const navigate = useNavigate();
  const {prettyName} = useStringFormatter();
  const {
    state: {id: userId, profiles, profile},
  } = useAuth();
  const {
    state: {threads},
    createThread,
    updateContent,
  } = useThreads();

  const {id: content_id, thread_id, owner_id, children} = content;
  const thread = threads?.[thread_id] ?? {};
  const {permissions, title, logo, security_level} = thread;

  const now = dateToTimestamp();

  const formik = useFormik({
    initialValues: {
      id: v4(),
      owner_id: userId,
      status: 'active',
      type: 'sub-thread',
      security_level: security_level,
      permissions,
      media: [],
      tags: [],
      title: `Break out from ${prettyName(profiles?.[owner_id])}'s comment`,
      content: '',
      logo: '',
      parent: content.id,
      expiration: null,
      ai_assisted: false,
    },
    validationSchema: Yup.object({
      title: Yup.string().required('Title is required'),
    }),

    onSubmit: async (values, helpers) => {
      try {
        const {permissions} = values;

        const members = permissions.map(permission => permission.user_id);
        const thread = {
          ...values,
          members,
          created: now,
          updated: now,
        };

        const {success, error} = await createThread(thread);
        if (success) {
          track('thread_created', {thread, user_id: userId});
          const thread_content_update = {
            id: content_id,
            children: children?.includes(thread?.id)
              ? children
              : [...(children || []), thread?.id],
          };

          const {success, error} = await updateContent(thread_content_update);

          helpers.resetForm();
          navigate(`/threads/${thread?.id}`);
          const other_members = members.filter(mem => mem !== userId);
          if (other_members?.length) {
            const {success, error} = await sendThreadInvitations({
              emails: other_members,
              thread,
              profile: prettyName(profile),
            });
          }
        }
        if (error) {
          // TODO
        }
      } catch (err) {
        helpers.setStatus({success: false});
        helpers.setErrors({submit: err.message});
        helpers.setSubmitting(false);
      }
    },
  });

  return (
    <Modal active={active} setActive={setActive}>
      <span className="create-breakout-thread__header">
        <h2>
          Break out from {logo} {title} <GoGitBranch color="grey" />
        </h2>
      </span>
      <TypeToggle formik={formik} />
      {/* <label className="">Break Out Logo</label>
      <EmojiInput
        emoji={formik?.values?.logo}
        setEmoji={emoji => {
          formik.setFieldValue('logo', emoji);
        }}
        setShowPicker={setEmoji}
        showPicker={emojiPicker}
      /> */}
      <div className="create-thread__field pretty-form-group ">
        <FormikTextfield
          formik={formik}
          name={'title'}
          header="Breakout Title"
        />
      </div>
      <div className="create-thread__field pretty-form-group">
        <MultilineFormikTextfield
          formik={formik}
          name={'content'}
          header="Breakout Description"
          rows={4}
        />
      </div>

      <div className="create-thread__submit-container">
        <button
          className="create-thread__submit-button"
          onClick={formik.handleSubmit}
          disabled={formik.isSubmitting}>
          Create Breakout Thread
        </button>
      </div>
      <br />
      <span className="card">
        <p className="text-bold">Add new members to the breakout thread</p>
        <span className="pretty-form-group">
          <PermissionsInput
            formik={formik}
            name="permissions"
            header="Thread Members"
          />
        </span>
        <br />

        <p className="text-bold">
          Following members will be added to the breakout thread
        </p>

        <RenderMemberContent
          thread={formik.values}
          button={{
            text: 'Remove',
            onClick: async item => {
              const {user_id} = item;
              const {permissions} = formik?.values ?? {};
              const filtered_permissions = permissions?.filter(
                user => user.user_id !== user_id,
              );
              formik.setFieldValue('permissions', filtered_permissions);
            },
          }}
        />
      </span>
    </Modal>
  );
};

export const EditSpecialContentModal = ({active, setActive, content}) => {
  const [popup, setPopup] = useState({on: false, message: ''});

  return (
    <>
      {active ? (
        <EditSpecialContent
          active={active}
          setActive={setActive}
          setPopup={setPopup}
          content={content}
        />
      ) : null}
      <ActionPopup message={popup.message} setOff={setPopup} on={popup.on} />
    </>
  );
};

const EditSpecialContent = ({active, setActive, setPopup, content}) => {
  const {capitalize, prettyName} = useStringFormatter();
  const {
    state: {threads},
    updateContent,
  } = useThreads();
  const {
    state: {profiles},
  } = useAuth();
  const {thread_id} = content;

  const {members} = threads?.[thread_id] ?? {};

  const users = members?.map(email => {
    const pretty = prettyName(profiles?.[email] ?? {email: email, id: email});
    return {name: pretty, email};
  });

  const {type} = content;

  // In content-input-field.js
  const editor = useCreateBlockNote(
    setupBlocks({
      initialContent: JSON.parse(content?.content ?? ''),
      schema: schema,
      trailingBlock: false,
    }),
  );

  const formik = useFormik({
    initialValues: {
      id: content.id ?? v4(),
      thread_id: content.thread_id ?? null,
      content: content?.content ?? '',
      content_html: content?.content_html ?? '',
      mentions: content?.mentions ?? [],
      dates: content?.dates ?? [],
      tasks: content?.tasks ?? [],
      media: content?.media ?? [],
      metrics: content?.metrics ?? [],
      choices: content?.choices ?? [],
    },
    validationSchema: Yup.object({
      content: Yup.string().required('Content is required'),
    }),

    onSubmit: async (values, helpers) => {
      try {
        const {id} = content;
        const update = {
          id,
          ...values,
          updated: dateToTimestamp(),
        };

        const {success, error} = await updateContent(update);

        if (success) {
          helpers.resetForm();

          setActive(false);
          setPopup({
            on: true,
            message: 'Success!',
          });
        }
        if (error) {
          setPopup({
            on: true,
            message: error,
          });
        }
      } catch (err) {
        console.log(err);
        helpers.setStatus({success: false});
        helpers.setErrors({submit: err.message});
        helpers.setSubmitting(false);
      }
    },
  });

  const getMentionMenuItems = editor => {
    return users.map(user => ({
      title: user.name,
      onItemClick: () => {
        editor.insertInlineContent([
          {
            type: 'mention',
            props: {
              user: user.name,
              email: user.email,
            },
          },
          ' ',
        ]);
      },
    }));
  };

  return (
    <Modal active={active} setActive={setActive}>
      <h4>Update {capitalize(type)}</h4>
      <span className="pretty-form-group">
        <label>{titles?.[type] ?? 'Content'}</label>
        <BlockNoteView
          editor={editor}
          theme={textfieldTheme}
          onChange={async () => {
            const stringy = JSON.stringify(editor.document);
            const content_html = await editor.blocksToHTMLLossy();
            const mentions = [];
            editor.forEachBlock(block => {
              const {content} = block;
              if (!Array.isArray(content)) {
                return;
              }

              content.forEach(item => {
                const {type, props} = item;
                if (item.type === 'mention' && !mentions?.includes(props.email))
                  mentions.push(props.email);
              });
            });
            formik.setFieldValue('mentions', mentions);
            formik.setFieldValue('content_html', content_html);
            formik.setFieldValue('content', stringy);
          }}>
          <SuggestionMenuController
            triggerCharacter={'@'}
            getItems={async query =>
              filterSuggestionItems(getMentionMenuItems(editor), query)
            }
          />
        </BlockNoteView>
        <SpecializedContentInputs formik={formik} type={type} edit={true} />
      </span>

      <div className="flex justify-center padding-top8">
        <button
          type="submit"
          className="special-comment-modal__submit"
          onClick={formik.handleSubmit}
          disabled={formik.isSubmitting}>
          Save
        </button>
      </div>
    </Modal>
  );
};

export const CreatePollModal = ({active, setActive, formik}) => {
  const [popup, setPopup] = useState({on: false, message: ''});

  return (
    <>
      {active ? (
        <PollContent
          active={active}
          setActive={setActive}
          setPopup={setPopup}
          formik={formik}
        />
      ) : null}
      <ActionPopup message={popup.message} setOff={setPopup} on={popup.on} />
    </>
  );
};

const PollContent = ({active, setActive, setPopup, formik}) => {
  const {type} = formik.values;

  const [chosenDate, setDate] = useState(null);

  const now = new Date();
  const past = chosenDate && chosenDate < now;

  return (
    <Modal active={active} setActive={setActive}>
      <h4>Create Poll</h4>
      <div className="flex-row">
        <button
          onClick={() => {
            formik.setFieldValue('type', 'open_poll');
          }}>
          free form
        </button>
        <button
          onClick={() => {
            formik.setFieldValue('type', 'choice_poll');
          }}>
          choice
        </button>
      </div>
      {type === 'choice_poll' && (
        <ArrayFormikTextfield
          formik={formik}
          header="Enter Poll Options"
          name="choices"
          items={[
            {value: 'not-started', key: 'Not Started'},
            {value: 'in-progress', key: 'In Progress'},
            {value: 'complete', key: 'Complete'},
            {value: 'later', key: 'Later'},
          ]}
        />
      )}
      <DateTimeInput date={chosenDate} setDate={setDate} />
      <div className="flex justify-center">
        <button
          disabled={!chosenDate || past || formik.isSubmitting}
          onClick={() => {
            const expiration = dateToTimestamp(chosenDate);
            formik.setFieldValue('expiration', expiration);
            formik.handleSubmit();
            setActive(false);
          }}>
          Save
        </button>
      </div>
    </Modal>
  );
};

export const CreateSpecialCommentModal = ({active, setActive, id}) => {
  const [popup, setPopup] = useState({on: false, message: ''});

  return (
    <>
      {active ? (
        <CreateSpecialCommentContent
          active={active}
          setActive={setActive}
          id={id}
          setPopup
        />
      ) : null}
      <ActionPopup message={popup.message} setOff={setPopup} on={popup.on} />
    </>
  );
};

const CreateSpecialCommentContent = ({active, setActive, id, setPopup}) => {
  const {
    state: {id: user_id, profile},
  } = useAuth();
  const [files, setFiles] = useState([]);
  const {
    state: {threads, thread_subs},
    createThreadContent,
    updateThreadSubscription,
    createThreadSubscription,
  } = useThreads();

  const editor = useCreateBlockNote(
    setupBlocks({
      schema: schema,
      trailingBlock: false,
    }),
  );

  const formik = useFormik({
    initialValues: {
      owner_id: user_id,
      thread_id: id,
      type: active,
      status: 'active',
      priority: false,
      content: '',
      content_html: '',
      version: 'block',
      media: [],
      mentions: [],
      responses: [],
      choices: [],
      parent: null,
      scheduled: null,
      reminder: false,
      expiration: null,
      dates: [
        {name: 'start', date: null},
        {name: 'due', date: null},
        {name: 'completed', date: null},
      ],
      metrics: [],
      tasks: [],
      sub_type: null,
    },
    validationSchema: Yup.object().shape({
      content: Yup.string().required('Content is required'),
      type: Yup.string().required('Type is required'),
      mentions: Yup.array(),
      media: Yup.array(),
      dates: Yup.array(),
      metrics: Yup.array(),
      sub_type: Yup.string().nullable(),
      choices: Yup.array()
        .of(Yup.string())
        .test('unique', 'Choices must be unique', function (value) {
          return value && value.length === new Set(value).size;
        }),
    }),
    onSubmit: async (values, helpers) => {
      try {
        const {type, sub_type, scheduled, reminder, mentions, tasks} = values;

        const {merged, duplicates, unique} = mergeArrays(
          mentions,
          tasks?.flatMap(task => task.assignee_ids),
        );

        const content = {
          id: v4(),
          ...values,
          thread_id: id,
          mentions: merged,
        };

        if (files?.length) {
          content.media = files;
        }

        if (reminder) {
          content.reminder = scheduled - 3600;
          content.created = scheduled;
          content.updated = scheduled;
        } else {
          const now = dateToTimestamp();
          content.reminder = null;
          content.created = now;
          content.updated = now;
        }

        // Update metrics with created and updated timestamps
        content.metrics = content.metrics.map(metric => ({
          ...metric,
          created: metric.created || dateToTimestamp(),
          updated: dateToTimestamp(),
        }));

        const {success, error} = await createThreadContent(content);

        if (success) {
          helpers.resetForm();
          setFiles([]);
          setActive(false);

          if (merged?.length) {
            await sendPriorityThreadContent({
              emails: merged,
              content,
              profile,
              thread: threads?.[id],
            });
          }

          const sub = thread_subs?.[id] ?? null;
          if (sub) {
            const update = {
              id: sub.id,
              thread_id: id,
              last_read: dateToTimestamp(),
            };
            updateThreadSubscription(update);
          } else {
            createThreadSubscription({
              id: v4(),
              user_id,
              thread_id: id,
              status: null,
              last_read: dateToTimestamp(),
            });
          }
        }

        if (error) {
          setPopup({on: true, message: 'Error creating special comment'});
        }
      } catch (err) {
        helpers.setStatus({success: false});
        helpers.setErrors({submit: err.message});
        helpers.setSubmitting(false);
      }
    },
  });

  return (
    <>
      <Modal active={active} setActive={setActive}>
        <SpecialCommentContent
          active={active}
          editor={editor}
          formik={formik}
        />
      </Modal>
    </>
  );
};

const SpecialCommentContent = ({active, editor, formik}) => {
  const {prettyName} = useStringFormatter();
  const {
    state: {threads},
  } = useThreads();
  const {
    state: {profiles},
  } = useAuth();
  const {thread_id} = formik?.values;

  const {members} = threads?.[thread_id] ?? {};

  const users = members?.map(email => {
    const pretty = prettyName(profiles?.[email] ?? {email: email, id: email});
    return {name: pretty, email};
  });

  const [activeTab, setActiveTab] = useState(active);

  useEffect(() => {
    if (active) {
      formik.resetForm();
      setActiveTab(active);
      formik.setFieldValue('type', active);
    }
  }, [active]);

  const handleTabChange = tabName => {
    setActiveTab(tabName);
    formik.setFieldValue('type', tabName);
    formik.setFieldValue('content', '');
    formik.setFieldValue('dates', [{}, {}]);
    formik.setFieldValue('metrics', []);
    formik.setFieldValue('tasks', []);
  };

  const getMentionMenuItems = editor => {
    return users.map(user => ({
      title: user.name,
      onItemClick: () => {
        editor.insertInlineContent([
          {
            type: 'mention',
            props: {
              user: user.name,
              email: user.email,
            },
          },
          ' ',
        ]);
      },
    }));
  };

  return (
    <div>
      <div className="flex-column padding-bottom8">
        <h4>
          Create a {activeTab.replace('_', ' ')}{' '}
          {activeTab === 'task' ? 'group' : ''}
        </h4>
        <p className="text-secondary">
          Choose the type of post below. This will create a special post in the
          thread upon saving.
        </p>
      </div>
      <div className="special-comment-modal__tabs">
        <button
          className={`special-comment-modal__tab ${
            activeTab === 'decision_point' ? 'active' : ''
          }`}
          onClick={() => handleTabChange('decision_point')}>
          <MdLabelImportantOutline />
        </button>
        {/* <button
          className={`special-comment-modal__tab ${
            activeTab === 'learning' ? 'active' : ''
          }`}
          onClick={() => handleTabChange('learning')}>
          <MdLabelImportantOutline />
        </button> */}
        <button
          className={`special-comment-modal__tab ${
            activeTab === 'milestone' ? 'active' : ''
          }`}
          onClick={() => handleTabChange('milestone')}>
          <RxSewingPinFilled />
        </button>
        <button
          className={`special-comment-modal__tab ${
            activeTab === 'question' ? 'active' : ''
          }`}
          onClick={() => handleTabChange('question')}>
          <RxQuestionMarkCircled />
        </button>
        <button
          className={`special-comment-modal__tab ${
            activeTab === 'poll' ? 'active' : ''
          }`}
          onClick={() => handleTabChange('poll')}>
          <BiPoll />
        </button>
        <button
          className={`special-comment-modal__tab ${
            activeTab === 'form' ? 'active' : ''
          }`}
          onClick={() => handleTabChange('form')}>
          <HiOutlineDocumentText />
        </button>
        <button
          className={`special-comment-modal__tab ${
            activeTab === 'task' ? 'active' : ''
          }`}
          onClick={() => handleTabChange('task')}>
          <RxCheckbox />
        </button>
        {/* <button
          className={`special-comment-modal__tab ${
            activeTab === 'meeting' ? 'active' : ''
          }`}
          onClick={() => handleTabChange('meeting')}>
          <MdEventNote />
        </button> */}
      </div>
      <div className="special-comment-modal__form">
        <span className="pretty-form-group">
          <div className="margin-h16">
            <label>{titles?.[activeTab] ?? 'Content'}</label>
            <div className="pretty-container">
              <BlockNoteView
                editor={editor}
                theme={textfieldTheme}
                onChange={async () => {
                  const stringy = JSON.stringify(editor.document);
                  const content_html = await editor.blocksToHTMLLossy();
                  const mentions = [];
                  editor.forEachBlock(block => {
                    const {content} = block;
                    if (!Array.isArray(content)) {
                      return;
                    }

                    content.forEach(item => {
                      const {type, props} = item;
                      if (
                        item.type === 'mention' &&
                        !mentions?.includes(props.email)
                      )
                        mentions.push(props.email);
                    });
                  });
                  formik.setFieldValue('mentions', mentions);
                  formik.setFieldValue('content_html', content_html);
                  formik.setFieldValue('content', stringy);
                }}>
                <SuggestionMenuController
                  triggerCharacter={'@'}
                  getItems={async query =>
                    filterSuggestionItems(getMentionMenuItems(editor), query)
                  }
                />
              </BlockNoteView>
            </div>
          </div>
          <SpecializedContentInputs formik={formik} type={activeTab} />
        </span>
      </div>
      <div className="special-comment-modal__footer">
        <button
          type="submit"
          className="special-comment-modal__submit"
          onClick={formik.handleSubmit}
          disabled={formik.isSubmitting}>
          Save{' '}
          {activeTab.replace('_', ' ').charAt(0).toUpperCase() +
            activeTab.replace('_', ' ').slice(1)}
        </button>
      </div>
    </div>
  );
};

export const SpecializedContentInputs = ({formik, type, edit}) => {
  switch (type) {
    case 'form':
      return <FormTab formik={formik} />;
    case 'decision_point':
      return <DecisionPointTab formik={formik} />;
    case 'milestone':
      return <MilestoneTab formik={formik} />;
    case 'question':
      return <QuestionTab formik={formik} />;
    case 'poll':
      return <PollTab formik={formik} edit={edit} />;
    case 'task':
      return <TaskTab formik={formik} />;
    // case 'meeting':
    // return <MeetingTab formik={formik}  />;
    default:
      return null;
  }
};

const QuestionTab = ({formik}) => {
  const {prettyName} = useStringFormatter();
  const {
    state: {threads},
  } = useThreads();
  const {
    state: {profiles},
  } = useAuth();

  const {members} = threads?.[formik.values.thread_id] ?? {};

  const [chosenDate, setDate] = useState(null);

  useEffect(() => {
    if (chosenDate) {
      const now = new Date();
      const past = chosenDate && chosenDate < now;
      if (past) {
        formik.setFieldValue('expiration', null);
      } else {
        formik.setFieldValue('expiration', dateToTimestamp(chosenDate));
      }
    } else {
      formik.setFieldValue('expiration', null);
    }
  }, [chosenDate]);

  return (
    <>
      <DateTimeInput
        date={chosenDate}
        setDate={setDate}
        label="Expiration Date"
        future_only={true}
      />
      <ArraySearchableDropDown
        header="Notify Specific Members"
        setValues={val => {
          formik.setFieldValue('mentions', val);
        }}
        values={formik.values.mentions || []}
        items={members?.map(email => {
          const profile = profiles?.[email] ?? {id: email, email};
          return {value: email, key: prettyName(profile)};
        })}
      />
    </>
  );
};
const FormTab = ({formik}) => {
  const [editingItem, setEditingItem] = useState(null);
  const formItems = formik.values.form_items || [];

  const addFormItem = type => {
    const newItem = {
      id: v4(),
      order: formItems.length,
      type,
      question: '',
      required: false,
      choices: type === 'choice' || type === 'multi-choice' ? [''] : undefined,
      validation: {
        type: null,
        min: null,
        max: null,
        pattern: null,
        error_message: null,
      },
    };

    formik.setFieldValue('form_items', [...formItems, newItem]);
    setEditingItem(newItem);
  };

  const removeFormItem = itemId => {
    const updatedItems = formItems
      .filter(item => item.id !== itemId)
      .map((item, index) => ({...item, order: index}));

    formik.setFieldValue('form_items', updatedItems);
    if (editingItem?.id === itemId) {
      setEditingItem(null);
    }
  };

  const updateFormItem = (itemId, updates) => {
    const updatedItems = formItems.map(item =>
      item.id === itemId ? {...item, ...updates} : item,
    );
    formik.setFieldValue('form_items', updatedItems);
  };

  const moveFormItem = (itemId, direction) => {
    const currentIndex = formItems.findIndex(item => item.id === itemId);
    if (
      (direction === 'up' && currentIndex === 0) ||
      (direction === 'down' && currentIndex === formItems.length - 1)
    ) {
      return;
    }

    const newIndex = direction === 'up' ? currentIndex - 1 : currentIndex + 1;
    const updatedItems = [...formItems];
    const [movedItem] = updatedItems.splice(currentIndex, 1);
    updatedItems.splice(newIndex, 0, movedItem);

    const reorderedItems = updatedItems.map((item, index) => ({
      ...item,
      order: index,
    }));

    formik.setFieldValue('form_items', reorderedItems);
  };

  return (
    <div className="special-comment-modal__form">
      <div className="special-comment-modal__form-header">
        <span className="flex-column">
          <h3>Form Questions</h3>
          <p className="text-secondary">
            Click question to add to form. Be sure to press save afterwards.
          </p>
        </span>
        <div className="special-comment-modal__form-actions">
          <button
            className="special-comment-modal__add-button"
            onClick={() => addFormItem('text')}>
            <RxPlus /> Text Question
          </button>
          <button
            className="special-comment-modal__add-button"
            onClick={() => addFormItem('choice')}>
            <RxPlus /> Single-Choice Question
          </button>
          <button
            className="special-comment-modal__add-button"
            onClick={() => addFormItem('multi-choice')}>
            <RxPlus /> Multi-Choice Question
          </button>
        </div>
      </div>

      <div className="special-comment-modal__form-items">
        {formItems.map((item, index) => (
          <div key={item.id} className="special-comment-modal__form-item">
            {editingItem?.id === item.id ? (
              <FormItemEditor
                item={item}
                onSave={updates => {
                  updateFormItem(item.id, updates);
                  setEditingItem(null);
                }}
                onCancel={() => setEditingItem(null)}
              />
            ) : (
              <FormItemPreview
                item={item}
                onEdit={() => setEditingItem(item)}
                onRemove={() => removeFormItem(item.id)}
                onMove={direction => moveFormItem(item.id, direction)}
                isFirst={index === 0}
                isLast={index === formItems.length - 1}
              />
            )}
          </div>
        ))}
      </div>

      <div className="special-comment-modal__form-footer">
        <DateTimeInput
          date={
            formik.values.expiration
              ? new Date(formik.values.expiration * 1000)
              : null
          }
          setDate={date =>
            formik.setFieldValue(
              'expiration',
              date ? dateToTimestamp(date) : null,
            )
          }
          label="Form Expiration Date"
          future_only={true}
        />
      </div>
    </div>
  );
};
const FormItemPreview = ({item, onEdit, onRemove, onMove, isFirst, isLast}) => {
  const {type, question, required, choices} = item;

  return (
    <div className="form-item-preview">
      <div className="form-item-preview__header">
        <div className="form-item-preview__type">
          {type === 'choice' && '○'}
          {type === 'multi-choice' && '☐'}
          {type === 'text' && '▭'}
        </div>
        <div className="form-item-preview__question">
          {question || '(No question)'}
          {required && <span className="form-item-preview__required">*</span>}
        </div>
        <div className="form-item-preview__actions">
          {!isFirst && (
            <button
              onClick={() => onMove('up')}
              className="form-item-preview__action-button">
              ↑
            </button>
          )}
          {!isLast && (
            <button
              onClick={() => onMove('down')}
              className="form-item-preview__action-button">
              ↓
            </button>
          )}
          <button onClick={onEdit} className="form-item-preview__action-button">
            <RxPencil1 />
          </button>
          <button
            onClick={onRemove}
            className="form-item-preview__action-button">
            <RxTrash />
          </button>
        </div>
      </div>
      {(type === 'choice' || type === 'multi-choice') &&
        choices?.length > 0 && (
          <div className="form-item-preview__choices">
            {choices.map((choice, index) => (
              <div key={index} className="form-item-preview__choice">
                {type === 'choice' ? '○' : '☐'} {choice || '(Empty choice)'}
              </div>
            ))}
          </div>
        )}
    </div>
  );
};

const FormItemEditor = ({item, onSave, onCancel}) => {
  const [formData, setFormData] = useState({...item});

  const handleChoiceChange = (index, value) => {
    const newChoices = [...formData.choices];
    newChoices[index] = value;
    setFormData({...formData, choices: newChoices});
  };

  const addChoice = () => {
    setFormData({
      ...formData,
      choices: [...(formData.choices || []), ''],
    });
  };

  const removeChoice = index => {
    const newChoices = formData.choices.filter((_, i) => i !== index);
    setFormData({...formData, choices: newChoices});
  };

  const validationTypes = {
    text: ['email', 'url', 'phone', 'regex'],
    number: ['min', 'max', 'range'],
  };

  return (
    <div className="form-item-editor pretty-form-group">
      <div className="form-item-editor__field">
        <label>Question</label>
        <input
          type="text"
          value={formData.question}
          onChange={e => setFormData({...formData, question: e.target.value})}
          placeholder="Enter your question"
        />
      </div>

      <div className="form-item-editor__field">
        <label className="form-checkbox-label">
          <input
            type="checkbox"
            checked={formData.required}
            onChange={e =>
              setFormData({...formData, required: e.target.checked})
            }
          />
          Required?
        </label>
      </div>

      {(formData.type === 'choice' || formData.type === 'multi-choice') && (
        <div className="form-item-editor__choices">
          <label>Choices</label>
          {formData.choices?.map((choice, index) => (
            <div key={index} className="form-item-editor__choice">
              <input
                type="text"
                value={choice}
                onChange={e => handleChoiceChange(index, e.target.value)}
                placeholder={`Choice ${index + 1}`}
              />
              <button
                onClick={() => removeChoice(index)}
                className="form-item-editor__remove-choice">
                <RxTrash />
              </button>
            </div>
          ))}
          <button onClick={addChoice} className="form-item-editor__add-choice">
            <RxPlus /> Add Choice
          </button>
        </div>
      )}
      {/* 
      {formData.type === 'text' && (
        <div className="form-item-editor__validation ">
          <label>Validation</label>
          <select
            value={formData.validation?.type || ''}
            onChange={e =>
              setFormData({
                ...formData,
                validation: {
                  ...formData.validation,
                  type: e.target.value || null,
                },
              })
            }>
            <option value="">None</option>
            {validationTypes.text.map(type => (
              <option key={type} value={type}>
                {type}
              </option>
            ))}
          </select>

          {formData.validation?.type === 'regex' && (
            <input
              type="text"
              value={formData.validation?.pattern || ''}
              onChange={e =>
                setFormData({
                  ...formData,
                  validation: {...formData.validation, pattern: e.target.value},
                })
              }
              placeholder="Regular expression pattern"
            />
          )}
        </div>
      )}

      {formData.type === 'number' && (
        <div className="form-item-editor__validation">
          <label>Validation</label>
          <select
            value={formData.validation?.type || ''}
            onChange={e =>
              setFormData({
                ...formData,
                validation: {
                  ...formData.validation,
                  type: e.target.value || null,
                },
              })
            }>
            <option value="">None</option>
            {validationTypes.number.map(type => (
              <option key={type} value={type}>
                {type}
              </option>
            ))}
          </select>

          {(formData.validation?.type === 'min' ||
            formData.validation?.type === 'range') && (
            <input
              type="number"
              value={formData.validation?.min || ''}
              onChange={e =>
                setFormData({
                  ...formData,
                  validation: {
                    ...formData.validation,
                    min: parseFloat(e.target.value),
                  },
                })
              }
              placeholder="Minimum value"
            />
          )}

          {(formData.validation?.type === 'max' ||
            formData.validation?.type === 'range') && (
            <input
              type="number"
              value={formData.validation?.max || ''}
              onChange={e =>
                setFormData({
                  ...formData,
                  validation: {
                    ...formData.validation,
                    max: parseFloat(e.target.value),
                  },
                })
              }
              placeholder="Maximum value"
            />
          )}
        </div>
      )} */}

      <div className="form-item-editor__actions">
        <button onClick={() => onCancel()} className="form-item-editor__cancel">
          Cancel
        </button>
        <button
          onClick={() => onSave(formData)}
          className="form-item-editor__save"
          disabled={
            !formData.question.trim() ||
            ((formData.type === 'choice' || formData.type === 'multi-choice') &&
              (!formData.choices?.length ||
                formData.choices.some(c => !c.trim())))
          }>
          Save Question
        </button>
      </div>
    </div>
  );
};
const TaskTab = ({formik}) => {
  const {prettyName} = useStringFormatter();
  const {
    state: {threads},
  } = useThreads();
  const {
    state: {profiles},
  } = useAuth();

  const {tasks} = formik.values || {};
  const {members} = threads?.[formik.values.thread_id] ?? {};

  const [chosenDate, setDate] = useState(null);
  const [form, setForm] = useState({
    id: v4(),
    description: '',
    status: 'todo',
    assignee_ids: [],
    due_date: null,
  });

  const editing = tasks?.find(t => t.id === form.id);

  const handleTaskUpdate = (id, updates) => {
    if (id) {
      const updatedTasks = tasks.map(task =>
        task.id === id ? {...task, ...updates} : task,
      );
      formik.setFieldValue('tasks', updatedTasks);
    } else {
      formik.setFieldValue('tasks', updates.tasks);
    }
  };

  useEffect(() => {
    if (chosenDate) {
      const now = new Date();
      const past = chosenDate && chosenDate < now;
      if (past) {
        setForm({...form, due_date: null});
      } else {
        setForm({...form, due_date: dateToTimestamp(chosenDate)});
      }
    } else {
      setForm({...form, due_date: null});
    }
  }, [chosenDate]);

  return (
    <div className="special-comment-modal__form">
      <div className="special-comment-modal__tasks">
        <h3>Add New Task</h3>
        <p className="text-secondary">
          Delete and Re-Add a Task if Date or Assignees are Incorrect
        </p>
        <div className="padding-top8" />
        <div className="special-comment-modal__task">
          <Textfield
            value={form.description}
            onChange={e => setForm({...form, description: e.target.value})}
            header="Task Description"
            placeholder="E.g. Complete report..."
          />
          <DateTimeInput
            label="Due Date"
            date={chosenDate}
            setDate={setDate}
            future_only={true}
          />
          <ArraySearchableDropDown
            header="Assignees"
            setValues={val => {
              setForm({...form, assignee_ids: val});
            }}
            values={form.assignee_ids}
            items={members?.map(email => {
              const profile = profiles?.[email] ?? {id: email, email};
              return {value: email, key: prettyName(profile)};
            })}
          />
          <button
            className="special-comment-modal__add-button"
            disabled={!form.description || !form?.assignee_ids?.length}
            onClick={() => {
              if (editing) {
                handleTaskUpdate(form.id, {
                  ...form,
                  updated: dateToTimestamp(),
                });
              } else {
                formik.setFieldValue('tasks', [
                  ...tasks,
                  {...form, updated: dateToTimestamp()},
                ]);
              }
              setForm({
                ...form,
                id: v4(),
                description: '',
                status: 'todo',
                assignee_ids: [],
              });
            }}>
            {editing ? 'Update Task' : 'Add Task'}
          </button>
        </div>

        <CreateTaskDisplay
          tasks={tasks}
          onUpdate={handleTaskUpdate}
          editable={true}
          profiles={profiles}
        />
      </div>
    </div>
  );
};

const MetricInput = ({metric, index, formik, onRemove}) => {
  const prefix = `metrics[${index}]`;

  return (
    <div className="special-comment-modal__metric">
      <div className="special-comment-modal__metric-header">
        <h4>Metric {index + 1}</h4>
        <button
          className="special-comment-modal__remove-button"
          onClick={() => onRemove(index)}>
          Remove
        </button>
      </div>
      <FormikTextfield
        formik={formik}
        name={`${prefix}.description`}
        header="Description"
        placeholder="What is the metric measuring?"
      />
      <FormikTextfield
        formik={formik}
        name={`${prefix}.goal`}
        header="Metric Goal"
        placeholder="e.g., 5, 100, etc. "
      />
      <FormikTextfield
        formik={formik}
        name={`${prefix}.progress`}
        header="Progress"
        placeholder="e.g., 50"
      />

      <FormikTextfield
        formik={formik}
        name={`${prefix}.unit`}
        header="Unit"
        placeholder="e.g., %, $, users"
      />
    </div>
  );
};

const MilestoneTab = ({formik}) => {
  const {prettyName} = useStringFormatter();
  const {
    state: {threads},
  } = useThreads();
  const {
    state: {profiles},
  } = useAuth();
  const {members} = threads?.[formik.values.thread_id] ?? {};
  const [startDate, setStartDate] = useState(() => {
    const startTimestamp = formik.values?.dates?.find(
      d => d.name === 'start',
    )?.date;
    return startTimestamp ? new Date(startTimestamp * 1000) : null;
  });

  const [endDate, setEndDate] = useState(() => {
    const endTimestamp = formik.values?.dates?.find(
      d => d.name === 'due',
    )?.date;
    return endTimestamp ? new Date(endTimestamp * 1000) : null;
  });

  const addMetric = () => {
    const metrics = [...formik.values.metrics];
    metrics.push({
      goal: '',
      progress: '',
      description: '',
      unit: '',
      created: dateToTimestamp(),
      updated: dateToTimestamp(),
    });
    formik.setFieldValue('metrics', metrics);
  };

  const removeMetric = index => {
    const metrics = [...formik.values.metrics];
    metrics.splice(index, 1);
    formik.setFieldValue('metrics', metrics);
  };

  useEffect(() => {
    if (startDate) {
      formik.setFieldValue('dates[0].date', dateToTimestamp(startDate));
    } else {
      formik.setFieldValue('dates[0].date', null);
    }
  }, [startDate]);

  useEffect(() => {
    if (endDate) {
      formik.setFieldValue('dates[1].date', dateToTimestamp(endDate));
    } else {
      formik.setFieldValue('dates[1].date', null);
    }
  }, [endDate]);

  return (
    <div className="special-comment-modal__form">
      <DateTimeInput
        date={startDate}
        setDate={setStartDate}
        label="Start Date"
      />
      <DateTimeInput date={endDate} setDate={setEndDate} label="End Date" />

      <div className="special-comment-modal__metrics">
        <h3>Metrics</h3>
        {formik.values?.metrics?.map((metric, index) => (
          <MetricInput
            key={index}
            metric={metric}
            index={index}
            formik={formik}
            onRemove={removeMetric}
          />
        ))}
        <button
          className="special-comment-modal__add-button"
          onClick={addMetric}>
          Add Metric
        </button>
      </div>
      <ArraySearchableDropDown
        header="Assignees"
        setValues={val => {
          formik.setFieldValue('mentions', val);
        }}
        values={formik.values.mentions || []}
        items={members?.map(email => {
          const profile = profiles?.[email] ?? {id: email, email};
          return {value: email, key: prettyName(profile)};
        })}
      />
      {/* <FormikTextfield
        formik={formik}
        name="mentions"
        header="Mentions (comma-separated user IDs)"
      /> */}
    </div>
  );
};

const PollTab = ({formik, edit}) => {
  const {prettyName} = useStringFormatter();
  const {
    state: {threads},
  } = useThreads();
  const {
    state: {profiles},
  } = useAuth();

  const {members} = threads?.[formik.values.thread_id] ?? {};

  const [chosenDate, setDate] = useState(null);

  useEffect(() => {
    if (chosenDate) {
      const now = new Date();
      const past = chosenDate && chosenDate < now;
      if (past) {
        formik.setFieldValue('expiration', null);
      } else {
        formik.setFieldValue('expiration', dateToTimestamp(chosenDate));
      }
    } else {
      formik.setFieldValue('expiration', null);
    }
  }, [chosenDate]);

  return (
    <>
      <>
        <FormikSearchableDropDown
          formik={formik}
          name="sub_type"
          header="Poll Type"
          items={[
            // {value: 'open_poll', key: 'Free Form'},
            {value: 'single_choice_poll', key: 'Single Choice'},
            {value: 'multi_choice_poll', key: 'Multi Choice'},
          ]}
        />
        <ArrayFormikTextfield
          formik={formik}
          header="Poll Options (Press enter to add)"
          name="choices"
        />
      </>
      <DateTimeInput
        date={chosenDate}
        setDate={setDate}
        label="Expiration Date"
        future_only={true}
      />
      <ArraySearchableDropDown
        header="Notify Specific Members"
        setValues={val => {
          formik.setFieldValue('mentions', val);
        }}
        values={formik.values.mentions || []}
        items={members?.map(email => {
          const profile = profiles?.[email] ?? {id: email, email};
          return {value: email, key: prettyName(profile)};
        })}
      />
    </>
  );
};

const DecisionPointTab = ({formik}) => {
  const {prettyName} = useStringFormatter();
  const {
    state: {threads},
  } = useThreads();
  const {
    state: {profiles},
  } = useAuth();

  const {members} = threads?.[formik.values.thread_id] ?? {};

  const [chosenDate, setDate] = useState(null);

  useEffect(() => {
    if (chosenDate) {
      formik.setFieldValue('dates[2].date', dateToTimestamp(chosenDate));
    } else {
      formik.setFieldValue('dates[2].date', null);
    }
  }, [chosenDate]);

  return (
    <>
      <DateTimeInput
        date={chosenDate}
        setDate={setDate}
        label="Decision Date"
      />
      <ArraySearchableDropDown
        header="Assignees"
        setValues={val => {
          formik.setFieldValue('mentions', val);
        }}
        values={formik.values.mentions || []}
        items={members?.map(email => {
          const profile = profiles?.[email] ?? {id: email, email};
          return {value: email, key: prettyName(profile)};
        })}
      />
    </>
  );
};
