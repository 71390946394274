const dayjs = require('dayjs');
var Parser = require('../parser').Parser;
var ParsedResult = require('../../result').ParsedResult;
var util  = require('../../utils/EN');

var PATTERN = new RegExp('(\\W|^)' +
    '(this|next|last|past)\\s*' +
    '('+ util.INTEGER_WORDS_PATTERN + '|[0-9]+|few|half(?:\\s*an?)?)?\\s*' +
    '(seconds?|min(?:ute)?s?|hours?|days?|weeks?|months?|years?)(?=\\s*)' +
    '(?=\\W|$)', 'i'
);

var MODIFIER_WORD_GROUP = 2;
var MULTIPLIER_WORD_GROUP = 3;
var RELATIVE_WORD_GROUP = 4;

exports.Parser = function ENRelativeDateFormatParser(){
    Parser.apply(this, arguments);

    this.pattern = function() { return PATTERN; };

    this.extract = function(text, ref, match, opt){

        var index = match.index + match[1].length;
        var modifier = match[MODIFIER_WORD_GROUP].toLowerCase().match(/^next/) ? 1 : -1;
        var text  = match[0];
        text  = match[0].substr(match[1].length, match[0].length - match[1].length);

        var result = new ParsedResult({
            index: index,
            text: text,
            ref: ref
        });
        result.tags['ENRelativeDateFormatParser'] = true;

        var num = match[MULTIPLIER_WORD_GROUP] === undefined ? '' : match[3].toLowerCase();
        if (util.INTEGER_WORDS[num] !== undefined) {
            num = util.INTEGER_WORDS[num];
        } else if (num === ''){
            num = 1;
        } else if (num.match(/few/i)){
            num = 3;
        } else if (num.match(/half/i)) {
            num = 0.5;
        } else {
            num = parseInt(num);
        }

        num *= modifier;
        var date = dayjs(ref);

        if (match[MODIFIER_WORD_GROUP].toLowerCase().match(/^this/)) {

            if (match[MULTIPLIER_WORD_GROUP]) {
                return null;
            }

            if (match[RELATIVE_WORD_GROUP].match(/day|week|month|year/i)) {
                
                // This week
                if (match[RELATIVE_WORD_GROUP].match(/week/i)) {
                    date = date.add(-date.get('d'), 'd');
                    result.start.imply('day', date.date());
                    result.start.imply('month', date.month() + 1);
                    result.start.imply('year', date.year());
                } 
                
                // This month
                else if (match[RELATIVE_WORD_GROUP].match(/month/i)) {
                    date = date.add(-date.date() + 1, 'd');
                    result.start.imply('day', date.date());
                    result.start.assign('year', date.year());
                    result.start.assign('month', date.month() + 1);
                } 

                // This year
                else if (match[RELATIVE_WORD_GROUP].match(/year/i)) {
                    date = date.add(-date.date() + 1, 'd');
                    date = date.add(-date.month(), 'month');

                    result.start.imply('day', date.date());
                    result.start.imply('month', date.month() + 1);
                    result.start.assign('year', date.year());
                } 

                return result;
            }
        }
        
        if (match[RELATIVE_WORD_GROUP].match(/day|week|month|year/i)) {

            if (match[RELATIVE_WORD_GROUP].match(/day/i)) {
                date = date.add(num, 'd');
                result.start.assign('year', date.year());
                result.start.assign('month', date.month() + 1);
                result.start.assign('day', date.date());
            } else if (match[RELATIVE_WORD_GROUP].match(/week/i)) {
                date = date.add(num * 7, 'd');
                // We don't know the exact date for next/last week so we imply
                // them
                result.start.imply('day', date.date());
                result.start.imply('month', date.month() + 1);
                result.start.imply('year', date.year());
            } else if (match[RELATIVE_WORD_GROUP].match(/month/i)) {
                date = date.add(num, 'month');
                // We don't know the exact day for next/last month
                result.start.imply('day', date.date());
                result.start.assign('year', date.year());
                result.start.assign('month', date.month() + 1);
            } else if (match[RELATIVE_WORD_GROUP].match(/year/i)) {
                date = date.add(num, 'year');
                // We don't know the exact day for month on next/last year
                result.start.imply('day', date.date());
                result.start.imply('month', date.month() + 1);
                result.start.assign('year', date.year());
            }

            return result;
        }

        if (match[RELATIVE_WORD_GROUP].match(/hour/i)) {

            date = date.add(num, 'hour');
            result.start.imply('minute', date.minute());
            result.start.imply('second', date.second());

        } else if (match[RELATIVE_WORD_GROUP].match(/min/i)) {

            date = date.add(num, 'minute');
            result.start.assign('minute', date.minute());
            result.start.imply('second', date.second());

        } else if (match[RELATIVE_WORD_GROUP].match(/second/i)) {

            date = date.add(num, 'second');
            result.start.assign('second', date.second());
            result.start.assign('minute', date.minute());
        }

        result.start.assign('hour', date.hour());
        result.start.assign('year', date.year());
        result.start.assign('month', date.month() + 1);
        result.start.assign('day', date.date());
        return result;
    };
};
