import React, {useEffect, useState} from 'react';
import useCollaborator from '../../hooks/use-collaborator';
import useSearch from '../../hooks/use-search';
import useSorter from '../../hooks/use-sorter';
import useStringFormatter from '../../hooks/use-string-formatter';
import {LinkButton} from '../buttons/buttons';
import './styles.css';

export const SimpleTagDisplay = ({
  inner_tags,
  outer_tags,
  limit,
  searchable,
}) => {
  const {easySearch} = useSearch();
  const {prettyTag} = useStringFormatter();
  const {sortTags} = useCollaborator();

  const {inner_sorted, outer_sorted} = sortTags(inner_tags, outer_tags);

  const combined = [...new Set([...inner_sorted, ...outer_sorted])];

  return (
    <div className="flex-column justify-between ">
      <div className="flex-row-wrap">
        {combined.slice(0, limit || 12).map(tag => {
          return (
            <p
              key={tag}
              className="bubble-small"
              onClick={() => {
                if (searchable) {
                  easySearch(searchable, [tag], []);
                }
              }}>
              {prettyTag(tag)}
            </p>
          );
        })}
      </div>
    </div>
  );
};

export const TagDisplay = ({inner_tags, outer_tags}) => {
  const {tagToObject, sortTags} = useCollaborator();

  const [innerSorted, setInnerSorted] = useState({});
  const [outerSorted, setOuterSorted] = useState({});
  const [innerSimilar, setInnerSimilar] = useState({});
  const [outerSimilar, setOuterSimilar] = useState({});
  const [innerAll, setInnerAll] = useState(false);
  const [outerAll, setOuterAll] = useState(false);
  const [innerDisabled, setInnerDisabled] = useState(false);
  const [outerDisabled, setOuterDisabled] = useState(false);

  useEffect(() => {
    const inner_sort = {};
    const inner_sim = {};
    const outer_sort = {};
    const outer_sim = {};

    const {inner_similar, outer_similar} = sortTags(inner_tags, outer_tags);

    inner_tags?.forEach(tag => {
      const obj = tagToObject(tag);
      if (obj) {
        if (inner_sort[obj.type] === undefined) {
          inner_sort[obj.type] = [];
        }
        inner_sort[obj.type].push(tag);
      }
    });
    outer_tags?.forEach(tag => {
      const obj = tagToObject(tag);
      if (obj) {
        if (outer_sort[obj.type] === undefined) {
          outer_sort[obj.type] = [];
        }
        outer_sort[obj.type].push(tag);
      }
    });

    inner_similar.forEach(tag => {
      const obj = tagToObject(tag);
      if (obj) {
        if (inner_sim[obj.type] === undefined) {
          inner_sim[obj.type] = [];
        }
        inner_sim[obj.type].push(tag);
      }
    });

    outer_similar.forEach(tag => {
      const obj = tagToObject(tag);
      if (obj) {
        if (outer_sim[obj.type] === undefined) {
          outer_sim[obj.type] = [];
        }
        outer_sim[obj.type].push(tag);
      }
    });
    const inner_simi = !Object.keys(inner_sort).length;
    const outer_simi = !Object.keys(outer_sort).length;
    setInnerAll(inner_simi);
    setOuterAll(outer_simi);
    setInnerSorted(inner_sort);
    setInnerSimilar(inner_sim);
    setOuterSorted(outer_sort);
    setOuterSimilar(outer_sim);
  }, [inner_tags, outer_tags]);

  return (
    <div>
      <Tags
        all={innerAll}
        disabled={innerDisabled}
        setAll={setInnerAll}
        sorted={innerSorted}
        similar={innerSimilar}
        header="Resources They Have"
      />
      <Tags
        all={outerAll}
        disabled={outerDisabled}
        setAll={setOuterAll}
        sorted={outerSorted}
        similar={outerSimilar}
        header="Requests They Have"
      />
    </div>
  );
};
export const ProfileTagDisplay = ({inner_tags, outer_tags, type}) => {
  const {tagToObject, sortTags} = useCollaborator();

  const [innerSorted, setInnerSorted] = useState({});
  const [outerSorted, setOuterSorted] = useState({});
  const [innerSimilar, setInnerSimilar] = useState({});
  const [outerSimilar, setOuterSimilar] = useState({});
  const [innerAll, setInnerAll] = useState(false);
  const [outerAll, setOuterAll] = useState(false);
  const [innerDisabled, setInnerDisabled] = useState(false);
  const [outerDisabled, setOuterDisabled] = useState(false);

  useEffect(() => {
    const inner_sort = {};
    const inner_sim = {};
    const outer_sort = {};
    const outer_sim = {};

    const {inner_similar, outer_similar} = sortTags(inner_tags, outer_tags);

    inner_tags?.forEach(tag => {
      const obj = tagToObject(tag);
      if (obj) {
        if (inner_sort[obj.type] === undefined) {
          inner_sort[obj.type] = [];
        }
        inner_sort[obj.type].push(tag);
      }
    });
    outer_tags?.forEach(tag => {
      const obj = tagToObject(tag);
      if (obj) {
        if (outer_sort[obj.type] === undefined) {
          outer_sort[obj.type] = [];
        }
        outer_sort[obj.type].push(tag);
      }
    });

    inner_similar.forEach(tag => {
      const obj = tagToObject(tag);
      if (obj) {
        if (inner_sim[obj.type] === undefined) {
          inner_sim[obj.type] = [];
        }
        inner_sim[obj.type].push(tag);
      }
    });

    outer_similar.forEach(tag => {
      const obj = tagToObject(tag);
      if (obj) {
        if (outer_sim[obj.type] === undefined) {
          outer_sim[obj.type] = [];
        }
        outer_sim[obj.type].push(tag);
      }
    });
    const inner_simi = !Object.keys(inner_sort).length;
    const outer_simi = !Object.keys(outer_sort).length;
    const no_inner_sim = Object.keys(inner_sim).length <= 0;
    const no_outer_sim = Object.keys(outer_sim).length <= 0;

    setInnerAll(inner_simi);
    setOuterAll(outer_simi);
    setInnerSorted(inner_sort);
    setInnerSimilar(inner_sim);
    setInnerDisabled(no_inner_sim);
    setOuterDisabled(no_outer_sim);
    setOuterSorted(outer_sort);
    setOuterSimilar(outer_sim);
  }, [inner_tags, outer_tags]);

  return (
    <div>
      {type === 'inner' && (
        <Tags
          all={innerDisabled ? true : innerAll}
          disabled={innerDisabled}
          setAll={setInnerAll}
          sorted={innerSorted}
          similar={innerSimilar}
          header="General Resources"
        />
      )}
      {type === 'outer' && (
        <Tags
          all={outerDisabled ? true : outerAll}
          disabled={outerDisabled}
          setAll={setOuterAll}
          sorted={outerSorted}
          similar={outerSimilar}
          header="General Requests"
        />
      )}
    </div>
  );
};
const Tags = ({disabled, all, setAll, sorted, similar, header}) => {
  const {prettyTag, prettyHeader} = useStringFormatter();

  const fields = Object.keys(all ? sorted : similar);

  return (
    <div className="card">
      <div className="flex-row align-center justify-between">
        <div className="flex-column">
          <h4>{header}</h4>
          {!disabled ? (
            <p className="text-left text-secondary">
              {all ? 'Showing all tags' : 'Only showing tags in common.'}
            </p>
          ) : (
            <p className="text-left text-secondary">
              Member's priorities are tagged; only request meetings that align.
            </p>
          )}
        </div>
        {!disabled && (
          <LinkButton
            onClick={() => {
              setAll(!all);
            }}>
            {all ? 'Hide All' : 'See All'}
          </LinkButton>
        )}
      </div>
      {fields.map(field => {
        const specific_tags = all ? sorted[field] : similar[field];
        return (
          <div key={field}>
            <p className="text-left text-bold">{prettyHeader(field)}</p>
            <div className="flex-row-wrap">
              {specific_tags?.length &&
                specific_tags.map(tag => {
                  return (
                    <div key={tag} className="bubble-secondary">
                      {prettyTag(tag)}
                    </div>
                  );
                })}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export const PreviewField = ({header, content}) => {
  return (
    <div className="display-container">
      <p className="text-bold text-left">{header}: </p>
      <p className="text-secondary text-left margin-h8 text-preview">
        {content}
      </p>
    </div>
  );
};

export const DisplayField = ({header, content}) => {
  return (
    <>
      {content && (
        <div className="display-container">
          <p className="text-secondary text-left">{header}: </p>
          <p className="text-bold text-left margin-h8">{content}</p>
        </div>
      )}
    </>
  );
};

export const EmailField = ({email}) => {
  return (
    <>
      {email && (
        <div className="display-container">
          <p className="text-secondary text-left">Email: </p>
          <a href={`mailto:${email}`} className="text-bold text-left margin-h8">
            {email}
          </a>
        </div>
      )}
    </>
  );
};

export const DisplayLink = ({header, link}) => {
  return (
    <>
      {link && (
        <div className="display-container">
          <p className="text-secondary  text-left">{header}: </p>
          <a
            href={link}
            target="_blank"
            rel="noreferrer"
            className="text-bold text-left margin-h8">
            {link}
          </a>
        </div>
      )}
    </>
  );
};

export const DisplayFields = ({header, items}) => {
  return (
    <>
      {!(!Array.isArray(items) || !items.length) && (
        <div className="display-container-array">
          <p className="list-subtitle text-left">{header}</p>
          <div className="bubble-wrap-list">
            {items && items.length
              ? items.map((item, index) => {
                  return (
                    <BubbleDisplay key={index} item={item} active={false} />
                  );
                })
              : null}
          </div>
        </div>
      )}
    </>
  );
};

export const ColoredDisplayFields = ({header, items, name, limit}) => {
  const {findSimilar} = useSorter();
  const options = ['one', 'two', 'three', 'four', 'five'];

  const stringToNumber = str => {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      hash += str.charCodeAt(i);
    }
    return hash;
  };

  const [sorted, setSorted] = useState([...items]);

  useEffect(() => {
    const sort = findSimilar(items, name);
    setSorted(sort);
  }, [items]);

  return (
    <div className="display-container-array">
      <p className="text-bold text-left">{header}:</p>
      <div className="indented-items">
        {sorted && sorted.length
          ? sorted.slice(0, limit || sorted.length).map((item, index) => {
              const hash = stringToNumber(item);
              const random = options[hash % 5];

              return (
                <div key={item} className={`colored-text ${random}`}>
                  <p
                    key={index}
                    className="text-secondary text-left text-inverted">
                    {item}
                  </p>
                </div>
              );
            })
          : null}
      </div>
    </div>
  );
};

export const StatField = ({header, content, children}) => {
  return (
    <div className="stat-display-container">
      <p className="text-secondary">{children || content}</p>
      <p className="text-bold">{header} </p>
    </div>
  );
};

export const ContactInfo = ({contact}) => {
  const {name, title, email, phone_number, college, url} = contact;

  return (
    <div className="card">
      <p className="text-left text-bold">{name}</p>
      {title && (
        <p className="text-left">
          {'\u2022'}{' '}
          {title
            .toLowerCase()
            .replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())}
        </p>
      )}
      {email && (
        <p className="text-left">
          {'\u2022'} {email}
        </p>
      )}
      {phone_number && (
        <p className="text-left">
          {'\u2022'} {phone_number}
        </p>
      )}
    </div>
  );
};

const BubbleDisplay = ({item, active}) => {
  return (
    // <div className='bubble-wrap-list'>
    <div className="bubble-no-interact">
      <p>{item}</p>
    </div>
    // </div>
  );
};
