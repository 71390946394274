import {useFormik} from 'formik';
import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import * as Yup from 'yup';
import '../../App.css';
import {track} from '../../api/analytics';
import ActionPopup from '../../components/action-feedback-popup/action-feedback-popup';
import {PrimaryButton} from '../../components/buttons/buttons';
import constants from '../../components/constants';
import {SideDrawer} from '../../components/drawers/side-drawer';
import {FormikPlacesAutocomplete} from '../../components/inputs/drop-down';
import {
  FormikTextfield,
  MultilineFormikTextfield,
} from '../../components/inputs/textfields';
import {ProfileImageUpload} from '../../components/inputs/uploader';
import {ProfileItem} from '../../components/item-details/profile-item';
import {SpinningIndicator} from '../../components/loading/loading-indicator';
import {useAuth} from '../../hooks/use-auth';
import useLoader from '../../hooks/use-loader';
import {useMounted} from '../../hooks/use-mounted';

const OrganizationSettings = ({}) => {
  const navigate = useNavigate();
  const {
    state: {id, organizations, current_organization},
  } = useAuth();

  const organization = organizations?.[current_organization] ?? {};

  const editable = organization?.members?.includes(id) ?? false;

  const [isPopupVisible, setPopupVisible] = useState({
    on: false,
    message: '',
  });

  return (
    <div className="page-container pretty-form-group">
      <h2>{organization.name}</h2>
      {process.env.NODE_ENV === 'development' && (
        <button
          onClick={() => {
            navigate(`/feed/organizations/detail/${current_organization}`);
          }}>
          VIEW PAGE
        </button>
      )}
      <div className="grid-container">
        <div className="grid-6">
          <OrganizationImage />
          <BasicInfoCard
            editable={editable}
            setPopupVisible={setPopupVisible}
          />
        </div>
        <div className="grid-6">
          <Members setPopupVisible={setPopupVisible} />
        </div>
      </div>
      {isPopupVisible.on && (
        <ActionPopup
          message={isPopupVisible.message}
          setOff={setPopupVisible}
          on={isPopupVisible}
        />
      )}
    </div>
  );
};

const BasicInfoCard = ({editable, setPopupVisible}) => {
  const isMounted = useMounted();
  const {
    state: {id, organizations, current_organization, profiles},
    updateOrganization,
    getProf,
  } = useAuth();

  const organization = organizations?.[current_organization] ?? {};

  const [show, setShow] = useState(false);

  const formik = useFormik({
    initialValues: {
      name: organization?.name ?? '',
      preferred_name: organization?.preferred_name ?? '',
      industries: organization?.industries ?? [],
      states: organization?.states ?? [],
      website: organization?.website ?? '',
      address: organization?.address ?? '',
      bio: organization?.bio ?? '',
      expiration: organization?.expiration ?? '',
      subscription: organization?.subscription ?? '',
    },
    validationSchema: Yup.object({
      website: Yup.string()
        .trim()
        .matches(/^(https?:\/\/)?([\w\d-]+\.)+\/?/, 'Please enter a valid URL'),
    }),
    onSubmit: async (values, helpers) => {
      try {
        const {name, industries, states, website, address, bio} = values;
        const {id: org_id} = organization;
        const query_name = name
          .replace(constants.remove_special_chars_regex, '')
          .toUpperCase();
        const update = {
          id: org_id,
          name,
          query_name,
          industries,
          states,
          website,
          address,
          bio,
        };
        const {success, error} = await updateOrganization(update);

        if (success) {
          setPopupVisible({on: true, message: 'Success!'});
          track('organization_update', {update, source: 'basic_info'});
        }

        if (error) {
          setPopupVisible({
            on: true,
            message: 'Error. Please try again later.',
          });

          helpers.setStatus({success: false});
          helpers.setErrors({submit: error});
          helpers.setSubmitting(false);
        }
      } catch (err) {
        if (isMounted()) {
          setPopupVisible({
            on: true,
            message: 'Error. Please try again later.',
          });
          helpers.setStatus({success: false});
          helpers.setErrors({submit: err.message});
          helpers.setSubmitting(false);
        }
      }
    },
  });

  return (
    <div className="card">
      <h4>Info</h4>
      <div className="flex-row">
        <div className="flex flex-column padding-h8">
          <FormikTextfield
            disabled={!editable}
            formik={formik}
            header="Name"
            placeholder=""
            name="name"
            type="text"
          />
        </div>
        <div className="flex flex-column padding-h8">
          <FormikTextfield
            disabled={!editable}
            formik={formik}
            header="Website"
            placeholder=""
            name="website"
            type="text"
          />
        </div>
      </div>
      <div className="padding-h8">
        <FormikPlacesAutocomplete
          formik={formik}
          header="Location"
          placeholder=""
          name="address"
          type="text"
        />
        <MultilineFormikTextfield
          formik={formik}
          header="Organization Description"
          placeholder=""
          name="bio"
          type="text"
        />
      </div>

      {editable && (
        <div className="flex justify-center">
          <PrimaryButton
            type="submit"
            onClick={() => {
              formik.handleSubmit();
            }}>
            SAVE
          </PrimaryButton>
        </div>
      )}
      <SideDrawer show={show} setShow={setShow}>
        <p>HERE</p>
      </SideDrawer>
    </div>
  );
};

const OrganizationImage = () => {
  return (
    <div className="flex justify-center">
      <ProfileImageUpload
        types={constants.image_mime_types}
        type="organization"
      />
    </div>
  );
};

const Members = ({setPopupVisible}) => {
  const {
    state: {id, organizations, current_organization, profiles},
  } = useAuth();
  const {loadProfiles} = useLoader();

  const organization = organizations?.[current_organization] ?? {};
  const {members} = organization;

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const fetchProfs = async () => {
      setLoading(true);
      await loadProfiles(members);
      setLoading(false);
    };
    fetchProfs();
  }, [members]);

  return (
    <div className="card">
      <h4>Team</h4>
      {loading ? (
        <SpinningIndicator />
      ) : (
        <div>
          {!!members?.length &&
            members.map(id => {
              return (
                <ProfileItem key={id} type="submit" item={profiles[id] || {}} />
              );
            })}
        </div>
      )}
    </div>
  );
};

export default OrganizationSettings;
