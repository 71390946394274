import React, {useState} from 'react';
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookMessengerShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterIcon,
  TwitterShareButton,
} from 'react-share';
import './styles.css';

export const PrimaryButton = props => {
  const [loading, setLoading] = useState(false);
  const {children, onClick} = props;

  return <button {...props}>{children}</button>;
};

export const SecondaryButton = props => {
  const [loading, setLoading] = useState(false);
  const {children, onClick} = props;

  return (
    <button className="secondary-button" {...props}>
      {children}
    </button>
  );
};

export const LinkButton = props => {
  const {children, start_icon, end_icon, className, ...rest} = props;
  return (
    <button className={`link-button ${className ? className : null}`} {...rest}>
      {start_icon && start_icon}
      {children}
      {end_icon && end_icon}
    </button>
  );
};

export const ConnectButton = props => {
  const {children, start_icon, end_icon, className, ...rest} = props;
  return (
    <button
      className={`connect-button ${className ? className : null}`}
      {...rest}>
      {start_icon && start_icon}
      {children}
      {end_icon && end_icon}
    </button>
  );
};

export const SkipButton = props => {
  const {children, start_icon, end_icon, className, ...rest} = props;
  return (
    <button className={`skip-button ${className ? className : null}`} {...rest}>
      {start_icon && start_icon}
      {children}
      {end_icon && end_icon}
    </button>
  );
};

export const IgnoreButton = props => {
  const {children, start_icon, end_icon, className, ...rest} = props;
  return (
    <button
      className={`error-button ${className ? className : null}`}
      {...rest}>
      {start_icon && start_icon}
      {children}
      {end_icon && end_icon}
    </button>
  );
};

export const InvisibleButton = props => {
  const {children, start_icon, end_icon} = props;
  return (
    <button className="invisible-button" {...props}>
      {start_icon && start_icon}
      {children}
      {end_icon && end_icon}
    </button>
  );
};

export const HeaderButton = props => {
  const {children, start_icon, end_icon} = props;
  return (
    <button className="header-button" {...props}>
      {start_icon && start_icon}
      {children}
      {end_icon && end_icon}
    </button>
  );
};

export const ErrorButton = props => {
  const {children, start_icon, end_icon} = props;
  return (
    <button className="error-button" {...props}>
      {start_icon && start_icon}
      {children}
      {end_icon && end_icon}
    </button>
  );
};

export const IconButton = props => {
  const {children, active, ...rest} = props;

  return (
    <button
      className={`icon-button ${active ? 'active-icon' : null}`}
      {...rest}>
      {children}
    </button>
  );
};

export const EyeButton = props => {
  const {children} = props;
  return (
    <button className="eye-button" {...props}>
      {children}
    </button>
  );
};

export const AIEmailAssistantCopyButton = ({email, setPopupVisible}) => {
  const [copySuccess, setCopySuccess] = useState('');

  const copyToClipboard = () => {
    navigator.clipboard
      .writeText(email)
      .then(() => {
        setPopupVisible({
          on: true,
          message: `Copied ${email} to clipboard.`,
        });
      })
      .catch(err => {
        setPopupVisible({
          on: true,
          message: 'Failed to copt email to clipboard.',
        });
        console.error('Failed to copy text: ', err);
      });
  };

  return (
    <div>
      <button onClick={copyToClipboard}>Copy Email to Clipboard for BCC</button>
    </div>
  );
};

export const EmailShare = props => {
  return (
    <EmailShareButton {...props}>
      <div className="share-button">
        <EmailIcon size={32} round={true} />
        <p className="margin-h8 text-14 text-inverted">Share with Email</p>
      </div>
    </EmailShareButton>
  );
};

export const LinkedInShare = props => {
  return (
    <LinkedinShareButton {...props}>
      <div className="share-button">
        <LinkedinIcon size={32} round={true} />
        <p className="margin-h8 text-14 text-inverted">Share to LinkedIn</p>
      </div>
    </LinkedinShareButton>
  );
};

export const TwitterShare = props => {
  return (
    <TwitterShareButton {...props}>
      <div className="share-button">
        <TwitterIcon size={32} round={true} />
        <p className="margin-h8 text-14 text-inverted">Share to Twitter</p>
      </div>
    </TwitterShareButton>
  );
};

export const FacebookShare = props => {
  return (
    <FacebookMessengerShareButton {...props}>
      <div className="share-button">
        <FacebookIcon size={32} round={true} />
        <p className="margin-h8 text-14 text-inverted">Share to FaceBook</p>
      </div>
    </FacebookMessengerShareButton>
  );
};
