import React, {useState} from 'react';
import {AuthHeader} from '../../components/layout/layout';
import {useAuth} from '../../hooks/use-auth';

const PaymentPending = ({}) => {
  const {
    state: {profile, organizations},
  } = useAuth();

  const [org, setOrg] = useState(null);
  const calendlyLink =
    'https://calendly.com/jack-publicmind/publicmind-membership-discussion';

  return (
    <div className="flex-column page-container justify-center align-center">
      <AuthHeader
        header="Membership Pending"
        subheader={`It looks like the payment for ${org}'s subscription is
          still pending.`}
      />
      <div className="card-minimal half-width text-center padding-16">
        <p className="text-bold">
          Schedule a meeting with the PublicMind team{' '}
          <a href={calendlyLink} target="_blank" rel="noopener noreferrer">
            here
          </a>{' '}
          to move forward
        </p>
        <br />
        <p className="text-secondary">
          <strong>Need help?</strong> Reach out to us via the black circle in
          the bottom right of your screen
        </p>
      </div>
    </div>
  );
};

export default PaymentPending;
