import React, {useState} from 'react';
import {ProfileImage} from '../../../components/inputs/uploader';
import {useAuth} from '../../../hooks/use-auth';
import './MentionedUsers.css';

const MentionedUsers = ({mentions}) => {
  const {
    state: {profiles},
  } = useAuth();
  const [isHovered, setIsHovered] = useState(false);

  if (!mentions || mentions.length === 0) return null;

  const displayedUsers = mentions.slice(0, 3);
  const remainingCount = mentions.length - 3;

  return (
    <div
      className={`mentioned-users ${isHovered ? 'expanded' : ''}`}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}>
      <div className="mentioned-users-preview">
        {displayedUsers.map((userId, index) => {
          const user = profiles[userId] || {};
          return (
            <ProfileImage
              key={userId}
              data={user.profile_image}
              style={{
                height: '24px',
                width: '24px',
                marginLeft: index > 0 ? '-8px' : '0',
              }}
            />
          );
        })}
        {remainingCount > 0 && (
          <div className="mentioned-users-count">+{remainingCount}</div>
        )}
      </div>
      {isHovered && (
        <div className="mentioned-users-expanded">
          <p className="text-bold text-left border-bottom">
            Associated Members
          </p>
          {mentions.map(userId => {
            const user = profiles[userId] || {};
            const {first_name, last_name, profile_image} = user;
            return (
              <div key={userId} className="mentioned-user">
                <ProfileImage
                  data={profile_image}
                  style={{height: '24px', width: '24px'}}
                />
                <span className="mentioned-user-name">{`${first_name} ${last_name}`}</span>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default MentionedUsers;
