const dayjs = require('dayjs');
const parser = require('../parser');
const ParsedResult = require('../../result').ParsedResult;
const util  = require('../../utils/DE');

const PATTERN = new RegExp('(\\W|^)' +
        '(?:am\\s*?)?' +
        '(?:(Sonntag|Montag|Dienstag|Mittwoch|Donnerstag|Freitag|Samstag|So|Mo|Di|Mi|Do|Fr|Sa)\\s*,?\\s*)?' +
        '(?:den\\s*)?' +
        '([0-9]{1,2})\\.' +
        '(?:\\s*(?:bis(?:\\s*(?:am|zum))?|\\-|\\–|\\s)\\s*([0-9]{1,2})\\.)?\\s*' +
        '(Jan(?:uar|\\.)?|Feb(?:ruar|\\.)?|Mär(?:z|\\.)?|Maerz|Mrz\\.?|Apr(?:il|\\.)?|Mai|Jun(?:i|\\.)?|Jul(?:i|\\.)?|Aug(?:ust|\\.)?|Sep(?:t|t\\.|tember|\\.)?|Okt(?:ober|\\.)?|Nov(?:ember|\\.)?|Dez(?:ember|\\.)?)' +
        '(?:' +
            ',?\\s*([0-9]{1,4}(?![^\\s]\\d))' +
            '(\\s*[vn]\\.?\\s*C(?:hr)?\\.?)?' +
        ')?' +
        '(?=\\W|$)', 'i'
    );

const WEEKDAY_GROUP = 2;
const DATE_GROUP = 3;
const DATE_TO_GROUP = 4;
const MONTH_NAME_GROUP = 5;
const YEAR_GROUP = 6;
const YEAR_BE_GROUP = 7;

exports.Parser = function DEMonthNameLittleEndianParser(){
    parser.Parser.apply(this, arguments);

    this.pattern = function() { return PATTERN; }

    this.extract = function(text, ref, match, opt){

        const result = new ParsedResult({
            text: match[0].substr(match[1].length, match[0].length - match[1].length),
            index: match.index + match[1].length,
            ref: ref,
        });

        var month = match[MONTH_NAME_GROUP];
        month = util.MONTH_OFFSET[month.toLowerCase()];

        var day = match[DATE_GROUP];
        day = parseInt(day);

        var year = null;
        if (match[YEAR_GROUP]) {
            year = match[YEAR_GROUP];
            year = parseInt(year);

            if(match[YEAR_BE_GROUP]){
                if (/v/i.test(match[YEAR_BE_GROUP])) {
                    // v.Chr.
                    year = -year;
                }
            } else if (year < 100){

                year = year + 2000;
            }
        }

        if(year){
            result.start.assign('day', day);
            result.start.assign('month', month);
            result.start.assign('year', year);
        } else {
            year = parser.findYearClosestToRef(ref, day, month);
            result.start.assign('day', day);
            result.start.assign('month', month);
            result.start.imply('year', year);
        }

        // Weekday component
        if (match[WEEKDAY_GROUP]) {
            var weekday = match[WEEKDAY_GROUP];
            weekday = util.WEEKDAY_OFFSET[weekday.toLowerCase()]
            result.start.assign('weekday', weekday);
        }

        // Text can be 'range' value. Such as '12 - 13 January 2012'
        if (match[DATE_TO_GROUP]) {
            result.end = result.start.clone();
            result.end.assign('day', parseInt(match[DATE_TO_GROUP]));
        }

        result.tags['DEMonthNameLittleEndianParser'] = true;
        return result;
    };
}
