import React, {useEffect, useMemo, useState} from 'react';
import {RxChevronLeft} from 'react-icons/rx';
import {useNavigate, useParams} from 'react-router-dom';
import {track} from '../../../api/analytics';
import {formatMMDDYYYY} from '../../../api/dates';
import {ProfileImage} from '../../../components/inputs/uploader';
import {SpinningIndicator} from '../../../components/loading/loading-indicator';
import {useAuth} from '../../../hooks/use-auth';
import useCrypto from '../../../hooks/use-crypto';
import useLoader from '../../../hooks/use-loader';
import useStringFormatter from '../../../hooks/use-string-formatter';
import {useThreads} from '../../../hooks/use-threads';
import './ThreadAdminPanel.css';

const ThreadAdminPanel = () => {
  const navigate = useNavigate();
  const {id} = useParams();
  const {loadProfiles} = useLoader();
  const {
    state: {id: user_id},
  } = useAuth();
  const {
    state: {
      threads,
      thread_content,
      content_ids,
      thread_subs_other,
      thread_subs,
    },
  } = useThreads();

  const [domains, setDomains] = useState([]);
  const [userStats, setStats] = useState({});
  const [subs, setSubs] = useState({});
  const [loading, setLoading] = useState(false);

  const thread = useMemo(() => threads?.[id] ?? {}, [id, threads]);
  const nested_content = content_ids?.[id] ?? [];
  const {permissions, members} = thread;
  const sub_ids = thread_subs_other?.[id] ?? [];

  useEffect(() => {
    loadProfiles(members);
  }, [members]);

  useEffect(() => {
    const load = async () => {
      const domain = {};

      permissions?.forEach(permission => {
        const {user_id} = permission;
        if (!user_id) return;
        const [username, ending] = user_id?.split('@');
        if (domain[ending] === undefined) {
          domain[ending] = {domain: ending, ids: []};
        }
        domain[ending].ids.push(user_id);
      });
      const dms = Object.values(domain).sort(
        (a, b) => b?.ids?.length - a?.ids?.length,
      );
      setDomains(dms);
    };
    load();
  }, [permissions]);

  useEffect(() => {
    const stats = {};

    const content = Object.keys(nested_content);

    content?.forEach(content_id => {
      const content = thread_content?.[content_id] ?? {};
      const children = nested_content[content_id];
      const {owner_id, media, reactions} = content;

      if (stats[owner_id] === undefined) {
        stats[owner_id] = {comments: 0, replies: 0, media: 0, reactions: 0};
      }
      if (media?.length) {
        stats[owner_id].media += media?.length;
      }
      stats[owner_id].comments += 1;

      reactions?.forEach(reaction => {
        const {user_id} = reaction;
        if (stats[user_id] === undefined) {
          stats[user_id] = {comments: 0, replies: 0, media: 0, reactions: 0};
        }

        stats[user_id].reactions += 1;
      });

      children.forEach(child_id => {
        const content = thread_content?.[child_id] ?? {};
        const {owner_id, media, reactions} = content;

        if (stats[owner_id] === undefined) {
          stats[owner_id] = {comments: 0, replies: 0, media: 0, reactions: 0};
        }
        if (media?.length) {
          stats[owner_id].media += media?.length;
        }
        stats[owner_id].replies += 1;

        reactions?.forEach(reaction => {
          const {user_id} = reaction;
          if (stats[user_id] === undefined) {
            stats[user_id] = {
              comments: 0,
              replies: 0,
              media: 0,
              reactions: 0,
            };
          }

          stats[user_id].reactions += 1;
        });
      });
    });
    setStats(stats);
  }, [content_ids?.[id]]);

  useEffect(() => {
    const sub = {[user_id]: thread_subs[id]};
    sub_ids?.forEach(id => {
      const s = thread_subs?.[id] ?? {};
      const {user_id} = s;
      sub[user_id] = s;
    });
    setSubs(sub);
  }, [thread_subs_other?.[id]]);

  useEffect(() => {
    if (thread) {
      track('admin_panel_viewed', {
        thread_id: thread?.id,
        thread_title: thread?.title,
        member_count: thread?.members?.length ?? 1,
      });
    }
  }, [thread]);

  return (
    <div className="page-container">
      <div className="thread-admin-panel">
        <div className="thread-admin-panel__header">
          <h1 className="thread-admin-panel__title">
            <RxChevronLeft
              className="clickable"
              onClick={() => {
                navigate(`/threads/${thread?.id}`);
              }}
            />{' '}
            {thread?.logo} {thread?.title} - Activity Panel
          </h1>
          <p className="thread-admin-panel__subtitle">
            Thread member statistics grouped by domain
          </p>
        </div>
        {loading ? (
          <SpinningIndicator />
        ) : (
          domains.map(domain => (
            <DomainCard
              key={domain.domain}
              domain={domain}
              userStats={userStats}
              subs={subs}
            />
          ))
        )}
      </div>
    </div>
  );
};

const DomainCard = ({domain, userStats, subs}) => {
  const {prettyName} = useStringFormatter();
  const {
    state: {profiles},
  } = useAuth();

  return (
    <div className="domain-card">
      <div className="domain-card__header">
        <h2 className="domain-card__title">{domain.domain}</h2>{' '}
        <p className="text-secondary text-12">Member Email Domain Name</p>
      </div>
      <table className="domain-table ">
        <thead className="domain-table__header">
          <tr>
            <th className="domain-table__header-cell">Name</th>
            {/* <th className="domain-table__header-cell">Date Joined</th> */}
            <th className="domain-table__header-cell">Last Active</th>
            <th className="domain-table__header-cell">Comments</th>
            <th className="domain-table__header-cell">Replies</th>
            <th className="domain-table__header-cell">Attachments</th>
            <th className="domain-table__header-cell">Reactions</th>
          </tr>
        </thead>
        <tbody>
          {domain.ids.map(id => (
            <UserRow
              key={id}
              id={id}
              profiles={profiles}
              prettyName={prettyName}
              userStats={userStats}
              subs={subs}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
};

const UserRow = ({id, profiles, prettyName, userStats, subs}) => {
  const profile = profiles?.[id] ?? {};
  const {signup_timestamp, profile_image} = profile;
  const pretty_name = prettyName(profile);
  const created = signup_timestamp
    ? formatMMDDYYYY(new Date(signup_timestamp * 1000))
    : 'N/A';
  const {encryptString} = useCrypto();
  const navigate = useNavigate();

  const stats = userStats?.[id] ?? {};
  const sub = subs?.[id] ?? {};
  const {last_read} = sub;
  const active = last_read ? formatMMDDYYYY(new Date(last_read * 1000)) : 'N/A';

  const isActive = last_read && Date.now() / 1000 - last_read < 86400;
  return (
    <tr className="domain-table__body-row">
      <td className="domain-table__body-cell">
        <span className="flex-row  align-center justify-center">
          <ProfileImage
            data={profile_image}
            style={{height: '20px', width: '20px'}}
            onClick={() => {
              const parsed = encryptString(profile?.id);
              navigate(`/feed/profiles/detail/${parsed}`);
            }}
          />{' '}
          <span className="padding-right4" />
          {pretty_name || 'N/A'}
        </span>
      </td>
      {/* <td className="domain-table__body-cell domain-table__date">{created}</td> */}
      <td className="domain-table__body-cell">
        <span className="domain-table__date">{active}</span>
        <span
          className={`badge-admin-panel ${
            isActive
              ? 'badge-admin-panel--active'
              : 'badge-admin-panel--inactive'
          }`}>
          {isActive ? 'Active' : 'Inactive'}
        </span>
      </td>
      <td className="domain-table__body-cell domain-table__stat">
        {stats?.comments ?? 0}
      </td>
      <td className="domain-table__body-cell domain-table__stat">
        {stats?.replies ?? 0}
      </td>
      <td className="domain-table__body-cell domain-table__stat">
        {stats?.media ?? 0}
      </td>
      <td className="domain-table__body-cell domain-table__stat">
        {stats?.reactions ?? 0}
      </td>
    </tr>
  );
};

export default ThreadAdminPanel;
