import {createReactBlockSpec} from '@blocknote/react';
import {Extension} from '@tiptap/core';
import React from 'react';
import {HiOutlineMinus} from 'react-icons/hi';
// Define regex patterns for thread and content URLs
const THREAD_URL_REGEX = new RegExp(
  `${window.location.origin}/threads/([a-zA-Z0-9-]+)$`,
  'g',
);
const CONTENT_URL_REGEX = new RegExp(
  `${window.location.origin}/threads/([a-zA-Z0-9-]+)/([a-zA-Z0-9-]+)$`,
  'g',
);

// Create a Tiptap extension for all our transformations
const createBlockTransformers = (threads, thread_content) =>
  Extension.create({
    name: 'blockTransformers',

    addInputRules() {
      return [
        // Divider rule stays the same
        {
          find: /^---\s$/,
          handler: ({state, match, range}) => {
            const {tr, schema, selection} = state;
            const {$from} = selection;
            const pos = $from.before();
            const end = $from.after();
            const dividerNode = schema.nodes.divider.create();
            tr.delete(range.from, range.to - 1);
            tr.replaceWith(pos, end, dividerNode);
            tr.setSelection(
              state.selection.constructor.near(tr.doc.resolve(pos + 1)),
            );
            return tr;
          },
        },
      ];
    },

    addPasteRules() {
      return [
        // Thread link rule
        {
          find: THREAD_URL_REGEX,
          handler: ({state, match, range}) => {
            try {
              const thread_id = match[1];
              const thread = threads[thread_id];
              if (!thread || thread.security_level === 'secure') {
                return null;
              }

              // Create a new transaction
              const {tr} = state;

              // Delete the pasted URL
              tr.delete(range.from, range.to);

              // Insert reference node
              const referenceNode = state.schema.nodes.reference.create({
                item: thread,
                threads: threads,
                thread_content: thread_content,
              });

              tr.insert(range.from, referenceNode);
              tr.insertText(' ', range.from + 1);

              return tr;
            } catch (error) {
              console.error('Error processing thread link:', error);
              return null;
            }
          },
        },
        // Thread content link rule
        {
          find: CONTENT_URL_REGEX,
          handler: ({state, match, range}) => {
            try {
              const thread_id = match[1];
              const content_id = match[2];
              const thread = threads[thread_id];
              const content = thread_content[content_id];
              // Only convert if user has access to both thread and content
              if (!thread || !content || thread.security_level === 'secure') {
                return null;
              }

              // Create a new transaction
              const {tr} = state;

              // Delete the pasted URL
              tr.delete(range.from, range.to);

              // Insert reference node
              const referenceNode = state.schema.nodes.reference.create({
                item: content,
                threads: threads,
                thread_content: thread_content,
              });

              tr.insert(range.from, referenceNode);
              tr.insertText(' ', range.from + 1);

              return tr;
            } catch (error) {
              console.error('Error processing content link:', error);
              return null;
            }
          },
        },
      ];
    },
  });

// Modified setup function that takes threads and content as parameters

export const setupBlocks = (
  options = {},
  threads = {},
  thread_content = {},
) => {
  return {
    ...options,
    schema: {
      ...options.schema,
      blockSpecs: {
        ...(options.schema?.blockSpecs || {}),
        divider: Divider,
      },
      nodes: {
        ...options.schema.nodes,
        reference: {
          inline: true,
          group: 'inline',
          attrs: {
            item: {default: null},
          },
          parseDOM: [
            {
              tag: 'span[data-reference]',
              getAttrs: dom => ({
                item: JSON.parse(dom.getAttribute('data-reference')),
              }),
            },
          ],
          toDOM: node => [
            'span',
            {
              'data-reference': JSON.stringify(node.attrs.item),
            },
          ],
        },
      },
    },
    _tiptapOptions: {
      ...(options._tiptapOptions || {}),
      extensions: [
        ...(options._tiptapOptions?.extensions || []),
        createBlockTransformers(threads, thread_content),
      ],
    },
  };
};

// Keep existing Divider component definition
export const Divider = createReactBlockSpec(
  {
    type: 'divider',
    propSchema: {},
    content: 'none',
  },
  {
    render: () => {
      return (
        <hr
          style={{
            borderTop: '1px solid #00000',
            margin: '8px 0',
            width: '100%',
          }}
        />
      );
    },
  },
);

// Keep existing divider slash command item
export const insertDividerLineItem = editor => ({
  key: 'divider',
  title: 'Insert Divider Line',
  onItemClick: () => {
    const currentBlock = editor.getTextCursorPosition().block;
    const dividerBlock = {
      type: 'divider',
    };
    editor.insertBlocks([dividerBlock], currentBlock, 'after');
  },
  aliases: ['divider', 'hr', 'line'],
  group: 'Others',
  icon: React.createElement(HiOutlineMinus, {size: 18}),
  subtext: 'Insert a horizontal divider line.',
});
