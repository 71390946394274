import {filterSuggestionItems} from '@blocknote/core';
import '@blocknote/core/fonts/inter.css';
import {BlockNoteView} from '@blocknote/mantine';
import '@blocknote/mantine/style.css';
import {
  getDefaultReactSlashMenuItems,
  SuggestionMenuController,
} from '@blocknote/react';
import React, {useEffect, useRef, useState} from 'react';
import {insertDividerLineItem} from '../../../components/blocks/divider';
import constants from '../../../components/constants';
import {blockInputTheme} from '../../../components/inputs/block-input';
import {MultipleFileUploaderSimple} from '../../../components/inputs/uploader';
import {useAuth} from '../../../hooks/use-auth';
import useStringFormatter from '../../../hooks/use-string-formatter';
import {useThreads} from '../../../hooks/use-threads';
import './BlockNote.css';
import './ChatInput.css';

// THE INPUT FIELD USED FOR COMMENTS AND REPLIES
export const ContentInputField = React.memo(
  ({
    formik,
    editor,
    thread_id,
    inputRef,
    isExpanded,
    setIsExpanded,
    showFileUploader,
    files,
    setFiles,
    onKeyDown,
    isFullScreen,
  }) => {
    const {prettyName} = useStringFormatter();
    const {
      state: {threads, content_ids, thread_content, template_ids, templates},
    } = useThreads();
    const {
      state: {profiles},
    } = useAuth();
    const {members} = threads?.[thread_id] ?? {};
    const containerRef = useRef(null);

    // Get current theme from localStorage
    const getCurrentTheme = () => {
      const savedTheme = localStorage.getItem('theme');
      return savedTheme === 'dark'
        ? blockInputTheme.dark
        : blockInputTheme.light;
    };

    // State to track current theme
    const [currentTheme, setCurrentTheme] = useState(getCurrentTheme());

    // Effect to update theme when localStorage changes
    useEffect(() => {
      const handleStorageChange = () => {
        setCurrentTheme(getCurrentTheme());
      };

      window.addEventListener('storage', handleStorageChange);

      // Also listen for local changes
      const localStorageCheck = setInterval(() => {
        const newTheme = getCurrentTheme();
        if (newTheme !== currentTheme) {
          setCurrentTheme(newTheme);
        }
      }, 100);

      return () => {
        window.removeEventListener('storage', handleStorageChange);
        clearInterval(localStorageCheck);
      };
    }, [currentTheme]);

    const users = members?.map(email => {
      const pretty = prettyName(profiles?.[email] ?? {email: email, id: email});
      return {name: pretty, email};
    });

    const getMentionMenuItems = editor => {
      return users.map(user => ({
        title: user.name,
        onItemClick: () => {
          editor.insertInlineContent([
            {
              type: 'mention',
              props: {
                user: user.name,
                email: user.email,
              },
            },
            ' ',
          ]);
        },
      }));
    };

    const getTemplateMenuItems = editor => {
      return template_ids.map(t_id => {
        const template = templates[t_id];
        return {
          title: template.name,
          onItemClick: e => {
            const position = editor.getTextCursorPosition();
            const blocks = JSON.parse(template.content);

            const {block, prevBlock, nextBlock} = position;

            if (!block?.content?.length && !block?.children?.length) {
              editor.replaceBlocks([block], blocks);
            } else {
              editor.insertBlocks(blocks, position.block, 'after');
            }
          },
          // Optional: Add an icon for the template
          // icon: <DocumentIcon />,
        };
      });
    };

    const getReferenceMenuItems = editor => {
      const getContentTypePrefix = type => {
        if (type === 'decision_point') return '[DECISION POINT]';
        return `[${type.toUpperCase()}] `;
      };

      // Get current thread_id from formik
      const currentThreadId = formik.values.thread_id;

      // Get referenceable content from current thread
      const currentThreadContent = currentThreadId
        ? Object.keys(content_ids[currentThreadId] || {})
            .map(id => thread_content[id])
            .filter(content => content)
        : [];

      // Get referenceable threads (excluding secure ones)
      const referencableThreads = Object.values(threads)
        .filter(thread => thread.security_level !== 'secure')
        .map(thread => ({
          type: 'thread',
          title: `${thread.type === 'sub-thread' ? '[BREAKOUT] ' : ''}${
            thread.logo
          } ${thread.title}`,
          item: thread,
        }));

      // Format content items for reference
      const referencableContent = currentThreadContent
        .map(content => {
          let preview = '';
          try {
            const contentObj = JSON.parse(content.content);
            if (contentObj[0]?.content?.[0]?.text) {
              preview = contentObj[0].content[0].text.slice(0, 50) + '...';
            } else if (contentObj[0]?.content?.[0]?.type === 'link') {
              preview =
                contentObj[0].content[0]?.content[0].text.slice(0, 50) + '...';
            }
          } catch (e) {
            preview = content.content.slice(0, 50) + '...';
          }

          if (!preview?.trim()) return null;

          return {
            type: 'content',
            title: `${getContentTypePrefix(content.type)}: ${preview}`,
            item: content,
          };
        })
        .filter(Boolean);

      // Combine both types of references
      const allReferenceable = [...referencableThreads, ...referencableContent];

      return allReferenceable.map(ref => ({
        title: ref.title,
        onItemClick: () => {
          editor.insertInlineContent([
            {
              type: 'reference',
              props: {
                item: ref.item,
              },
            },
            ' ',
          ]);
        },
      }));
    };

    const handleDragOver = e => {
      e.preventDefault();
      e.stopPropagation();
      containerRef.current.classList.add('dragover');
      setIsExpanded(true);
    };

    const handleDragLeave = e => {
      e.preventDefault();
      e.stopPropagation();
      containerRef.current.classList.remove('dragover');
    };

    const handleDrop = e => {
      e.preventDefault();
      e.stopPropagation();
      containerRef.current.classList.remove('dragover');

      if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
        setFiles(prevFiles => [...prevFiles, ...e.dataTransfer.files]);
      }
    };

    useEffect(() => {
      setIsExpanded(false);
    }, [setIsExpanded, thread_id]);

    return (
      <div
        ref={containerRef}
        className="reply-popup-container"
        onClick={() => setIsExpanded(true)}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}>
        <div
          style={{
            height: isFullScreen ? '100%' : isExpanded ? '300px' : '25px',
            overflowY: 'scroll',
            transition: 'height 0.3s ease',
          }}>
          <BlockNoteView
            editor={editor}
            slashMenu={false}
            theme={currentTheme}
            onKeyDown={event => {
              if ((event.metaKey || event.ctrlKey) && event.key === 'Enter') {
                event.preventDefault();
                event.stopPropagation();
                const blocks = editor.document;

                if (blocks.length > 0) {
                  const lastBlock = blocks[blocks.length - 1];

                  if (lastBlock.content && Array.isArray(lastBlock.content)) {
                    const textItems = lastBlock.content.filter(
                      item => item.type === 'text',
                    );

                    if (textItems.length > 0) {
                      const lastTextItem = textItems[textItems.length - 1];

                      if (
                        lastTextItem.text &&
                        lastTextItem.text.endsWith('\n')
                      ) {
                        lastTextItem.text = lastTextItem.text.replace(
                          /\n$/,
                          '',
                        );

                        editor.updateBlock(lastBlock, {
                          ...lastBlock,
                          content: lastBlock.content.map(item =>
                            item === textItems[textItems.length - 1]
                              ? lastTextItem
                              : item,
                          ),
                        });
                      }
                    }
                  }
                }

                onKeyDown(event);
                return false;
              }
            }}
            onChange={async () => {
              const stringy = JSON.stringify(editor.document);
              const content_html = await editor.blocksToHTMLLossy();
              const mentions = [];
              editor.forEachBlock(block => {
                const {content} = block;
                if (!Array.isArray(content)) {
                  return;
                }
                content.forEach(item => {
                  const {type, props} = item;
                  if (
                    item.type === 'mention' &&
                    !mentions?.includes(props.email)
                  )
                    mentions.push(props.email);
                });
              });
              formik.setFieldValue('content', stringy);
              formik.setFieldValue('content_html', content_html);
              formik.setFieldValue('mentions', mentions);
            }}>
            <SuggestionMenuController
              triggerCharacter={'@'}
              getItems={async query =>
                filterSuggestionItems(getMentionMenuItems(editor), query)
              }
              preventBackgroundScroll={true}
            />
            <SuggestionMenuController
              triggerCharacter={'#'}
              getItems={async query =>
                filterSuggestionItems(getReferenceMenuItems(editor), query)
              }
              preventBackgroundScroll={true}
            />
            <SuggestionMenuController
              triggerCharacter={'$'}
              getItems={async query =>
                filterSuggestionItems(getTemplateMenuItems(editor), query)
              }
              preventBackgroundScroll={true}
            />
            <SuggestionMenuController
              triggerCharacter={'/'}
              getItems={async query => {
                const defaultItems = await getDefaultReactSlashMenuItems(
                  editor,
                );
                const dividerItem = insertDividerLineItem(editor);
                return filterSuggestionItems(
                  [...defaultItems, dividerItem],
                  query,
                );
              }}
              preventBackgroundScroll={true}
            />
          </BlockNoteView>
        </div>

        <MultipleFileUploaderSimple
          showFileUploader={showFileUploader}
          files={files}
          setFiles={setFiles}
          types={[
            ...constants.image_mime_types,
            ...constants.video_mime_types,
            ...constants.document_mime_types,
            ...constants.compressed_mime_types,
            ...constants.audio_mime_types,
          ]}
          limit={constants.file_20mb}
        />
      </div>
    );
  },
);
