import {
  downloadData,
  getUrl,
  list,
  remove,
  uploadData,
} from 'aws-amplify/storage';
import {track} from './analytics';

// key - filename, data - file
const uploadFile = async (key, data, options) => {
  try {
    const response = await uploadData({
      key,
      data,
      options: options || {accessLevel: 'public'},
    }).result;

    return response;
  } catch (err) {
    track('api_error', {type: 's3', function: 'uploadData', err});
    throw err;
  }
};

const getFile = async (key, options) => {
  try {
    const response = await downloadData({
      key,
      options: options || {
        accessLevel: 'public',
      },
    }).result;
    return response;
  } catch (err) {
    track('api_error', {type: 's3', function: 'downloadData', err});
    throw err;
  }
};

// example path student-institution/testing.json
const getFileURL = async (key, options) => {
  try {
    const response = await getUrl({
      key,
      options: options || {
        accessLevel: 'public',
        expiresIn: 3600,
      },
    });
    return response;
  } catch (err) {
    track('api_error', {type: 's3', function: 'getUrl', err});
    throw err;
  }
};

// for listing ALL files without prefix, pass '' instead
const listFiles = async (key, options) => {
  try {
    const {results} = await list({
      key,
      options: options || {
        accessLevel: 'public',
      },
    });
    return results;
  } catch (err) {
    track('api_error', {type: 's3', function: 'list', err});
    throw err;
  }
};

const removeFile = async (key, options) => {
  try {
    await remove({
      key,
      options: options || {
        accessLevel: 'public',
      },
    });
  } catch (err) {
    track('api_error', {type: 's3', function: 'remove', err});
    throw err;
  }
};

export {getFile, getFileURL, listFiles, removeFile, uploadFile};
